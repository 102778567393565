import React, {
  useState,
  //  useEffect f
} from "react";
// import { useLocation } from "react-router-dom";
import {
  Box,
  // Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // Link,
} from "@mui/material";
import {
  //  VscGraphLine, VscDeviceCameraVideo,
  VscGraph,
} from "react-icons/vsc";
import { BiUser, BiStore } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { BsChatRightText } from "react-icons/bs";
import {
  MdOutlineContactPage,
  // MdOutlineDocumentScanner,
  MdOutlineSpaceDashboard,
} from "react-icons/md";
import NeoLogo from "../Authservice/images/neo_disha_final_logo.gif";
import Logo from "../Authservice/images/reliance-trends-logo-png.png";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";

const drawerWidth = 260;
const DrawerChildren = (
  <Box
    sx={{
      height: "100vh",
      bgcolor: "background.paper",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <div>
      <nav aria-label="main mailbox folders">
        <List>
          {/* <NavLink to={`/dashboard`} className=""> */}
          <button
            className="w-full px-14"
            type="button"
            onClick={() => window.open("/dashboard", "_self")}
          >
            <img
              src={Logo}
              className="scale-[0.8] scale-y-[2] scale-x-[] w-full h-fit"
              alt="Logo"
            />
          </button>
          {/* </NavLink> */}
          {/* <Box sx={{ paddingY: 2 }}>
            <Divider />
          </Box> */}

          <NavLink to={`/dashboard`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <MdOutlineSpaceDashboard
                    size="1.5em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  className=" text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide  "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={`/analysis`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <VscGraph
                    size="1.5em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Analysis"
                  className="text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={`/stores`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BiStore
                    size="1.5em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Store"
                  className="text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>

          <NavLink to={`/user`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BiUser
                    size="1.5em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="User"
                  className="text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          {/* <NavLink to={`/chatPage`}> */}
          <NavLink to={`/comingSoon`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BsChatRightText
                    size="1.4em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Active Comm."
                  className="text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={`/documentation`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CgFileDocument
                    size="1.6em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Documentation"
                  className="text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to={`/contactUs`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <MdOutlineContactPage
                    size="1.6em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Contact Us"
                  className="text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide "
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </List>
      </nav>
      {/* <Divider /> */}
      {/* <nav aria-label="secondary mailbox folders">
        <List>
          <a
            // href="https://docs.google.com/document/d/1lyMFIWKa44RXQwZ8DOKyGSdqaP_C8uHfKKKc3d_ya3c/edit?usp=sharing"
            href="https://drive.google.com/file/d/1p9nL1VNTdVVCzWRpoE23DAe5MF_REh9x/view"
            target="_blank"
            rel="noreferrer"
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CgFileDocument
                    size="1.5em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText primary="Documentation" />
              </ListItemButton>
            </ListItem>
          </a> */}
      {/* <a
            href="https://neophyte.live/contact"
            target="_blank"
            rel="noreferrer"
          > */}
      {/* <NavLink to={`/contactUs`}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <MdOutlineContactPage
                    size="1.5em"
                    className="text-gray-600 font-medium text-md"
                  />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItemButton>
            </ListItem>
          </NavLink> */}
      {/* </a> */}
      {/* </List>
      </nav> */}
    </div>
    <Box sx={{ alignItems: "center" }}>
      <div className="p-4">
        {/* <p className="text-gray-400 font-medium">
          <span>Version Info:0.01</span>{" "}
        </p> */}
        <div className="w-full">
          <img
            src={NeoLogo}
            className="w-full h-fit"
            alt="Logo"
          />
        </div>
        <p className="font-medium pt-3">Copyright © 2023 NeoPhyte</p>
      </div>
    </Box>
  </Box>
);

const MobileNav = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton
        onClick={() => setOpen((prevState) => !prevState)}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon fontSize="large" className="text-gray-800" />
      </IconButton>

      {/* <Drawer
        open={open}
        onClose={() => setOpen((prevState) => !prevState)}
        
       
      >
       
      </Drawer> */}
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => setOpen((prevState) => !prevState)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {DrawerChildren}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {DrawerChildren}
      </Drawer>
    </div>
  );
};

export default MobileNav;

// https://neophyte.live/contact
