import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { ImCross } from "react-icons/im";
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";
import { HiMenuAlt2 } from "react-icons/hi";
import { BsCardHeading } from "react-icons/bs";
import { FiPaperclip } from "react-icons/fi";
import { RxActivityLog } from "react-icons/rx";
import { DialogContent, Dialog } from "@mui/material";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import { CREATECOMMENTCHAT } from "../../Authservice/api";

export default function ViewChat(props) {
  // pop for image showing
  const [open, setOpen] = useState(false);
  // const [fullWidth] = useState(true);
  // const [maxWidth] = useState("sm");
  const [zoomImage, setZoomImage] = useState(0);

  const handleClose = () => setOpen(false);

  const chatData = props.chatData;
  const allUser = props.userIDs;
  const [
    // commentData,
    setCommentData,
  ] = useState(false);
  const [comment, setComment] = useState("");
  // console.log("edihdy", chatData);
  let idChat = "";
  if (chatData) {
    idChat = chatData._id;
  }

  const users = localStorage.getItem("users");
  // console.log("Useres id", users);
  const userData = JSON.parse(users);
  let userID = "";
  if (userData) {
    userID = userData.data._id;
  }

  // CREATE COMMENT API INTEGREATION
  const [chatDatas, setchatDatas] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setchatDatas((oldChats) => [comment, ...oldChats]);
    // console.log("chatData", chatDatas)
    const datas = {
      usersID: userID,
      comments: comment,
    };
    try {
      const data = await CREATECOMMENTCHAT(datas, idChat);

      setCommentData(data.data);
    } catch (error) {
      console.log(error);
    }
    setComment("");
  };
  // console.log("commentData", commentData);
  // GET THE USER NAME
  if (chatData && allUser) {
    const filterStore = allUser.filter((store) => {
      return store._id === chatData.userID;
    });
    // console.log("filter ", filterStore);
    chatData["user_name"] = filterStore[0].user_name;
    // checkedItems[count]["fullness"] = filterStore[0];
  }
  // console.log("all  user", allUser);
  // FILTER THE COMMENT USER NAME
  // if (chatData && allUser) {
  //   for (let count = 0; count < chatData.comment.length; count++) {
  //     const filterStore = allUser.filter((store) => {
  //       return store._id === chatData.comment[count].usersID;
  //     });
  //     // chatData.comment[count]["user_name"] = filterStore[0].user_name

  //     console.log("filterStorew", chatData.comment[count].usersID);
  //     // checkedItems[count]["fullness"] = filterStore[0];
  //   }
  // }
  const openCardView = (e, index) => {
    e.preventDefault();
    // setCardViewIsActive(!cardViewIsActive);
    setZoomImage(index);
    setOpen(true);
  };

  //!NAVIGATION SCRIPT FOR THE IMAGE VIEWER
  const leftClick = () => {
    // console.log("start Process");
    if (zoomImage > 0) {
      setZoomImage(zoomImage - 1);
    }
  };

  const rightClick = () => {
    // console.log("start Process");
    if (zoomImage < chatData.length - 1) {
      setZoomImage(zoomImage + 1);
    }
  };

  // if (chatData) {
  //   // console.log("image url", chatData.imgUrl[zoomImage]);
  // }

  // console.log("zoomImage", zoomImage);
  // console.log("comments", chatData);
  const month = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "nov",
    "dec",
  ];

  return (
    <div>
      {chatData ? (
        <>
          <div className="w-full flex flex-col space-y-5 m-auto pb-4 md:pb-0">
            <div className="w-full h-12 flex space-x-2 ">
              <Avatar
                alt={chatData.user_name}
                sx={{
                  backgroundColor: "#10b981",
                }}
                src="/static/images/avatar/1.jpg"
              />
              <span
                id="rubikFont"
                className="self-center text-base font-semibold"
              >
                {chatData.user_name}
              </span>
            </div>
            <div className="flex flex-col space-y-2">
              <div
                id="rubikFont"
                className="w-full flex flex-col justify-center "
              >
                <div className="w-full h-12 flex flex-row px-0 md:px-3 font-bold text-gray-600 text-2xl space-x-3">
                  <BsCardHeading className="self-center " />
                  <span className="self-center ">
                    Subject
                    {/* {chatData.title} */}
                  </span>
                </div>
                <div className="text-xl pl-10 md:pl-12">{chatData.title}</div>
              </div>
              <div
                id="rubikFont"
                className="w-full flex flex-col justify-center "
              >
                <div className="w-full h-12 flex flex-row px-0 md:px-3 font-bold text-gray-600 text-2xl space-x-3">
                  <HiMenuAlt2 className="self-center " />
                  <span className="self-center">Description</span>
                </div>
                <div
                  id="description"
                  className="text-sm block md:text-lg pl-10 md:pl-12"
                >
                  {chatData.body}
                </div>
              </div>
              {/* <div id="rubikFont" className="w-full">
                {chatData.body}
              </div> */}
            </div>
            {/* {chatData.imgUrl === [] ? ( */}
            {chatData.imgUrl.length > 0 ? (
              <div className="flex flex-col space-y-2">
                <div
                  id="rubikFont"
                  className="w-full flex flex-col justify-center "
                >
                  <div className="w-full h-12 flex flex-row px-0 md:px-3 font-bold text-gray-600 text-2xl space-x-3">
                    <FiPaperclip className="self-center " />
                    <span className="self-center">Attachments</span>
                  </div>
                </div>
                <div className="w-full h-36 flex flex-row pl-10 md:pl-12 space-x-2 md:space-x-5">
                  {chatData.imgUrl.map((val, id) => {
                    return (
                      <div className="">
                        <button onClick={(e) => openCardView(e, id)}>
                          <img
                            className="h-36 rounded-md"
                            src={val}
                            alt="not found"
                          />
                        </button>
                        <Dialog
                          // fullWidth={fullWidth}
                          // maxWidth={maxWidth}
                          fullScreen
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            sx: {
                              width: "100%",
                              maxHeight: "1300px",
                              background: "black",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
                            {open === true ? (
                              // chatData && (
                              <div className="self-center">
                                <ImCross
                                  onClick={handleClose}
                                  className="z-20 text-xl cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
                                  style={{
                                    right: "4%",
                                    top: "2%",
                                  }}
                                />
                                <GoChevronLeft
                                  onClick={leftClick}
                                  className="text-white opacity-60 hover:opacity-100 text-7xl absolute z-10"
                                  style={{
                                    left: "2%",
                                    top: "45%",
                                  }}
                                />
                                <GoChevronRight
                                  // onClick={setZoomImage((zoomImage+1))}
                                  onClick={rightClick}
                                  className="text-white opacity-60 hover:opacity-100 text-7xl absolute z-10"
                                  style={{ right: "2%", top: "45%" }}
                                />
                                <TransformWrapper>
                                  <TransformComponent>
                                    <img
                                      className="self-center"
                                      style={{ maxHeight: "95svh" }}
                                      src={chatData.imgUrl[zoomImage]}
                                      alt="not found "
                                    />
                                  </TransformComponent>
                                </TransformWrapper>
                              </div>
                            ) : (
                              // )
                              <>no image found</>
                            )}
                          </DialogContent>
                        </Dialog>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
            {chatData.type === "NHQ" ? (
              <><div className="w-full h-5"></div></>
            ) : (
              <>
                <hr className="border-2 rounded-md" />
                {/* <div className="flex flex-col"> */}

                <div className="flex flex-col">
                  <div
                    id="rubikFont"
                    className="w-full h-12 flex flex-row px-0 md:px-3 font-bold text-gray-600 text-2xl space-x-3"
                  >
                    <RxActivityLog className="self-center " />
                    <span className="self-center ">
                      Activity
                      {/* {chatData.title} */}
                    </span>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="w-full h-16 flex flex-row space-x-3"
                  >
                    <Avatar
                      className="self-center"
                      alt="MANAGER"
                      sx={{
                        backgroundColor: "#fb7185",
                      }}
                      src="/static/images/avatar/1.jpg"
                    />
                    {/* <form onSubmit={handleSubmit}> */}
                    <div className="w-full self-center flex flex-col space-y-2">
                      <input
                        id="comment"
                        name="comment"
                        onChange={(e) => setComment(e.target.value)}
                        className="w-full h-12 bg-gray-200 border outline-none rounded-lg shadow-md pl-4 border-gray-500"
                        type="text"
                        value={comment}
                        placeholder="Add a comment..."
                      />
                    </div>
                    <div className="h-full flex flex-col justify-center">
                      <button
                        id="rubikFont"
                        type="submit"
                        className="w-20 h-10 hidden md:block rounded-md shadow-md bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400 text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  {/* </form> */}

                  {/* SUBMIT BUTTON IN MOBILE VERSION */}
                  <div className="w-full flex justify-end pr-3">
                    <button
                      id="rubikFont"
                      className="w-16 h-8 md:hidden rounded-md text-sm shadow-md bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400 text-white"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}

            {/* </div> */}
            {chatDatas.length > 0 ? (
              chatDatas.map((val) => {
                return (
                  <div
                    id="messages"
                    className="grid bg-gray-200 rounded-md p-2 space-y-1 ml-0 md:ml-14"
                  >
                    <span>{val}</span>
                    <span
                      id="rubikFont"
                      className="text-xs flex justify-end text-gray-500"
                    >
                      {/* 6 May 2022 at 12:13 pm */}
                    </span>
                    {/* <span>{val.usersID}</span> */}
                    {/* <span>{comment}</span> */}
                  </div>
                );
              })
            ) : (
              <></>
            )}
            {chatData.comment.map((val, id) => {
              return (
                <div
                  id="messages"
                  className="grid bg-gray-200 rounded-md p-2 space-y-1 ml-0 md:ml-14"
                  key={id}
                >
                  <span>{val.comments}</span>
                  <span
                    id="rubikFont"
                    className="text-xs flex  space-x-1 justify-end text-gray-500"
                  >
                    <span>{new Date(val.timestamps).getDate()}</span>
                    <span>{month[new Date(val.timestamps).getMonth()]}</span>
                    <span>{new Date(val.timestamps).getFullYear()}</span>
                    <span>at</span>
                    <div>
                      <span>
                        {new Date(val.timestamps).getHours() < 10 ? (
                          <>0{new Date(val.timestamps).getHours()}</>
                        ) : (
                          <>{new Date(val.timestamps).getHours()}</>
                        )}
                      </span>
                      :
                      <span>
                        {new Date(val.timestamps).getMinutes() < 10 ? (
                          <>0{new Date(val.timestamps).getMinutes()}</>
                        ) : (
                          <>{new Date(val.timestamps).getMinutes()}</>
                        )}
                      </span>
                    </div>
                    {/* {val.timestamps} */}
                  </span>
                  {/* <span>{val.usersID}</span> */}
                  {/* <span>{comment}</span> */}
                </div>
              );
            })}

            {/* <div className="w-full h-16 flex flex-row space-x-3">
              <Avatar
                className="self-center"
                alt="MANAGER"
                sx={{
                  backgroundColor: "#fb7185",
                }}
                src="/static/images/avatar/1.jpg"
              />
              <div className="w-full self-center flex flex-col space-y-2">
                <input
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full h-12 border rounded-lg shadow-md pl-4 border-gray-500"
                  type="text"
                  placeholder="Add a comment..."
                />
                <div className="h-full w-full flex flex-row justify-end">
                  <button
                    id="rubikFont"
                    className="w-20 h-10 rounded-md shadow-md bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400 text-white"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div> */}
            {/* <div>
                      <div> {chatData.userID} </div>
                      <div>{chatData.storeID}</div>
                      <div>{chatData.title}</div>
                      <div>{chatData.body}</div>
                      <div>
                        {chatData.imgUrl.map((img, id) => {
                          return (
                            <div>
                              <img src={img} alt=" not found" />
                            </div>
                          );
                        })}
                      </div>
                    </div>*/}
          </div>
        </>
      ) : (
        <>No data found</>
      )}
    </div>
  );
}
