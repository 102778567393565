import React from "react";
import UserMenu from "./userMenu";
import NotificationModal from "./notification";
import MobileNav from "../others/mobileNav";
import MobileUserDetailsPage from "../others/mobileUserDetailsPage";
import BroadcastNotification from "./Chat/broadcast/broadcastNotification";
// import { BROADCASTNOTIFICATION } from "../Authservice/api";
// import Tooltip from "@mui/material/Tooltip";
// import ChatModel from "./chat";
export default function DashHeader(props) {
  const stores = props.store;

  // console.log("header store", stores)
  // console.log("users Detauls", JSON.parse(users))

  // POP up user

  return (
    <>
      <div className="w-full flex justify-between  md:px-8 md:pt-4  px-2 pt-2 md:pb-0">
        <div className="w-full flex  items-center ">
          <div className="md:hidden block">
            <MobileNav />
          </div>
          <div className=" text-2xl font-sans md:block text-gray-600 font-bold tracking-wide  ">
            Dashboard
          </div>
        </div>

        <div className="flex space-x-4  items-center justify-end ">
          {/* <div className="pr-6">
            <span>
             <ChatModel/>
            </span>
          </div> */}
          <div className="">
            <span>
              <BroadcastNotification store={stores} />
            </span>
          </div>

          {/* <div className="">
            <span>
              <NotificationModal store={stores} />
            </span>
          </div> */}

          <div className="md:block hidden">
            <span>
              <UserMenu />
            </span>
          </div>

          <div className="md:hidden block">
            <MobileUserDetailsPage />
          </div>
        </div>
      </div>
    </>
  );
}