import React, { useState, useEffect } from "react";

import BarChart from "./DoughnutChart";
import axios from "axios";
import token from "../Authservice/authToken";
import { useParams } from "react-router-dom";
import SideNav from "../others/sidenav";
import SummaryHeader from "./summaryHeader";
// import MobileNav from "../others/mobileNav";
// import MobileUserDetailsPage from "../others/mobileUserDetailsPage";

// const url = "http://216.48.181.202:9889";
// const url = "http://localhost:8080";
// const url = "http://216.48.183.210:9889";

export default function ImageAnalytsis(props) {
  // const data = props.data;
  // console.log("data is here", data);
  let params = useParams();
  const lambUrl = "https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev";
  // const lambUrl = "https://18nowtq8u6.execute-api.ap-south-1.amazonaws.com/prod"

  // const lambUrl = "https://18nowtq8u6.execute-api.ap-south-1.amazonaws.com/prod";

  var selectedDatas = params.selectedData.split(",");
  // console.log("selectedDatas", selectedDatas);
  var fromdate = params.fromDate;
  // console.log("fromDate ", fromdate);
  var todate = params.toDate;
  var shelfId = params.shelfIds;
  // console.log("todate", typeof shelfId);
  // console.log("comparision", shelfId === false.toString());

  let final_shelfID = "";
  if (shelfId === false.toString()) {
    final_shelfID = undefined;
  } else {
    final_shelfID = [shelfId];
  }
  // console.log("finala Shelf ID ", final_shelfID);
  // Api integration
  const [analysisData, setAnalysisData] = useState(false);
  useEffect(() => {
    async function getAllUrlAnalysis() {
      const allData = {
        Store_IDs: selectedDatas,
        start_date: fromdate,
        end_date: todate,
        Shelf_IDs: final_shelfID,
      };
      try {
        const res = await axios.post(`${lambUrl}/getimagesanalysis`, allData, {
          headers: {
            Accept: "application/json",
            Authorization: await token(),
          },
        });
        // console.log(res.data);
        setAnalysisData(res);
      } catch (error) {
        console.log("Error Calling all Store API: ", error);
      }
    }
    getAllUrlAnalysis();
    // eslint-disable-next-line
  }, []);

  // console.log("all Data ", analysisData);
  return (
    <>
      <div className="w-full h-fit flex flex-col md:flex-row bg-gray-100 md:overflow-hidden">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen hidden md:block"></div>

        <div className="w-full md:w-10/12 md:h-screen  xl:h-screen">
          <div className="w-full bg-white">
            <SummaryHeader />
          </div>
          <div className="w-full h-16 hidden md:block"></div>
          <BarChart data={analysisData} />
        </div>
      </div>
    </>
  );
}
