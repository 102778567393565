import React, { useState } from "react";
import SideNav from "../others/sidenav";
import DocHeader from "./docheader";
import pdfFile from "../Authservice/images/Documentation_compressed.pdf";
import { Document, Page, pdfjs } from "react-pdf";
// import PDFReader from "./pdfReader";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Documentation() {
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    //   setPageNumber(1);
  }

  // function changePage(offSet){
  //   setPageNumber(prevPageNumber => prevPageNumber + offSet);
  // }

  // function changePageBack(){
  //   changePage(-1)
  // }

  // function changePageNext(){
  //   changePage(+1)
  // }

  return (
    <>
      <div className="w-full h-screen flex flex-row md:h-screen md:overflow-hidden">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen md:block hidden"></div>
        <div className=" w-full lg:w-5/6 h-full bg-gray-100">
          <div className="w-full bg-white">
            <DocHeader />
          </div>
          <div className="w-full h-16 md:block hidden"></div>
          <div className="w-full md:px-10 px-4 py-1 bg-gray-100 overflow-y-auto scrollbar">
            <div className="flex justify-center">
              <div
                className="h-[90svh] w-[100%] sm:w-[800px]"
                // style={{
                //   // width: "700px",
                //   // height:"90vh",
                //   // border: "3px solid gray",
                //   // backgroundColor: "black",
                // }}
              >
                {/* <header className="App-header">
          <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
            <Page height="600" pageNumber={pageNumber} />
          </Document>
          <p> Page {pageNumber} of {numPages}</p>
          { pageNumber > 1 && 
          <button onClick={changePageBack}>Previous Page</button>
          }
          {
            pageNumber < numPages &&
            <button onClick={changePageNext}>Next Page</button>
          }
        </header> */}
                <center>
                  <div
                    className="h-full"
                    style={{
                      // height: "600px",
                      // overflowY: "scroll",
                      backgroundColor: "black",
                    }}
                  >
                    <Document
                      file={pdfFile}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          customTextRenderer={false}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        ></Page>
                      ))}
                    </Document>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
