import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';

const avatarColors = [
  '#FF5722', // Deep Orange
  '#3F51B5', // Indigo
  '#E91E63', // Pink
  '#2196F3', // Blue
];

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: '0.875rem',
    position: 'relative', 
    zIndex: 1, 
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));

const CityAvatarGroup = ({ cities }) => {
  const classes = useStyles();

  return (
    <div className={classes.avatarContainer}>
      <AvatarGroup max={3}>
        {cities.map((city, index) => (
          <Avatar
            key={index}
            alt={city.name} 
            style={{
              backgroundColor: avatarColors[index % avatarColors.length],
              marginRight: `${-index * 0.5}px`, 
            }}
            className={classes.avatar}
          >
            {city.shortForm}
          </Avatar>
        ))}
      </AvatarGroup>
    </div>
  );
};

export default CityAvatarGroup;
