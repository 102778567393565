import React from "react";
import MobileNav from "../others/mobileNav";
import MobileUserDetailsPage from "../others/mobileUserDetailsPage";

export default function SettingsHeader() {
  return (
    <>
      <div className="w-full h-16 md:fixed bg-white z-10  border-b-2 border-white-200 px-3  ">
        <div className="flex  justify-between  items-center ">
          <div className="md:hidden block">
            <MobileNav />
          </div>
          <div className="md:p-4 md:flex m-1 items-center  md:text-2xl text-xl font-sans text-gray-600 font-bold tracking-wide  ">
            My Settings
          </div>
          <div className="md:hidden block">
            <MobileUserDetailsPage />
          </div>
        </div>
      </div>
    </>
  );
}
