import React, { useState } from "react";
import { BsFillSuitDiamondFill } from "react-icons/bs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import { IoMdClose } from "react-icons/io";
// import FilledImg from "./FilledImage.jpg";
// import FilledImg from "./FilledImage.jpg";
// import Landscape from "./Landscape.jpg";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import { ImCross } from "react-icons/im";

export default function SopVerificationBrand(props) {
  // const [open, setOpen] = useState(false);
  const [imgFull, setImgFull] = useState(false);
  const [sopCount, setSopCount] = useState(false);
  // const fullWidth = true;
  // const maxWidth = "md";
  const [
    alignment,
    // setAlignment
  ] = useState("empty");
  const [imageUrl, setImageUrl] = useState("");
  const sopData = props.verifyData;

  // const handleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  const handleOpen = (e, value) => {
    setImageUrl(value);
    setSopCount(e);
    setImgFull(true);
  };

  const handleCloseFull = () => {
    setImgFull(false);
  };
  return (
    <>
      {sopData &&
        sopData.sop.map((val, id) => {
          return val[0].filled_image.status_verify === "verified" ? (
            <Tooltip
              title={
                <div className="flex flex-col space-y-1">
                  <span>
                    {sopData.brand} / Shelf {id + 1}
                  </span>
                  <img
                    className="w-fit h-fit max-h-[300px] z-10"
                    src={val[0].filled_image.image_url}
                    alt="Not found"
                  />
                </div>
              }
            >
              <span>
                <BsFillSuitDiamondFill
                  onClick={(e) => handleOpen(id, val[0].filled_image.image_url)}
                  className="text-2xl m-1 text-green-500 Blink hover:cursor-pointer"
                />
              </span>
            </Tooltip>
          ) : val[0].filled_image.status_verify ===
            "pending_verification" ? (
            <Tooltip title="Pending Verification ">
              <span>
                <BsFillSuitDiamondFill className="text-2xl m-1 text-amber-500 hover:cursor-pointer" />
              </span>
            </Tooltip>
          ) : val[0].filled_image.status_verify === "pending_upload" ? (
            <Tooltip title="No Data">
              <span>
                <BsFillSuitDiamondFill className="text-2xl m-1 text-red-500" />
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <div className="grid">
                  <span>Re-Capture</span>
                  <span>{val[0].rejected_reason}</span>
                </div>
              }
            >
              <span>
                <BsFillSuitDiamondFill
                  onClick={(e) => handleOpen(id, val[0].filled_image.image_url)}
                  className="text-2xl m-1 text-blue-500"
                />
              </span>
            </Tooltip>
          );
        })}
      <Dialog
        fullScreen
        open={imgFull}
        onClose={handleCloseFull}
        PaperProps={{
          sx: {
            width: "100%",
            maxHeight: "1300px",
            background: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
          <div className="self-center">
            <ImCross
              onClick={handleCloseFull}
              className="z-20 text-xl cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
              style={{
                right: "4%",
                top: "2%",
              }}
            />

            <div
              className="fixed top-4 px-4 left-0 w-full h-16 z-10 flex flex-col space-y-1"
              style={{ background: "rgba(0, 0, 0, 0.3)" }}
            >
              <span className="text-white opacity-100 font-semibold text-lg">
                {sopData.brand}
              </span>
              <span className="text-white opacity-100 font-semibold text-lg">
                Shelf {sopCount + 1}
              </span>
            </div>

            <TransformWrapper>
              <TransformComponent>
                {alignment === "empty" ? (
                  <img
                    className="self-center"
                    style={{ maxHeight: "95svh" }}
                    src={imageUrl}
                    alt="img not found"
                  />
                ) : (
                  <img
                    className="self-center"
                    style={{ maxHeight: "95svh" }}
                    src={imageUrl}
                    alt="img not found"
                  />
                )}
              </TransformComponent>
            </TransformWrapper>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
