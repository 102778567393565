// import React from "react";

function ArrayAddition({ values }) {
  const lengthValue = values.length;
  var sum = 0; // Initialize the sum variable

  for (var i = 0; i < values.length; i++) {
    sum += values[i].fullness_image; // Add each value to the sum
  }

  const finalData = (sum / lengthValue).toFixed(2)
  return finalData ;
}

export default ArrayAddition;
