import React from "react";
import MobileNav from "../others/mobileNav";
import MobileUserDetailsPage from "../others/mobileUserDetailsPage";

// import { styled, alpha } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';

export default function StoreHeader() {
  // const Search = styled('div')(({ theme }) => ({
  //     position: 'relative',
  //     borderRadius: theme.shape.borderRadius,
  //     backgroundColor: alpha(theme.palette.common.white, 0.15),
  //     // '&:hover': {
  //     //   backgroundColor: alpha(theme.palette.common.white, 0.25),
  //     // },
  //     marginLeft: 0,
  //     width: '100%',
  //     [theme.breakpoints.up('sm')]: {
  //         marginLeft: theme.spacing(1),
  //         width: 'auto',
  //     },
  // }));

  // const SearchIconWrapper = styled('div')(({ theme }) => ({
  //     padding: theme.spacing(0, 2),
  //     height: '100%',
  //     position: 'absolute',
  //     pointerEvents: 'none',
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //     color: 'inherit',
  //     '& .MuiInputBase-input': {
  //         padding: theme.spacing(1, 1, 1, 0),
  //         // vertical padding + font size from searchIcon
  //         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //         transition: theme.transitions.create('width'),
  //         width: '100%',
  //         [theme.breakpoints.up('sm')]: {
  //             width: '12ch',
  //             '&:focus': {
  //                 width: '30ch',
  //             },
  //         },
  //     },
  // }));
  return (
    <>
      <div className="w-full h-16 md:fixed bg-white z-10  border-b-2 border-white-200 px-3  ">
        <div className="flex  justify-between  items-center ">
          <div className="md:hidden block">
            <MobileNav />
          </div>
          <div className="md:p-4 md:flex m-1 items-center  md:text-2xl text-xl font-sans text-gray-600 font-bold tracking-wide  ">
            Store
          </div>
          <div className="md:hidden block">
            <MobileUserDetailsPage />
          </div>
        </div>
      </div>
    </>

    // <>
    //   <div className="w-full h-16  border-b-2 border-white-200 flex justify-between">
    //     <div className="w-full flex justify-between">
    //       <div className="p-4 text-2xl md:block hidden font-sans text-gray-600 font-bold tracking-wide  ">
    //         Store
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}
