import React, { useState, useEffect, useRef } from "react";
import {
  // GetALLStoreAPI,
  GetFullnessCaptures,
  GetALLStoreAPI_ROLE,
  GETFULLNESSIMAGE,
  GetFilteredStoresIds,
  SaveForRetrainingAPI,
  GetImageID,
} from "../Authservice/api";
import Tooltip from "@mui/material/Tooltip";
import { BsFillSuitDiamondFill } from "react-icons/bs";
import Button from "@mui/material/Button";
// import { CgSpinner } from "react-icons/cg";

import { ImCross } from "react-icons/im";
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";
import { DialogContent, Dialog, Snackbar, Alert } from "@mui/material";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import { IoChevronUp } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import { AiFillPlusSquare } from "react-icons/ai";
import { AiFillMinusSquare } from "react-icons/ai";
// import { ConstructionOutlined } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
// import { inputLabelClasses } from "@mui/material/InputLabel";
import { ANALYSISFILTER, EXPORT_ANALYSIS_DATA } from "../Authservice/api";
import ArrayAddition from "../others/arrayValueAdd";
import NewLoader from "../others/loaderUi";
// import { is } from "date-fns/locale";
import { useData } from "../../context/chartContext";
// import TextField from "@mui/material/TextField";
import axios from "axios";
import token from "../Authservice/authToken";
import "./alignment.css";
// import { set } from "date-fns";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CircularProgress from "@mui/material/CircularProgress";
// import Box from "@mui/material/Box";

export default function AnalysisStoreNew({
  setParentData,
  filteredTable,
  // setFiltereredTable,
  // selectedData,
  openRows,
  setOpenRows,
}) {
  const {
    filteredFullnessVar,
    setFilteredFullnessVar,
    cappedDate,
    // setCappedDate,
  } = useData();
  const [fullness, setFullness] = useState(false);
  const [fullnessImage, setFullnessImage] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [analysisData, setAnalysisData] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [shelfIds, setShelfIds] = useState(null);
  const [brand_name, setBrand_name] = useState(false);
  const [isBrandFiltered, setIsBrandFiltered] = useState(false);
  const [checkedItemsCopy, setCheckedItemsCopy] = useState([]);
  const [storeIndex, setStoreIndex] = useState();
  // eslint-disable-next-line
  const [retraining, setRetraining] = useState({
    storeId: "",
    shelfId: "",
    userId: "",
    plottedImgUrl: "",
    imageId: "",
    rawImgUrl: "",
    imgFullness: "",
    timeStamp: "",
  });
  const [muiToast, setMuiToast] = useState({
    isToast: false,
    message: "",
    type: "",
    vertical: "",
    horizontal: "",
    isLoading: false,
    duration: 8000,
  });
  // const [loading, setLoading] = useState(false);
  // const [brand_wise_image, setBrand_wise_image] = useState(false);
  let brand_wise_image = [];

  const [todayDate, setTodayDate] = useState(
    cappedDate ? cappedDate : new Date()
  );
  const copyCappedDate = new Date(cappedDate);
  const [prevDate, setPrevDate] = useState(
    cappedDate == null
      ? new Date(
          new Date().setDate(new Date().getDate() - 15)
        ).toLocaleDateString("en-GB")
      : new Date(
          copyCappedDate.setDate(copyCappedDate.getDate() - 15)
        ).toLocaleDateString("en-GB")
  );

  // //console.log("filteredTable", filteredTable);

  const [period, setPeriod] = useState(15);
  const [selectPeriod, setSelectPeriod] = useState(null);

  const handleChangePeriod = (event) => {
    setSelectPeriod(event.target.value);
  };

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  let stores;
  if (userData) {
    stores = userData.data.stores;
  }
  // eslint-disable-next-line
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // Set the component to be mounted when the effect is run
    setIsMounted(true);
    // Return a cleanup function to set the component to unmounted
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const [data1, data2, data3] = await Promise.all([
          GetALLStoreAPI_ROLE(stores),
          GETFULLNESSIMAGE(),
          GetFullnessCaptures(),
        ]);

        if (data1) {
          setCheckedItems((prevData) => data1.data);
          setCheckedItemsCopy((prevData) => data1.data);
        }
        if (data2) {
          setFullnessImage((prevData) => data2.data);
        }
        if (data3) {
          setFullness((prevData) => data3.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const __dateData =
    new Date(todayDate).getFullYear() +
    "-" +
    (new Date(todayDate).getMonth() + 1) +
    "-" +
    new Date(todayDate).getDate();

  // console.log("__dateData", __dateData);
  // console.log("todayDate", todayDate);
  // console.log("prevDate", prevDate);
  // API FOR GET FILTER WISE DATA
  const finalData = {
    range: period,
    Store_IDs: stores,
    date:
      new Date(todayDate).getFullYear() +
      "-" +
      (new Date(todayDate).getMonth() + 1) +
      "-" +
      new Date(todayDate).getDate(),
  };
  useEffect(() => {
    async function getStoresImageFullnness() {
      try {
        const data = await ANALYSISFILTER(finalData);

        if (data) {
          setAnalysisData(data.data);
        }
        // setStore(data.data.data);

        // //console.log("FullnessImage", fullnessImage);
      } catch (error) {
        console.log(error);
      }
    }
    getStoresImageFullnness();
    // eslint-disable-next-line
  }, [period]);
  // API State

  const storeID = [];
  const [storeName, setStoreName] = useState([]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      // Add the name to the storeName array if checked is true
      if (!storeName.includes(name)) {
        setStoreName([...storeName, name]); // Use spread operator to update state
      }
    } else {
      // Remove the name from the storeName array if checked is false
      setStoreName(storeName.filter((item) => item !== name));
    }

    if (name === "All Select") {
      let TempData = checkedItems.map((items) => {
        return { ...items, isChecked: checked };
      });
      setCheckedItems(TempData);
    } else {
      let TempData = checkedItems.map((items) =>
        items.name === name ? { ...items, isChecked: checked } : items
      );
      setCheckedItems(TempData);
    }
    setOpenRows([]);
  };

  const [brandName, setBrandName] = useState(false);

  const lambUrl = "https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev";

  useEffect(() => {
    async function getAllbrandName() {
      try {
        const brands = await axios.get(`${lambUrl}/brands/breandsname`, {
          headers: {
            Accept: "application/json",
            Authorization: await token(),
          },
        });
        if (brands) {
          setBrandName(brands.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllbrandName();
  }, []);
  let _brandName = "";
  if (brandName) {
    _brandName = brandName[0].brands_name;
    _brandName = _brandName.filter((item) => item !== "");
    _brandName.unshift("All");
  }

  let data = {
    brandNames: shelfIds,
    range: period,
  };

  useEffect(() => {
    async function fetchData() {
      //filter here
      try {
        if (!shelfIds?.includes("All")) {
          const response = await GetFilteredStoresIds(data);
          if (response) {
            const response_data = response;
            const temp = checkedItemsCopy.filter((item) =>
              response_data.includes(item._id)
            );
            temp.forEach((item) => {
              Object.keys(item.storeWise_diamond.store_analysis).forEach(
                (key) => {
                  if (item.storeWise_diamond.store_analysis[key] === false) {
                    delete item.storeWise_diamond.store_analysis[key];
                  }
                }
              );
            });
            setCheckedItems(temp);
            setIsBrandFiltered(true);
            setFilterClicked(false);
          }
        } else {
          setCheckedItems(checkedItemsCopy);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [filterClicked]);

  // }
  // console.log("checkedItems", checkedItems);

  useEffect(() => {
    //  extract store ID from store
    if (checkedItems) {
      checkedItems.filter((items) =>
        items.isChecked === true ? storeID.push(items.id) : null
      );
    }
    setParentData(storeID);
    // eslint-disable-next-line
  }, [checkedItems]);

  // FULLNESS FILTER

  // const categories = ["Full", "Semi-full", "Empty"];

  const [
    category,
    // setCategory
  ] = useState([]);

  // function handleChoose(event) {
  //   const {
  //     target: { value },
  //   } = event;
  //   setCategory(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // }

  let filterStore;

  const names = ["Trends", "Smart", "Beauty"];
  // // const names = ["Mayur", "Raj", "Beauty"];

  const [personName, setPersonName] = useState([]);
  const [selectPersonName, setSelectPersonName] = useState(null);

  function handleSelect(event) {
    const {
      target: { value },
    } = event;
    setSelectPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  }
  filterStore = filteredTable;
  const allFilterData = [];
  if (personName.length === 0) {
  } else {
    for (let count = 0; count < personName.length; count++) {
      allFilterData.push(personName[count]);
    }
  }
  if (category.length === 0) {
  } else {
    for (let count = 0; count < category.length; count++) {
      allFilterData.push(category[count]);
    }
  }
  if (filterStore) {
    allFilterData.push(filterStore);
  }

  const keys = ["name", "location", "store_id", "type", "fullnessType"];

  const searchTable = (data) => {
    if (allFilterData.length === 0) {
      return data;
    } else {
      return data.filter((item) =>
        keys.some((key) =>
          allFilterData.some(
            (filter) =>
              item[key] !== undefined &&
              item[key].toString().toLowerCase().includes(filter.toLowerCase())
          )
        )
      );
    }
  };

  //  filter the store and add the fullness and capture complete

  if (checkedItems && fullness) {
    for (let count = 0; count < checkedItems.length; count++) {
      const filterStore = fullness.filter((store) => {
        return store._id === checkedItems[count]._id;
      });
      if (filterStore[0] === undefined) {
        checkedItems[count]["fullness"] = {
          avg_shelffullness: 0,
        };
      } else {
        checkedItems[count]["fullness"] = filterStore[0];
        // //console.log("filterStore[0]", filterStore[0].avg_shelffullness);

        // checkedItems[count]["fullnessType"]=  filterStore[0]
      }
    }
  }

  if (checkedItems && fullnessImage) {
    for (let count = 0; count < checkedItems.length; count++) {
      const filterStore = fullnessImage.filter((store) => {
        return store._id === checkedItems[count]._id;
      });
      if (filterStore[0] === undefined) {
        checkedItems[count]["image_fulness"] = {
          store_analysis: [],
        };
      } else {
        checkedItems[count]["image_fulness"] = filterStore[0];
      }
    }
  }

  // STOREWISE DIAMOND THEN BRANDWISE THEN SHELF WISE
  if (checkedItems && analysisData) {
    for (let count = 0; count < checkedItems.length; count++) {
      var filterStores = analysisData[0].filter((store) => {
        return store._id === checkedItems[count]._id;
      });
      // console.log("filterStores", filterStores );
      if (filterStores[0] === undefined) {
        // //console.log("OK");
        checkedItems[count]["storeWise_diamond"] = {
          avg_shelffullness: 0,
        };
        checkedItems[count]["fullnessType"] = "Empty";
      } else {
        // //console.log("not ok");
        checkedItems[count]["storeWise_diamond"] = filterStores[0];
      }
    }
  }

  if (checkedItems.length > 0) {
    // //console.log("ok", checkedItems.length);
    try {
      checkedItems.sort(function (a, b) {
        var c = new Date(a.storeWise_diamond.avg_shelffullness);
        var d = new Date(b.storeWise_diamond.avg_shelffullness);
        return d - c;
      });
    } catch (error) {}
  } else {
  }
  // //console.log("checkedItems", checkedItems)
  // if (checkedItems.image_fulness.store_analysis === undefined) {

  // } else {
  //   checkedItems.image_fulness.store_analysis.sort(function (a, b) {
  //     var c = new Date(a.fullness.avg_shelffullness);
  //     var d = new Date(b.fullness.avg_shelffullness);
  //     return d - c;
  //   })
  // }
  // console.log("checkedItems", checkedItems);

  //?IMAGE VIEWER STARTS
  // const [open, setOpen] = useState(false);
  // const handleImgError = (e) => {
  //   e.target.src = "filled";
  // };
  // const [tableIndex, setTableIndex] = useState(false);
  // const [zoomImage, setZoomImage] = useState(0);
  const latestIdRef = useRef();
  // const [brandKeys, setBrandKeys] = useState(0);
  // //console.log("tableIndex", tableIndex);

  const handleclickTable = (e, index) => {
    e.preventDefault();
  };

  let zoomImage = 0;
  const openCardView = (index) => {
    // setZoomImage(index);
    zoomImage = index;
    // setOpen(true);
  };

  let _value = [];
  let diamondKeys = new Date();

  //!IMAGE VIEWER ENDS
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const toggleRow = (id) => {
    if (
      // checkedItems[zoomImage].storeWise_diamond.store_analysis.length === 0 &&
      checkedItems[zoomImage].storeWise_diamond.store_analysis === undefined
    ) {
      setBrand_name("");
    } else {
      const checkData = _value;

      const frequencyMap = {};
      const commonValues = [];

      for (let i = 0; i < checkData.length; i++) {
        const value = checkData[i].brand_name;
        frequencyMap[value] = (frequencyMap[value] || 0) + 1;
      }

      for (const key in frequencyMap) {
        if (frequencyMap[key] >= 1) {
          commonValues.push(key);
        }
      }

      for (let count = 0; count < commonValues.length; count++) {
        const filteredDocuments = checkData.filter(
          (doc) => doc.brand_name === commonValues[count]
        );

        let shelfData = [];
        let totalFullness = 0;

        for (let i = 0; i < filteredDocuments.length; i++) {
          shelfData.push({
            shelf_name: `Shelf${i + 1}`,
            image_data: filteredDocuments[i],
          });
          totalFullness += filteredDocuments[i].fullness_image;
        }

        brand_wise_image.push({
          brandName: commonValues[count],
          brand_fullness: totalFullness / filteredDocuments.length,
          shelf_data: shelfData,
          image: filteredDocuments,
        });
      }

      // console.log("shelfIds", shelfIds);
      if (!shelfIds?.includes("All") && isBrandFiltered) {
        brand_wise_image = brand_wise_image.filter((item) =>
          shelfIds.includes(item.brandName)
        );
        // setFilterClicked(false)
      }

      // console.log("brand_wise_image", brand_wise_image);

      // PUSH THE IMAGE IN BRAND_NAME VARIABLE

      // //console.log("filteredDocuments", filteredDocuments);
      // setBrand_wise_image(filteredDocuments);
    }
    setBrand_name(brand_wise_image);

    if (openRows.includes(zoomImage)) {
      setOpenRows(openRows.filter((i) => i !== zoomImage));
    } else {
      setOpenRows([zoomImage]);
    }
  };
  // console.log("brand name", brand_name);

  const handleRowClick = (id, key) => {
    // e.preventDefault();
    // console.log("handleRowClick called");
    openCardView(id);
    toggleRow(key);
    setStoreIndex(id);
  };

  // //console.log("checkedItem", checkedItems)

  const [isTrue, setIsTrue] = useState([]);
  const [idVal, setidVal] = useState(0);
  const [showPlus, setShowPlus] = useState(true);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const handleMenuOpen = (e, val) => {
    if (openBrand === false) {
      setidVal(val);
    }
  };

  const handleChangeShelf = (e, val) => {
    if (isTrue.includes(val)) {
      setIsTrue(isTrue.filter((i) => i !== val));
    } else {
      setIsTrue([val]);
    }
    setShowPlus(!showPlus);
  };

  const [brandImageIndex, setBrandImageIndex] = useState(0);

  const [openBrand, setOpenBrand] = useState(false);
  const openCardViewBrand = (e, index) => {
    console.log("clicked opencardviewbrand");
    e.preventDefault();
    setBrandImageIndex(index);
    setOpenBrand(true);
  };

  const leftClickBrand = () => {
    if (brandImageIndex > 0) {
      setBrandImageIndex(brandImageIndex - 1);
    }
  };

  const rightClickBrand = () => {
    if (brandImageIndex < brand_name[idVal].image.length - 1) {
      // setidVal(idVal);
      setBrandImageIndex(brandImageIndex + 1);
    }
  };

  console.log("idVal", idVal);
  console.log("brand image index", brandImageIndex);
  const handleCloseBrand = () => {
    setOpenBrand(false);
  };

  // SHELF WISE IMAGE
  const [shelfIndex, setShelfIndex] = useState(0);
  // const [shelfImage, setShelfImage] = useState(0);
  const [openShelf, setOpenShelf] = useState(false);
  const openCardViewShelf = (e, index) => {
    e.preventDefault();
    // setShelfImage(index);
    setOpenShelf(true);
  };

  const handleCloseShelf = () => setOpenShelf(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const MenuProps2 = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //IF THE CAPTURE NOT PERFORM THEN IT REPRESENT THE AS PER DAY DIAMOND
  const diamond_Array = Array.from({ length: period }, (_, index) => index);

  //Function to handle change in filter select field
  const [fullnessFilter, setFullnessFilter] = useState(
    filteredFullnessVar === 0 ? 1 : filteredFullnessVar
  );
  const [selectFilter, setSelectFilter] = useState(
    filteredFullnessVar === 0 ? null : filteredFullnessVar
  );

  const handleFilterChange = (event) => {
    setSelectFilter(event.target.value);
  };

  const handleBrandsChange = (event) => {
    // const value = event;
    setShelfIds(event.target.value);
    setOpenRows([]);
    setIsBrandFiltered(false);
  };

  const handleApplyFilter = () => {
    setFilterClicked(true);

    // for fullness filter
    if (selectFilter != null) {
      setFullnessFilter(selectFilter);
    }

    // for period filter
    if (selectPeriod != null) {
      setPeriod(selectPeriod);
      setTodayDate(cappedDate == null ? new Date() : new Date(todayDate));
      const date = todayDate;
      if (period) {
        //   console.log("prerod", event.target.value);
        date.setDate(date.getDate() - selectPeriod);
      }
      setPrevDate(date.toLocaleDateString("en-GB"));
    }

    //for category filter
    if (selectPersonName != null) {
      setPersonName(selectPersonName);
    }

    if (shelfIds != null) {
      setFilterClicked(!filterClicked);
    }
  };

  // console.log("todayDate", todayDate);
  // console.log("prevDate", prevDate);
  // logic for redirection to analysis page on clicking of section of pie chart in dashboard page
  const [rendered, setRendered] = useState(false);
  if (rendered === false) {
    if (filteredFullnessVar !== 0) {
      setFullnessFilter(filteredFullnessVar);
      setSelectFilter(filteredFullnessVar);
      setFilteredFullnessVar(0);
    }
    setRendered(true);
  }

  // DOWNLOAD THE CSV
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  };
  const downloadCSV = async () => {
    // console.log("start");
    try {
      const api_Data = {
        period: period || 15,
        fullness: selectFilter || 1,
        brand: shelfIds,
      };
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear().toString().substring(2);
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      const timestamp = `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
      const response = await EXPORT_ANALYSIS_DATA(api_Data);
      // console.log("response", response);
      // if (response && response.data.length === 0) {
      //   alert("No data to download.");
      //   return;
      // }
      const csvData = convertToCSV(response);
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `NEODISHA_ANALYSIS_${timestamp}.csv`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#a3a4a0",
          },
        },
      },
    },
  });

  // useEffect(() => {
  //   if (storeIndex) {
  //     setRetraining({
  //       storeId: checkedItems[storeIndex].store_id,
  //     });
  //   }

  //   if (brandImageIndex) {
  //     setRetraining({
  //       shelfId: brand_name[idVal].image[brandImageIndex].shelf_id,
  //       userId: brand_name[idVal].image[brandImageIndex].users_id,
  //       plottedImgUrl: brand_name[idVal].image[brandImageIndex].image_url,
  //       rawImgUrl: "",
  //       imgFullness: brand_name[idVal].image[brandImageIndex].fullness_image,
  //       timeStamp:
  //         new Date(brand_name[idVal].image[brandImageIndex].date).getDate() +
  //         "-" +
  //         monthNames[
  //           new Date(brand_name[idVal].image[brandImageIndex].date).getMonth()
  //         ] +
  //         "-" +
  //         new Date(
  //           brand_name[idVal].image[brandImageIndex].date
  //         ).getFullYear() +
  //         " " +
  //         new Date(brand_name[idVal].image[brandImageIndex].date).getHours() +
  //         ":" +
  //         new Date(brand_name[idVal].image[brandImageIndex].date).getMinutes(),
  //     });
  //   }

  //   // eslint-disable-next-line
  // }, [idVal, brandImageIndex]);
  // eslint-disable-next-line
  const [imageIdExistsInDatabase, setImageIdExistsInDatabase] = useState(false);

  useEffect(() => {
    if (
      brandImageIndex !== null &&
      brand_name[idVal]?.image?.[brandImageIndex]
    ) {
      setRetraining({
        storeId: checkedItems[storeIndex]?.store_id || "",
        shelfId: brand_name[idVal].image[brandImageIndex].shelf_id || "",
        userId: brand_name[idVal].image[brandImageIndex].users_id || "",
        plottedImgUrl: brand_name[idVal].image[brandImageIndex].image_url || "",
        imageId: brand_name[idVal].image[brandImageIndex].image_id || "",
        rawImgUrl: "",
        imgFullness:
          brand_name[idVal].image[brandImageIndex].fullness_image || "",
        timeStamp:
          new Date(brand_name[idVal].image[brandImageIndex].date).getDate() +
          "-" +
          monthNames[
            new Date(brand_name[idVal].image[brandImageIndex].date).getMonth()
          ] +
          "-" +
          new Date(
            brand_name[idVal].image[brandImageIndex].date
          ).getFullYear() +
          " " +
          new Date(brand_name[idVal].image[brandImageIndex].date).getHours() +
          ":" +
          new Date(brand_name[idVal].image[brandImageIndex].date).getMinutes(),
      });
    }
    // eslint-disable-next-line
  }, [brandImageIndex, idVal, storeIndex]);

  const [toastOpen, setToastOpen] = useState(false);

  const handleToastClose = () => {
    setMuiToast({
      isToast: false,
      message: "",
      type: "",
      vertical: "",
      horizontal: "",
      isLoading: false,
      duration: 8000,
    });
    setToastOpen(false);
  };
  // eslint-disable-next-line
  const handleRetraining = async () => {
    if (
      brandImageIndex !== null &&
      brand_name[idVal]?.image?.[brandImageIndex]
    ) {
      const postData = {
        store_id: checkedItems[storeIndex]?.store_id || "",
        shelf_id: brand_name[idVal].image[brandImageIndex].shelf_id || "",
        user_id: brand_name[idVal].image[brandImageIndex].users_id || "",
        plotted_img_url:
          brand_name[idVal].image[brandImageIndex].image_url || "",
        image_id: brand_name[idVal].image[brandImageIndex].image_id || "",
        raw_img_url: "",
        img_fullness:
          brand_name[idVal].image[brandImageIndex].fullness_image || "",
      };

      try {
        const data = await SaveForRetrainingAPI(postData);
        setIsLoading(true);
        setRetraining(data);
        if (data.status === 200) {
          setMuiToast({
            isToast: true,
            message: "Sent for retraining",
            type: "success",
            vertical: "top",
            horizontal: "center",
            isLoading: false,
            duration: 8000,
          });
          setToastOpen(true);
          setIsLoading(false);
          // checkImageId(brand_name[idVal].image[brandImageIndex].image_id);
          setImageIdExistsInDatabase(true);
        }
        console.log("API response", data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkImageId = async (imageId) => {
    try {
      if (!imageId) throw new Error("Image ID not found");

      const response = await GetImageID(imageId);
      const existsInDatabase = response.data.data.exists;
      setImageIdExistsInDatabase(existsInDatabase);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("Store ID");
  // console.log("All stores", checkedItems);
  // console.log("Store ID", storeIndex);
  // // console.log("Retraining Data", retraining);
  // console.log("Retraining", retraining);

  return (
    <>
      <div className="flex flex-col space-y-2 items-center justify-center w-full md:p-10 p-4 pt-0 md:pt-0">
        <div className="w-full px-2 flex justify-between">
          {/* <div className="w-full md:w-2/12 self-center">
            <TextField
              fullWidth
              label="Search Store"
              className="md:w-2/12 w-full"
              variant="outlined"
              color="success"
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
            />
          </div> */}

          <div className="w-full md:w-2/12 self-center ml-2">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-multiple-checkbox-label" color="success">
                Category
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectPersonName == null ? personName : selectPersonName}
                onChange={handleSelect}
                input={<OutlinedInput label="Category" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                color="success"
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectPersonName?.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-full md:w-2/12 self-center">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-multiple-checkbox-label" color="success">
                Brands
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={shelfIds == null ? [] : shelfIds}
                onChange={handleBrandsChange}
                color="success"
                input={<OutlinedInput label="Name" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps2}
              >
                {Array.isArray(_brandName) ? (
                  _brandName.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      // style={getStyles(name, personName, theme)}
                    >
                      <Checkbox checked={shelfIds?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No brands available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="w-full md:w-2/12 mr-2 ml-2">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label" color="success">
                Fullness Filter
              </InputLabel>
              <Select
                labelId="new-select-label"
                id="new-select"
                label="Fullness Filter"
                color="success"
                onChange={handleFilterChange}
                value={selectFilter == null ? 1 : selectFilter}
              >
                <MenuItem value={1}>All</MenuItem>
                <MenuItem value={2}>Above 80%</MenuItem>
                <MenuItem value={3}>Between 50%-80%</MenuItem>
                <MenuItem value={4}>Less than 50%</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-full md:w-2/12 ">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label" color="success">
                Result of
              </InputLabel>
              <Select
                color="success"
                size="small"
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectPeriod == null ? period : selectPeriod}
                label="Result of"
                onChange={handleChangePeriod}
              >
                <MenuItem value={15}>Last 15 days</MenuItem>
                <MenuItem value={7}>Last 7 days</MenuItem>
                <MenuItem value={3}>Last 3 days</MenuItem>
                <MenuItem value={1}>Current day</MenuItem>
              </Select>
            </FormControl>
          </div>

          {selectFilter ||
          selectPeriod != null ||
          selectPersonName ||
          shelfIds != null ? (
            // <button
            //   onClick={() => {
            //     handleApplyFilter();
            //   }}
            //   className="md:w-2/12 md:h-10 rounded-md text-base w-full h-12 font-bold text-white bg-emerald-500 ml-2"
            // >
            //   Apply Filter
            // </button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#10b981",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => {
                handleApplyFilter();
              }}
              className="md:w-2/12 md:h-10 rounded-md text-base w-full h-12 font-bold text-white bg-emerald-500 ml-2"
            >
              Apply Filter
            </Button>
          ) : (
            <Tooltip title="Select one of the filters to proceed">
              <button className="md:w-2/12 md:h-10 w-full h-12  cursor-not-allowed rounded-md text-base  font-bold text-white bg-emerald-200 ml-2">
                Apply Filter
              </button>
            </Tooltip>
          )}

          <Tooltip title="Export Data">
            <button
              onClick={downloadCSV}
              className="w-10 h-10 ml-[6px] rounded-full border-none cursor-pointer text-white text-xs bg-emerald-500"
            >
              <FileUploadIcon style={{ fontSize: "24px", color: "white" }} />
            </button>
          </Tooltip>
        </div>

        <div className="container rounded-xl overflow-y-auto scrollbar">
          {/* <div className="container xl:h-[21rem] md:h-96 rounded-xl 2xl:h-[36rem] overflow-y-auto scrollbar"> */}
          <table className="w-[1100px] xl:w-full bg-white mb-5">
            <thead className=" text-white whitespace-nowrap">
              <tr id="rubikFont" className="bg-emerald-500 rounded-xl">
                <th className="text-left w-[4%] pr-6 py-4 ">
                  <input
                    type="checkbox"
                    className=" rounded-lg  cursor-pointer  border-gray-300  shadow-sm invisible"
                    name="All Select"
                    checked={
                      checkedItems.filter((items) => items?.isChecked !== true)
                        .length < 1
                    }
                    onChange={handleChange}
                    disabled
                  />
                </th>
                {/* </Tooltip> */}
                <th className="text-left text-lg xl:text-base 2xl:text-lg font-semibold w-[19%] 2xl:w-[22%]">
                  <div className="w-full flex space-x-1">
                    <span className="self-center">Stores</span>
                    {/* <div className="self-center w-24">
                      <FormControl size="small" fullWidth>
                        <InputLabel
                          sx={{
                            color: "white",
                            fontSize: "13px",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "white",
                              fontSize: "16px",
                            },
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleSelect}
                          input={<OutlinedInput label="Category" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          sx={{
                            backgroundColor: "#10b981",
                            color: "white",
                          }}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>
                  {/* <div className="font-semibold">Store name</div> */}
                </th>

                <th className="text-center text-lg xl:text-base 2xl:text-lg font-semibold w-[18%] 2xl:w-[21%]">
                  <div className="w-full flex justify-center space-x-1">
                    <span className="self-center">Shelf-fullness</span>
                    {/* <div className="self-center w-24">
                      <FormControl
                        size="small"
                        fullWidth
                        // sx={{ height: 40, width: { xs: 70, lg: 60, xl: 100 } }}
                      >
                        <InputLabel
                          sx={{
                            color: "white",
                            fontSize: "13px",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "white",
                              fontSize: "16px",
                            },
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={category}
                          onChange={handleChoose}
                          input={<OutlinedInput label="Category" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          sx={{
                            backgroundColor: "#10b981",
                            color: "white",
                          }}
                        >
                          {categories.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={category.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>
                </th>

                <th className="text-left text-lg xl:text-base 2xl:text-lg font-semibold px-3">
                  {/* Images 
                  <span className="pl-3 flex space-x-2">
                    ( <span>01-05-23</span>
                    <span>to</span>
                    <span>15-05-23</span> )
                  </span> */}
                  <div className="flex">
                    <span>Images</span>
                    <span className="pl-3 flex space-x-2">
                      ({" "}
                      <span>
                        {new Date(todayDate).toLocaleDateString("en-GB")}
                      </span>
                      <span>-</span>
                      <span>{prevDate.toLocaleString()}</span> )
                    </span>
                    <ThemeProvider theme={theme}>
                      <Tooltip
                        title={
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginBottom: "2px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "red",
                                  height: "10px",
                                  width: "10px",
                                  marginRight: "5px",
                                }}
                              ></div>
                              <div>Below 50% fullness</div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginBottom: "2px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#F59E0B",
                                  height: "10px",
                                  width: "10px",
                                  marginRight: "5px",
                                }}
                              ></div>
                              <div>Above 50% below 80% fullness</div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginBottom: "2px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#22C55E",
                                  height: "10px",
                                  width: "10px",
                                  marginRight: "5px",
                                }}
                              ></div>
                              <div>Above 80% fullness</div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginBottom: "2px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#6B7280",
                                  height: "10px",
                                  width: "10px",
                                  marginRight: "5px",
                                }}
                              ></div>
                              <div>No Captures</div>
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <button
                          style={{
                            textAlign: "center",
                            lineHeight: "12px",
                            backgroundColor: "white",
                          }}
                          className="w-[15px] h-[15px] ml-[8px] rounded-full border-none cursor-pointer text-green-500 text-[15px] mt-[6px]"
                        >
                          i
                        </button>
                      </Tooltip>
                    </ThemeProvider>
                  </div>
                </th>
              </tr>
            </thead>
            {fullness && analysisData ? (
              <tbody className="whitespace-nowrap xl:whitespace-nowrap">
                {fullness && analysisData ? (
                  // eslint-disable-next-line
                  searchTable(checkedItems).map((f, id) => {
                    let fullnessCheck = true;

                    switch (fullnessFilter) {
                      case 1:
                        break;
                      case 2:
                        fullnessCheck =
                          f.storeWise_diamond.avg_shelffullness > 80;
                        break;
                      case 3:
                        fullnessCheck =
                          f.storeWise_diamond.avg_shelffullness >= 50 &&
                          f.storeWise_diamond.avg_shelffullness <= 80;
                        break;
                      case 4:
                        fullnessCheck =
                          f.storeWise_diamond.avg_shelffullness < 50;
                        break;
                      default:
                        break;
                    }

                    if (fullnessCheck) {
                      return (
                        <React.Fragment key={id}>
                          <tr
                            key={id}
                            ref={latestIdRef}
                            className={`rounded-l-lg ${
                              f.fullness === undefined
                                ? ""
                                : "bg-white hover:bg-emerald-100 rounded-l-lg"
                            }`}
                            onClick={(e) => {
                              handleRowClick(id, diamondKeys);
                              // openCardView(e, id)}
                            }}
                          >
                            <td className="px-5 py-4 text-center  ">
                              <button className="focus:outline-none ">
                                {openRows.includes(id) ? (
                                  <div className="h-full flex flex-col justify-center invisible">
                                    <IoChevronUp className="text-lg mt-1" />
                                  </div>
                                ) : (
                                  <div className="h-full flex flex-col justify-center invisible">
                                    <IoChevronDown className="text-lg mt-1" />
                                  </div>
                                )}
                              </button>
                            </td>
                            {/* <td className="pr-6 py-4 text-center ">
                              <input
                                type="checkbox"
                                className="form-check-input rounded-lg  cursor-pointer  border-gray-300  shadow-sm  invisible"
                                checked={f.isChecked || false}
                                value={f.id}
                                name={f.name}
                                id="mastercheck"
                                onChange={handleChange}
                                disabled
                              />
                            </td> */}

                            <td className="text-sm font-semibold tracking-tight 2xl:text-base p-3 ">
                              <Tooltip title={f.name}>
                                <span>
                                  {f.store_id}-{f.name.split("-")[1]}
                                </span>
                              </Tooltip>
                            </td>

                            <td className="text-sm 2xl:text-base text-center ">
                              {f.storeWise_diamond.length === 0 ? (
                                <>00.00%</>
                              ) : (
                                <span>
                                  {f.storeWise_diamond.avg_shelffullness.toFixed(
                                    2
                                  )}
                                  %
                                </span>
                              )}
                            </td>
                            <td
                              onClick={(e) => handleclickTable(e, id)}
                              className="h-full flex py-4 xl:flex-wrap text-sm 2xl:text-base  text-center "
                            >
                              {!f.storeWise_diamond.store_analysis ? (
                                <>
                                  {diamond_Array.map((divIndex) => (
                                    <div
                                      key={divIndex}
                                      className="text-gray-500"
                                    >
                                      <BsFillSuitDiamondFill className="text-2xl m-1" />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {Object.entries(
                                    // compareDate(
                                    f.storeWise_diamond.store_analysis
                                    // )
                                  )
                                    .sort(
                                      ([keyA], [keyB]) =>
                                        new Date(keyB) - new Date(keyA)
                                    )
                                    .map(([key, value]) =>
                                      value === false ? (
                                        <>
                                          <Tooltip
                                            title={
                                              <div className="grid space-y-2">
                                                <span>
                                                  Fullness: No capture
                                                </span>

                                                <span>Date:{key}</span>
                                              </div>
                                            }
                                          >
                                            <div className="text-gray-500">
                                              <BsFillSuitDiamondFill className="text-2xl  m-1" />
                                            </div>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <Tooltip
                                          title={
                                            <div className="grid space-y-2">
                                              <span>
                                                Fullness:{" "}
                                                <ArrayAddition values={value} />
                                              </span>

                                              <span>Date:{key}</span>
                                            </div>
                                          }
                                        >
                                          {ArrayAddition({ values: value }) <
                                          50 ? (
                                            <div
                                              key={key}
                                              onClick={(e) => {
                                                diamondKeys = key;
                                                _value = value;
                                                setIsTrue([]);
                                              }}
                                              className="text-red-500"
                                            >
                                              <BsFillSuitDiamondFill className="text-2xl m-1" />
                                            </div>
                                          ) : ArrayAddition({ values: value }) >
                                            80 ? (
                                            <div
                                              key={key}
                                              onClick={(e) => {
                                                diamondKeys = key;
                                                _value = value;
                                                setIsTrue([]);
                                              }}
                                              className="text-green-500"
                                            >
                                              <BsFillSuitDiamondFill className="text-2xl m-1" />
                                            </div>
                                          ) : (
                                            <div
                                              key={key}
                                              onClick={(e) => {
                                                // setDiamondKeys(key)
                                                _value = value;
                                                diamondKeys = key;
                                                setIsTrue([]);
                                              }}
                                              className="text-amber-500"
                                            >
                                              <BsFillSuitDiamondFill className="text-2xl m-1" />
                                            </div>
                                          )}
                                        </Tooltip>
                                      )
                                    )}
                                  {/* {f.image_fulness.store_analysis
                                .sort(function (a, b) {
                                  var c = new Date(a.fullness_image);
                                  var d = new Date(b.fullness_image);
                                  return d - c;
                                })
                                .map((val, id) => {
                                  return (
                                    <div
                                      key={id}
                                      onClick={(e) => openCardView(e, id)}
                                    >
                                      {val.fullness_image < 30 ? (
                                        <Tooltip
                                          title={
                                            <div>
                                              <img
                                                className="w-fit h-fit max-h-[300px] z-10"
                                                src={val.image_url}
                                                alt="Not found"
                                              />
                                            </div>
                                          }
                                        >
                                          <div className="text-red-500">
                                            <BsFillSuitDiamondFill className="text-2xl m-1" />
                                          </div>
                                        </Tooltip>
                                      ) : val.fullness_image > 70 ? (
                                        <Tooltip
                                          title={
                                            <div>
                                              <img
                                                className="w-fit h-fit max-h-[300px] "
                                                src={val.image_url}
                                                alt="Not found"
                                              />
                                            </div>
                                          }
                                        >
                                          <div className="text-green-500">
                                            <BsFillSuitDiamondFill className="text-2xl m-1" />
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title={
                                            <div>
                                              <img
                                                className="w-fit h-fit max-h-[300px]"
                                                src={val.image_url}
                                                alt="Not found"
                                              />
                                            </div>
                                          }
                                        >
                                          <div className="text-amber-500">
                                            <BsFillSuitDiamondFill className="text-2xl m-1" />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                  );
                                })} */}
                                </>
                              )}
                            </td>
                          </tr>

                          {openRows.includes(id) && (
                            <tr className="border-t border-b bg-slate-100 border-gray-200">
                              <td colSpan="5" className="">
                                <div className="w-full h-full py-2">
                                  <table className="w-full">
                                    <thead
                                      id="rubikFont"
                                      className=" text-gray-500 font-semibold"
                                    >
                                      <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <React.Fragment>
                                        {brand_name.map((val, id) => {
                                          return (
                                            <div>
                                              <tr>
                                                <td className="w-[4%] px-5  ">
                                                  <div
                                                    onClick={(e) =>
                                                      handleChangeShelf(e, id)
                                                    }
                                                    className="h-full flex flex-col justify-center"
                                                  >
                                                    {showPlus === true ? (
                                                      <AiFillPlusSquare className="text-lg text-blue-500 cursor-pointer mt-1" />
                                                    ) : (
                                                      <AiFillMinusSquare className="text-lg text-rose-500 cursor-pointer mt-1" />
                                                    )}
                                                  </div>
                                                </td>
                                                {/* <td className="w-[2.7%] pr-6 bg-red-300">
                                                <input
                                                  type="checkbox"
                                                  className="rounded-lg invisible cursor-pointer  border-gray-300  shadow-sm "
                                                  name="All Select"
                                                  // checked={
                                                  //   checkedItems.filter(
                                                  //     (items) => items?.isChecked !== true
                                                  //   ).length < 1
                                                  // }
                                                  // onChange={handleChange}
                                                />
                                              </td> */}

                                                <td
                                                  id="rubikFont"
                                                  className="px-3 w-[20%] 2xl:w-[22%] text-base 2xl:text-lg text-left "
                                                >
                                                  {val.brandName}
                                                </td>
                                                <td className=" sm:w-[180px] md:w-[180px] lg:w-[180px] xl:w-[178px] 2xl:w-[21%] text-sm 2xl:text-base text-center responsive-width1 ">
                                                  {val.brand_fullness.toFixed(
                                                    2
                                                  )}
                                                  %
                                                </td>
                                                <td
                                                  onClick={(e) =>
                                                    handleMenuOpen(e, id)
                                                  }
                                                  className=""
                                                >
                                                  {brand_wise_image && (
                                                    <div className="h-full flex px-3 py-4 xl:flex-wrap text-sm 2xl:text-base  text-center">
                                                      {val.image.map(
                                                        (val, ik) => {
                                                          return (
                                                            <div
                                                              key={ik}
                                                              onClick={(e) =>
                                                                openCardViewBrand(
                                                                  e,
                                                                  ik
                                                                )
                                                              }
                                                            >
                                                              {val.fullness_image <
                                                              50 ? (
                                                                <Tooltip
                                                                  title={
                                                                    <div>
                                                                      <img
                                                                        className="w-fit h-fit max-h-[300px] z-10"
                                                                        src={
                                                                          val.image_url
                                                                        }
                                                                        alt="Not found"
                                                                      />
                                                                    </div>
                                                                  }
                                                                >
                                                                  <div className="text-red-500">
                                                                    <BsFillSuitDiamondFill className="text-2xl m-1" />
                                                                  </div>
                                                                </Tooltip>
                                                              ) : val.fullness_image >
                                                                80 ? (
                                                                <Tooltip
                                                                  title={
                                                                    <div>
                                                                      <img
                                                                        className="w-fit h-fit max-h-[300px] "
                                                                        src={
                                                                          val.image_url
                                                                        }
                                                                        alt="Not found"
                                                                      />
                                                                    </div>
                                                                  }
                                                                >
                                                                  <div className="text-green-500">
                                                                    <BsFillSuitDiamondFill className="text-2xl m-1" />
                                                                  </div>
                                                                </Tooltip>
                                                              ) : (
                                                                <Tooltip
                                                                  title={
                                                                    <div>
                                                                      <img
                                                                        className="w-fit h-fit max-h-[300px]"
                                                                        src={
                                                                          val.image_url
                                                                        }
                                                                        alt="Not found"
                                                                      />
                                                                    </div>
                                                                  }
                                                                >
                                                                  <div className="text-amber-500">
                                                                    <BsFillSuitDiamondFill className="text-2xl m-1" />
                                                                  </div>
                                                                </Tooltip>
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      )}

                                                      <Dialog
                                                        // fullWidth={fullWidth}
                                                        // maxWidth={maxWidth}
                                                        fullScreen
                                                        open={openBrand}
                                                        onClose={
                                                          handleCloseBrand
                                                        }
                                                        PaperProps={{
                                                          sx: {
                                                            width: "100%",
                                                            maxHeight: "1300px",
                                                            background: "black",
                                                            boxShadow: "none",
                                                          },
                                                        }}
                                                      >
                                                        {brand_name && (
                                                          <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
                                                            {openBrand ===
                                                              true && (
                                                              <div className="self-center">
                                                                <GoChevronLeft
                                                                  onClick={
                                                                    leftClickBrand
                                                                  }
                                                                  className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                                                                  style={{
                                                                    left: "2%",
                                                                    top: "45%",
                                                                  }}
                                                                />
                                                                <GoChevronRight
                                                                  onClick={
                                                                    rightClickBrand
                                                                  }
                                                                  className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                                                                  style={{
                                                                    right: "2%",
                                                                    top: "45%",
                                                                  }}
                                                                />
                                                                <div
                                                                  id="rubikFont"
                                                                  className="fixed top-4 px-4 left-0 w-full h-20 z-10 flex justify-between font-semibold 2xl:font-bold text-xl md:text-2xl 2xl:text-3xl"
                                                                  style={{
                                                                    background:
                                                                      "rgba(0, 0, 0, 0.3)",
                                                                  }}
                                                                >
                                                                  <span className="text-white opacity-100">
                                                                    {/* {all_img_url[zoomImage].brand_name} */}

                                                                    {
                                                                      brand_name[
                                                                        idVal
                                                                      ].image[
                                                                        brandImageIndex
                                                                      ]
                                                                        .brand_name
                                                                    }
                                                                  </span>
                                                                  <div className="flex space-x-4 md:space-x-5 pr-1 md:pr-4">
                                                                    {/* <Button
                                                                      id="rubikFont"
                                                                      variant="contained"
                                                                      sx={{
                                                                        backgroundColor:
                                                                          "#10b981",
                                                                        height: 35,
                                                                      }}
                                                                      // onClick={
                                                                      //   handleRetraining
                                                                      // }
                                                                      onClick={() =>
                                                                        handleRetraining(
                                                                          brandImageIndex
                                                                        )
                                                                      }
                                                                      disabled={imageIdExistsInDatabase}
                                                                      //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
                                                                    >
                                                                      {isLoading && (
                                                                        <CgSpinner className="mr-2 text-lg animate-spin" />
                                                                      )}
                                                                      Send for
                                                                      retraining
                                                                    </Button> */}
                                                                    <ImCross
                                                                      onClick={
                                                                        handleCloseBrand
                                                                      }
                                                                      className="text-xl cursor-pointer text-white opacity-60 hover:opacity-100 mt-2"
                                                                      style={{
                                                                        right:
                                                                          "4%",
                                                                        top: "2%",
                                                                      }}
                                                                    />

                                                                    {muiToast.isToast && (
                                                                      <Snackbar
                                                                        open={
                                                                          toastOpen
                                                                        }
                                                                        autoHideDuration={
                                                                          muiToast.duration
                                                                        }
                                                                        onClose={
                                                                          handleToastClose
                                                                        }
                                                                        anchorOrigin={{
                                                                          vertical:
                                                                            muiToast.vertical,
                                                                          horizontal:
                                                                            muiToast.horizontal,
                                                                        }}
                                                                      >
                                                                        <Alert
                                                                          onClose={
                                                                            handleToastClose
                                                                          }
                                                                          className="flex"
                                                                          // variant="filled"
                                                                          color="info"
                                                                          elevation={
                                                                            6
                                                                          }
                                                                          severity={
                                                                            muiToast.type
                                                                          }
                                                                          sx={{
                                                                            width:
                                                                              "100%",
                                                                            // backgroundColor: "#22c55e",
                                                                            fontSize:
                                                                              "16px",
                                                                          }}
                                                                        >
                                                                          <div className="h-full self-center flex space-x-2">
                                                                            <span className="h-full self-center">
                                                                              {
                                                                                muiToast.message
                                                                              }
                                                                            </span>
                                                                          </div>
                                                                        </Alert>
                                                                      </Snackbar>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  id="rubikFont"
                                                                  className="fixed bottom-4 px-4 left-0 w-full h-30 z-10 flex flex-row justify-between font-semibold 2xl:font-bold text-lg md:text-2xl 2xl:text-3xl"
                                                                  style={{
                                                                    background:
                                                                      "rgba(0, 0, 0, 0.3)",
                                                                  }}
                                                                >
                                                                  <div className="flex flex-col space-y-0">
                                                                    <div className="text-white opacity-100">
                                                                      <span>
                                                                        Slot
                                                                        Fullness:{" "}
                                                                        {
                                                                          brand_name[
                                                                            idVal
                                                                          ]
                                                                            .image[
                                                                            brandImageIndex
                                                                          ]
                                                                            .actualFullness
                                                                        }
                                                                        %
                                                                      </span>
                                                                    </div>
                                                                    {/* <div className="text-white opacity-100">
                                                                      <span>
                                                                        Predicted
                                                                        Fullness:{" "}
                                                                        {
                                                                          brand_name[
                                                                            idVal
                                                                          ]
                                                                            .image[
                                                                            brandImageIndex
                                                                          ]
                                                                            .predicted_fullness
                                                                        }
                                                                        %
                                                                      </span>
                                                                    </div> */}
                                                                    <div className="text-white opacity-100">
                                                                      <span>
                                                                        Weighted
                                                                        Fullness:{" "}
                                                                        {
                                                                          brand_name[
                                                                            idVal
                                                                          ]
                                                                            .image[
                                                                            brandImageIndex
                                                                          ]
                                                                            .fullness_image
                                                                        }
                                                                        %
                                                                      </span>
                                                                    </div>
                                                                    <div className="text-white opacity-100">
                                                                      <span>
                                                                        {new Date(
                                                                          brand_name[
                                                                            idVal
                                                                          ].image[
                                                                            brandImageIndex
                                                                          ].date
                                                                        ).getDate() +
                                                                          "-" +
                                                                          monthNames[
                                                                            new Date(
                                                                              brand_name[
                                                                                idVal
                                                                              ].image[
                                                                                brandImageIndex
                                                                              ].date
                                                                            ).getMonth()
                                                                          ] +
                                                                          "-" +
                                                                          new Date(
                                                                            brand_name[
                                                                              idVal
                                                                            ].image[
                                                                              brandImageIndex
                                                                            ].date
                                                                          ).getFullYear() +
                                                                          " " +
                                                                          new Date(
                                                                            brand_name[
                                                                              idVal
                                                                            ].image[
                                                                              brandImageIndex
                                                                            ].date
                                                                          ).getHours() +
                                                                          ":" +
                                                                          new Date(
                                                                            brand_name[
                                                                              idVal
                                                                            ].image[
                                                                              brandImageIndex
                                                                            ].date
                                                                          ).getMinutes()}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  <div className="text-sart text-white flex flex-col space-y-1">
                                                                    <span>
                                                                      {
                                                                        brand_name[
                                                                          idVal
                                                                        ].image[
                                                                          brandImageIndex
                                                                        ]
                                                                          .users_id
                                                                      }
                                                                    </span>
                                                                    <span>
                                                                      {
                                                                        brand_name[
                                                                          idVal
                                                                        ].image[
                                                                          brandImageIndex
                                                                        ]
                                                                          .user_name
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>

                                                                {/* <div className="text-white">
                                                                {brand_name[
                                                                        idVal
                                                                      ].image[
                                                                        brandImageIndex
                                                                      ]
                                                                        .user_name}
                                                                </div> */}
                                                                <TransformWrapper>
                                                                  <TransformComponent>
                                                                    <img
                                                                      className="self-center"
                                                                      onLoad={() =>
                                                                        checkImageId(
                                                                          brand_name[
                                                                            idVal
                                                                          ]
                                                                            .image[
                                                                            brandImageIndex
                                                                          ]
                                                                            .image_id
                                                                        )
                                                                      }
                                                                      style={{
                                                                        maxHeight:
                                                                          "95svh",
                                                                      }}
                                                                      // src={all_img_surl[zoomImage].img_url}
                                                                      src={
                                                                        brand_name[
                                                                          idVal
                                                                        ].image[
                                                                          brandImageIndex
                                                                        ]
                                                                          .image_url
                                                                      }
                                                                      alt=" not found"
                                                                    />
                                                                  </TransformComponent>
                                                                </TransformWrapper>
                                                              </div>
                                                            )}
                                                          </DialogContent>
                                                        )}
                                                      </Dialog>
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                              {isTrue.includes(id) && (
                                                <tr className="border-t bg-neutral-100 border-gray-200">
                                                  <td colSpan="5" className="">
                                                    <div className="w-full h-full">
                                                      <table className="w-full">
                                                        <thead
                                                          id="rubikFont"
                                                          className=" text-gray-500 font-semibold"
                                                        >
                                                          <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <React.Fragment>
                                                            {val.shelf_data.map(
                                                              (
                                                                shelVal,
                                                                shelfId
                                                              ) => {
                                                                return (
                                                                  <tr
                                                                    key={
                                                                      shelfId
                                                                    }
                                                                  >
                                                                    <td className="w-[4%] px-5 ">
                                                                      <div
                                                                        onClick={
                                                                          handleMenuOpen
                                                                        }
                                                                        className="h-full flex flex-col justify-center"
                                                                      >
                                                                        {/* {isTrue !== true ? ( */}
                                                                        <AiFillPlusSquare className="text-lg invisible text-blue-600 mt-1" />
                                                                        {/* ) : ( */}
                                                                        {/* <AiFillMinusSquare className="text-lg text-blue-600 mt-1" /> */}
                                                                        {/* )} */}
                                                                      </div>
                                                                    </td>
                                                                    {/* <td className="w-[2.7%] pr-6 py-1 ">
                                                                      <input
                                                                        type="checkbox"
                                                                        className="rounded-lg invisible cursor-pointer  border-gray-300  shadow-sm  "
                                                                        name="All Select"
                                                                        // checked={
                                                                        //   checkedItems.filter(
                                                                        //     (items) => items?.isChecked !== true
                                                                        //   ).length < 1
                                                                        // }
                                                                        // onChange={handleChange}
                                                                      />
                                                                    </td> */}
                                                                    <td
                                                                      id="rubikFont"
                                                                      className="px-3 py-2 w-[20%] 2xl:w-[22%] text-sm 2xl:text-base text-left "
                                                                    >
                                                                      {
                                                                        shelVal.shelf_name
                                                                      }
                                                                    </td>
                                                                    <td className="px-3  sm:w-[180px] md:w-[180px] lg:w-[180px] xl:w-[178px] 2xl:w-[21%] text-sm 2xl:text-base text-center responsive-width1 ">
                                                                      {
                                                                        shelVal
                                                                          .image_data
                                                                          .fullness_image
                                                                      }
                                                                      %
                                                                    </td>
                                                                    <td
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        setShelfIndex(
                                                                          shelfId
                                                                        )
                                                                      }
                                                                      className="h-full flex px-3 py-4 xl:flex-wrap text-sm 2xl:text-base  text-center "
                                                                    >
                                                                      <div
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          openCardViewShelf(
                                                                            e,
                                                                            id
                                                                          )
                                                                        }
                                                                      >
                                                                        {shelVal
                                                                          .image_data
                                                                          .fullness_image <
                                                                        50 ? (
                                                                          <Tooltip
                                                                            title={
                                                                              <div>
                                                                                <img
                                                                                  className="w-fit h-fit max-h-[300px] z-10"
                                                                                  src={
                                                                                    shelVal
                                                                                      .image_data
                                                                                      .image_url
                                                                                  }
                                                                                  alt="Not found"
                                                                                />
                                                                              </div>
                                                                            }
                                                                          >
                                                                            <div className="text-red-500">
                                                                              <BsFillSuitDiamondFill className="text-2xl m-1" />
                                                                            </div>
                                                                          </Tooltip>
                                                                        ) : shelVal
                                                                            .image_data
                                                                            .fullness_image >
                                                                          80 ? (
                                                                          <Tooltip
                                                                            title={
                                                                              <div>
                                                                                <img
                                                                                  className="w-fit h-fit max-h-[300px] "
                                                                                  src={
                                                                                    shelVal
                                                                                      .image_data
                                                                                      .image_url
                                                                                  }
                                                                                  alt="Not found"
                                                                                />
                                                                              </div>
                                                                            }
                                                                          >
                                                                            <div className="text-green-500">
                                                                              <BsFillSuitDiamondFill className="text-2xl m-1" />
                                                                            </div>
                                                                          </Tooltip>
                                                                        ) : (
                                                                          <Tooltip
                                                                            title={
                                                                              <div>
                                                                                <img
                                                                                  className="w-fit h-fit max-h-[300px]"
                                                                                  src={
                                                                                    shelVal
                                                                                      .image_data
                                                                                      .image_url
                                                                                  }
                                                                                  alt="Not found"
                                                                                />
                                                                              </div>
                                                                            }
                                                                          >
                                                                            <div className="text-amber-500">
                                                                              <BsFillSuitDiamondFill className="text-2xl m-1" />
                                                                            </div>
                                                                          </Tooltip>
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <Dialog
                                                                      // fullWidth={fullWidth}
                                                                      // maxWidth={maxWidth}
                                                                      fullScreen
                                                                      open={
                                                                        openShelf
                                                                      }
                                                                      onClose={
                                                                        handleCloseShelf
                                                                      }
                                                                      PaperProps={{
                                                                        sx: {
                                                                          width:
                                                                            "100%",
                                                                          maxHeight:
                                                                            "1300px",
                                                                          background:
                                                                            "black",
                                                                          boxShadow:
                                                                            "none",
                                                                        },
                                                                      }}
                                                                    >
                                                                      {brand_name && (
                                                                        <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
                                                                          {openShelf ===
                                                                            true && (
                                                                            <div className="self-center">
                                                                              <ImCross
                                                                                onClick={
                                                                                  handleCloseShelf
                                                                                }
                                                                                className="z-20 text-xl cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
                                                                                style={{
                                                                                  right:
                                                                                    "4%",
                                                                                  top: "2%",
                                                                                }}
                                                                              />
                                                                              {/* <GoChevronLeft
                                                                            onClick={
                                                                              leftClickShelf
                                                                            }
                                                                            className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                                                                            style={{
                                                                              left: "2%",
                                                                              top: "45%",
                                                                            }}
                                                                          /> */}
                                                                              {/* <GoChevronRight
                                                                            onClick={
                                                                              rightClickShelf
                                                                            }
                                                                            className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                                                                            style={{
                                                                              right:
                                                                                "2%",
                                                                              top: "45%",
                                                                            }}
                                                                          /> */}
                                                                              <div
                                                                                id="rubikFont"
                                                                                className="fixed top-4 px-4 left-0 w-full h-20 z-10 flex flex-col space-y-1 font-semibold 2xl:font-bold text-xl md:text-2xl 2xl:text-3xl"
                                                                                style={{
                                                                                  background:
                                                                                    "rgba(0, 0, 0, 0.3)",
                                                                                }}
                                                                              >
                                                                                <span className="text-white opacity-100">
                                                                                  {/* {all_img_url[zoomImage].brand_name} */}

                                                                                  {
                                                                                    brand_name[
                                                                                      idVal
                                                                                    ]
                                                                                      .shelf_data[
                                                                                      shelfIndex
                                                                                    ]
                                                                                      .image_data
                                                                                      .brand_name
                                                                                  }
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                id="rubikFont"
                                                                                className="fixed bottom-4 px-4 left-0 w-full h-20 z-10 flex flex-col space-y-1 font-semibold 2xl:font-bold text-xl md:text-2xl 2xl:text-3xl"
                                                                                style={{
                                                                                  background:
                                                                                    "rgba(0, 0, 0, 0.3)",
                                                                                }}
                                                                              >
                                                                                <span className="text-white opacity-100">
                                                                                  {/* Fullness {all_img_url[zoomImage].fullness}% */}
                                                                                  <span>
                                                                                    Fullness{" "}
                                                                                  </span>
                                                                                  {
                                                                                    brand_name[
                                                                                      idVal
                                                                                    ]
                                                                                      .shelf_data[
                                                                                      shelfIndex
                                                                                    ]
                                                                                      .image_data
                                                                                      .fullness_image
                                                                                  }

                                                                                  %
                                                                                </span>
                                                                                <span className="text-white opacity-100">
                                                                                  {new Date(
                                                                                    brand_name[
                                                                                      idVal
                                                                                    ].shelf_data[
                                                                                      shelfIndex
                                                                                    ].image_data.date
                                                                                  ).getDate() +
                                                                                    "-" +
                                                                                    monthNames[
                                                                                      new Date(
                                                                                        brand_name[
                                                                                          idVal
                                                                                        ].shelf_data[
                                                                                          shelfIndex
                                                                                        ].image_data.date
                                                                                      ).getMonth()
                                                                                    ] +
                                                                                    "-" +
                                                                                    new Date(
                                                                                      brand_name[
                                                                                        idVal
                                                                                      ].shelf_data[
                                                                                        shelfIndex
                                                                                      ].image_data.date
                                                                                    ).getFullYear() +
                                                                                    " " +
                                                                                    new Date(
                                                                                      brand_name[
                                                                                        idVal
                                                                                      ].shelf_data[
                                                                                        shelfIndex
                                                                                      ].image_data.date
                                                                                    ).getHours() +
                                                                                    ":" +
                                                                                    new Date(
                                                                                      brand_name[
                                                                                        idVal
                                                                                      ].shelf_data[
                                                                                        shelfIndex
                                                                                      ].image_data.date
                                                                                    ).getMinutes()}
                                                                                </span>
                                                                              </div>
                                                                              <TransformWrapper>
                                                                                <TransformComponent>
                                                                                  <img
                                                                                    className="self-center"
                                                                                    style={{
                                                                                      maxHeight:
                                                                                        "95svh",
                                                                                    }}
                                                                                    // src={all_img_url[zoomImage].img_url}
                                                                                    src={
                                                                                      brand_name[
                                                                                        idVal
                                                                                      ]
                                                                                        .shelf_data[
                                                                                        shelfIndex
                                                                                      ]
                                                                                        .image_data
                                                                                        .image_url
                                                                                    }
                                                                                    alt="img not found"
                                                                                  />
                                                                                </TransformComponent>
                                                                              </TransformWrapper>
                                                                            </div>
                                                                          )}
                                                                        </DialogContent>
                                                                      )}
                                                                    </Dialog>
                                                                  </tr>
                                                                );
                                                              }
                                                            )}
                                                          </React.Fragment>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </React.Fragment>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    }
                  })
                ) : (
                  <div>NO Data Found </div>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5} className="w-full">
                    <div className="w-full flex justify-center">
                      <NewLoader />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
