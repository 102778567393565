import React, { useState } from "react";
import axios from "axios"; 
// import { withRouter } from "react-router-dom";
// import Checkbox from "@mui/material/Checkbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
// import { BsCircle, BsCheckCircleFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { ImLocation } from "react-icons/im";
// import MetaTags from "react-meta-tags";
import SideNav from "../others/sidenav";
import ContactUsHeader from "./header";
import toast, { Toaster } from "react-hot-toast";
import "./contactUs.css";

export default function ContactUs() {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:8081/data/contactUs/send-email", formData);
      if (response.status === 200) {
        toast.success("Message sent successfully")
        window.location.reload(false);
      } else {
        toast.error("Error sending the message.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  return (
    <>
      <div className="w-full h-screen flex bg-gray-100 overflow-y-auto md:scrollbar">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen hidden md:block"></div>

        <div className=" w-full lg:w-5/6 h-full bg-gray-100">
          <div className="w-full bg-white">
            <ContactUsHeader />
          </div>
          <div className="w-full h-16 hidden md:block"></div>
          <div className="flex-grow py-5 md:px-10 px-4 flex flex-row">
            <div className="why-us-contact" class="contact-us">
              <div className="flex flex-col sm:flex-row">
                <div className="w-full sm:w-[40%]">
                  <div className="contact-div-first-home rounded-t-lg sm:rounded-tr-none sm:rounded-l-lg">
                    <Fade bottom duration={1400}>
                      <p className="contact-div1-title">Contact Information</p>
                      <p className="contact-div1-subtitle">
                        Fill up the form and our team will get back to you in 24
                        hrs
                      </p>
                      <div className="contact-icon-first-home">
                        <Row>
                          <Col md={1} xs={2} sm={2}>
                            <FaPhoneAlt size={22} className="loc-icons" />
                          </Col>
                          <Col md={5} xs={10} sm={10}>
                            <a href={"tel: +918826627310"}>
                              <p className="contact-div1-subtitle-home number">
                                +91 702-170-2920
                              </p>
                            </a>
                          </Col>
                        </Row>
                      </div>
                      <div className="contact-icon-second-home">
                        <Row>
                          <Col md={1} xs={2} sm={2}>
                            <IoIosMail size={28} className="loc-icons" />
                          </Col>
                          <Col md={4} xs={10} sm={10}>
                            <p
                              className="contact-div1-subtitle-home number"
                              onClick={() =>
                                (window.location = "mailto:admin@neophyte.live")
                              }
                            >
                              disha@neophyte.ai
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="contact-icon-second-home">
                        <Row>
                          <Col md={1} xs={2} sm={2}>
                            <ImLocation size={28} className="loc-icons" />
                          </Col>
                          <Col md={9} xs={10} sm={10}>
                            <p
                              className="contact-div1-subtitle-home number-2"
                              onClick={() =>
                                window.open(
                                  "https://maps.google.com?q=" +
                                    19.05033358336438 +
                                    "," +
                                    73.06530312559231
                                )
                              }
                            >
                              Plot No 4, Shop No 5,
                              <br />
                              Vishwakarma / Annapurna Mahila Mandal CHS, Sector
                              21, Kharghar Navi Mumbai, Maharashtra 410210
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="sc-hm-one-contact rounded-b-lg sm:rounded-bl-none sm:rounded-r-lg">
                    <div className="sc-hm-one-in rounded-b-lg sm:rounded-bl-none sm:rounded-r-lg">
                      <div className="home-contact-inner rounded-r-lg">
                        <Fade bottom duration={1400}>
                          <input
                            className="in-cus-ct input_"
                            type="text"
                            placeholder="Name"
                            enterKeyHint="next"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                          <input
                            className="in-cus-ct input_"
                            type="text"
                            placeholder="Email"
                            inputMode="email"
                            enterKeyHint="next"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />

                          <input
                            className="in-cus-ct input_"
                            type="number"
                            placeholder="Phone Number"
                            inputMode="number"
                            enterKeyHint="next"
                            name="number"
                            value={formData.number}
                            onChange={handleChange}
                            required
                          />
                          {/* <div
                            style={{ height: 35 }}
                            className="contact-radio-selector-forMobile"
                          >
                            <Row>
                              <Col md={4} xs={4} sm={4}>
                                <center>
                                  <Checkbox
                                    icon={
                                      <BsCircle
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                    checkedIcon={
                                      <BsCheckCircleFill
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                  />
                                  <br></br>
                                  <span className="newDemo-radio-txt">
                                    Demo
                                  </span>
                                </center>
                              </Col>
                              <Col md={4} xs={4} sm={4}>
                                <center>
                                  <Checkbox
                                    icon={
                                      <BsCircle
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                    checkedIcon={
                                      <BsCheckCircleFill
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                  />
                                  <br></br>
                                  <span className="newDemo-radio-txt">
                                    Consult
                                  </span>
                                </center>
                              </Col>
                              <Col md={4} xs={4} sm={4}>
                                <center>
                                  <Checkbox
                                    icon={
                                      <BsCircle
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                    checkedIcon={
                                      <BsCheckCircleFill
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                  />
                                  <br></br>
                                  <span className="newDemo-radio-txt">
                                    Enquiry
                                  </span>
                                </center>
                              </Col>
                            </Row>
                          </div> */}
                          {/* <div
                            style={{ height: 35 }}
                            className="contact-radio-selector-forPC"
                          >
                            <Row>
                              <Col md={4}>
                                <center>
                                  <Checkbox
                                    icon={
                                      <BsCircle
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                    checkedIcon={
                                      <BsCheckCircleFill
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                  />
                                  <span className="newDemo-radio-txt">
                                    Demo Request
                                  </span>
                                </center>
                              </Col>
                              <Col md={4}>
                                <center>
                                  <Checkbox
                                    icon={
                                      <BsCircle
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                    checkedIcon={
                                      <BsCheckCircleFill
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                  />
                                  <span className="newDemo-radio-txt">
                                    Consultation
                                  </span>
                                </center>
                              </Col>
                              <Col md={4}>
                                <center>
                                  <Checkbox
                                    icon={
                                      <BsCircle
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                    checkedIcon={
                                      <BsCheckCircleFill
                                        size={20}
                                        className="chbk-icons"
                                      />
                                    }
                                  />
                                  <span className="newDemo-radio-txt">
                                    Enquiry
                                  </span>
                                </center>
                              </Col>
                            </Row>
                          </div> */}
                          <br className="br-forMobile"></br>
                          <textarea
                            className="in-cus-ct messageInput"
                            type="text"
                            placeholder="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                          />
                          <center>
                            <button onClick={handleSubmit} className="requestQuote-homecontact ">
                              Submit
                            </button>
                          </center>
                        </Fade>
                        <Toaster />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
