import React, { useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import Loaders from "../others/loader";
import axios from "axios";
// import token from "../Authservice/authToken";
import ImageModal from "./ImageModal";
// import { Box } from "@mui/material";
// import summaryHeader from "./summaryHeader";

// import { OfflineShareTwoTone } from "@mui/icons-material";

function BarChart(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.register(CategoryScale, LinearScale, BarElement);

  const chartValue = props.data;
  // console.log("chart datas", chartValue);

  var imgURL = "";
  if (chartValue) {
    imgURL = chartValue.data.Img_url;
  }
  // console.log("imgURL", imgURL)
  

  // const url = "http://216.48.181.202:9898";
  // const url = "http://216.48.183.210:9898";
  // const url = "http://localhost:9898";

  // Api integration
  // const [analysisDataLamb, setAnalysisDataLamb] = useState(false);
  const [analysisData, setAnalysisData] = useState(false);
  const lambUrl = "https://9j28o7b639.execute-api.ap-south-1.amazonaws.com/Dev";
  // const getLambAllUrlAnalysis = async () => {
  //   const allData = {
  //     Img_url: imgURL,
  //   };
  //   // console.log("img data", stringUrl);

  //   try {
  //     const res = await axios.post(
  //       `https://9j28o7b639.execute-api.ap-south-1.amazonaws.com/Dev/getalll`,
  //       allData,
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: await token(),
  //         },
  //       }
  //     );
  //     // console.log(res.data);

  //     setAnalysisDataLamb(res);
  //   } catch (error) {
  //     console.log("Error Calling all Store API: ", error);
  //   }
  // };

  const getAllUrlAnalysis = async () => {
    const allData = {
      Img_url: imgURL,
    };

    // console.log()
    // console.log("img data", stringUrl);

    // try {
    //   const res = await axios.post(
    //     `http://216.48.183.210:9898/media/static/getAll`,
    //     allData,
    //     {
    //       headers: {
    //         Accept: "application/json",
    //         Authorization: await token(),
    //       },
    //     }
    //   );
    //   // console.log(res.data);

    //   // setAnalysisData(res.data.data);
    // } catch (error) {
    //   console.log("Error Calling all Store API: ", error);
    // }

    try {
      // console.log("allData data comes", allData);
      const res = await axios.post(`${lambUrl}/getalll`, allData, {
        headers: {
          Accept: "application/json",
          // Authorization: await token(),
        },
      });
      // console.log(res.data);

      setAnalysisData(res.data);
    } catch (error) {
      console.log("Error Calling all Store API: ", error);
    }
  };
  // console.log("a", analysisDataLamb);
  // console.log("all Data ", analysisData);
  let captureCompletd = "";
  if (chartValue) {
    captureCompletd = chartValue.data.one_day.captures_completed;
  }
  // console.log("capture", captureCompletd);

  let storeData = "";
  if (analysisData) {
    storeData = Object.values(analysisData).map((val) => val.store);
  }

  // console.log("storedata", storeData);
  let pieChart_value = "";
  let pieChart_value_filed = "";
  let pieChart_no_detction = "";
  if (chartValue) {
    pieChart_value = chartValue.data.one_day.pie_chart.Empty;
    pieChart_value_filed = chartValue.data.one_day.pie_chart.Filled;
    pieChart_no_detction = chartValue.data.one_day.pie_chart.No_Detections;
  }

  //   Average value of emptyness
  const avrg_empty =
    (pieChart_value * 100) /
    (pieChart_value + pieChart_value_filed + pieChart_no_detction);
  const avrg_filled =
    (pieChart_value_filed * 100) /
    (pieChart_value + pieChart_value_filed + pieChart_no_detction);
  const avrg_no_detection =
    (pieChart_no_detction * 100) /
    (pieChart_value + pieChart_value_filed + pieChart_no_detction);

  const options = {
    maintainAspectRation: true,
    responsive: true,
    // plugins: {
    //   legend: {
    //     position: "top",
    //   },
    //   title: {
    //     display: true,
    //     text: "Fullness (%)",
    //   },
    // },
  };

  const optionsBar = {
    maintainAspectRation: true,

    responsive: true,
    scales: {
      x: { title: { display: true, text: "Timestamps", color: "black" } },
      y: {
        title: {
          display: true,
          text: "Average shelf-fullness (%)",
          color: "black",
        },
        max: 100,
      },
    },

    // scales: {
    //   y: {
    //     max: 100,
    //   },
    // },
    plugins: {
      legend: {
        position: "top",
      },

      // title: {
      //   display: true,
      //   text: "Time series",
      // },
    },
  };

  // const plugins = [
  //   {
  //     beforeDraw: function (chart) {
  //       var width = chart.width,
  //         height = chart.height,
  //         ctx = chart.ctx;
  //       ctx.restore();
  //       var fontSize = (height / 270).toFixed(2);
  //       ctx.font = fontSize + "em sans-serif";
  //       ctx.textBaseline = "top";
  //       var text = "Total: " + captureCompletd,
  //         textX = Math.round((width - ctx.measureText(text).width) / 2),
  //         textY = height / 1.85;
  //       ctx.fillText(text, textX, textY);
  //       ctx.save();
  //     },
  //   },
  // ];
  // const drawerWidth = 260;

  return (
    <div
      className="w-10/12 mx-auto h-full"
      // sx={{
      //   // width: {
      //   //   p: 14,
      //   //   xl: `calc(100% - ${drawerWidth}px)`,
      //   //   lg: `calc(96% - ${drawerWidth}px)`,
      //   // },
      //   width: "83.33%",
      //   m: "auto",
      //   // pb: 10,
      //   height: "100%",
      // }}
    >
      <div className="flex space-y-4 flex-col">
        <div className="pt-4 m-auto md:m-0 w-10/12 md:w-11/12 flex 2xl:pl-2 flex-col md:pl-0 space-y-4 lg:space-y-7 2xl:space-y-14 ">
          {/* <div className="hidden md:block font-sans font-semibold text-gray-700 text-3xl 2xl:text-4xl">
            Summary
          </div> */}
          {chartValue && (
            <span
              id="rubikFont"
              className="text-xl text-center md:text-left 2xl:text-2xl font-md xl:pt-2 2xl:pt-6"
            >
              Total captures: {captureCompletd}
            </span>
          )}
        </div>

        <div className="flex md:flex-row flex-col md:w-10/12 xl:w-full self-center md:space-x-6 space-x-0 md:space-y-0 space-y-4 2xl:mt-3 items-center">
          {!chartValue ? (
            <div className="w-1/2 self-center">
              <Loaders />
            </div>
          ) : (
            <div className=" bg-white  h-5/6   w-[22rem] md:w-1/3 rounded-xl  shadow-md ">
              <div className="text-center w-full mt-2 tracking-wide text-xs font-bold ">
                Shelf-fullness Distribution (%)
              </div>
              {chartValue.data.one_day.captures_completed === 0 ? (
                <div className="font-bold text-lg text-center w-full  h-80 mt-12">
                  No data available{" "}
                </div>
              ) : (
                <div className="md:p-4 xl:p-2 2xl:p-4  w-10/12   m-auto md:w-full ">
                  {/* {chartValue.data.one_day} */}
                  <Doughnut
                    options={options}
                    data={{
                      labels: Object.keys(chartValue.data.one_day.pie_chart),
                      datasets: [
                        {
                          data: [
                            avrg_empty.toFixed(2),
                            avrg_filled.toFixed(2),
                            avrg_no_detection.toFixed(2),
                          ],
                          backgroundColor: [
                            "rgb(255,82,82)",

                            "rgb(52,211,153)",

                            "rgb(96,125,139)",
                          ],

                          hoverOffset: 6,
                        },
                      ],
                    }}
                    // plugins={plugins}
                    height="290"
                  />
                </div>
              )}
            </div>
          )}

          {chartValue ? (
            // <div className=" bg-white rounded-xl h-full xl:w-7/12 2xl:w-2/3 w-full shadow-md ">
            <div className="bg-white rounded-xl w-[22rem] md:w-3/5 shadow-md h-80 md:h-5/6">
              <div className="text-center flex flex-col w-full mt-2 tracking-wide text-sm font-bold ">
                <div>Time-series Analysis (%)</div>
              </div>

              {/* <Chart
                options={areaData.options}
                series={areaData.series}
                type="bar"
                // height={460}
              /> */}

              <div className="w-full flex h-full">
                {/* <div className="md:block border border-black hidden	text-[10px] xl:w-10 2xl:w-10 mt-40 h-4 -rotate-90 tracking-wide text-center font-bold ">
                  <span>Shelf fullness(%)</span>
                </div> */}

                {/* FOR MOBILE SCREEN */}
                <div className="md:hidden w-full h-full pr-4 relative">
                {/* <div id="rubikFont" className="text-sm bg-emerald-600 text-white px-1 rounded-md absolute top-8 right-5">
                    <span>Avg Fullness: 83%</span>
                  </div> */}
                  <Bar
                    options={optionsBar}
                    data={{
                      labels: Object.keys(
                        chartValue.data.seven_day.seven_day_res_filled
                      ),

                      datasets: [
                        {
                          label: "Fullness",

                          data: Object.values(
                            chartValue.data.seven_day.seven_day_res_filled
                          ),
                          backgroundColor: (context) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(
                              0,
                              0,
                              0,
                              700
                            );
                            gradient.addColorStop(0, "rgba(16, 185, 129,1)");
                            gradient.addColorStop(1, "rgba(110,231,183,1)");
                            return gradient;
                          },

                          // backgroundColor: [
                          //   // "rgb(255,82,82)",
                          //   // "rgb(30 64 175)", this color was for filled in bar and graph

                          //   "rgb(52,211,153)",

                          //   // "rgb(96,125,139)",
                          // ],
                          borderRadius: 5,
                        },
                        // {
                        //   label: "Empty",
                        //   data: Object.values(
                        //     chartValue.data.seven_day.seven_day_res
                        //   ),

                        //   backgroundColor: "rgb(255,82,82)",
                        // },
                      ],
                    }}
                    height="415"
                    width="480"
                  />
                  {/* <div className="text-sm  font-bold w-full tracking-wide	 text-center">
                    Timestamps
                  </div> */}
                </div>

                {/* FOR LARGER THAN MOBILE SCREEN */}
                <div className="hidden md:block w-full h-full pr-5 relative">
                  {/* <div id="rubikFont" className="text-sm bg-emerald-600 text-white px-1 rounded-md absolute top-8 right-10">
                    <span>Avg Fullness: 83%</span>
                  </div> */}
                  <Bar
                    options={optionsBar}
                    data={{
                      labels: Object.keys(
                        chartValue.data.seven_day.seven_day_res_filled
                      ),

                      datasets: [
                        {
                          label: "Fullness",

                          data: Object.values(
                            chartValue.data.seven_day.seven_day_res_filled
                          ),
                          backgroundColor: (context) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(
                              0,
                              0,
                              0,
                              700
                            );
                            gradient.addColorStop(0, "rgba(16, 185, 129,1)");
                            gradient.addColorStop(1, "rgba(110,231,183,1)");
                            return gradient;
                          },

                          // backgroundColor: [
                          //   // "rgb(255,82,82)",
                          //   // "rgb(30 64 175)", this color was for filled in bar and graph

                          //   "rgb(52,211,153)",

                          //   // "rgb(96,125,139)",
                          // ],
                          borderRadius: 5,
                        },
                        // {
                        //   label: "Empty",
                        //   data: Object.values(
                        //     chartValue.data.seven_day.seven_day_res
                        //   ),

                        //   backgroundColor: "rgb(255,82,82)",
                        // },
                      ],
                    }}
                    height="307"
                    width="540"
                  />
                  {/* <div className="text-sm  font-bold w-full tracking-wide	 text-center">
                    Timestamps
                  </div> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="w-1/2 self-center">
              <Loaders />
            </div>
          )}
        </div>
      </div>

      <div className="md:my-8 my-2 text-center">
        <ImageModal data={storeData} handleClick={getAllUrlAnalysis} />
      </div>
    </div>
  );
}
export default BarChart;
