import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Badge, Divider } from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
// import CampaignIcon from "@mui/icons-material/Campaign";
import { GETALLNOTIFICATIONAPI } from "../../../Authservice/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import io from "socket.io-client";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// import { styled } from "@mui/material/styles";
// import Avatar from "@mui/material/Avatar";
import { GetALLUserAPI } from "../../../Authservice/api";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { HiSpeakerphone } from "react-icons/hi";
// const ENDPOINT = 'wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test';
import {
  BROADCASTNOTIFICATIONICONSDATA,
  BROADCASTNOTIFICATION,
} from "../../../Authservice/api";
export default function BroadcastNotification(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notification, setNotification] = useState(false);
  const [allBroadcastData, setBroadcastData] = useState(false);
  const store = props.store;

  const userss = localStorage.getItem("users");
  const userDatas = JSON.parse(userss);

  let storesData;
  let user_roles;

  if (userDatas) {
    storesData = userDatas.data.stores;
    user_roles = userDatas.data.user_role;
  }

  useEffect(() => {
    async function getData() {
      try {
        const finalData = {
          role: user_roles,
          storeID: storesData,
        };
        const data = await BROADCASTNOTIFICATION(finalData);
        if (data) {
          // console.log("Use Effect data recive",data.data.data )
          setBroadcastData(data.data.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    getData();
    // eslint-disable-next-line
  }, []);

  const [messagess, setMessagess] = useState([]);
  const socket = new WebSocket(
    "wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test"
  );

  useEffect(() => {
    socket.onopen = () => {
      console.log("socket connected");
      const userName = user_roles;
      // console.log("userName", userName);
      const payload = {
        action: "setName",
        userName,
      };

      socket.send(JSON.stringify(payload));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.message.notification === undefined) {
        // console.log("not selecte");
      } else {
        // setMessagess(data.message.notification); setChat((oldChats) => [data.data, ...oldChats]);
        setMessagess((oldData) => [data.message.notification, ...oldData]);
      }
    };

    socket.onclose = () => {
      // console.log("WebSocket disconnected");
    };

    return () => {
      socket.close();
      // console.log("WebSocket disconnected");
    };
    // eslint-disable-next-line
  }, [socket]);

  // const [
  //   notificationCount2,
  //   //  setNotificationCount2
  // ] = useState(0);

  const getnotification = async () => {
    try {
      const data = await GETALLNOTIFICATIONAPI();
      // console.log("oktest", data.data);

      setNotification(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("all notification ", notification);
  let notificationCheck = "";
  if (notification) {
    notificationCheck = notification.data;
  }

  const open = Boolean(anchorEl);
  const handleClick = async (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setMessagess([]);

    try {
      const finalData = {
        role: user_roles,
        storeID: storesData,
      };
      const data = await BROADCASTNOTIFICATIONICONSDATA(finalData);
      if (data) {
        // console.log("get the data ", data.data)
        setBroadcastData(data.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    // socket.emit("emitvalue", notificationCount);
    getnotification();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme();
  theme.components.MuiIcon = {
    "@media (min-width:600px)": {
      fontSize: "medium",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "large",
    },
  };

  // match store value with notification store id and update the object of Get all Notification
  // console.log("notification", notification)
  let notification_length = "";
  let notification_value = "";
  if (notification) {
    notification_length = notification.data.length;
    notification_value = notification.data;
  }

  let storeData = "";
  if (store) {
    storeData = store;
  }
  // console.log("storeData", storeData)

  for (let i = 0; i < notification_length; i++) {
    let filterStore_ID = "";

    filterStore_ID = storeData.filter((store) => {
      return store._id === notification_value[i].storeId;
    });

    // let copyofFilter_store_name = "";
    if (filterStore_ID.length === 0) {
    } else {
      notification_value[i].storeId = filterStore_ID[0].name;
    }
  }
  // console.log("update_notification", notification_value)

  //Styled Icon
  // const StyledBadge = styled(Badge)(({ theme }) => ({
  //   "& .MuiBadge-badge": {
  //     backgroundColor: "#c70606",
  //     color: "#c70606",
  //     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  //     "&::after": {
  //       position: "absolute",
  //       top: 0,
  //       left: 0,
  //       width: "100%",
  //       height: "100%",
  //       borderRadius: "50%",
  //       animation: "ripple 1.2s infinite ease-in-out",
  //       border: "1px solid currentColor",
  //       content: '""',
  //     },
  //   },
  //   "@keyframes ripple": {
  //     "0%": {
  //       transform: "scale(.8)",
  //       opacity: 1,
  //     },
  //     "100%": {
  //       transform: "scale(2.4)",
  //       opacity: 0,
  //     },
  //   },
  // }));

  // Get all users
  const [user, setUser] = useState(false); //store the user value
  useEffect(() => {
    async function getUsers() {
      try {
        const data = await GetALLUserAPI();
        if (data) {
          setUser(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getUsers();
  }, []);
  // console.log("Get all user ",notification_value)
  for (let i = 0; i < notification_length; i++) {
    let filterUser_ID = "";

    filterUser_ID = user.filter((user) => {
      return user.number === notification_value[i].userID;
    });

    // let copyofFilter_store_name = "";
    if (filterUser_ID.length === 0) {
    } else {
      notification_value[i]["userName"] = filterUser_ID[0].user_name;
    }
  }

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Nov",
    "Dec",
  ];

  // GET NOTIFICATION COUNT

  const notificationCount = [];
  if (allBroadcastData) {
    for (let count = 0; count < allBroadcastData.length; count++) {
      if (allBroadcastData[count].active === false) {
        notificationCount.push(allBroadcastData[count].active);
      }
    }
  }

  if (messagess.length === 0) {
    // console.log("not working this ")
  } else {
    for (let i = 0; i < messagess.length; i++) {
      notificationCount.push(messagess[i]);
    }
  }

  return (
    <>
      {user_roles &&
        (user_roles === "Super Admin" || user_roles === "NHQ" ? (
          <></>
        ) : (
          <Box>
            <Badge
              badgeContent={notificationCount.length}
              color="primary"
              className="hover:cursor-pointer"
            >
              <ThemeProvider theme={theme}>
                <Tooltip title="Broadcasts">
                  <div className="relative">
                    <HiSpeakerphone
                      onClick={handleClick}
                      className="hover:cursor-pointer text-3xl text-gray-400 relative"
                    />
                    {/* {notificationCount.length === 0 ? (
                    <></>
                  ) : (
                    <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white rounded-full">{notificationCount.length}</div>
                  )} */}
                    {/* {messagess === undefined ? (
                    notificationCount.length === 0 ? (
                      <></>
                    ) : (
                      <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white rounded-full"></div>
                    )
                  ) : (
                    <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white rounded-full"></div>
                  )} */}
                  </div>
                </Tooltip>
              </ThemeProvider>
            </Badge>
          </Box>
        ))}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            borderRadius: 2,
            maxWidth: 400,
            maxHeight: "86%",
            overflowY: "auto",
            margin: 0,
            padding: 1,
            listStyle: "none",
            height: "100%",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              margin: 1,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.3)",
              borderRadius: 2,
            },

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {notification ? (
          notificationCheck.length > 0 ? (
            <div>
              {/* {notification.data.map((val, id) => { */}
              {/* return ( */}

              <div>
                <div>
                  <div className="text-xs flex justify-between  pt-1.5 pb-2 px-4">
                    <span className="font-semibold text-slate-400 uppercase">
                      Broadcasts Received
                    </span>
                    <Link to={`/chatPage`}>
                      <span className="font-semibold text-blue-500 underline hover:cursor-pointer">
                        View All
                      </span>
                    </Link>
                  </div>
                  {allBroadcastData &&
                    allBroadcastData.map((val, id) => {
                      return (
                        <ul>
                          <li className="border-b border-slate-200 last:border-0">
                            <Link
                              className="block hover:bg-purple-100 py-2 px-4 rounded-md"
                              to={`/chatPage`}
                            >
                              <span className="text-sm mb-2 flex flex-col">
                                <span className="font-medium text-slate-800">
                                  📣 {val.title}
                                </span>{" "}
                                <span class="block-ellipsis">{val.body}</span>
                              </span>
                              <span className="flex space-x-1  justify-end text-xs font-medium text-slate-400">
                                <span>{new Date(val.updatedAt).getDate()}</span>
                                <span>
                                  {month[new Date(val.updatedAt).getMonth()]}
                                </span>
                                <span>
                                  {new Date(val.updatedAt).getFullYear()}
                                </span>
                                <span>at</span>
                                <div>
                                  <span>
                                    {new Date(val.updatedAt).getHours() < 10 ? (
                                      <>0{new Date(val.updatedAt).getHours()}</>
                                    ) : (
                                      <>{new Date(val.updatedAt).getHours()}</>
                                    )}{" "}
                                  </span>
                                  :
                                  <span>
                                    {new Date(val.updatedAt).getMinutes() <
                                    10 ? (
                                      <>
                                        0{new Date(val.updatedAt).getMinutes()}
                                      </>
                                    ) : (
                                      <>
                                        {new Date(val.updatedAt).getMinutes()}
                                      </>
                                    )}
                                  </span>
                                </div>
                              </span>
                            </Link>
                          </li>
                        </ul>
                      );
                    })}
                </div>
                <Divider light="true" />
              </div>

              {/* ); */}
              {/* })} */}
            </div>
          ) : (
            <>
              <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}

                {/* For other variants, adjust the size with `width` and `height` */}
                <div className="flex space-x-2">
                  <Skeleton variant="circular" width={40} height={40} />
                  <div className="w-9/12">
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </div>
                </div>

                <Skeleton variant="rectangular" width={210} height={60} />
                <Skeleton variant="rounded" width={210} height={60} />
                <div className="text-lg font-bold text-emerald-500">
                  No notification
                </div>
              </Stack>
            </>
          )
        ) : (
          <>
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}

              {/* For other variants, adjust the size with `width` and `height` */}
              <div className="flex space-x-2">
                <Skeleton variant="circular" width={40} height={40} />
                <div className="w-9/12">
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </div>
              </div>

              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
            </Stack>
          </>
        )}
      </Menu>
    </>
  );
}
