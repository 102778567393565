import axios from "axios";
import token from "./authToken";
// const testurl = 'http://216.48.181.202:9889';
// const localUrl= 'http://localhost:8080';
// const lambUrl = "https://18nowtq8u6.execute-api.ap-south-1.amazonaws.com/prod"

// const storeID="62da9a21693aef833b4f80d8";
// const datestr="2022-07-23T11:48:27.155Z";
// http://localhost:8080/data/brand/getAll
const url = "http://216.48.183.210:9889";
const lambUrl = "https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev";
const shelf_lambda = "https://jqrmkbg6sk.execute-api.ap-south-1.amazonaws.com/prod";

export const GetAllStoreVerifiedReport = async () => {
  try {
    const res = await axios.get(`${lambUrl}/report/verified_shelf`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling Verified API: ", error);
  }
};

// GET UNIQUE APK ID  GetAllUniqueApk
export const GetAllUniqueApk = async () => {
  try {
    const res = await axios.get(`${lambUrl}/user/unique_apk_versions`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling Verified API: ", error);
  }
};
export const GetAllStorePendingReport = async () => {
  try {
    const res = await axios.get(`${lambUrl}/report/pending_shelf`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling Verified API: ", error);
  }
};

export const GetAllStoreRejectedReport = async () => {
  try {
    const res = await axios.get(`${lambUrl}/report/rejected_shelf`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling Verified API: ", error);
  }
};

export const GetAllStoreReport = async () => {
  try {
    const res = await axios.get(`${lambUrl}/report/all_store`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling Verified API: ", error);
  }
};

export const GetAllUserReport = async () => {
  try {
    const res = await axios.get(`${lambUrl}/report/all_users`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling User API: ", error);
  }
};

export const GetAllUserInstallReport = async (verson) => {
  try {
    const res = await axios.get(`${lambUrl}/report/install_app?version=${verson}`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return res;
  } catch (error) {
    console.log("Error Calling User Install API: ", error);
  }
};

export const GetAllUserNotInstallReport = async () => {
  try {
    const res = await axios.get(`${lambUrl}/report/not_install_app`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    
    return res;
  } catch (error) {
    console.log("Error Calling user not install API: ", error);
  }
};

export const GetALLStoreAPI = async () => {
  try {
    const res = await axios.get(`${lambUrl}/stores`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};


export const UPDATE_USER = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/user/updateuser`,data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};



export const UPDATE_SOP = async (final_data) => {
  try {
    const res = await axios.post(`${lambUrl}/sop/update_sop`, final_data,{
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};



// API INETEGRATION FOR GET ALL THE STORE AS FOR ROLE BASED
export const GetALLStoreAPI_ROLE = async (storeIDs) => {
  const stores = {
    store_id: storeIDs,
  };
  try {
    const res = await axios.post(`${lambUrl}/stores/geall_role`, stores, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// API INTEGRATION  FOR GET THE FULLNESS AND IMAGE OF THE STORE
export const GETFULLNESSIMAGE = async () => {
  try {
    const res = await axios.get(`${lambUrl}/stores/store_images_fullness`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// export const GetALLStoreAPI = async () => {
//   try {
//     const res = await axios.get(`${testurl}/data/store/stores`, {
//       headers: {
//         Accept: "application/json",
//         Authorization: await token(),
//       },
//     });
//     // console.log(res.data);
//     return res;
//   } catch (error) {
//     console.log("Error Calling all Store API: ", error);
//   }
// };

// get fullness and capture completed store wise API Integration
export const GetFullnessCaptures = async () => {
  try {
    const res = await axios.get(`${lambUrl}/stores/getfullnescapture`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const GetALLCaptureProgress = async (data) => {
  try {
    const res = await axios.post(
      `${lambUrl}/shelf/totalimage_capture_role`,
      data,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log("res",res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const GetallStoreTableApi = async (data) => {
  try {
    const res = await axios.post(
      `${lambUrl}/stores/getstores_pagi_role`,
      data,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
  ;

    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// delete Store api
export const DeleteStore = async (id) => {
  try {
    const final_data ={
      storeID: id
    }
    const data = await axios.post(`${lambUrl}/stores/delete`,final_data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return data;
    // console.log(data.data)
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// get store API
export const GetstoreDetails = async (id) => {
  // console.log("store id is here ", id)
  try {
    const data = await axios.get(`${lambUrl}/data/store/get/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return data;
    //  console.log(data.data)
  } catch (error) {
    console.log(error);
  }
};

//delete User api
export const DeleteUser = async (id) => {
  // console.log("api user id", id)
  try {
    const data = await axios.get(`${lambUrl}/user/delete_user?userId=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    return data;
    // console.log(data.data)
  } catch (error) {
    console.log("user Error", error);
  }
};

// get all user
export const GetALLUserAPI = async () => {
  try {
    const res = await axios.get(`${lambUrl}/user`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// get all user based on paggination
export const GetALLUserAPI_PAGI = async (page, size) => {
  try {
    const res = await axios.get(
      `${lambUrl}/user/pagi_getuser?page=${page}&size=${size}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const GetALLUserAPI_PAGI_ROLE = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/user/getall_pagi_role`, data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const SaveForRetrainingAPI = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/training/create`, data, {
      headers: {
        Accept: "application/json",
        // Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const GetImageID = async (imageId) => {
  try {
    const res = await axios.get(
      // `http://localhost:8081/data/training/checkImageID?imageId=${imageId}`,
      `${lambUrl}/training/check?imageId=${imageId}`,
      {
        headers: {
          Accept: "application/json",
          // Authorization: await token(),
        },
      }
    );
    console.log("response from imageid api",res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Analysis API: ", error);
  }
};


// export const GetALLUserAPI_PAGI_ROLE = async (data) => {
//   try {
//     const res = await axios.post(`http://localhost:8081/data/user/getall_pagi_role`, data, {
//       headers: {
//         Accept: "application/json",
//         Authorization: await token(),
//       },
//     });
//     return res;
//   } catch (error) {
//     console.log("Error Calling all Store API: ", error);
//   }
// };

// export const galleryMedia = async () => {
// 	try {
// 		const res = await axios.get(`${url}/media/static/getAll`, {
// 			headers: {
// 				Accept: 'application/json',
// 			},
// 		});
// console.log(res.data);
// 		return res;
// 	} catch (error) {
// 		console.log('Error Calling all Store API: ', error);
// 	}
// };

export const GetAnalysisCard = async (storeID, datestr) => {
  try {
    const res = await axios.get(
      `${url}/data/analysis/getAnalysis/${storeID}/${datestr}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Analysis API: ", error);
  }
};

//  get date wise store wise analysis and img url

export const getAllAnalysisUrl = async () => {
  try {
    const res = await axios.get(`${lambUrl}/data/analysis/getImagesAnalysis`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const GETALLNOTIFICATIONAPI = async () => {
  try {
    const res = await axios.get(`${lambUrl}/notification/getall`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const GETSHELVESDETAILS = async (values) => {
  const shelvesID = {
    ShelfID: values,
  };
  // console.log("shelvesID", shelvesID)
  try {
    const res = await axios.post(
      `${lambUrl}/data/shelf/shelves_brand_details`,
      shelvesID,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    return res;
    // console.log(res)
  } catch (error) {
    console.log(error);
  }
};

//  get all brand Api

export const GetAllBrandApi = async () => {
  try {
    const res = await axios.get(`${lambUrl}/brands`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

//  get all Shlef Api

export const GetAllShelfApi = async () => {
  try {
    const res = await axios.get(`${lambUrl}/data/shelf/getAll`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// get all chat api
export const GETALLCHAT = async () => {
  try {
    const res = await axios.get(
      `${lambUrl}/data/active_communication/allData`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// GET ALL REPORT BESED ON STORE ID'S
export const GETALLCHATIDS = async (body) => {
  try {
    const res = await axios.post(`${lambUrl}/activecom/storewiseweb`, body, {
      headers: {
        Accept: "application/json",
       
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const getChatData = async (body) => {
  try {
    const res = await axios.post('http://localhost:8081/data/active_communication/storeWiseWeb', body, {
      headers: {
        Accept: 'application/json',
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log('Error Calling storeWise API: ', error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};

export const UPDATE_SHELF_VERIFICATION_SOP = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/sop/update_sop_shelf`,data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const SHELF_VERFICATION = async (data) => {
  try {
    console.log("data", data)
    const res = await axios.post(`${shelf_lambda}/onboard-shelf`,data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const getBroadcastData = async (userId) => {
  try {
    const res = await axios.get(`http://localhost:8081/data/active_communication/createBroadcastMessage?userId=${userId}`, {
      headers: {
        Accept: 'application/json',
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log('Error Calling broadcasting API: ', error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};

// get one chat  besed on ID API

export const GETONECHAT = async (values) => {
  // console.log("id is here", values)
  try {
    const res = await axios.get(`${lambUrl}/activecom/get?activeID=${values}`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// COMMENT ON THE MESSAGE BASED ON USER
export const CREATECOMMENTCHAT = async (values, id) => {
  try {
    const res = await axios.post(
      `${lambUrl}/activecom/createcomment?commentID=${id}`,
      values,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// REMOVE STORE FROM THE USER API INTEGRATION
export const REMOVESTOREUSER = async (id, storeIDs) => {
  const data = {
    userId: id,
    storeID: storeIDs,
  };

  // console.log("remove store data", data)
  try {
    const res = await axios.post(`${lambUrl}/user/remove_store`, data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// ADD STORE FROM THE USER API INTEGRATION
export const ADDSTOREUSER = async (id, storeValue) => {
  const data = {
    userId: id,
    storeID: storeValue,
  };
  try {
    const res = await axios.post(`${lambUrl}/user/add_store`, data, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// MOBILE APPLICATION URL TEST
export const MOBILEAPPTESTANLAYSIS = async (date, storeID) => {
  try {
    const res = await axios.get(
      `${lambUrl}/getanalysis?dateStr=${date}&storeID=${storeID}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const MOBILEAPPTESTANLAYSIS0 = async (date, storeID) => {
  try {
    const res = await axios.get(
      `${lambUrl}/getanalysis/weeklyanalysis?dateStr=${date}&storeID=${storeID}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};
export const MOBILEAPPTESTANLAYSIS2 = async (date, storeID) => {
  try {
    const res = await axios.get(
      `${lambUrl}/getanalysis/piechartanalysis?dateStr=${date}&storeID=${storeID}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// GET ONE STORE API INTEGRATION

// https://g94d6drueh.execute-api.ap-south-1.amazonaws.com/dev/stores/getonestore
export const GETONESTORES = async (storeID) => {
  try {
    // console.log("storeID", storeID)
    // console.log("api Store ID", storeID); /stores/getonestore
    const res = await axios.get(
      `${lambUrl}/stores/getonestore?storeId=${storeID}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// GET SHELVES API INTEGRATION

export const GETSHELVES = async () => {
  try {
    const res = await axios.get(`${lambUrl}/sop/get_sops`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// GET USER DETAIL API INTREGRATION
export const GETUSERDETAILS = async (data) => {
  try {
    // console.log("user id is :", data)
    const res = await axios.get(
      `${lambUrl}/user/get_user_details?userId=${data}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// GET ALL THE ANALYSIS OF THE SELECTED STORE API INTEGRATION


export const GETANALYSISOFSTORE = async (storeId) => {
  try {
    const res = await axios.get(
      `${lambUrl}/stores/storewise_fullness?storeId=${storeId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

// STORE BULK UPLOAD API INTEGRATION
export const STOREBULKUPLOAD = async (storeId) => {
  try {
    const res = await axios.post(
      `${lambUrl}/stores/bulk_store_create`,
      storeId,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const USERBULKUPLOAD = async (storeId) => {
  try {
    const res = await axios.post(`${lambUrl}/user/bulk_user_create`, storeId, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};
// GET THE ALL BRANDS WITH COORDINATE

export const BRANDCOORDINATE = async () => {
  try {
    const res = await axios.get(`${lambUrl}/brands/map_analysis`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log("Error Calling all Store API: ", error);
  }
};

export const CREATEBROADCAST = async (allData) => {
  try {
    const res = await axios.post(`${lambUrl}/notification/create`, allData, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {}
};

export const BROADCASTCREATEDBYDATA = async (allData) => {
  try {
    const res = await axios.get(
      `${lambUrl}/activecom/nhq_createdby_message?userId=${allData}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    console.log("allData",allData);
    // console.log(res.data);
    return res;
  } catch (error) {}
};


export const BROADCASTNOTIFICATION = async (allData) => {
  try {
    const res = await axios.post(
      `${lambUrl}/activecom/get_broadcast_notification`,
      allData,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {}
};

export const BROADCASTNOTIFICATIONICONSDATA = async (allData) => {
  try {
    const res = await axios.post(
      `${lambUrl}/activecom/get_notification_message`,
      allData,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {}
};

// API FOR DASHBOARD CAPTURE AND SHELFULNESS CARD
export const FULLNESSCAPTURECARD = async (allData) => {
  try {
    const res = await axios.post(
      `${lambUrl}/sop/store_image_captures`,
      allData,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {}
};

// API FOR GET THE SOP VERIFICATION IMAGES FOR  ALL STORE

export const SOPVERIFICATION = async (allData) => {
  try {
    const res = await axios.post(`${lambUrl}/sop/get_store_wise_sop`, allData, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;
  } catch (error) {}
};

// API FOR ANALYSIS PAGE GET THE FILTER WISE DATA

export const ANALYSISFILTER = async (allData) => {
  try {
    const res = await axios.post(
      `${lambUrl}/analysis/analysis_filter`,
      allData,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {}
};

export const GetFilteredStoresIds = async (data) => {
  try {
    const res = await axios.post(
      `${lambUrl}/shelf/filter_storeid`,
      data,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    return res.data;
  } catch (error) {}
};
// EXPORT THE ANALYSIS DATA  /report/image_analysis
// export const EXPORT_ANALYSIS_DATA = async (data) => {
//   try {
//     const res = await axios.post(
//       `${lambUrl}/report/image_analysis`,
//       data,
//       {
//         headers: {
//           Accept: "application/json",
//           Authorization: await token(),
//         },
//       }
//     );
//     return res.data;
//   } catch (error) {}
// };

export const EXPORT_ANALYSIS_DATA = async (data) => {
  try {
    const res = await axios.post(
      `${lambUrl}/report/image_analysis`,
      data,
      {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      }
    );
    return res.data;
  } catch (error) {}
};

// GET WEIGHATGE SOP DATA  
export const GET_WEIGHATAGE_SOP = async(allData) => {
  try{
    const res = await axios.post(`${lambUrl}/sop/get_weightage`,allData, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;

  } catch(error){

  }
}


// GET ALLL THE REGION 

export const GET_STORES_REGION = async() => {
  try{
    const res = await axios.get(`${lambUrl}/stores/reqgion`, {
      headers: {
        Accept: "application/json",
        Authorization: await token(),
      },
    });
    // console.log(res.data);
    return res;

  } catch(error){

  }
}
///activecom/get_notification_message

export const GET_CAPTURE_SUMMARY = async(foramttedDate) => {
  try {
    const response = await axios.post(
      `${lambUrl}/summary/capture/analysis_data`,
      { date: foramttedDate }
    );
    console.log('summary',response)
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
  }
}

export const GET_CAPTURE_WATERFALL_CHART = async(dates,selectedPeriod) => {
  try {
    console.log("line1 executed")
    const response = await axios.post(
      `${lambUrl}/summary/capture/water_fall_chart`,
      { date: dates, period: selectedPeriod  }
    );
    console.log("line2 executed")
    console.log('capture summary chart',response)
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
  }
}

export const GET_SHELFULLNESS_SUMMARY = async(foramttedDate) => {
  try {
    const response = await axios.post(
      `${lambUrl}/summary/shelf_fullness/analysis_data`,
      { date: foramttedDate }
    );
    console.log('summary',response)
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
  }
}

export const GET_SHELFULLNESS_WATERFALL_CHART = async(dates,selectedPeriod) => {
  try {
    console.log("dates inside GET_SHELFULLNESS_WATERFALL_CHART",dates)
    const response = await axios.post(
      // 'http://localhost:8081/data/analysis/shelfFullnessBarChart',
      `${lambUrl}/summary/shelf_fullness/candlestick_chart`,
      { date: dates, period: selectedPeriod  }
    );
     console.log('waterfall',response)
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
  }
}

export const GET_STORES_SUMMARY = async(foramttedDate) => {
  try {
    const response = await axios.post(
      `${lambUrl}/summary/store/analysis_data`,
      { date: foramttedDate }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
  }
}

export const GET_STORES_WATERFALL_CHART = async(date,selectedPeriod) => {
  console.log(date)
  console.log(selectedPeriod)
  try {
    const response = await axios.post(
      `https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev/summary/store/water_fall_chart`,
      { date: date, period: selectedPeriod }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
  }
}
