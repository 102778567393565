import React from "react";
import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

//AiFillCaretDown

export default function MobileMonthWiseSelector({ selected, setSelected }) {
  const [isActive, setIsActive] = useState(false);
  const options = [
    { name: "Jan", value: 1 },
    { name: "Feb", value: 2 },
    { name: "Mar", value: 3 },
    { name: "Apr", value: 4 },
    { name: "May", value: 5 },
    { name: "Jun", value: 6 },
    { name: "Jul", value: 7 },
    { name: "Aug", value: 8 },
    { name: "Sep", value: 9 },
    { name: "Oct", value: 10 },
    { name: "Nov", value: 11 },
    { name: "Dec", value: 12 },
  ];
  const month = new Date().getMonth() + 1;
  return (
    <>
      <div id="month_dropdown" className="md:hidden border-gray-300">
        <div
          id="month_dropdown-btn"
          className="flex flex-row justify-between"
          onClick={(e) => setIsActive(!isActive)}
        >
          Select Month
          <AiFillCaretDown className="self-center" />
        </div>
        {isActive && (
          <div id="month_dropdown-content">
            {options.map((option) => (
              <div
                onClick={(e) => {
                  setSelected(option.value);
                  setIsActive(false);
                }}
                id="month_dropdown-item"
              >
                {month === option.value ? (
                  <div
                    id="month_dropdown-item_now"
                    // className="bg-emerald-500 w-full h-full rounded-md text-white"
                  >
                    {option.name}
                  </div>
                ) : selected === option.value ? (
                  <>
                    <divn id="month_dropdown-item_selected">{option.name}</divn>
                  </>
                ) : (
                  <>
                    <span>{option.name}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
