import { React
    , useEffect 
  } from "react";
  // import { GetAnalysisCard } from '../Authservice/api';
  import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
  import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
  // import { BsArrowDown, BsArrowUp } from "react-icons/bs";
  // import SouthIcon from "@mui/icons-material/South";
  import CenterFocusStrongRoundedIcon from "@mui/icons-material/CenterFocusStrongRounded";
  import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
  import { GetAllStoreVerifiedReport } from "../Authservice/api";
  // import { FULLNESSCAPTURECARD, GetALLCaptureProgress } from "../Authservice/api";
  // import { useState } from "react";
  import NewLoader from "../others/loaderUi";
import { useState } from "react";
  // import NorthIcon from "@mui/icons-material/North";
  export default function CardNew(props) {
    // console.log("props", props);
    const fullnessCapture = props.fullneCapture;
    const captureStore = props.captureStore;
  
    // const data = props.data;
    const date = props.date;
  
    var storeLength = "";
  
    //  get the user Details
    const users = localStorage.getItem("users");
    const userData = JSON.parse(users);
  
    let storesID;
  
    storesID = userData.data.stores;
  
    // if (storesID && captureStore) {
    //   storeLength = ((captureStore.length * 100) / storesID.length).toFixed(1);
    // }

    const [onboardedStore, setOnboardedStore] = useState(false)
    useEffect(() => {
      async function getData(){
        try{
          const response = await GetAllStoreVerifiedReport();
          if(response){
            setOnboardedStore(response.data)
          }
        } catch(error){
          console.log("error", error)
        }
      }
      getData();
    }, [date])
    // console.log("onboardedStore", onboardedStore) 
   
    if (storesID && captureStore) {
      storeLength = ((captureStore.length * 100) / onboardedStore.length).toFixed(1);
    }
  
    //  comparision between previous day shelfullness and Capture Complete
    // let current_day_fullness = "";
    // let prev_day_fullness = "";
    // let current_day_capture = "";
    // let prev_day_capture = "";
    // if (data) {
    //   current_day_fullness = data.one_day.shelf_fullness;
    //   prev_day_fullness = data.one_day.prev_day_shelf_fullness;
    //   current_day_capture = data.one_day.captures_completed;
    //   prev_day_capture = data.one_day.prev_day_Capture_Complete;
    // }
  
    // const comparision_fullness = current_day_fullness - prev_day_fullness;
    // const comparision_capture_completed = current_day_capture - prev_day_capture;
  
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthIndex = new Date(date).getMonth();
    let monthName = monthNames[monthIndex];
    let yearName = new Date(date).getFullYear();
    const dates = `${new Date(date).getDate()}-${monthName}-${yearName}`;
  
    // "rgb(68,190,84)",
    //   "rgb(255,191,0)",
    //   "rgb(251,23,81)",
  
    return (
      <>
        <>
          {fullnessCapture ? (
            <div className="flex items-center xl:space-x-6 space-x-4 w-full h-full px-2 py-2">
              <div
                className={`w-full h-full relative flex justify-between shadow-md rounded-2xl p-4 ${
                  fullnessCapture.fullness.toFixed(1) >= 80
                    ? "bg-[#44BE54]"
                    : fullnessCapture.fullness.toFixed(1) < 50
                    ? "bg-[#FB1751]"
                    : "bg-[#F39820]"
                }`}
              >
                <div className="w-60 xl:w-full h-36 md:h-full flex flex-col justify-between">
                  <div className="flex w-full">
                    <span className=" text-left  tracking-wide text-white font-bold ">
                      Shelf-fullness
                    </span>
                  </div>
  
                  <div className="w-full">
                    <div className="flex text-left text-4xl xl:text-3xl 2xl:text-4xl text-white font-bold">
                      {fullnessCapture.fullness.toFixed(1) > 100 ? (
                        <>100%</>
                      ) : (
                        <>{fullnessCapture.fullness.toFixed(1)}%</>
                      )}
                      
                    </div>
                  </div>
  
                  <div className="w-full flex space-x-1 items-center  font-sans  font-semibold  text-white">
                    <div className="">
                      <CalendarMonthIcon fontSize="small" />
                    </div>
                    <div>
                      <span className=" text-xs align-middle">{dates} </span>
                    </div>
                  </div>
                </div>
  
                <div className="flex absolute right-4 justify-end">
                  <div
                    className={`text-center flex justify-center shadow-md rounded-xl h-12 w-12 ${
                      fullnessCapture.fullness.toFixed(0) >= 80
                        ? "bg-green-700"
                        : fullnessCapture.fullness.toFixed(0) < 50
                        ? "bg-rose-700"
                        : "bg-amber-700"
                    }`}
                  >
                    <ApartmentRoundedIcon
                      sx={{ color: "white" }}
                      fontSize="large"
                      className="self-center"
                    />
                  </div>
                </div>
              </div>
  
              <div
                className={`w-full h-full relative flex justify-between shadow-md rounded-2xl p-4 ${
                  fullnessCapture.capture.toFixed(1) >= 80
                    ? "bg-[#44BE54]"
                    : fullnessCapture.capture.toFixed(1) < 50
                    ? "bg-[#FB1751]"
                    : "bg-[#F39820]"
                }`}
              >
                <div className="w-60 xl:w-full h-36 md:h-full flex flex-col justify-between">
                  <div className="flex w-full">
                    <span className=" text-left  tracking-wide text-white font-bold ">
                      Captures
                    </span>
                  </div>
  
                  <div className="w-full">
                    <div className="flex text-left text-4xl xl:text-3xl 2xl:text-4xl text-white font-bold">
                      {fullnessCapture.capture.toFixed(1) > 100 ? (
                        <>100%</>
                      ) : (
                        <>{fullnessCapture.capture.toFixed(1)}%</>
                      )}
                      {/* <div
                        className={`rounded-md text-white self-end ml-2 w-12 flex text-xs h-5  shadow-md ${
                          fullnessCapture.capture.toFixed(1) >= 80
                            ? "bg-emerald-900"
                            : fullnessCapture.capture.toFixed(1) < 50
                            ? "bg-rose-700"
                            : "bg-amber-700"
                        }`}
                      >
                        <div className="w-1/2 h-full rounded-sm px-1 flex justify-center items-center">
                          {comparision_capture_completed > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}
                        </div>
                        <div className="h-full flex items-center pr-2">
                          {comparision_capture_completed}
                        </div>
                      </div> */}
                    </div>
                  </div>
  
                  <div className="w-full flex space-x-1 items-center  font-sans  font-semibold  text-white">
                    <div className="">
                      <CalendarMonthIcon fontSize="small" />
                    </div>
                    <div>
                      <span className=" text-xs align-middle">{dates} </span>
                    </div>
                  </div>
                </div>
  
                <div className="flex absolute right-4 justify-end">
                  <div
                    className={`text-center flex justify-center shadow-md rounded-xl h-12 w-12 ${
                      fullnessCapture.capture.toFixed(1) >= 80
                        ? "bg-green-700"
                        : fullnessCapture.capture.toFixed(1) < 50
                        ? "bg-rose-700"
                        : "bg-amber-700"
                    }`}
                  >
                    <CenterFocusStrongRoundedIcon
                      sx={{ color: "white" }}
                      fontSize="large"
                      className="self-center"
                    />
                  </div>
                </div>
              </div>
  
              <div
                className={`w-full h-full relative flex justify-between shadow-md rounded-2xl p-4 ${
                  storeLength >= 80
                    ? "bg-[#44BE54]"
                    : storeLength < 50
                    ? "bg-[#FB1751]"
                    : "bg-[#F39820]"
                }`}
              >
                <div className="w-60 xl:w-full h-36 md:h-full flex flex-col justify-between">
                  <div className="flex w-full">
                    <span className=" text-left  tracking-wide text-white font-bold ">
                      Stores
                    </span>
                  </div>
  
                  <div className="w-full">
                    <div className="flex text-left text-4xl xl:text-3xl 2xl:text-4xl text-white font-bold">
                      {storeLength > 100 ? <>100%</> : <>{storeLength}%</>}
                      {/* <div
                        className={`rounded-md text-white self-end ml-2 w-12 flex text-xs h-5  shadow-md ${
                          fullnessCapture.fullness.toFixed(1) >= 80
                            ? "bg-emerald-900"
                            : fullnessCapture.fullness.toFixed(1) < 50
                            ? "bg-rose-700"
                            : "bg-amber-700"
                        }`}
                      >
                        <div className="w-1/2 h-full rounded-sm px-1 flex justify-center items-center">
                          {comparision_fullness > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}
                        </div>
                        <div className="h-full flex items-center pr-2">
                          {comparision_fullness.toFixed(1)}
                        </div>
                      </div> */}
                    </div>
                  </div>
  
                  <div className="w-full flex space-x-1 items-center  font-sans  font-semibold  text-white">
                    <div className="">
                      <CalendarMonthIcon fontSize="small" />
                    </div>
                    <div>
                      <span className=" text-xs align-middle">{dates} </span>
                    </div>
                  </div>
                </div>
  
                <div className="flex absolute right-4 justify-end">
                  <div
                    className={`text-center flex justify-center shadow-md rounded-xl h-12 w-12 ${
                      storeLength >= 80
                        ? "bg-green-700"
                        : storeLength < 50
                        ? "bg-rose-700"
                        : "bg-amber-700"
                    }`}
                  >
                    <StoreRoundedIcon
                      sx={{ color: "white" }}
                      fontSize="large"
                      className="self-center"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <NewLoader />
            </>
          )}
        </>
      </>
    );
  }