import React, { useState } from "react";
import SideNav from "../others/sidenav";
import AnaHeader from "./header";
// import Store from "./store";\
// import Chart from "./DoughnutChart";
import Filter from "./filter";
// import AnalysisStore from "./analysisStore";
import AnalysisStoreNew from "./analysisStoreNew";
export default function Analysis() {
  const [
    // store,
    setStore,
  ] = useState();
  const [filteredTable, setFiltereredTable] = useState("");

  const [parentData, setParentData] = useState();
  const [openRows, setOpenRows] = useState([]);

  // console.log("par",parentData)
  return (
    <>
      <div className="w-full md:h-screen h-screen flex  bg-gray-100 overflow-y-auto scrollbar">
        <div className="hidden md:contents">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen hidden md:block"></div>
        <div className="md:w-5/6 w-full h-full">
          <div className="w-full bg-white">
            <AnaHeader setFiltereredTable={setFiltereredTable} setOpenRows={setOpenRows}/>
          </div>
          <div className="w-full h-10 hidden md:block"></div>
          <div className="w-full md:px-12 p-6">
            {/* <Filter
              setStore={setStore}
              selectedData={parentData}
            /> */}
          </div>
          {/* <div className="w-full  flex space-between pl-4 pr-4">
            <Store/>
          </div> */}

          <div className="bg-gray-100">
            {/* <AnalysisStore
              setParentData={setParentData}
              filteredTable={filteredTable}
            /> */}
            <AnalysisStoreNew
              setParentData={setParentData}
              filteredTable={filteredTable}
              setFiltereredTable={setFiltereredTable}
              selectedData={parentData}
              openRows={openRows}
              setOpenRows={setOpenRows}
            />
          </div>
        </div>
      </div>
    </>
  );
}
