import React from "react";
import MobileSignIn from "./mobileSignIn";
import NewSignIn from "./newSignIn";

export default function Login() {
  return (
    <>
      <div className="w-full ">
        <div>
          <MobileSignIn />
          <NewSignIn />
        </div>
      </div>
    </>
  );
}
