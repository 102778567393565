import React from "react";
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { 
  // Bar, 
  Doughnut } from "react-chartjs-2";
import { useData } from "../../context/chartContext";


function DoughnutChart(props) {
  // data get from dashboard components
  const data = props.data;

  // console.log("Bar Chart Data", data.data);
  const fullnessData = data.data;
  const brand_wise = data.data
  console.log("brand_wise", brand_wise)
  let fullnessPerc = [];

  if (fullnessData) {
    fullnessPerc = fullnessData.fullness_percentage;
  }
  // console.log(fullnessPerc);
  let getFullnessValue = (arr, num) => arr.filter((n) => n > num);
  let getFullnessValues = (arr, num, num2) =>
    arr.filter((n) => n > num && n < num2);
  let getNullValues = (arr, num) => arr.filter((n) => n < num);

  // console.log(returnLarger( [65, 16, 0, 6, 64, 1, 68], 16))
  const fullness90 = getFullnessValue(fullnessPerc, 80);
  const fullness80 = getFullnessValues(fullnessPerc, 51, 80);
  // const fullness70 = getFullnessValues(fullnessPerc, 69, 80);
  // const fullness60 = getFullnessValues(fullnessPerc, 59, 70);
  // const fullness50 = getFullnessValues(fullnessPerc, 49, 60);
  const fullness0 = getNullValues(fullnessPerc, 50);

  // console.log("fullness90", fullness90);
  // console.log("fullness80", fullness80);
  // console.log("fullness0", fullness0);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  // const options = {
  //   responsive: true,
  //   scales: {
  //     x: {
  //       title: { display: true, text: "Shelf-fullness", color: "black" },
  //     },
  //     y: {
  //       title: { display: true, text: "Number of Images", color: "black" },
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Shelf Image Distribution",
  //       color: "black",
  //     },
  //   },
  // };

  //Options for New Donut Chart
  ChartJS.register(ArcElement, Tooltip, Legend);

  const optionsDonut = {
    responsive: true,
    elements: {
      arc: {
        // angle: 50,
        // borderAlign: "inner",
        // borderJoinStyle: "round",
        // borderWidth: 5
      },
    },

    plugins: {
      legend: {
        position: "top",
        align: "center",
        fullSize: false,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          pointStyleWidth: 17,
        },
      },
      title: {
        display: true,
        text: "Shelf Image Fullness Category",
        color: "black",
      },
    },
  };

  // const {setFilteredFullnessVar, selectCappedDate, setCappedDate} = useData();
  // const navigate = useNavigate();
  // const handleDoughnutClick = (elements) => {
  //   console.log("selectedCappedDate", selectCappedDate)
  //   setCappedDate(selectCappedDate)
  //   const clickedElementId = elements[0].index + 2;
  //   setFilteredFullnessVar(clickedElementId);
  //   navigate('/analysis');
  // }

  return ( 
    <div className="m-auto w-full h-full flex text center  pr-2">
      <div className="w-full">
        {!data ? (
          // <Bar
          //   options={options}
          //   data={{
          //     labels: ["January", "February", "March", "April", "May", "June"],
          //     datasets: [
          //       {
          //         label: "Dataset 1",
          //         data: [80, 20, 30, 40, 50, 100],

          //         backgroundColor: "rgba(54, 162, 235, 0.7)",
          //       },
          //       {
          //         label: "Dataset 2",
          //         data: [86, 8, 25, 49, 55, 19],

          //         backgroundColor: "rgba(255, 206, 86, 0.7)",
          //       },
          //     ],
          //   }}
          //   height={"215"}
          // />
          <></>
        ) : (
          <div className="w-11/12 m-auto p-2">
            {/* <Bar
              options={options}
              data={{
                labels: [
                  "(100-90)%",
                  "(90-80)%",
                  "(80-70)%",
                  "(70-60)%",
                  "(60-50)%",
                  "<50%",
                ],
                datasets: [
                  {
                    label: "No. of images",
                    data: [
                      fullness90.length,
                      fullness80.length,
                      fullness70.length,
                      fullness60.length,
                      fullness50.length,
                      fullness0.length,
                    ],

                    backgroundColor: [
                      "rgb(52,211,153)",
                      "rgb(52,211,153)",
                      "rgb(52,211,153)",
                      "rgb(52,211,153",
                      "rgb(52,211,153)",

                      "rgb(255,82,82)",
                    ],
                    borderRadius: 5,
                  },
                ],
              }}
              height={"300"}
            /> */}
            <Doughnut
              options={{
                ...optionsDonut,
                // onClick: (event, elements) => handleDoughnutClick(elements),
              }}
              data={{
                labels: [
                  ">80%",
                  "(80-50)%",
                  // "(80-70)%",
                  // "(70-60)%",
                  // "(60-50)%",
                  "<50%",
                ],
                datasets: [
                  {
                    label: "No. of images",
                    data: [
                      fullness90.length,
                      fullness80.length,

                      fullness0.length,
                    ],

                    backgroundColor: [
                      "rgb(68,190,84)",
                      "rgb(243,152,32)",
                      "rgb(251,23,81)",
                      // "rgb(52,211,153)",
                      // "rgb(245, 158, 11)",
                      // "rgb(244, 63, 94)",
                    ],

                    hoverOffset: 6,
                  },
                ],
              }}
              width="100%"
              height="100%"
            />
          </div>
        )}
      </div>
    </div>

    // <div className="w-full h-full flex text center p-4">
    //   {/* <div className="	text-xs w-10 m-auto h-2  -rotate-90 tracking-wide	 text-center font-bold ">
    //     Frequency
    //   </div> */}
    //   <div className="w-full">
    //     {!data ? (
    //       <Bar
    //         options={options}
    //         data={{
    //           labels: ["January", "February", "March", "April", "May", "June"],
    //           datasets: [
    //             {
    //               label: "Dataset 1",
    //               data: [80, 20, 30, 40, 50, 100],

    //               backgroundColor: "rgba(54, 162, 235, 0.7)",
    //             },
    //             {
    //               label: "Dataset 2",
    //               data: [86, 8, 25, 49, 55, 19],

    //               backgroundColor: "rgba(255, 206, 86, 0.7)",
    //             },
    //           ],
    //         }}
    //         height={"215"}
    //       />
    //     ) : (
    //       <div className="w-11/12">
    //         <Bar
    //           options={options}
    //           data={{
    //             labels: ["100-90", "90-80", "80-70", "70-60", "60-50", "<50"],
    //             datasets: [
    //               {
    //                 label: "Image Count",
    //                 data: [
    //                   fullness90.length,
    //                   fullness80.length,
    //                   fullness70.length,
    //                   fullness60.length,
    //                   fullness50.length,
    //                   fullness0.length,
    //                 ],

    //                 backgroundColor: [
    //                   "rgb(52,211,153)",
    //                   "rgb(52,211,153)",
    //                   "rgb(52,211,153)",
    //                   "rgb(52,211,153",
    //                   "rgb(52,211,153)",
    //                   // "rgb(	59, 130, 246)",
    //                   // "rgb(		6, 182, 212)",
    //                   // // "rgb(	132, 204, 22)",
    //                   // "rgb(	245, 158, 11)",
    //                   // "rgb(	249, 115, 22)",

    //                   "rgb(255,82,82)",
    //                 ],
    //                 borderRadius: 5,
    //               },
    //             ],
    //           }}
    //           height={"300"}
    //         />
    //       </div>
    //     )}
    //     <div className="text-xs font-md w-full text-center ">
    //       Shelf-fullness (%)
    //     </div>
    //   </div>
    // </div>
  );
}
export default DoughnutChart;