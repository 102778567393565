import React, { useState, useEffect } from "react";
import DatePicker from "sassy-datepicker";
// import Calendar from "react-calendar";
// import  "./calender.css";
// import '../../index.css'
import "../../App.css";

export default function Calender({ setParentdata }) {
  // Date change events
  //  const handleonClick= props.apiRes
  const [calender, setCalender] = useState(new Date());

  const handlechange = (date) => {
    // console.log("calender page date",date)
    setCalender(date);

    // handleonClick();
  };
  useEffect(() => {
    setParentdata(formatDate(calender));
    // apiRes()
    // eslint-disable-next-line
  }, [calender]);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  return (
    <>
      <div className="w-full h-full">
        <DatePicker
          id="datepicker"
          selected={new Date()}
          // onClick={apiRes}
          onChange={handlechange}
          minDate={new Date(2019, 10, 16)}
          maxDate={new Date()}
        />

        {/* <Calendar
          selected={new Date()}
          // onClick={apiRes}
          onChange={handlechange}
          minDate={new Date(2019, 10, 16)}
          maxDate={new Date()}
        /> */}
      </div>
    </>
  );
}
