import { React, useState, useEffect } from "react";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
import {
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import Box from "@mui/material/Box";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import toast, { Toaster } from "react-hot-toast";
import { UPDATE_SOP } from "../../../Authservice/api";
import axios from "axios";

import { GET_WEIGHATAGE_SOP } from "../../../Authservice/api";
import { subMilliseconds } from "date-fns/esm";
// import { inputLabelClasses } from "@mui/material/InputLabel";
export default function WeightageTable(props) {
  const inputState = props.filled;
  const sopData = props.sopdata;
  const saveClicked = props.saveClicked;
  const setSaveClicked = props.setSaveClicked;
  const resetClicked = props.resetClicked;
  const setResetClicked = props.setResetClicked;

  const [copyOf_sopData, setCopyOf_sopData] = useState(undefined);
  const [selectedValues, setSelectedValues] = useState([]);
  const [inputWeightage, setInputWeigtage] = useState("");
  const [inputThreshold, setInputThreshold] = useState("");
  const [groupName, setGroupName] = useState("");
  const [stateChange, setStateChange] = useState(false);
  const [sopdataval, setSopdataval] = useState(props.sopdata);
  const [currentFocus, setCurrentFocus] = useState({ row: 0, column: 0 });
  // console.log("Current focus", currentFocus);

  const handleKeyDown = (event, type) => {
    // console.log("event type",event.key);
    const { row, column } = currentFocus;
    let newRow = row;
    let newColumn = column;

    if (event.key === "ArrowDown") {
      event.preventDefault();
      newRow = row < sopData[0].tray_group.length - 1 ? row + 1 : row;
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      newRow = row > 0 ? row - 1 : row;
    }
    // else if (event.key === "ArrowRight") {
    //   event.preventDefault();
    //   newColumn =
    //     column < Object.keys(sopData[0].tray_group[row]).length - 1
    //       ? column === 0
    //         ? column + 2
    //         : column + 1
    //       : column;
    // } else if (event.key === "ArrowLeft") {
    //   event.preventDefault();
    //   newColumn =
    //     column > 1 ? (column === 2 ? column - 2 : column - 1) : column;
    // }
    setCurrentFocus({ row: newRow, column: newColumn });

    if (type === "groupName") {
      // console.log("Current focus", currentFocus);
       if (["ArrowDown", "ArrowUp"].indexOf(event.key) !== -1) {
        const input = document.getElementById(
          `outlined-adornment-weight groupName-${newRow}-${newColumn}`
        );
        input?.focus();
      }
    } else if (type === "weightage") {
      // console.log("Current focus", currentFocus);
      if (["ArrowDown", "ArrowUp"].indexOf(event.key) !== -1) {
        const input = document.getElementById(
          `outlined-adornment-weight weightage-${newRow}-${newColumn}`
        );
        input?.focus();
      }
    } else if (type === "threshold") {
      // console.log("Current focus", currentFocus);
      if (["ArrowDown", "ArrowUp"].indexOf(event.key) !== -1) {
        const input = document.getElementById(
          `outlined-adornment-weight threshold-${newRow}-${newColumn}`
        );
        input?.focus();
      }
    }
  };
  // const names = ["Mayur", "Raj", "Beauty"];

  const handleSelect = (event, groupId) => {
    const prevSelectedValues = selectedValues[groupId] || [];
    const selectedValue = event.target.value;
    const filteredData = sopData[0].trays.filter((item) =>
      selectedValue.includes(item.number)
    );
    let exampleData = sopData[0].tray_group[groupId].trys;
    if (copyOf_sopData === undefined) {
      const clone_sopData = sopData[0].tray_group[groupId].weightage;
      setCopyOf_sopData(clone_sopData);
    }
    const unselectedValues = prevSelectedValues.filter(
      (value) => !selectedValue.includes(value)
    );
    const filteredArray1 = exampleData.filter(
      (obj) => !unselectedValues.some((num) => num === obj.tray_number)
    );
    if (unselectedValues.length > 0) {
      exampleData = filteredArray1;
    }
    for (let count = 0; count < filteredData.length; count++) {
      exampleData.push({
        tray_number: filteredData[count].number,
        tray_weightage: filteredData[count].weighatage,
      });
    }
    const uniqueArray = exampleData.reduce((accumulator, obj) => {
      const found = accumulator.find(
        (item) => item.tray_number === obj.tray_number
      );
      if (!found) {
        accumulator.push(obj);
      }
      return accumulator;
    }, []);
    setSelectedValues((prevSelectedValues) => {
      const updatedValues = { ...prevSelectedValues };
      updatedValues[groupId] = selectedValue;
      return updatedValues;
    });
    // Perform actions with unselected values
    unselectedValues.forEach((value) => {
      const selectedData = sopData[0].trays.find(
        (item) => item.number === value
      );
      sopData[0].tray_group[value - 1].trys.push({
        tray_number: selectedData.number,
        tray_weightage: selectedData.weighatage,
      });
    });
    updateSOPdata(selectedValue);
    sopData[0].tray_group[groupId].trys = uniqueArray;
    let finalUpdate = sopData[0].tray_group;
    for (let count = 0; count < finalUpdate.length; count++) {
      const sum = finalUpdate[count].trys.reduce(
        (total, item) => total + item.tray_weightage,
        0
      );
      sopData[0].tray_group[count].weightage = sum;
    }
    setSopdataval(sopData);
    // setStateChange(sopData)
    props.setDataFromChild(sopData[0]);
  };



  const updateSOPdata = (selectedValue) => {
    selectedValue.forEach((value) => {
      sopData[0].tray_group.forEach((obj) => {
        obj.trys = obj.trys.filter((v) => v.tray_number !== value);
      });
    });
  };
  const handleChangeThreshold = (value, groupID) => {
    setInputThreshold(value);
    // console.log("sopData", sopData[0])
    props.setDataFromChild(sopData[0]);
  };

  const handleChangeGroupName = (value, groupID) => {
    setGroupName(value);
    // console.log("sopData", sopData[0])
    props.setDataFromChild(sopData[0]);
  };

  const [groupIDs, setGroupIDs] = useState([]);
  const handleChange = (value, groupID, currentGroupIDs) => {
    const updatedGroupIDs = [...currentGroupIDs, groupID];
    setGroupIDs(updatedGroupIDs);
    const uniqueArrGroupIds = [...new Set(updatedGroupIDs)];
    // console.log("uniqueArrGroupIds", uniqueArrGroupIds);
    if (value > 100) {
      toast.error("Weightage should not exceed 100");
    } else {
      let tray_group_len;
      if (sopData) {
        // totalTrayLength= sopData[0].tray_group[groupID].trays.length
        tray_group_len = sopData[0].tray_group.length;
      }
      const sopData_trays_value = sopData[0].trays;
      const sopData_group_value = sopData[0].tray_group;
      const selected_tray_id = sopData_group_value[groupID].trys[0].tray_number;
      const foundObject = sopData_trays_value.find(
        (obj) => obj.number === selected_tray_id
      );
      if (foundObject) {
        foundObject.weighatage = parseInt(value);
      }
      const length_of_group = [];
      for (let countg = 0; countg < sopData_group_value.length; countg++) {
        if (sopData_group_value[countg].trys.length > 0) {
          length_of_group.push(sopData_group_value[countg]);
        }
      }
      if (sopData_trays_value.length === length_of_group.length) {
        // console.log(" am Active");
        let selectedData_weightage = [];
        for (let gdVal = 0; gdVal < uniqueArrGroupIds.length; gdVal++) {
          const index_unique = uniqueArrGroupIds[gdVal];
          const group_selected_tray =
            sopData[0].tray_group[index_unique].weightage;
          selectedData_weightage.push(parseInt(group_selected_tray));
        }
        const sum = selectedData_weightage.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const updateVal =
          (100 - sum) / (tray_group_len - uniqueArrGroupIds.length);
        sopData[0].tray_group[groupID].trys[0].tray_weightage = parseInt(value);
        sopData[0].trays = sopData[0].trays.map((val, id) => {
          if (uniqueArrGroupIds.includes(id)) {
            return val;
          } else {
            return {
              ...val,
              weighatage: updateVal,
            };
          }
        });
        sopData[0].tray_group = sopData[0].tray_group.map((obj, ind) => {
          if (uniqueArrGroupIds.includes(ind)) {
            return obj; // Keep the original weightage for selected indices
          } else {
            return {
              ...obj,
              weightage: updateVal, // Update weightage for other indices
              trys: obj.trys.map((tryObj, tryInd) => {
                if (tryInd === 0) {
                  return {
                    ...tryObj,
                    tray_weightage: updateVal, // Update tray_weightage for the first index
                  };
                } else {
                  return tryObj;
                }
              }),
            };
          }
        });
      } else {
        const otherunselect = [];
        const sopDataCopy = sopData[0].tray_group;
        for (let count = 0; count < sopDataCopy.length; count++) {
          if (sopDataCopy[count].group === groupID + 1) {
            // console.log("selected");
            const selected_group_wt = value / sopDataCopy[count].trys.length;
            const sopData_trays = sopDataCopy[count].trys;
            for (
              let tray_count = 0;
              tray_count < sopData_trays.length;
              tray_count++
            ) {
              sopData_trays[tray_count].tray_weightage = selected_group_wt;
            }
            for (let i = 0; i < sopData[0].trays.length; i++) {
              // sopData_trays[i].tray_weightage = selected_group_wt;
              const updatedData = sopData_trays.filter((trayVal) => {
                return trayVal.tray_number === sopData[0].trays[i].number;
              });
              if (updatedData.length === 0) {
              } else {
                sopData[0].trays[i].weighatage = selected_group_wt;
              }
            }
            sopData[0].tray_group[count].trys = sopData_trays;
          } else if (sopDataCopy[count].trys.length === 0) {
          } else {
            otherunselect.push(sopDataCopy[count].group);
            // console.log("sopDataCopy", sopDataCopy[count].group)
          }
        }
        const filteredArray2 = otherunselect.filter(
          (value) => !uniqueArrGroupIds.includes(value)
        );
        const total_val_selected_tray = [];
        for (let countt = 0; countt < uniqueArrGroupIds.length; countt++) {
          const unqueValue = uniqueArrGroupIds[countt];
          const group_data = sopData[0].tray_group[unqueValue].weightage;
          total_val_selected_tray.push(parseInt(group_data));
        }
        const remove_selected_value = otherunselect.filter(
          (value) => !uniqueArrGroupIds.includes(value - 1)
        );
        const sum = total_val_selected_tray.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const other_unselected_wt = (100 - sum) / remove_selected_value.length;
        for (let count = 0; count < sopDataCopy.length; count++) {
          if (
            uniqueArrGroupIds.filter(
              (val) => val + 1 === sopDataCopy[count].group
            ).length > 0
          ) {
          } else if (sopDataCopy[count].trys.length === 0) {
          } else if (sopDataCopy[count].trys.length === 1) {
            for (let i = 0; i < sopData[0].trays.length; i++) {
              // sopData_trays[i].tray_weightage = selected_group_wt;
              const updatedData = sopDataCopy[count].trys.filter((trayVal) => {
                return trayVal.tray_number === sopData[0].trays[i].number;
              });
              if (updatedData.length === 0) {
              } else {
                sopData[0].trays[i].weighatage = other_unselected_wt;
              }
            }
            sopData[0].tray_group[count].weightage = other_unselected_wt;
            sopData[0].tray_group[count].trys[0].tray_weightage =
              other_unselected_wt;
            // console.log("sopDataCopy", sopDataCopy[count].group)
          } else {
            // console.log("mult", sopDataCopy[count])
            const tray_unselcted = sopDataCopy[count].trys;
            const trays_length = sopDataCopy[count].trys.length;
            const tray_wise_wt = other_unselected_wt / trays_length;
            // console.log("trays_length", trays_length);
            // console.log(
            //   "sopData[0].tray_group[count]",
            //   sopData[0].tray_group[count].group
            // );
            for (let i = 0; i < trays_length; i++) {
              // console.log(
              //   "sopData[0].",
              //   sopData[0].tray_group[count].trys[i].tray_weightage
              // );
              sopData[0].tray_group[count].trys[i].tray_weightage =
                tray_wise_wt;
            }
            // console.log("tray_wise_wt", tray_wise_wt);
            for (let i = 0; i < sopData[0].trays.length; i++) {
              // sopData_trays[i].tray_weightage = selected_group_wt;
              const updatedData = sopDataCopy[count].trys.filter((trayVal) => {
                return trayVal.tray_number === sopData[0].trays[i].number;
              });
              if (updatedData.length === 0) {
              } else {
                sopData[0].trays[i].weighatage = tray_wise_wt;
              }
            }
            // sopData[0].tray_group[count].trys = tray_unselcted;
            sopData[0].tray_group[count].weightage = other_unselected_wt;
          }
        }
      }
    }
    let sum_weightage = 0;
    const check_wt = [];
    for (const item of sopData[0].tray_group) {
      // console.log("item.weightage", item.weightage)
      sum_weightage += parseFloat(item.weightage);
      // console.log("sum_weightage", sum_weightage)
      if (parseFloat(item.weightage) >= 0) {
      } else {
        check_wt.push(item.weightage);
      }
    }
    // console.log("check_wt", check_wt);
    // console.log("sum_weightage", sum_weightage);
    if (sum_weightage > 100) {
      toast.error("Weightage should not exceed 100");
    }
    if (check_wt[0] === "") {
    } else if (check_wt.length > 0) {
      toast.error("Weightage should not exceed 100");
    }
    setSopdataval(sopData);
    props.setDataFromChild(sopData[0]);
  };

  // console.log("sopData", sopData);
  const isValueSelected = (groupId, value) => {
    return selectedValues[groupId] && selectedValues[groupId].includes(value);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 30,
      },
    },
  };
  // GET THE TRAY VALUES
  let all_tray;
  if (sopData) {
    all_tray = sopData[0].trays;
  }

  // useEffect(() => {
  //   const data = sopData[0];
  //   props.setDataFromChild(data);
  // }, [stateChange]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleReset = async () => {
    setResetClicked(true);
    setSaveClicked(true);
    const updatedSopData = JSON.parse(JSON.stringify(sopData));
    const tray_data = updatedSopData[0].trays;
    const weighatge = 100 / tray_data.length;
    for (let count = 0; count < tray_data.length; count++) {
      updatedSopData[0].trays[count].weighatage = weighatge;
    }
    for (let i = 0; i < tray_data.length; i++) {
      updatedSopData[0].tray_group[i].threshold = 0;
      updatedSopData[0].tray_group[i].weightage = weighatge;
      updatedSopData[0].tray_group[i].groupName = "";
      updatedSopData[0].tray_group[i].trys = [
        {
          tray_number: i + 1,
          tray_weightage: weighatge,
        },
      ];
    }

    let all_data = updatedSopData[0];
    sopData[0] = all_data;
    const final_data = {
      id: updatedSopData[0]._id,
      sop: {
        tray_group: updatedSopData[0].tray_group,
        trays: updatedSopData[0].trays,
      },
    };
    const update = await UPDATE_SOP(final_data);
    if (update) {
      // console.log("update", update)
      toast.success("Reset Successfully");
      // window.location.reload();
      setSopdataval(updatedSopData);
    }
    setConfirmDialogOpen(false)
  };

  const [undoing, setUndoing] = useState(false);

  const handleUndo = async () => {
    try {
      // if(saveClicked && resetClicked){
            setUndoing(true);
      const response = await axios.post(
        "http://localhost:8081/data/weightage_version/route1",
        {
          sop_id: sopData[0]._id,
        }
      );
      sopData[0].tray_group = response.data.data.tray_group;
      sopData[0].trays = response.data.data.trays;
      setResetClicked(false);
      setSaveClicked(false);
      // }
      // else if(saveClicked && !resetClicked){
      //   setUndoing(true);
      //   setSaveClicked(false);
      //   const response = await axios.post(
      //     "http://localhost:8081/data/weightage_version/route2",
      //     {
      //       sop_id: sopData[0]._id,
      //     }
      //   );

      //   sopData[0].tray_group = response.data.data[0].tray_group;
      //   sopData[0].trays = response.data.data[0].trays;
      // }

      toast.success("Undo Successful");
    } catch (error) {
      console.log("error", error);
      toast.error("Undo Failed");
    } finally {
      setUndoing(false);
    }
  };

  return (
    <>
      <table className="w-full bg-white border-l rounded-lg table-auto overflow-y-auto">
        <thead className="bg-emerald-500 text-white">
          <tr id="rubikFont" className="tracking-wider font-semibold">
            <th className="py-2 text-center">Groups</th>
            <th className="py-2 text-center">Name</th>
            <th className="text-center">Trays</th>
            <th className="text-center">Weightage</th>
            <th className="text-center">Fullness count</th>
          </tr>
        </thead>
        <tbody
          id="poppinsFont"
          className="w-full text-center font-semibold overflow-y-auto"
        >
          {sopData ? (
            <>
              {sopData[0].tray_group.map((val, id) => {
                // console.log("val", val);
                const groupId = id;
                {
                  return val.trys.length > 0 ? (
                    <tr key={id}>
                      <td className="py-2 w-[10%]">{id + 1}</td>
                      {/* <td className="py-2 w-[17%]">{val.group}</td> */}
                      {Object.keys(val).map((columnKey, columnIndex) => {
                        // console.log("columnKey: ", columnKey);
                        if (columnKey === "group") {
                          return (
                            <td
                              style={{ textAlign: "center" }}
                              className="w-[20%]"
                            >
                              <div className="w-24 2xl:w-28 inline-block">
                                <FormControl
                                  disabled={
                                    inputState === "filled" ? false : true
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{
                                    // backgroundColor: "#10b981",
                                    // color: "white",
                                    fontSize: "14px",
                                  }}
                                  // sx={{ m: 1, width: "25ch" }}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id={`outlined-adornment-weight groupName-${id}-${columnIndex}`}
                                    onClick={() =>
                                      setCurrentFocus({
                                        row: id,
                                        column: columnIndex,
                                      })
                                    }
                                    onKeyDown={(event) =>
                                      handleKeyDown(event, "groupName")
                                    }
                                    // endAdornment={
                                    //   <InputAdornment position="end">%</InputAdornment>
                                    // }
                                    value={val.groupName}
                                    // onChange={(e)=>setGroupName(e.target.value)}
                                    onChange={(event) => {
                                      console.log(event.key);
                                      const value = event.target.value;
                                      // Update the value in the `val` object
                                      // val.threshold = value;
                                      val.groupName = value;
                                      handleChangeGroupName(value, groupId);
                                    }}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </td>
                          );
                        } else if (columnKey === "trys") {
                          return (
                            <td
                              //   style={{ display: "grid", placeItems: "center" }}
                              style={{ textAlign: "center" }}
                              className="w-[20%]"
                              onClick={() =>
                                setCurrentFocus({
                                  row: id,
                                  column: columnIndex,
                                })
                              }
                            >
                              <div className="w-[120px] inline-block 2xl:w-[150px]">
                                <FormControl
                                  size="small"
                                  fullWidth
                                  disabled={
                                    inputState === "filled" ? false : true
                                  }
                                >
                                  <Select
                                    labelId={`demo-multiple-checkbox-label-${groupId}`}
                                    id={`demo-multiple-checkbox-${groupId}`}
                                    multiple
                                    value={val.trys.map(
                                      (obj) => obj.tray_number
                                    )}
                                    onChange={(event) =>
                                      handleSelect(event, groupId)
                                    }
                                    renderValue={(selected) =>
                                      selected.join(", ")
                                    }
                                  >
                                    {all_tray.map((name) => (
                                      <MenuItem
                                        key={name.number}
                                        value={name.number}
                                      >
                                        <Checkbox
                                          // val.trys && val.trys.includes(name.number)
                                          checked={
                                            (val.trys &&
                                              val.trys.some(
                                                (obj) =>
                                                  obj.tray_number ===
                                                  name.number
                                              )) ||
                                            // selectedValues[groupId]?.includes(
                                            //   name.number
                                            // ) ||
                                            false
                                          }
                                        />
                                        {name.number}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </td>
                          );
                        } else if (columnKey === "weightage") {
                          return (
                            <td
                              style={{ textAlign: "center" }}
                              className="w-[20%]"
                              onClick={() =>
                                setCurrentFocus({
                                  row: id,
                                  column: columnIndex,
                                })
                              }
                            >
                              <div className="w-24 2xl:w-28 inline-block">
                                <FormControl
                                  disabled={
                                    inputState === "filled" ? false : true
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{
                                    // backgroundColor: {}
                                    borderColor: "#10b981",
                                    // color: "white",
                                    fontSize: "14px",
                                  }}
                                  // sx={{ m: 1, width: "25ch" }}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id={`outlined-adornment-weight weightage-${id}-${columnIndex}`}
                                    onKeyDown={(event) =>
                                      handleKeyDown(event, "weightage")
                                    }
                                    endAdornment={
                                      <InputAdornment position="end"></InputAdornment>
                                    }
                                    sx={{}}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                    // value={val.weightage}
                                    // placeholder={val.weightage}
                                    value={val.weightage} // Set the value from the `val` object
                                    // max={100}
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      // Update the value in the `val` object
                                      val.weightage = value;
                                      // setInputWeigtage(value); // Update the inputWeightage state
                                      handleChange(value, groupId, groupIDs);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </td>
                          );
                        } else if (columnKey === "threshold") {
                          return (
                            <td
                              style={{ textAlign: "center" }}
                              className="w-[20%]"
                              onClick={() =>
                                setCurrentFocus({
                                  row: id,
                                  column: columnIndex,
                                })
                              }
                            >
                              <div className="w-24 2xl:w-28 inline-block">
                                <FormControl
                                  disabled={
                                    inputState === "filled" ? false : true
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{
                                    // backgroundColor: "#10b981",
                                    // color: "white",
                                    fontSize: "14px",
                                  }}
                                  // sx={{ m: 1, width: "25ch" }}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id={`outlined-adornment-weight threshold-${id}-${columnIndex}`}
                                    // endAdornment={
                                    //   <InputAdornment position="end">%</InputAdornment>
                                    // }
                                    // className={`threshold-${id}-${columnIndex}`}
                                    onKeyDown={(event) =>
                                      handleKeyDown(event, "threshold")
                                    }
                                    value={val.threshold}
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      // Update the value in the `val` object
                                      val.threshold = value;
                                      handleChangeThreshold(value, groupId);
                                    }}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </td>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  ) : (
                    <></>
                  );
                }
              })}
            </>
          ) : (
            <>Loader....</>
          )}
        </tbody>
        <Toaster />
      </table>
      <div className="w-full pt-2 flex justify-end px-2">
        
          <button
            onClick={handleUndo}
            className={`rounded-md font-[roboto] shadow-md bg-[#f0ad4e] ${
              (undoing || !saveClicked)
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "active:bg-amber-400 hover:bg-amber-700"
            } text-white w-24 h-10 mr-2`
            }
            disabled={undoing || !saveClicked}
          >
            Undo
          </button>

        <button
          onClick={() =>   setConfirmDialogOpen(true)}
          className={`rounded-md font-[roboto] shadow-md bg-[#f0ad4e] ${
           ( resetClicked )
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "active:bg-amber-400 hover:bg-amber-700"
          } text-white w-24 h-10 mr-2`}
          disabled={resetClicked}
        >
          Reset
        </button>

      </div>
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogContent className="flex flex-col space-y-4 m-auto">
          <div className="m-auto text-7xl">
            <AiOutlineExclamationCircle />
          </div>
          <div className="text-xl m-auto font-semibold">Confirm Reset ?</div>
          <Box sx={{ "& button": { m: 1 } }}>
            <div>
              <Button
                onClick={handleReset}
                variant="contained"
                color="error"
                size="medium"
              >
                Yes, Reset it
              </Button>
              <Button
                onClick={() => setConfirmDialogOpen(false)}
                color="success"
                variant="contained"
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      
    </>
  );
}
