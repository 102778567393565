import React from "react";
import SideNav from "../../others/sidenav";
import ChatHeader from "./chatHeader";
import { useEffect, useState } from "react";
import { Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../../Authservice/images/coming-soon.svg";

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(75)};
  `
);

const TypographyH3 = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.black[50]};
  `
);

export default function ComingSoon() {
  return (
    <>
      <div className="w-full h-screen flex bg-gray-100 overflow-y-auto md:scrollbar">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen hidden md:block"></div>

        <div className="w-full lg:w-5/6 h-full">
          <div className="w-full bg-white">
            <ChatHeader />
          </div>
          <div className="w-full h-16 hidden md:block"></div>
          <div
            // id="settingsMainDiv"
            className="w-full px-2 py-8 xl:px-7 xl:py-8 2xl:p-7 bg-gray-100 flex flex-col xl:space-y-0 2xl:space-y-4"
          >
            <MainContent>
              <Container maxWidth="md">
                {/* <Logo /> */}
                <div className="w-full text-center flex flex-col space-y-8">
                  <Container maxWidth="xs" className="flex flex-col space-y-8">
                    <div>
                      <span className="font-[rubik] text-4xl">Coming Soon</span>
                    </div>
                    <div className="font-[roboto] text-lg">
                      Page Under Construction
                    </div>
                  </Container>
                  <div className="w-full flex justify-center">
                    <img
                      alt="Coming Soon"
                      height={200}
                      src={Logo}
                      className=""
                    />
                  </div>
                </div>
              </Container>
            </MainContent>
          </div>
        </div>
      </div>
    </>
  );
}
