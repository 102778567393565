import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { PageProvider } from "./context/usePageNumber";
import Login from "./components/lanidngPage/login";
import DashboardAnalyse from "./components/Dashboard/dashboard";
import Analysis from "./components/shelf_fullness/analysis";
import ImageAnalytsis from "./components/shelf_fullness/imageAnalysis";
import EmptyImage from "./components/shelf_fullness/emptyImage";
import Store from "./components/Store/store";
import Filled from "./components/shelf_fullness/filledImage";
import User from "./components/User/user";
import Summary from "./components/Summary/summary";
import ChatPage from "./components/Dashboard/Chat/chatPageNew";
import ContactUs from "./components/contactUs/contactUs";
import Documentation from "./components/Documentation/documetation";
import Settings from "./components/Settings/settings";
import ComingSoon from "./components/Dashboard/Chat/comingSoon";
import { ChartdataProvider } from "./context/chartContext";
function App() {
  return (
      <ChartdataProvider>
      <BrowserRouter>
        <Routes basename="/">
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<DashboardAnalyse/>} />
\          <Route path="/analysis" element={<Analysis />} />
          <Route path="/user" element={<User />} />
          <Route path="/summary" element={<Summary />} />
          <Route
            path="/image_analysis/:selectedData/:fromDate/:toDate/:shelfIds"
            element={<ImageAnalytsis />}
          />
          <Route path="/stores" element={<Store />} />
          <Route path="/image_analysis/empty_images" element={<EmptyImage />} />
          <Route path="/filled_analysis" element={<Filled />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/chatPage" element={<ChatPage />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/comingSoon" element={<ComingSoon />} />
          <Route path="/documentation" element={<Documentation />} />
        </Routes>
      </BrowserRouter>
      </ChartdataProvider>
  );
}

export default App;