import React from "react";
import MobileNav from "../others/mobileNav";
import MobileUserDetailsPage from "../others/mobileUserDetailsPage";
import Report from "./report";

export default function Header({setAllData, setLoading}) {
  return (
    <>
      <div className=" w-full h-16 md:fixed bg-white z-10  border-b-2 border-white-200  ">
        <div className="flex h-full justify-between items-center ">
          <div className="md:hidden block">
            <MobileNav />
          </div>
          <div className="flex flex-row justify-between xl:px-8 w-5/6">
            <span className="text-xl md:text-2xl font-sans text-gray-600 font-bold tracking-wide">
              Summary
            </span>
            <div className="hidden md:block self-center">
              <Report setAllData={setAllData} setLoading={setLoading}/>
            </div>
          </div>
          <div className="hidden md:block"></div>
          <div className="md:hidden block">
            <MobileUserDetailsPage />
          </div>
        </div>
      </div>
    </>
  );
}
