import React, { useState, useEffect } from "react";
// import { GetALLUserAPI } from "../Authservice/api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
// import UserDetails from "./userDetails";
// import { FaEdit } from "react-icons/fa";
import {
  DeleteUser,
  // GetALLUserAPI_PAGI,
  // MOBILEAPPTESTANLAYSIS,
  // MOBILEAPPTESTANLAYSIS0,
  // MOBILEAPPTESTANLAYSIS2,
  GetALLUserAPI_PAGI_ROLE,
} from "../Authservice/api";
import Pagination from "@mui/material/Pagination";
// import Tooltip from "@mui/material/Tooltip";
// import NewEditUser from "./newEditUser";
import EditUser from "./editUser";
// import LapDeskEdit from "./lapDeskEdit";
// import MobTabEdit from "./mobTabEdit";
import NewLoader from "../others/loaderUi";
// import noDataFoundImage from "E:/disha_web_app_frontend/src/components/Authservice/images/3009287.png"
import noDataFoundImage from "../Authservice/images/3009287.png"


const UserList = (props) => {
  const [usersVal, setUsersVal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  let searchReasults = props.searchResult;
  let searchInput = props.searchInput;
  // const [selectUser, setSelectUser] = useState(false);
  // console.log("delte user", deleteUser);
  // useEffect(() => {
  //   async function getUser() {
  //     try {
  //       const data = await GetALLUserAPI();
  //       console.log(data.data);
  //       setUser(data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getUser();
  // }, []);
  // const storeId = "630c567f750c21c85d674cae";
  // const date = "2023-01-18";
  // const [result1, setResult1] = useState(false);
  // const [result2, setResult2] = useState(false);
  // const [result3, setResult3] = useState(false);
  // const apiSubmit = async (e) => {
  //   e.preventDefault();

  //   //  console.log("done ho geya ")
  //   try {
  //     const data = await MOBILEAPPTESTANLAYSIS(date, storeId);

  //     setResult1(data);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   try {
  //     const data = await MOBILEAPPTESTANLAYSIS0(date, storeId);

  //     setResult2(data);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   try {
  //     const data = await MOBILEAPPTESTANLAYSIS2(date, storeId);

  //     setResult3(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // console.log("result1", result1);
  // console.log("result2", result2);
  // console.log("result3", result3);

  const size = 7;
  const [page, setPage] = useState(1);

  let selectedFilter = props.selectedFilter;

  const pageChangeHandle = (event, pageNumber) => {
    if (props.onClickFunction) {
      props.onClickFunction(pageNumber);
    }
    setPage(pageNumber);
  };

  const userss = localStorage.getItem("users");
  const userDatas = JSON.parse(userss);

  let stores;
  if (userDatas) {
    stores = userDatas.data.stores;
  }

  useEffect(() => {
    async function getUser() {
      const datas = {
        pages: page,
        sizes: size,
        store_id: stores,
      };
      try {
        const data = await GetALLUserAPI_PAGI_ROLE(datas);
          setUsersVal(data);
        
      } catch (error) {
        console.log(error);
      }
    }
    getUser();
    // eslint-disable-next-line
  }, [page]);



  let user;
 
  if (searchReasults) {
    user = searchReasults;
  } else {
    user = usersVal;
  }

 

  let userLength = "";
  if (user) {
    userLength = Math.ceil(user.data.userLength / size);
  }

  //  popup  Delete user button

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //  Popup Edit User button
  // const [edit, setEdit] = useState(false);

  // const handleUpdateOpen = () => {
  //   setEdit(true);
  // };

  // const handleUpdateClose = () => {
  //   setEdit(false);
  // };

  // const [fullWidth, setFullWidth] = useState(true);
  // const [maxWidth, setMaxWidth] = useState("md");

  //Api integration for delete User
  const deleteUserAPi = async () => {
    try {
      // console.log("deleteUser", deleteUser)
      const data = await DeleteUser(deleteUser);
      // console.log("delete", data);
      // console.log("User deleted successfully ");
      if (data) {
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      // console.log("User not deleted ");
    }
  };

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  console.log("searchInput",searchInput)

  return (
    <div className="items-center justify-center w-full md:p-10 p-4 pt-0 md:pt-0">
      {/* <div><button onClick={apiSubmit}>Button</button></div> */}
      <div className="container overflow-x-auto m-auto">
        <table className="w-full  sm:bg-white rounded-lg md:overflow-hidden overflow-x-auto sm:shadow-lg my-5">
        {user && user.data.users.length === 0 && searchInput.length > 0 ?(<div> </div>):
          <thead className=" text-white whitespace-nowrap md:whitespace-normal">
            <tr className="bg-emerald-500 sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <th className="px-5 py-4 ">
                <div className="font-semibold text-left text-base">User ID</div>
              </th>
              <th className="px-5 py-4 ">
                <div className="font-semibold text-left text-base">Store ID</div>
              </th>
              <th className="px-5 py-4">
                <div className="font-semibold text-left text-base">Name</div>
              </th>
              <th className="px-5 py-4">
                <div className="font-semibold text-left text-base">Role</div>
              </th>
              {/* <th className="px-5 py-4">
                <div className="font-semibold text-left text-base">Email</div>
              </th> */}
              <th className="px-5 py-4">
                <div className="font-semibold text-left text-base">Number</div>
              </th>
              <th className="py-4">
                <div className="font-semibold text-left text-base">
                  APK Vers.
                </div>
              </th>
              {/* <th className="px-5 py-4">
                <div className="font-semibold text-left">Store ID</div>
              </th> */}
              <th className="px-5 py-4 font-semibold text-center text-base">
                Actions
              </th>
              {/* <th className="px-7 py-4">
                <div className="font-semibold text-right text-base">
                  Delete user
                </div>
              </th> */}
              {/* <th className="px-5 py-4">
                <div className="font-semibold text-left">Delete User</div>
              </th> */}
            </tr>
          </thead>}
          {user && user.data.users.length > 0 ? (
            user.data.users
            .map((val, id) => {
              
              return (
                <tbody
                  key={id}
                  className="text-md 2xl:text-lg font-medium hover:bg-gray-200 text-left whitespace-nowrap md:whitespace-normal"
                >
                  <tr className="border-b border-gray-300">
                    <td className="p-3 2xl:p-5">{val.user_id}</td>
                    <td className="p-3 2xl:p-5">{val.store_id}</td>
                    <td className=" px-5 py-3 border-b border-gray-300">
                      {val.user_name}
                    </td>
                    {/* <Dialog
                    open={edit}
                    onClose={handleUpdateClose}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                  >
                    <DialogContent className="flex flex-col space-y-4">
                      <UserDetails setValue={selectUser} />
                    </DialogContent>
                  </Dialog> */}
                    <td className="px-5 py-3">
                      {val.user_role === "user" ? (
                        <>Agent</>
                      ) : val.user_role === "NHQ" ? (
                        <>Super User</>
                      ) : val.user_role === "manager" ? (
                        <>Manager</>
                      ) : (
                        <>{val.user_role}</>
                      )}
                    </td>
                    {/* <td className=" px-5 py-3">{val.user_email}</td> */}
                    <td className=" px-5 py-3">{val.number}</td>

                    <td className=" px-5 py-3">
                      {/* {val.user_role === "user" ? <>1.0.2</> : <>...</>} */}
                      {val.apk_version ? <>{val.apk_version}</> : <>...</>}
                    </td>
                    {/* <td className=" px-5 py-3 border-b border-gray-300 ">
                    {val.store}
                  </td> */}
                    {/* {userData.data.user_role === "Super Admin" ? ( */}
                    <td className="px-5 py-4 text-2xl hover:bg-gray-200">
                      <div className="flex justify-center space-x-3 xl:space-x-8 2xl:space-x-12">
                        <div className="">
                          {/*EDIT VERSION FOR MOBILE VERSION*/}
                          {/* <MobTabEdit setValue={val._id} /> */}
                          <EditUser setValue={val._id} />
                          {/* EDIT VERSION FOR DESKTOP VERSION */}
                          {/* <LapDeskEdit setValue={val._id} /> */}
                        </div>

                        {userData.data.user_role === "Super Admin" ? (
                          <>
                            <MdDelete
                              onClick={() => {
                                handleClickOpen();
                                setDeleteUser(val._id);
                              }}
                              className="text-rose-600 hover:cursor-pointer"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                  {/* <td className="text-right px-3 py-3 border-b border-gray-300">
                    {userData.data.user_role === "Super Admin" ? (
                      <Button
                        onClick={() => {
                          handleClickOpen();
                          setDeleteUser(val._id);
                        }}
                        variant="contained"
                        // color="success"
                        sx={{
                          backgroundColor: "#ef4444",
                        }}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Tooltip title="Disabled">
                        <button className="flex flex-row space-x-2 bg-red-300 text-white px-3 py-2 rounded-md shadow-lg capitalize cursor-not-allowed">
                          <MdDelete className="text-xl" />
                          <span id="rubikFont">DELETE</span>
                        </button>
                      </Tooltip>
                    )}
                  </td> */}
                  <Dialog open={open} onClose={handleClose}>
                    <DialogContent className="flex flex-col space-y-4 m-auto">
                      <div className="m-auto text-7xl">
                        <AiOutlineExclamationCircle />
                      </div>
                      <div className="text-xl m-auto font-semibold">
                        Are you sure ?
                      </div>
                      <Box sx={{ "& button": { m: 1 } }}>
                        <div>
                          <Button
                            onClick={deleteUserAPi}
                            variant="contained"
                            color="error"
                            size="medium"
                          >
                            Yes, delete it!
                          </Button>
                          <Button
                            onClick={handleClose}
                            color="success"
                            variant="contained"
                            size="medium"
                          >
                            Cancel
                          </Button>
                        </div>
                      </Box>
                    </DialogContent>
                  </Dialog>

                  {/* Edit User Popup Here */}
                </tbody>
              );
            })
          ) : searchInput.length==0 ? (
            <tbody>
              <tr>
                <td colSpan={6} className="w-full">
                  <div className="w-full flex justify-center">
                    <NewLoader />
                  </div>
                </td>
              </tr>
            </tbody>
          ):<div></div>}
       
        </table>

        {user && user.data.users.length === 0 && searchInput.length > 0 && (
        <div className="flex items-center justify-center mt-10">
          <img
            src={noDataFoundImage}
            alt="No Data Found"
            style={{ width: "35%", height: "35%" }}
          />
        </div>
      )}
        {user && user.data.users.length === 0 && searchInput.length > 0 ?(<div> </div>):
        <div className="w-full flex justify-center">
          {" "}
          <Pagination
            className="min-w-max m-auto text-center"
            onChange={(event, pageNumber) =>
              pageChangeHandle(event, pageNumber)
            }
            count={userLength}
            color="success"
          />
        </div>}
      </div>
    </div>
  );
};

export default UserList;
