import React, { useState, useEffect } from "react";
import SideNav from "../others/sidenav";
import AddUser from "./addUserModal";
import UserHeader from "./header";
import UserList from "./userList";
import TextField from "@mui/material/TextField";
import { FiSearch } from "react-icons/fi";
import {
  GetALLUserAPI_PAGI_ROLE,
  GetAllUserReport,
  GetAllUserInstallReport,
  GetAllUserNotInstallReport,
  GetAllUniqueApk,
} from "../Authservice/api";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { FiDownload } from "react-icons/fi";
import { Box, Link } from "@mui/material";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Typography from "@material-ui/core/Typography";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./dropdown";
import axios from "axios";

const User = () => {
  const [
    soon,
    //  setSoon
  ] = useState(true);
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchValue, setSearchValue] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [uniqueApkVersions, setUniqueApkVersions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const size = 7;

  const usersIDs = localStorage.getItem("users");
  const userDataID = JSON.parse(usersIDs);
  let stores;
  if (userDataID) {
    stores = userDataID.data.stores;
  }
  const handleSearch = async (pageNumber) => {
    let no_page;
    console.log("datsjsjjsjsja", pageNumber);
    if (pageNumber) {
      no_page = pageNumber;
    } else {
      no_page = page;
    }

    console.log("no_page", no_page);
    const response = {
      pages: no_page,
      sizes: size,
      search: searchUserInput,
      store_idss: stores,
      status: selectedFilter,
    };

    try {
      const data = await GetALLUserAPI_PAGI_ROLE(response);

      setSearchValue(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUserInput, selectedFilter, page]);

  const handleFilter = (event) => {
    setSelectedFilter(event.target.value);
    handleSearch(event.target.value);
  };

  console.log("selectVesrion", selectedFilter);

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  };

  const downloadCSV = async () => {
    try {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear().toString().substring(2);
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      const timestamp = `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
      if (selectedFilter === "Not install") {
        const response = await GetAllUserNotInstallReport();

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }

        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_user_${selectedFilter}_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else if (selectedFilter === "All") {
        const response = await GetAllUserReport();

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }

        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_user_All_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const response = await GetAllUserInstallReport(selectedFilter);

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }

        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_user_${selectedFilter}_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while downloading agent data.");
    }
  };

  useEffect(() => {
    const fetchUniqueApkVersions = async () => {
      try {
        const response = await GetAllUniqueApk();
        setUniqueApkVersions(response.data);
      } catch (error) {
        console.log("Error fetching unique APK versions: ", error);
      }
    };

    fetchUniqueApkVersions();
  }, []);

  console.log("dnhdghdhyd", uniqueApkVersions);

  return (
    <>
      {soon === false ? (
        <div className="w-full h-screen flex  bg-gray-100 ">
          <div className="md:contents hidden">
            <SideNav />
          </div>
          <div className="w-2/12 h-screen hidden md:block"></div>

          <div div className="md:w-full w-5/6 h-full grid ">
            <div className="h-full"></div>
            <div className="w-full text-center font-extrabold text-2xl">
              Coming Soon
            </div>

            <div className="h-full"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex  bg-gray-100 overflow-y-auto md:scrollbar">
          <div className="md:contents hidden">
            <SideNav />
          </div>
          <div className="w-2/12 h-screen hidden md:block"></div>

          <div className=" w-full lg:w-5/6 h-full bg-gray-100">
            <div className="w-full bg-white">
              <UserHeader />
            </div>
            <div className="w-full h-16 hidden md:block"></div>

            {/* <div className="bg-gray-100 flex space-x-3 mt-3"> */}
            {/* <AddUser /> */}
            <div className="flex w-full md:px-10 px-4 pt-0 md:pt-0 justify-between gap-2 md:items-end md:flex-row flex-col mt-5">
              <div className="flex-grow flex flex-row relative">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Search User By ID Or Name"
                  size="small"
                  color="success"
                  value={searchUserInput}
                  className="flex-grow"
                  onChange={(e) => {
                    setSearchUserInput(e.target.value);
                  }}
                ></TextField>
                <div
                  onClick={(e) => handleSearch()}
                  className="w-10 h-full flex justify-center hover:cursor-pointer absolute right-2"
                >
                  <FiSearch className="self-center text-3xl text-emerald-500 hover:text-emerald-400 active:text-emerald-700" />
                </div>
              </div>
              <div>
                <FormControl variant="outlined" size="small" color="success">
                  <InputLabel
                    id="demo-select-small-label"
                    color="success"
                    sx={{ marginLeft: "8px" }}
                  >
                    APK Installation
                  </InputLabel>
                  <Select
                    labelId="new-select-label"
                    id="new-select"
                    label="Verification Filter"
                    color="success"
                    value={selectedFilter}
                    onChange={handleFilter}
                    className=" ml-2 mr-5 "
                    style={{ width: "200px" }}
                  >
                    <MenuItem value={"All"}>All</MenuItem>

                    <Dropdown
                      // key={apkVersion}
                      trigger={
                        <DropdownNestedMenuItem
                          label="Installed"
                          rightIcon={<ArrowRight />}
                        />
                      }
                      menu={[
                        uniqueApkVersions.map((apkVersion) => (
                          <DropdownMenuItem
                            key={apkVersion}
                            value={apkVersion}
                            onClick={() => {
                              setSelectedFilter(apkVersion);
                              handleSearch();
                            }}
                          >
                            {`APK version ${apkVersion}`}
                          </DropdownMenuItem>
                        )),
                      ]}
                    />

                    {uniqueApkVersions.map((apkVersion) => (
                      <MenuItem
                        key={apkVersion}
                        value={apkVersion}
                        style={{ display: "none" }}
                      >
                        {" "}
                        {`APK version ${apkVersion}`}
                      </MenuItem>
                    ))}
                    <MenuItem value={"Not install"}>Not Installed</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <AddUser />
              <Button
                className="h-10"
                id="rubikFont"
                variant="contained"
                sx={{
                  backgroundColor: "#10b981",
                }}
                startIcon={<FiDownload />}
                onClick={downloadCSV}
                // className="font-bold pd-4 text-white w-24 rounded-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500  h-8 mt-2 "
              >
                Report
              </Button> 
            </div>

            {/* </div> */}
            <UserList
              searchInput={searchUserInput}
              searchResult={searchValue}
              onClickFunction={handleSearch}
              selectedFilter={selectedFilter}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default User;
