import React, { useState } from "react";
import { BsFillSuitDiamondFill } from "react-icons/bs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import { IoMdClose } from "react-icons/io";
// import FilledImg from "./FilledImage.jpg";
// import FilledImg from "./FilledImage.jpg";
// import Landscape from "./Landscape.jpg";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import { ImCross } from "react-icons/im";

export default function SopVerificationShelf(props) {
  // const [open, setOpen] = useState(false);
  const [imgFull, setImgFull] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  // const fullWidth = true;
  // const maxWidth = "md";
  // const [alignment, setAlignment] = useState("empty");

  const sop = props.sopData;

  // const handleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  const handleOpen = (e, imgUrls) => {
    // console.log("imgUrl", imgUrls )
    setImgUrl(imgUrls);
    setImgFull(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpenFull = () => {
  //   setImgFull(true);
  // };
  const handleCloseFull = () => {
    setImgFull(false);
  };
  return (
    <>
      {sop &&
        (sop[0].filled_image.status_verify === "verified" &&
        sop[0].empty_image.status_verify ? (
          <Tooltip
            title={
              <div className="flex flex-col space-y-1">
                <span>Flormar </span>
                <img
                  className="w-fit h-fit max-h-[300px] z-10"
                  src={sop[0].filled_image.image_url}
                  alt="Not found"
                />
              </div>
            }
          >
            <span>
              <BsFillSuitDiamondFill
                onClick={(e) => handleOpen(e, sop[0].filled_image.image_url)}
                className="text-2xl m-1 text-green-500 Blink hover:cursor-pointer"
              />
            </span>
          </Tooltip>
        ) :  sop[0].filled_image.status_verify ===
        "pending_verification" ? (
        <Tooltip title="Pending Verification ">
          <span>
            <BsFillSuitDiamondFill className="text-2xl m-1 text-amber-500 hover:cursor-pointer" />
          </span>
        </Tooltip>
      ) :  sop[0].filled_image.status_verify === "pending_upload" ? (
        <Tooltip title="No Data">
          <span>
            <BsFillSuitDiamondFill className="text-2xl m-1 text-red-500" />
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            <div className="grid">
              <span>Re-Capture</span>
              <span>{ sop[0].rejected_reason}</span>
            </div>
          }
        >
          <span>
            <BsFillSuitDiamondFill
              onClick={(e) => handleOpen(e, sop[0].filled_image.image_url)}
              className="text-2xl m-1 text-blue-500"
            />
          </span>
        </Tooltip>
      ))}
      {/* <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            height: "auto",
            maxHeight: "800px",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <div className="w-full h-full flex flex-col space-y-3">
            <div className="w-full h-10 flex flex-row justify-between px-3">
              <span
                id="rubikFont"
                className="font-semibold text-xl text-gray-600 self-center"
              >
                Flormar / Shelf 1
              </span>
              <IoMdClose
                onClick={handleClose}
                className="text-2xl hover:cursor-pointer self-center"
              />
            </div>

            <div className="w-full h-[70svh] flex justify-center">
              {alignment === "empty" ? (
                <img
                  onClick={handleOpenFull}
                  src={imgUrl}
                  alt="Empty"
                  className="h-full hover:cursor-pointer"
                />
              ) : (
                <img
                  onClick={handleOpenFull}
                  src={imgUrl}
                  alt="Empty"
                  className="h-full hover:cursor-pointer"
                />
              )} */}
      <Dialog
        // fullWidth={fullWidth}
        // maxWidth={maxWidth}
        fullScreen
        open={imgFull}
        onClose={handleCloseFull}
        PaperProps={{
          sx: {
            width: "100%",
            maxHeight: "1300px",
            background: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
          <div className="self-center">
            <ImCross
              onClick={handleCloseFull}
              className="z-20 text-xl cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
              style={{
                right: "4%",
                top: "2%",
              }}
            />
            {/* <GoChevronLeft
                      // onClick={leftClick}
                      className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                      style={{
                        left: "2%",
                        top: "45%",
                      }}
                    />
                    <GoChevronRight
                      // onClick={rightClick}
                      className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                      style={{ right: "2%", top: "45%" }}
                    /> */}
            <div
              className="fixed top-4 px-4 left-0 w-full h-16 z-10 flex flex-col space-y-1"
              style={{ background: "rgba(0, 0, 0, 0.3)" }}
            >
              <span className="text-white opacity-100 font-semibold text-lg">
                Flormar
              </span>
              <span className="text-white opacity-100 font-semibold text-lg"></span>
            </div>
            {/* <div
                      className="fixed bottom-4 px-4 left-0 w-full h-16 z-10 flex flex-col space-y-1"
                      style={{ background: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <span className="text-white opacity-100 font-semibold text-lg">
                        Fullness {all_img_url[zoomImage].fullness}%
                      </span>
                      <span className="text-white opacity-100 font-semibold text-lg">
                        {new Date(all_img_url[zoomImage].date).getDate() +
                          "-" +
                          monthNames[
                            new Date(all_img_url[zoomImage].date).getMonth()
                          ] +
                          "-" +
                          new Date(all_img_url[zoomImage].date).getFullYear()}
                      </span>
                    </div> */}
            <TransformWrapper>
              <TransformComponent>
                <img
                  className="self-center"
                  style={{ maxHeight: "95svh" }}
                  src={imgUrl}
                  alt="img not found"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </DialogContent>
      </Dialog>
      {/* </div>
            <div className="w-full h-12 flex justify-center">
              <div> */}
      {/* <ToggleButtonGroup
                  fullWidth
                  sx={{ minWidth: 300 }}
                  className="h-10"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="empty"
                    sx={{ borderColor: "#10b981", color: "#10b981" }}
                  >
                    Filled SOP
                  </ToggleButton>
                  {/* <ToggleButton
                    value="filled"
                    sx={{ borderColor: "#10b981", color: "#10b981" }}
                  >
                    Emptied SOP
                  </ToggleButton> */}
      {/* </ToggleButtonGroup> */}
      {/* </div>
            </div>
          </div>
        </DialogContent>
      </Dialog> */}
    </>
  );
}
