// DataContext.js
import React, { createContext, useContext, useState } from 'react';

const ChartContext = createContext();
// export const PageContext = createContext();

export function ChartdataProvider({ children }) {
  const [clickedElementStoreId, setClickedElementStoreId] = useState("");
  const [filteredFullnessVar, setFilteredFullnessVar] = useState(0);
  const [selectCappedDate, setSelectCappedDate] = useState(null); 
  const [cappedDate, setCappedDate] = useState(null);

  const [page, setPage] = useState(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    return storedPage ? parseInt(storedPage) : 1;
  });

  const setAndStorePage = (newPage) => {
    setPage(newPage);
    sessionStorage.setItem('currentPage', newPage);
  }

  return (
    <ChartContext.Provider value={{ clickedElementStoreId, setClickedElementStoreId, filteredFullnessVar, setFilteredFullnessVar, selectCappedDate, setSelectCappedDate, cappedDate, setCappedDate, page, setPage: setAndStorePage }}>
      {children}
    </ChartContext.Provider>
  );
}

export function useData() {
  return useContext(ChartContext);
}

// export const usePageNumber = () => useContext(PageContext);