import React, { useState, useEffect } from "react";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useData } from "../../context/chartContext";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { TextField } from "@mui/material";

export default function CalenderNew({ setParentdata }) {
  const [calender, setCalender] = useState(new Date());
  // const [open, setOpen] = useState(false);

  //   const handleOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const {setSelectCappedDate} = useData();

  const handlechange = (date) => {
    setSelectCappedDate(date)
    setCalender(date);
  };

  console.log("Calender",calender.toLocaleDateString("en-GB"))

  useEffect(() => {
    setParentdata(formatDate(calender));
    // eslint-disable-next-line
  }, [calender]);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  return (
    <>
      <div className="w-full cursor-pointer">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
          className=" cursor-pointer"
            // open={open}
            // onClose={() => setOpen(false)}
            sx={{ backgroundColor: "white",
                  "& .MuiInputBase-root": {
                    height: "40px", // Adjust the height as needed
            }, }}
            slotProps={{
              textField: {
                fullWidth: true,
                backgroundColor: "white",
                // readOnly: true,
                // onClick: () => setOpen(true),
              },
            }}
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label="Date Selected"
            value={calender}
            onChange={handlechange}
            minDate={new Date(2019, 10, 16)}
            maxDate={new Date()}
            closeOnSelect={false}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}