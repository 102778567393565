import { initializeApp, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCwwQQrsfjQR9ZXyxqKlC5oJXO_nsootL0",
  authDomain: "neo-disha-auth-143df.firebaseapp.com",
  projectId: "neo-disha-auth-143df",
  storageBucket: "neo-disha-auth-143df.appspot.com",
  messagingSenderId: "785567132704",
  appId: "1:785567132704:web:6513fd547ce30c0611e045",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const getFirebaseAuth = () => getAuth(getApp());
export const authentication = getAuth(app);
