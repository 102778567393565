import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";

export default function AddedFailedList(props) {
  // PROPS DRILLING
  const all_data = props.upload_data;
  const [clickVal, setClickVal] = useState(false);

  // GET THE NO OF FAILD AND SUCCESS DATA
  const faild_data = [];
  const success_data = [];
  // console.log("all data", all_data);
  for (let count = 0; count < all_data.length; count++) {
    if (
      all_data[count].valid === false ||
      all_data[count].name === false ||
      all_data[count].type === false ||
      all_data[count].region === false ||
      all_data[count].cord === false
    ) {
      faild_data.push(all_data[count]);
    } else {
      success_data.push(all_data[count]);
    }
  }

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [open, setOpen] = useState(false);
  const handleOpen = (event) => {
    setClickVal(event);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="w-full flex flex-col space-y-1.5 py-2">
        <div className="self-center flex flex-row space-x-4">
          <div
            value="success"
            onClick={(e) => handleOpen("success")}
            id="rubikFont"
            className="flex flex-row space-x-3 px-4 py-1.5 bg-emerald-400 hover:bg-emerald-500 hover:cursor-pointer drop-shadow-xl shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)] text-center lg:text-lg 2xl:text-xl rounded-md 2xl:rounded-md justify-center text-emerald-50"
          >
            <span>Added :</span>
            <span>{success_data.length}</span>
          </div>
          <div
            onClick={(e) => handleOpen("failed")}
            id="rubikFont"
            className="flex flex-row space-x-3 px-4 py-1.5 bg-red-400 hover:bg-red-500 hover:cursor-pointer drop-shadow-xl shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)] text-center lg:text-lg 2xl:text-xl rounded-md 2xl:rounded-md justify-center text-red-50"
          >
            <span>Failed :</span>
            <span>{faild_data.length}</span>
          </div>
        </div>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          // PaperProps={{
          //   style: {
          //     // backgroundColor: "#d1d5db",
          //     boxShadow: "none",
          //   },
          // }}
        >
          <DialogContent>
            <div>
              <div
                id="rubikFont"
                className="text-lg text-gray-400 font-bold p-b2"
              >
                {clickVal === "failed" ? (
                  <span>FAILED TO UPLOAD</span>
                ) : (
                  <span>UPLOADED SUCCESSFULLY</span>
                )}
              </div>
              <table className="w-full  sm:bg-white rounded-lg md:overflow-hidden overflow-x-auto sm:shadow-lg">
                <thead className="  text-white whitespace-nowrap md:whitespace-normal">
                  <tr
                    //  className="bg-emerald-500 rounded-lg  mb-2"
                    className={`rounded-lg  mb-2 ${
                      clickVal === "failed" ? "bg-red-500" : "bg-emerald-500"
                    }`}
                  >
                    <th className="px-3 py-3 text-center border-b border-gray-300 tracking-wider">
                      <div className=" font-semibold text-base">ID</div>
                    </th>

                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Name
                      </div>
                    </th>
                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Region
                      </div>
                    </th>
                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Type
                      </div>
                    </th>
                    {/* <th className="px-8 py-4">
                    <div className="font-semibold text-right text-base">
                      Delete
                    </div>
                  </th> */}
                  </tr>
                </thead>
                {clickVal === "failed"
                  ? faild_data.length > 0 &&
                    faild_data.map((val, id) => {
                      return (
                        <tbody className="text-sm divide-y hover:bg-gray-200 divide-grey-light whitespace-nowrap	md:whitespace-normal">
                          <tr className="border-b border-gray-300">
                            {/* <td className="px-3 py-3 text-center">{val.valid ? val._1 : <span className="text-red-600 font-bold	">Duplicate Data</span>}</td> */}
                            <td className="px-3 py-3 text-center">
                              {val.valid === false ? (
                                <span className="text-red-600	font-bold">
                                  Duplicate ID
                                </span>
                              ) : val.empty_id === false ? (
                                <span className="text-red-600	font-bold">
                                  Missing Data
                                </span>
                              ) : (
                                val._1
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.name ? (
                                val._8
                              ) : (
                                <span className="text-red-600	font-bold">
                                  Missing data
                                </span>
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.region ? (
                                val._5
                              ) : (
                                <span className="text-red-600	font-bold">
                                  Missing data
                                </span>
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.type ? (
                                val._6
                              ) : (
                                <span className="text-red-600	font-bold">
                                  Missing data
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  : success_data.length > 0 &&
                    success_data.map((val, id) => {
                      return (
                        <tbody className="text-sm divide-y hover:bg-gray-200 divide-grey-light whitespace-nowrap	md:whitespace-normal">
                          <tr className="border-b border-gray-300">
                            <td className="px-3 py-3 text-center">{val._1}</td>
                            <td className="px-3 py-3 text-center">{val._8}</td>
                            <td className="px-3 py-3 text-center">{val._5}</td>
                            <td className="px-3 py-3 text-center">{val._6}</td>
                          </tr>
                        </tbody>
                      );
                    })}
              </table>
            </div>
          </DialogContent>
        </Dialog>
        {/* <div className="self-center py-1.5">
                  <span
                    className="text-blue-700 text-lg font-medium underline hover:text-blue-500 hover:cursor-pointer"
                    id="rubikFont"
                  >
                    View Result in List
                  </span>
                </div> */}
      </div>
    </>
  );
}
