import React from "react";
import Loader from "react-js-loader";

export default function Loaders() {
  return (
    <>
      <div>
        <div className={"item"}>
          <Loader
            type="ekvalayzer"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  );
}
