import React, { useState } from "react";
import SideNav from "../others/sidenav";
import Header from "./header";
import Details from "./details";
import Download from "./download";

export default function Summary() {
    const [tableData, setTableData] = useState([]);
    const [allData, setAllData] = useState({}); 
    const [loading, setLoading] = useState(false);

    console.log("allData inside summary", allData)
  return (
    <>
    <div className="w-full md:h-screen h-screen flex  bg-gray-100 overflow-y-auto scrollbar">
      <div className="hidden md:contents">
        <SideNav />
      </div>
      <div className="w-2/12 h-screen hidden md:block"></div>
      <div className="md:w-5/6 w-full h-full">
        <div className="w-full bg-white">
           <Header 
           setAllData={setAllData}
           setLoading={setLoading}
           />
        </div>
        <div className="w-full h-16 hidden md:block"></div>
        <div className="w-full md:px-12 p-6">
           <Download
             tableData={tableData}
             allData={allData}
            />
        </div>
        <div className="bg-gray-100">
           <Details
            tableData={tableData}
            setTableData={setTableData}
            allData={allData}
            loading={loading}
            setLoading={setLoading}
            />
        </div>
      </div>
    </div>
  </>
  );
}
