import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Pagination from "@mui/material/Pagination";
import { DialogContent, Dialog } from "@mui/material";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";

export default function Filled(props) {
  // pop for image showing
  const [open, setOpen] = useState(false);

  // const [fullWidth, setFullWidth] = useState(true);
  // const [maxWidth, setMaxWidth] = useState("md");
  const filledData = props.data;
  // console.log("filledData", filledData);
  const store = props.store;
  // console.log("store", store)

  const handleImgError = (e) => {
    e.target.src = "filled";
  };

  const all_img_url = [];
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(filledData)) {
    var date_data = value;

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(date_data)) {
      let storewise_data = value;
      for (let i = 0; i < storewise_data["filled"].length; i++) {
        all_img_url.push(storewise_data["filled"][i]);
      }
    }
  }

  const pageLimit = 6;
  const [totalpage] = useState(Math.ceil(all_img_url.length / pageLimit));
  // console.log("total page ", totalpage);
  const [page, setPage] = useState(1);
  const pageChangeHandle = (event, pageNumber) => {
    setPage(pageNumber);
  };

  const lastpage = pageLimit * page;
  const fisrtPage = lastpage - pageLimit;
  const allData = all_img_url.slice(fisrtPage, lastpage);
  // console.log("allData", allData)

  // THIS FUNCTION USE FOR
  function getStoreName(storeId) {
    const filterStore = store.filter((store) => {
      return store._id === storeId;
    });
    return filterStore[0].name;
  }

  const [zoomImage, setZoomImage] = useState(0);

  const openCardView = (e, index) => {
    e.preventDefault();
    // setCardViewIsActive(!cardViewIsActive);
    const totalCount = 5 * page;
    const totalIndex = totalCount + (page - 1);
    // console.log("totalIndex", totalIndex);
    setZoomImage(totalIndex - (5 - index));
    setOpen(true);
  };

  const leftClick = () => {
    // console.log("start Process");
    if (zoomImage > 0) {
      setZoomImage(zoomImage - 1);
    }
  };

  const rightClick = () => {
    // console.log("start Process");
    if (zoomImage < all_img_url.length - 1) {
      setZoomImage(zoomImage + 1);
    }
  };

  const handleClose = () => setOpen(false);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // zoom the image
  // console.log("open", open )

  return (
    <>
      {allData.length === 0 ? (
        <div>No image available</div>
      ) : (
        <div>
          {!allData ? (
            <></>
          ) : (
            <>
              <div>
                <ImageList
                  // sx={{ width: "500", height: "550" }}
                  cols={2}
                  gap={10}
                  variant="woven"
                  rowHeight="auto"
                >
                  {allData.map((val, id) => (
                    <button onClick={(e) => openCardView(e, id)}>
                      <ImageListItem key={id}>
                        <img
                          src={val.img_url}
                          alt="Filled Images"
                          loading="lazy"
                          onError={handleImgError}
                        />

                        <ImageListItemBar
                          className="text-left	"
                          title={<span>Fullness: {val.fullness}</span>}
                          subtitle={
                            new Date(val.date).getDate() +
                            "-" +
                            monthNames[new Date(val.date).getMonth()]
                          }
                        />
                      </ImageListItem>
                    </button>
                  ))}
                </ImageList>
                <Dialog
                  // fullWidth={fullWidth}
                  // maxWidth={maxWidth}
                  fullScreen
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    sx: {
                      width: "100%",
                      maxHeight: "1300px",
                      background: "black",
                      boxShadow: "none",
                    },
                  }}
                >
                  <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
                    {open === true ? (
                      allData && (
                        <div className="self-center">
                          <ImCross
                            onClick={handleClose}
                            className="z-20 text-xl cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
                            style={{
                              right: "4%",
                              top: "2%",
                            }}
                          />
                          <GoChevronLeft
                            onClick={leftClick}
                            className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                            style={{
                              left: "2%",
                              top: "45%",
                            }}
                          />
                          <GoChevronRight
                            onClick={rightClick}
                            className="text-white opacity-50 hover:opacity-100 text-7xl absolute z-10"
                            style={{ right: "2%", top: "45%" }}
                          />
                          <div
                            className="fixed top-4 px-4 left-0 w-full h-16 z-10 flex flex-col space-y-1"
                            style={{ background: "rgba(0, 0, 0, 0.3)" }}
                          >
                            <span className="text-white opacity-100 font-semibold text-lg">
                              {getStoreName(all_img_url[zoomImage].store)}
                            </span>
                            <span className="text-white opacity-100 font-semibold text-lg">
                              {all_img_url[zoomImage].brand_name}
                            </span>
                          </div>
                          <div
                            className="fixed bottom-4 px-4 left-0 w-full h-16 z-10 flex flex-col space-y-1"
                            style={{ background: "rgba(0, 0, 0, 0.3)" }}
                          >
                            <span className="text-white opacity-100 font-semibold text-lg">
                              Fullness {all_img_url[zoomImage].fullness}%
                            </span>
                            <span className="text-white opacity-100 font-semibold text-lg">
                              {new Date(all_img_url[zoomImage].date).getDate() +
                                "-" +
                                monthNames[
                                  new Date(
                                    all_img_url[zoomImage].date
                                  ).getMonth()
                                ] +
                                "-" +
                                new Date(
                                  all_img_url[zoomImage].date
                                ).getFullYear()}
                            </span>
                          </div>
                          <TransformWrapper>
                            <TransformComponent>
                              <img
                                className="self-center"
                                style={{ maxHeight: "95svh" }}
                                src={all_img_url[zoomImage].img_url}
                                alt="img not found"
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      )
                    ) : (
                      <>no image found</>
                    )}
                  </DialogContent>
                </Dialog>

                <div className="w-11/12 m-auto pt-2 flex justify-center   ">
                  <Pagination
                    className="min-w-max m-auto text-center   "
                    onChange={(event, pageNumber) =>
                      pageChangeHandle(event, pageNumber)
                    }
                    count={totalpage}
                    color="success"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
