import { React, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import {
  //  Doughnut,
  Bar,
} from "react-chartjs-2";
import { useData } from "../../context/chartContext";

// import token from "../Authservice/authToken";
// import axios from "axios";
// export const ChartContext = createContext();

function BarChart(props) {
  const { setClickedElementStoreId, selectCappedDate, setCappedDate } =
    useData();
  // const data = props.data;
  // const date = props.date;  
  const fullnessScore = props.fullnessScore;
  const performance = props.performance
  const store = props.store;
  let storeData;
  if (store) {
    storeData = store;
  }
  // const users = localStorage.getItem("users");
  // const userData = JSON.parse(users);

  // let stores;
  // if (userData) {
  //   stores = userData.data.stores;
  // }

  function storeFilter(id) {
    // const storeFull_id =[id]
    for (let count = 0; count < id.length; count++) {
      let filterStore = "";
      if (storeData) {
        filterStore = storeData.filter((store) => {
          return store._id === id[count];
        });
      }

      let valueStore_name = "";
      if (filterStore.length > 0) {
        valueStore_name = filterStore[0].store_id; // PRODUCTION ENVIROMENT
        // valueStore_name = filterStore[0].name.split(" ")[1];     // TEST ENVIROMENT
      } else {
        valueStore_name = 0;
      }

      // console.log("jbyugu", valueStore_name);      // id[count].store_name = filterStore

      id[count] = valueStore_name;
    }
    // console.log("new id ame paigalu", id)
    return id;
  }
  // console.log("fulness", fullnessScore)
  const colour = [];
  if (fullnessScore) {
    // storeFilter(fullnessScore.map((val) => val.avg_shelffullness));
    const data_length = fullnessScore.map((val) => val.avg_shelffullness);
    // console.log("data_length", data_length);
    for (let i = 0; i < data_length.length; i++) {
      // console.log("chbdkh",data_length[i]> 50)
      if (data_length[i] < 50) {
        // console.log(data_length[i]);
        if (data_length[i] === 0) {
        } else {
          colour.push("rgb(251,23,81)");
        }
      } else if (data_length[i] >= 80) {
        // console.log("8-0");
        // console.log(data_length[i]);
        colour.push("rgb(68,190,84)");
        // colour.push("rgb(56, 189, 248)");
      } else {
        // console.log("002002");
        // console.log(data_length[i]);
        // colour.push("rgb(	234,179,8)");
        // colour.push("rgb(52,211,153)");
        colour.push("rgb(243,152,32)");
      }
    }
    // if(fullnessScore.map((val) => val.avg_shelffullness) >0 )
  }

  // console.log("colour", colour);

  // console.log("store", storeData)
  //For Donut chart
  ChartJS.register(ArcElement, Tooltip, Legend);
  //For Horizontal Bar Chart
  ChartJS.register(CategoryScale, LinearScale, BarElement);

  //Options for Ranking Bar Chart
  const optionsRankingBar = {
    maintainAspectRation: true,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },

    responsive: true,
    scales: {
      x: {
        max: 100,
        grid: {
          display: false,
        },
      },
      y: {
        title: { display: true, text: "Fullness (%)", color: "black" },
        grid: {
          display: true,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        position: "top",
        // align: "start",
        padding: { top: 20 },
        display: false,
        text: "Store Fullness Status",
        color: "black",
      },

      tooltip: {
        callbacks: {
          label: (context) => {
            const dataIndex = context.dataIndex;
            const correspondingFilterStore = storeData.filter(
              (store) => store._id === final_fulnessScore[dataIndex]._id
            );
            const storeName =
              correspondingFilterStore[0]?.name || "Unknown Store";
            return storeName;
          },
          footer: (context) => {
            const value = context[0].formattedValue;
            return `\t\t\t\t\tFullness (%): ${value}`;
          },
        },
      },

      // title: {
      //   display: true,
      //   text: "Time series",
      // },
    },
  };

  //Options for previous Donut chart
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Shelf Image Classification (%)",
  //       color: "black",
  //     },
  //   },
  // };

  // For previous Doughnut chart Convert Empty Filed No detction Value into Percentage
  // let pieChart_value = "";
  // let pieChart_value_filed = "";
  // let pieChart_no_detction = "";
  // if (data) {
  //   pieChart_value = data.data.one_day.pie_chart.Empty;
  //   pieChart_value_filed = data.data.one_day.pie_chart.Filled;
  //   pieChart_no_detction = data.data.one_day.pie_chart.No_Detections;
  // }

  // For previous Doughnut chart  Average value of emptyness
  // const avrg_empty =
  //   (pieChart_value * 100) /
  //   (pieChart_value + pieChart_value_filed + pieChart_no_detction);
  // const avrg_filled =
  //   (pieChart_value_filed * 100) /
  //   (pieChart_value + pieChart_value_filed + pieChart_no_detction);
  // const avrg_no_detection =
  //   (pieChart_no_detction * 100) /
  //   (pieChart_value + pieChart_value_filed + pieChart_no_detction);

  // console.log("huduhdsu", storeFilter(fullnessScore1.map((val) => val._id)))
  const finalData = [];
  for (let count = 0; count < fullnessScore.length; count++) {
    if (fullnessScore[count].avg_shelffullness === 0) {
      finalData.push(0);
    } else {
      finalData.push(fullnessScore[count]);
    }
  }

  const final_fulnessScore = [];
  for (let count = 0; count < fullnessScore.length; count++) {
    if (fullnessScore[count].avg_shelffullness === 0) {
      final_fulnessScore.push({
        _id: 0,
        avg_shelffullness: undefined,
      });
    } else {
      final_fulnessScore.push(fullnessScore[count]);
    }
  }

  final_fulnessScore.sort((a, b) => {
    if (
      a.avg_shelffullness === undefined &&
      b.avg_shelffullness === undefined
    ) {
      return 0;
    }
    if (a.avg_shelffullness === undefined) {
      return 1;
    }
    if (b.avg_shelffullness === undefined) {
      return -1;
    }
    return a.avg_shelffullness - b.avg_shelffullness;
  });
  console.log("fullnessScore", final_fulnessScore);

  // const [clickedElementStoreId, setClickedElementStoreId] = useState("");
  const navigate = useNavigate();
  const handleBarClick = (elements) => {
    setCappedDate(selectCappedDate);
    const _id = final_fulnessScore[elements[0].index]._id;
    const clickedElement = storeData.filter((store) => {
      return store._id === _id;
    });
    const storeId = clickedElement[0].store_id;
    // console.log("storeId", storeId);
    setClickedElementStoreId(storeId);
    navigate("/analysis");
  };

  if(performance === 'High to low'){
    final_fulnessScore.reverse()
  }
  // console.log("clickedElementStoreId", clickedElementStoreId)

  return (
    <div className="w-11/12 pt-[7%] lg:w-12/12 2xl:w-11/12 ">
      {/* // <div className="m-auto  w-10/12 "> */}
      {!fullnessScore ? (
        <></>
      ) : (
        // <Doughnut
        //   data={{
        //     labels: ["Filled", "Empty", "No Detection"],
        //     datasets: [
        //       {
        //         label: "percentage",
        //         data: [0, 19, 3],
        //         backgroundColor: [
        //           "rgb(29, 78, 216)",
        //           "rgba(54, 162, 235, 0.7)",
        //           "rgrgb(105,240,174)",
        //         ],

        //         hoverOffset: 6,
        //       },
        //     ],
        //   }}
        // />
        // <Doughnut
        //   options={options}
        //   data={{
        //     labels: Object.keys(data.data.one_day.pie_chart),
        //     datasets: [
        //       {
        //         label: "Shelf fulness",
        //         data: [
        //           avrg_empty.toFixed(2),
        //           avrg_filled.toFixed(2),
        //           avrg_no_detection.toFixed(2),
        //         ],

        //         backgroundColor: [
        //           "rgb(255,82,82)",

        //           "rgb(52,211,153)",

        //           "rgb(96,125,139)",
        //         ],

        //         hoverOffset: 6,
        //       },
        //     ],
        //   }}
        // />

        <Bar
          options={{
            ...optionsRankingBar,
            onClick: (event, elements) => handleBarClick(elements),
          }}
          data={{
            labels: storeFilter(final_fulnessScore.map((val) => val._id)),

            datasets: [
              {
                label: "Fullness (%)",

                data: final_fulnessScore.map((val) => val.avg_shelffullness),

                backgroundColor:  colour ,

                borderRadius: 8,
              },
            ],
          }}
          height="90%"
          width="100%"
        />
      )}
    </div>
  );
}
export default BarChart;