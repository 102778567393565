import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { FiDownload } from "react-icons/fi";

export default function Download({ tableData, allData }) {
  const downloadCSV = () => {
    if (tableData.length === 0) {
      alert("No data to download.");
      return;
    }

    const headers = Object.keys(tableData[0]);
    const csvData = [headers.join(",")];

    tableData.forEach((row) => {
      const values = headers.map((header) => row[header]);
      csvData.push(values.join(","));
    });

    const csvContent = csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${allData.report}_Report.csv`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="flex flex-col ">
        <div className="w-full flex flex-col justify-end md:flex-row space-x-0 md:space-x-2">
          <div className="w-full md:w-fit">
            <Button
              id="rubikFont"
              variant="contained"
              sx={{
                backgroundColor: "#10b981",
              }}
              startIcon={<FiDownload />}
              onClick={downloadCSV}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
