import React, {
  useState,
  // useEffect
} from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  //  ThemeProvider, createTheme
} from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

// import { GetALLCaptureProgress } from "../Authservice/api";
// import PropTypes from "prop-types";
// import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
// import Fab from "@mui/material/Fab";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import Fade from "@mui/material/Fade";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

//Elevate bar and to the top option from MUI

export default function ProgressBar(props) {
  const store = props.captureStore;
  console.log("date data", props);
  const [open, setOpen] = useState(false);
  const [
    fullWidth,
    //  setFullWidth
  ] = useState(true);
  const [
    maxWidth,
    //  setMaxWidth
  ] = useState("md");
  const [inputData, setInputData] = useState("");

  // const date = props.date;

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  // const [store, setStore] = useState(false);
  // const users = localStorage.getItem("users");
  // const userData = JSON.parse(users);

  // let stores;
  // if (userData) {
  //   stores = userData.data.stores;
  // }

  const gettotalCapture = (data) => {
    if (data > 100) {
      return "100";
    } else {
      return data;
    }
  };

  // filter the store

  const searchTable = (data) => {
    // console.log("data", data)
    return data.filter((item) =>
      item.name.toLowerCase().includes(inputData.toLowerCase())
    );
  };

  const percVal = 100;
  return (
    <>
      <div className="w-full h-80 md:h-full pb-10 md:pb-6 relative overflow-y-auto bg-white flex flex-col shadow-md rounded-lg">
        <div className="w-full border-b-2 rounded-t-lg py-4 flex subpixel-antialiased">
          <span className="font-semibold lg:tracking-thin 2xl:tracking-normal self-center ml-4 lg:font-semibold 2xl:font-bold text-gray-600 text-lg lg:text-lg 2xl:text-xl">
            Capture Progress
          </span>
        </div>
        <div className="flex flex-col flex-grow overflow-y-auto overflow-x-hidden scrollbar">
          {store &&
            store.map((val, id) => {
              return (
                <div className="w-full m-1 md:m-0 py-2 text-gray-600 border-b px-4 flex flex-col border-gray-300">
                  <div className="w-full h-12 truncate lg:mt-1 flex flex-col justify-center lg:text-sm 2xl:text-base font-semibold lg:tracking-wider 2xl:tracking-wide">
                    {val.name}
                  </div>
                  <div className="w-full lg:h-full 2xl:h-1/2 flex flex-col justify-center">
                    <Progress
                      theme={{
                        active: { color: "#1173F0" },
                      }}
                      className="lg:h-1.5 2xl:h-2"
                      percent={gettotalCapture(
                        ((val.sum_n / val.totalCapture) * 100).toFixed(0)
                      )}
                    />
                  </div>
                  <div className="w-full flex justify-start">
                    <span className="font-[roboto] text-xs font-semibold">
                      {(() => {
                        const timestamp = new Date(val.latestTimestamp);
                        const hours = timestamp.getHours();
                        const minutes = timestamp.getMinutes();
                        const ampm = hours >= 12 ? "PM" : "AM";
                        const formattedHours = hours % 12 || 12; // Convert 0 to 12

                        return `${formattedHours}:${
                          minutes < 10 ? "0" : ""
                        }${minutes} ${ampm}`;
                      })()}
                    </span>
                  </div>
                </div>
              );
            })}
          {/* {store.length > 0 && (
            <div className="w-full m-1 md:m-0 py-2 text-gray-600 border-b px-4 flex flex-col space-y-2 border-gray-300">
              <div className="w-full h-10 2xl:h-1/2 truncate lg:mt-1 flex flex-col justify-center lg:text-sm 2xl:text-base font-semibold lg:tracking-wider 2xl:tracking-wide">
                {store.length > 0 && store[0].name}
              </div>
              <div className="w-full lg:h-full 2xl:h-1/2 flex flex-col justify-center">
                <Progress className="lg:h-1.5 2xl:h-2" percent={firstStore} />
              </div>
            </div>
          )} */}
          {/* {store.length - 1 > 0 && (
            <div className="w-full m-1 md:m-0 py-2 text-gray-600 border-b px-4 flex flex-col space-y-2 border-gray-300">
              {store.length - 1 > 0 && (
                <div className="w-full h-10 2xl:h-1/2 truncate lg:mt-1 flex flex-col justify-center lg:text-sm 2xl:text-base font-semibold lg:tracking-wider 2xl:tracking-wide">
                  {store && store[1].name}
                </div>
              )}
              <div className="w-full lg:h-full 2xl:h-1/2 flex flex-col justify-center">
                <Progress className="lg:h-1.5 2xl:h-2" percent={secondStore} />
              </div>
            </div>
          )}
          {store.length > 2 && (
            <div className="w-full m-1 md:m-0 py-2 text-gray-600 border-b px-4 flex flex-col space-y-2 border-gray-300">
              <div className="w-full h-10 truncate lg:mt-1 flex flex-col justify-center lg:text-sm 2xl:text-base font-semibold lg:tracking-wider 2xl:tracking-wide">
                {store.length > 2 && store[2].name}
              </div>
              <div className="w-full lg:h-full 2xl:h-1/2 flex flex-col justify-center">
                <Progress className="lg:h-1.5 2xl:h-2" percent={thirdStore} />
              </div>
            </div>
          )} */}
        </div>

        {/* <div className="w-full absolute bottom-0 2xl:pb-1	 md:h-5 flex justify-end rounded-b-lg underline decoration-sky-500 font-medium">
          <div className="self-end text-gray-600 text-sm pr-3">
            <button
              type="button"
              onClick={handleClickOpen}
              className="text-blue-600 hover:bg-blue-100 hover:rounded-md"
            >
              View all
            </button>
          </div>
        </div> */}
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div className="w-full h-full flex flex-col space-y-3">
            {/* <div className="w-full h-1/6 pb-2 text-gray-600 font-semibold text-lg">
              All Classes with total data
            </div> */}
            <div className="w-full h-5/6 container overflow-y-auto">
              <div className="container overflow-y-auto">
                <Box sx={{ flexGrow: 1 }}>
                  <AppBar
                    position="static"
                    color="primary"
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "#10b981",
                    }}
                  >
                    <Toolbar>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        Capture progress of{" "}
                        {store ? <>{store.length}</> : <>0</>} stores
                      </Typography>
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search store"
                          inputProps={{ "aria-label": "search" }}
                          onChange={(e) => setInputData(e.target.value)}
                        />
                      </Search>
                    </Toolbar>
                  </AppBar>
                  <Container>
                    {store &&
                      searchTable(store).map((val, id) => {
                        return (
                          <div
                            key={id}
                            className="w-full h-1/4 text-gray-600 rounded-md border border-emerald-400 mt-2 pl-2 pb-1 pr-2 flex flex-col space-y-2 md:space-y-0 lg:flex lg:flex-row"
                          >
                            <div className="w-full truncate lg:w-8/12 2xl:h-12 self-center flex">
                              <span className="self-center text-md font-semibold tracking-wide">
                                {val.name}
                              </span>
                            </div>
                            <div className="w-full lg:w-4/12 2xl:h-12 flex flex-col justify-center self-center">
                              <div className="w-full h-1/2 hidden lg:inline font-medium tracking-wide text-sm pt-2">
                                Image capture progress
                              </div>
                              <div className="w-full h-1/2">
                                {/* {{(
                                    (val.sum_n / (val.totalCapture * 3)) *
                                    100
                                  ).toFixed(0)} > 100 ? <>100</> : {(
                                    (val.sum_n / (val.totalCapture * 3)) *
                                    100
                                  ).toFixed(0)}} */}
                                <Progress
                                  theme={{
                                    active: { color: "#1173F0" },
                                  }}
                                  percent={
                                    (
                                      (val.sum_n / (val.totalCapture * 2)) *
                                      100
                                    ).toFixed(0) > 100
                                      ? percVal
                                      : (
                                          (val.sum_n / (val.totalCapture * 2)) *
                                          100
                                        ).toFixed(0)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Container>
                </Box>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

// const Progress_bar = ({ bgcolor, progress, height }) => {
//   const Parentdiv = {
//     height: height,
//     width: "100%",
//     backgroundColor: "white",
//     borderRadius: 40,
//   };

//   const Childdiv = {
//     height: "100%",
//     width: `${progress}%`,

//     backgroundColor: bgcolor,
//     borderRadius: 40,
//     textAlign: "right",
//     color: "white",
//   };

//   const progresstext = {
//     padding: 10,

//     color: "white",
//     fontWeight: 900,
//   };

//   return (
//     <div style={Parentdiv}>
//       <div style={Childdiv}>
//         <span className="mt-4" style={progresstext}>{`${progress}`}</span>
//       </div>
//     </div>
//   );
// };

// export default Progress_bar;
