import React, { useState, useEffect } from "react";
import axios from "axios";
import NewLoader from "../others/loaderUi";

export default function Details(props) {
  const allData = props.allData;
  const loading = props.loading;
  const setLoading = props.setLoading;
  console.log("loading", loading)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an Axios POST request
        const response = await axios.post("http://localhost:8081/data/summary/", allData);
  
        if (response.status === 200) {
          const data = response.data;
          props.setTableData(data.data);
          console.log("tableData", data);
        } else {
          console.error("Failed to fetch data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
  
    fetchData();
  }, [allData]);

  const headers = props.tableData.length > 0 ? Object.keys(props.tableData[0]) : [];

// Function to capitalize the first letter of a string
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Function to convert snake_case to Capitalized Words
const convertSnakeCaseToCapitalizedWords = (str) => {
  return str
    .split('_')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

// Modify the tableHeaders array
const tableHeaders = headers.map((header) => {
  // Replace underscores with spaces and capitalize words
  return convertSnakeCaseToCapitalizedWords(header);
});

  return (
    <>
          <div
            // id="settingsMainDiv"
            className="w-full px-2 xl:px-7 2xl:p-7 bg-gray-100 flex flex-col xl:space-y-0 2xl:space-y-4"
          >
      <div className="container overflow-auto m-auto">
        {!loading && (<table className="w-full sm:bg-white rounded-lg md:overflow-hidden overflow-x-auto sm:shadow-lg ">
          <thead>
            <tr className="bg-emerald-500 text-white rounded-lg mb-2">
              {tableHeaders.map((header, index) => (
                <th key={index} className="text-left">
                  <div className="px-3 py-3 font-semibold text-base 2xl:text-lg">
                    {header}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableData.map((item, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                } hover:bg-gray-200`}
              >
                {headers.map((header, index) => (
                  <td key={index} className="px-3 py-3">
                    {item[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          
        </table>)}
        {loading && (
            <div className="w-full flex justify-center">
              <NewLoader />
            </div>
          )}
      </div>
      </div>
    </>
  );
}
