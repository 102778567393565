import React, { useState, useEffect } from "react";
import SideNav from "../others/sidenav";
import SettingsHeader from "./settingsheader";
import { IoStorefront } from "react-icons/io5";
import AddRemoveStores from "./addRemoveStores";
import AddedStores from "./addedStores";
import AddNewStore from "./addnewStore";
import { GetALLStoreAPI, GetALLStoreAPI_ROLE } from "../Authservice/api";
export default function Settings() {
  const [stores, setStores] = useState(false);
  const [allStore, setAllStore] = useState(false);
  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  let storeIds;
  if (userData) {
    storeIds = userData.data.stores;
  }
  // console.log("storeIds", storeIds);
  // GET ALL THE STORE API INTEGRATION
  useEffect(() => {
    async function getStores() {
      try {
        const data = await GetALLStoreAPI_ROLE(storeIds);
        // console.log(data.data);
        setStores(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getStores() {
      try {
        const data = await GetALLStoreAPI();
        // console.log(data.data);
        setAllStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
  }, []);

  // console.log("get all store id in setting page ", stores);
  return (
    <>
      <div className="w-full h-screen flex bg-gray-100 overflow-y-auto md:scrollbar">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen hidden md:block"></div>

        <div className="w-full lg:w-5/6 h-full">
          <div className="w-full bg-white">
            <SettingsHeader />
          </div>
          <div className="w-full h-16 hidden md:block"></div>
          <div
            id="settingsMainDiv"
            className="w-full bg-gray-100 flex flex-col space-y-5"
          >
            <div
              id="settingsHeading"
              className="pl-4 flex flex-row justify-between"
            >
              <div className="flex flex-row space-x-3">
                <IoStorefront className="self-center text-gray-500 text-2xl lg:text-3xl 2xl:text-3xl" />
                <span className="self-center font-bold text-xl lg:text-2xl 2xl:text-3xl text-gray-600">
                  My Stores
                </span>
              </div>
              <div className="flex">
                <div className="flex flex-row self-center space-x-3">
                  <AddRemoveStores stores={stores} />

                  <AddNewStore stores={allStore} data={stores} />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col space-y-2">
              {/* <div
                // id="buttonOuterDiv"
                className="w-full h-10 md:h-10 hidden space-x-2 2xl:pr-2 flex justify-end"
              >
                <AddRemoveStores stores={stores} />

                <AddNewStore stores={allStore} data={stores} />
              </div> */}
              <div className="container rounded-xl m-auto">
                <AddedStores stores={stores} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}