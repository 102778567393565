import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import Filled from "./filledImage";
import EmptyImage from "./emptyImage";
import NoDetections from "./noDetection";
// import logoImage from "../Authservice/images/NeoPhyte_logo.gif";
import logoImage from "../Authservice/images/neo_disha_final_logo.gif";
import { GetALLStoreAPI } from "../Authservice/api";
export default function ImageModal({ data, handleClick }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const [
    fullWidth,
    // setFullWidth
  ] = useState(true);
  const [
    maxWidth,
    // setMaxWidth
  ] = useState("md");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClick();
    // eslint-disable-next-line
  }, [open]);

  // GET ALL THE STORE API INTEGRATION
  const [store, setStore] = useState(false);

  useEffect(() => {
    async function getStores() {
      try {
        const data = await GetALLStoreAPI();
        // console.log(data.data);
        setStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
  }, []);

  return (
    <div className="pb-12 md:pb-0 pt-3 xl:pt-5 xl:pr-16">
      {/* <Card sx={{ maxWidth: 345 }} onClick={handleOpen}>
      <CardActionArea   >
        <CardMedia
          component="img"
          height="140"
          image={cardimg}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Image Analysis
          </Typography>
          
        </CardContent>
      </CardActionArea>
      </Card> */}
      <button
        onClick={handleOpen}
        className="w-9/12 h-10 xl:w-2/12 xl:h-10 2xl:h-16 rounded-md hover:bg-emerald-800 bg-emerald-500 text-white text-sm 2xl:text-lg font-bold text-center "
      >
        View image
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Analysis by Category</DialogTitle>

        <Box sx={{ px: 3 }}>
          {data ? (
            <FormControl>
              <RadioGroup
                aria-labelledby="imageAnalysis"
                value={value}
                color="success"
                onChange={(e) => setValue(e.target.value)}
                row
              >
                <FormControlLabel
                  control={
                    <Radio
                      color="success"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  label="Filled"
                  value="filled"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="error"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  label="Empty"
                  value="empty"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  label="No Detections"
                  value="no_detections"
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <>
              <FormControl>
                <RadioGroup aria-labelledby="imageAnalysis" row>
                  <FormControlLabel
                    control={<Radio color="success" />}
                    label="Filled"
                    disabled
                    value="filled"
                  />
                  <FormControlLabel
                    control={<Radio color="success" />}
                    disabled
                    label="Empty"
                    value="empty"
                  />
                  <FormControlLabel
                    control={<Radio color="success" />}
                    disabled
                    label="No Detections"
                    value="no_detections"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </Box>

        <DialogContent className="overflow-y-auto scrollbar">
          {(() => {
            if (value === "filled") {
              return <Filled store={store} data={data} />;
            } else if (value === "empty") {
              return <EmptyImage store={store} data={data} />;
            } else if (value === "no_detections") {
              return <NoDetections store={store} data={data} />;
            } else if (value === false) {
              return (
                <div className="grid h-80 ">
                  <div className="w-full h-full justify-center flex">
                    <span className="self-center xl:text-xl 2xl:text-2xl font-semibold text-blue-600">
                      Please select a category above to proceed
                    </span>
                  </div>
                  <div className="w-full flex flex-col space-y-6 md:space-y-6">
                    {/* <div className="text-2xl font-bold text-emerald-500 text-center">
                      Neo-DIShA
                    </div> */}
                    <div className="">
                      <img
                        className="2xl:w-4/12 bg-white 2xl:h-15 xl:w-3/12 xl:h-15 w-8/12 m-auto "
                        src={logoImage}
                        alt="img not found "
                      />
                    </div>
                  </div>

                  {/* <div></div> */}
                </div>
              );
            }
          })()}
        </DialogContent>
      </Dialog>
    </div>
  );
}
