import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { MdEdit } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Autocomplete from "@mui/material/Autocomplete";
import stateCities from "./stateCityData.json";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  GetallStoreTableApi,
  GETONESTORES,
  GETANALYSISOFSTORE,
} from "../Authservice/api";
import { useFormik } from "formik";
import token from "../Authservice/authToken";
import { createTheme, ThemeProvider } from "@mui/material";

export default function EditStore(props) {
  const userID_props = props.setuserID;
  // const mapStoreID = props.mapStoreID;
  const [store, setStore] = useState(false);
  // const [storeDelete, setStoreDelete] = useState(false);
  const [
    usersID,
    //  setUsersID
  ] = useState(false);
  const [analysisStore, setAnalysisStore] = useState(false);
  // console.log("store id ", usersID);
  const [detailStore, setDetailsStore] = useState(false);

  const [brand, setBrand] = useState(false);
  const [shelf, setShelf] = useState(false);
  const [
    // shelvesValue,
    setShelvesValue,
  ] = useState([]);
  const [
    // brandValue,
    setBrandValue,
  ] = useState([]);
  const [storeInfo, setStoreInfo] = useState({ id: 0, name: "", type: "" });
  const [location, setLocation] = useState({
    area: "",
    region: "",
    state: "",
    city: "",
    // pinCode: 0,
  });
  // const [
  //   // number,
  //   setNumber,
  // ] = useState("");

  // const [geography, setGeography] = useState({ latitude: "", longitude: "" });

  const [counter, setCounter] = useState(0);

  const states = Object.keys(stateCities);

  // const storeType = ["Trends", "Smart", "Beauty"];
  const storeType = ["TRENDS", "SMART", "BEAUTY"];

  const handleClick = () => {
    setCounter(counter + 1);
    // console.log(counter);
  };

  const url = "http://216.48.183.210:9889";

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  //Formik for edit store
  const initialValues = {
    name: "",
    id: "",
    location: "",
    number: "",
    // brands: "",
    // shelves: "",
  };

  const onSubmit = async (values) => {
    // console.log(values);
    const storeDetails = {
      name: values.name,
      id: values.id,
      number: values.number,
      location: values.location,
      // brands: values.brands,
      // shelves: values.shelves,
    };

    try {
      const data = await axios.post(
        `${url}/data/store/update/details/${usersID}`,
        storeDetails,
        {
          headers: {
            Accept: "application/json",
            Authorization: await token(),
          },
        }
      );
      // console.log(data);
      if (data) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const size = 7;
  const [
    page,
    //  setPage
  ] = useState(1);

  // const pageChangeHandle = (event, pageNumber) => {
  //   setPage(pageNumber);
  // };f

  const usersIDs = localStorage.getItem("users");
  const userDataID = JSON.parse(usersIDs);

  let stores;
  if (userDataID) {
    stores = userDataID.data.stores;
  }
  useEffect(() => {
    async function getStores() {
      const response = {
        pages: page,
        sizes: size,
        store_id: stores,
      };
      try {
        const data = await GetallStoreTableApi(response);
        // console.log(data);
        setStore(data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
    // eslint-disable-next-line
  }, [page]);

  // if (store) {
  //   storeLength = Math.ceil(store.data.storeLength / size);
  // }

  //  popup  Delete Store button

  const [edit, setEdit] = useState(false);

  const handleUpdateOpen = async (storeID) => {
    setEdit(true);
    try {
      const data = await GETONESTORES(userID_props);
      if (data) {
        setDetailsStore(data.data.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const data = await GETANALYSISOFSTORE(userID_props);
      if (data) {
        setAnalysisStore(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("store details", detailStore);

  const handleUpdateClose = () => {
    setEdit(false);
  };

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            fontSize: "20px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            "&.MuiInputLabel-shrink": {
              fontSize: "20px",
            },
          },
        },
      },
    },
  });
  return (
    <>
      <div
        onClick={() => {
          handleUpdateOpen();
        }}
        className="cursor-pointer m-auto"
      >
        {userData.data.user_role === "Super Admin" ? (
          <>
            <MdEdit className="text-emerald-600" />
          </>
        ) : (
          <>
            <BsFillEyeFill className="text-emerald-600" />
          </>
        )}
      </div>
      <Dialog
        open={edit}
        onClose={handleUpdateClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent className="overflow-y-auto scrollbar">
          <div className="w-full font-[Roboto]">
            <div className="w-full flex justify-between text-xl mb-5 text-gray-600 font-bold">
              <span className="self-center">Store Details</span>
              <IconButton
                edge="end"
                onClick={handleUpdateClose}
                aria-label="close"
                className="self-center"
              >
                <CloseIcon />
              </IconButton>
            </div>
            {/* <form onSubmit={formik.handleSubmit} action=""> */}

            {detailStore && (
              <>
                <div className="w-full space-y-6">
                  <div className="flex justify-start items-center gap-2">
                    <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                      1
                    </p>
                    <h1 className="text-lg">Store Info</h1>
                  </div>
                  <div className="w-full flex bprder sm:space-x-2 flex-wrap sm:flex-nowrap">
                    <div className="sm:w-1/2 w-full sm:mb-0 mb-2">
                      <ThemeProvider theme={theme}>
                        <TextField
                          className="w-full"
                          type="text"
                          InputProps={
                            userData.data.user_role === "Super Admin"
                              ? { readOnly: false }
                              : { readOnly: true }
                          }
                          defaultValue={detailStore.store_id}
                          label="Store ID"
                          variant="outlined"
                          name="number"
                          onChange={(e) =>
                            setStoreInfo({ ...storeInfo, id: e.target.value })
                          }
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          // value={formik.values.number}
                        />
                      </ThemeProvider>

                      {/* {formik.touched.number && formik.errors.number ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.number}
                  </div>
                ) : null} */}
                    </div>
                    <div className="w-full sm:mb-0 mb-2">
                      <ThemeProvider theme={theme}>
                        <TextField
                          type="text"
                          className="w-full"
                          InputProps={
                            userData.data.user_role === "Super Admin"
                              ? { readOnly: false }
                              : { readOnly: true }
                          }
                          defaultValue={detailStore.name}
                          label="Store Name"
                          variant="outlined"
                          name="name"
                          onChange={(e) =>
                            setStoreInfo({ ...storeInfo, name: e.target.value })
                          }
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          // value={formik.values.name}
                        />
                      </ThemeProvider>

                      {/* {formik.touched.name && formik.errors.name ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.name}
                  </div>
                ) : null} */}
                    </div>

                    <div className="sm:w-1/2 w-full">
                      <ThemeProvider theme={theme}>
                        <FormControl fullWidth>
                          <InputLabel>Store Type</InputLabel>
                          <Select
                            label="Store Type"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            //   defaultValue="Rakesh"
                            name="type"
                            defaultValue={detailStore.type}
                            onChange={(e) => {
                              setStoreInfo({
                                ...storeInfo,
                                type: e.target.value,
                              });
                              console.log(e.target.value);
                            }}
                          >
                            {storeType.map((type, index) => {
                              return (
                                <MenuItem value={type} key={index}>
                                  {type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </ThemeProvider>
                    </div>
                  </div>
                  <hr />
                  <div className="flex justify-start items-center gap-2">
                    <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                      2
                    </p>
                    <h1 className="text-lg">Store Location</h1>
                    {/* <TextField
                          type="text"
                          className="w-full"
                          label="Area"
                          variant="outlined"
                          name="area"
                          InputProps={
                            userData.data.user_role === "Super Admin" ||
                            userData.data.user_role === "NHQ"
                              ? { readOnly: false }
                              : { readOnly: true }
                          }
                          defaultValue={detailStore.location}
                          onChange={(e) =>
                            setLocation({ ...location, area: e.target.value })
                          }
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          // value={formik.values.name}
                        /> */}
                  </div>

                  <div className="w-full flex bprder gap-2 flex-wrap">
                    <div className="flex w-full gap-2 sm:flex-nowrap flex-wrap">
                      <div className="w-full mb-0.5 sm:mb-0">
                        <ThemeProvider theme={theme}>
                          <TextField
                            type="text"
                            className="w-full"
                            label="Area"
                            variant="outlined"
                            name="area"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            defaultValue={detailStore.location}
                            onChange={(e) =>
                              setLocation({ ...location, area: e.target.value })
                            }
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // value={formik.values.name}
                          />
                        </ThemeProvider>

                        {/* {formik.touched.name && formik.errors.name ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.name}
                  </div>
                ) : null} */}
                      </div>
                      <div className="w-full mb-0.5 sm:mb-0">
                        <ThemeProvider theme={theme}>
                          <TextField
                            type="text"
                            className="w-full"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            label="Region"
                            variant="outlined"
                            name="region"
                            defaultValue={detailStore.region}
                            onChange={(e) =>
                              setLocation({
                                ...location,
                                region: e.target.value,
                              })
                            }
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // value={formik.values.name}
                          />
                        </ThemeProvider>

                        {/* {formik.touched.name && formik.errors.name ? (
                  <div className=" ml-2 text-sm text-red-600">
                    {formik.errors.name}
                  </div>
                ) : null} */}
                      </div>
                    </div>
                    <div className="w-full flex gap-0.5 sm:gap-2 flex-wrap sm:flex-nowrap">
                      <div className="w-full mb-2 sm:mb-0">
                        <ThemeProvider theme={theme}>
                          <Autocomplete
                            options={states.sort()}
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Your State"
                                variant="outlined"
                              />
                            )}
                            defaultValue={detailStore.state}
                            onChange={(event, value) => {
                              setLocation({ ...location, state: value });
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="w-full mb-2 sm:mb-0">
                        <ThemeProvider theme={theme}>
                          <TextField
                            type="text"
                            className="w-full"
                            label="City"
                            variant="outlined"
                            // name="area"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            defaultValue={detailStore.city}
                            onChange={(e) =>
                              setLocation({ ...location, city: e.target.value })
                            }
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // value={formik.values.name}
                          />
                        </ThemeProvider>

                        {/* {location.state ? ( */}
                        {/* <Autocomplete
                          options={stateCities[location.state]}
                          noOptionsText="No locations"
                          disableListWrap
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Your City"
                              variant="outlined"
                            />
                          )}
                          // defaultValue={detailStore.city}
                          onChange={(event, value) => {
                            setLocation({ ...location, city: value });
                          }}
                        /> */}
                        {/* ) : (
                          <TextField
                            label="First Select State"
                            variant="outlined"
                            className="w-full"
                            disabled
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-full">
            <TextField
              className="w-full"
              label="Location"
              variant="outlined"
              name="location"
              onChange={(e) => setLocation(e.target.value)}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.location}
            />
            // {formik.touched.location && formik.errors.location ? (
            //     <div className=" ml-2 text-sm text-red-600">
            //       {formik.errors.location}  
            //     </div>
            //   ) : null}
          </div> */}
                  <hr />
                  {/* <div className="flex justify-start items-center gap-2">
                    <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                      3
                    </p>
                    <h1 className="text-lg">Geography</h1>
                  </div>
                  <div className="w-full flex bprder sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2">
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        type="text"
                        label="Latitude"
                        variant="outlined"
                        name="latitude"
                        InputProps={
                          userData.data.user_role === "Super Admin" ||
                          userData.data.user_role === "NHQ"
                            ? { readOnly: false }
                            : { readOnly: true }
                        }
                        defaultValue={detailStore.location_ip
                          .split(",")[0]
                          .trim()}
                        onChange={(e) =>
                          setGeography({
                            ...geography,
                            latitude: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="w-full">
                      <TextField
                        className="w-full"
                        type="text"
                        label="Longitude"
                        variant="outlined"
                        name="lognitude"
                        InputProps={
                          userData.data.user_role === "Super Admin" ||
                          userData.data.user_role === "NHQ"
                            ? { readOnly: false }
                            : { readOnly: true }
                        }
                        defaultValue={detailStore.location_ip
                          .split(",")[1]
                          .trim()}
                        onChange={(e) =>
                          setGeography({
                            ...geography,
                            longitude: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <hr /> */}
                  <div className="flex justify-start items-center gap-2">
                    <p className="rounded-full flex justify-center items-center w-6 h-6 bg-[#10b981] text-white p-2">
                      4
                    </p>
                    <h1 className="text-lg">Brand & Shelves</h1>
                  </div>
                  <div className="w-full flex space-x-2">
                    <div className=" w-full flex space-x-2">
                      <div className="w-full">
                        <ThemeProvider theme={theme}>
                          <TextField
                            className="w-full"
                            type="text"
                            label="Number of brands"
                            variant="outlined"
                            name="lognitude"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            defaultValue={detailStore.brands.length}
                            // onChange={(e) =>
                            //   setGeography({
                            //     ...geography,
                            //     longitude: e.target.value,
                            //   })
                            // }
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // value={formik.values.number}
                          />
                        </ThemeProvider>

                        {/* <FormControl fullWidth>
                          <InputLabel>Brand</InputLabel>
                          <Select
                            label="Brands"
                            name="brands"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            //   defaultValue={states[0]}
                            onChange={(e) => setBrandValue(e.target.value)}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // value={formik.values.brands}
                          >
                            {brand &&
                              brand.data.map((val) => (
                                <MenuItem value={val.id}>{val.name}</MenuItem>
                              ))}
                          </Select>
                        </FormControl> */}
                        {/* {formik.touched.brands && formik.errors.brands ? (
                    <div className=" ml-2 text-sm text-red-600">
                      {formik.errors.brands}
                    </div>
                  ) : null} */}
                      </div>
                    </div>
                    <div className="w-full flex space-x-2">
                      <div className="w-full">
                        {/* {shelf && ( */}
                        <ThemeProvider theme={theme}>
                          <TextField
                            className="w-full"
                            type="text"
                            label="Number of Shelves"
                            variant="outlined"
                            name="lognitude"
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            defaultValue={detailStore.shelves.length}
                            // onChange={(e) =>
                            //   setGeography({
                            //     ...geography,
                            //     longitude: e.target.value,
                            //   })
                            // }
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // value={formik.values.number}
                          />
                        </ThemeProvider>

                        {/* <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={shelf}
                            getOptionLabel={(option) => option.name}
                            // value={(option) => setShelvesValue([option._id]) }
                            InputProps={
                              userData.data.user_role === "Super Admin"
                                ? { readOnly: false }
                                : { readOnly: true }
                            }
                            //   defaultValue={states[0]}
                            onChange={(option) => setShelvesValue(option)}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Shelves"
                                placeholder="Favorites"
                              />
                            )}
                          /> */}
                        {/* )} */}
                        {/* {formik.touched.shelves && formik.errors.shelves ? (
                    <div className=" ml-2 text-sm text-red-600">
                      {formik.errors.shelves}
                    </div>
                  ) : null} */}
                      </div>

                      {/* {userData.data.user_role === "Super Admin" ? (
                        <div className="m-auto">
                          <Button
                            sx={{
                              backgroundColor: "#059669",
                            }}
                            onClick={handleClick}
                            variant="contained"
                          >
                            <ControlPointIcon />
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )} */}
                    </div>
                  </div>
                  {/* {Array.from(Array(counter)).map((c, index) => {
                    return (
                      <div key={index} className="w-full flex space-x-4">
                        <div className=" w-full flex space-x-2">
                          <div className="w-full">
                            <FormControl fullWidth>
                              <InputLabel>Brand</InputLabel>
                              <Select
                                label="Brands"
                                name="brands"
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                // value={formik.values.brands}
                              >
                                {brand &&
                                  brand.data.map((val) => (
                                    <MenuItem value={val.id}>
                                      {val.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                           
                          </div>
                        </div>
                        <div className="w-full flex space-x-2">
                          <div className="w-full">
                            {shelf && (
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={shelf}
                                getOptionLabel={(option) => option.name}
                                // value={(option)=> option.id}
                                // value={(option) => setShelvesValue([option._id]) }
                                onChange={(option) =>
                                  setShelvesValue(option.id)
                                }
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Shelves"
                                    placeholder="Favorites"
                                  />
                                )}
                              />
                            )}
                         
                          </div>
                       
                        </div>
                      </div>
                    );
                  })} */}

                  <div className="flex justify-end w-full gap-2">
                    <button
                      onClick={handleUpdateClose}
                      className="rounded-md shadow-md text-sm font-bold bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-20 h-8"
                    >
                      Cancel
                    </button>
                    {userData.data.user_role === "Super Admin" ? (
                      <button
                        type="submit"
                        className=" rounded-md shadow-md text-sm font-bold bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-20 h-8"
                      >
                        Save
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* </form> */}
          </div>
        </DialogContent>
        {/* <DialogContent className="overflow-y-auto scrollbar">
          <div className="w-full h-full">
            <div className="w-full h-full py-2 flex flex-col justify-end">
              <form className="h-full" onSubmit={formik.handleSubmit} action="">
                {detailStore && (
                  <div className="flex h-full justify-evenly flex-col space-y-7">
                    <div
                      id="rubikFont"
                      className="w-full flex justify-center text-2xl font-semibold text-gray-500"
                    >
                      <span>Store Details</span>
                    </div>
                    <TextField
                      fullWidth
                      label="Store Name"
                      name="name"
                      defaultValue={detailStore.name}
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="Location"
                      name="location"
                      defaultValue={detailStore.location}
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="Store Number"
                      name="number"
                      defaultValue={detailStore.number}
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="No' of brands"
                      name="brands"
                      defaultValue={detailStore.brands.length}
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <TextField
                      fullWidth
                      label="No' of shelves"
                      name="shelves"
                      defaultValue={detailStore.shelves.length}
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className=" flex justify-end">
                      <div className="flex flex-row space-x-2">
                        <button
                          onClick={handleUpdateClose}
                          className="bg-red-600 rounded-md shadow-md text-sm font-bold active:bg-red-500 hover:bg-red-800 text-white w-20 h-8"
                        >
                          Cancel
                        </button>
                        <div>
                          <button
                            type="submit"
                            className="bg-emerald-500 rounded-md shadow-md text-sm font-bold active:bg-emerald-500 hover:bg-emerald-800 text-white w-20 h-8"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </DialogContent> */}
      </Dialog>
    </>
  );
}
