import React, { useState, useEffect, useRef } from "react";
import { FiSearch } from "react-icons/fi";
import MobileNav from "../others/mobileNav";
import MobileUserDetailsPage from "../others/mobileUserDetailsPage";
import { TextField } from "@mui/material";
import { useData } from "../../context/chartContext";
import "./searchboxAnimations.css";
import Fade from "react-reveal/Fade";
// import { set } from "date-fns";

export default function AnaHeader({ setFiltereredTable, setOpenRows }) {
  const [searchVisible, setSearchVisible] = useState(true);
  const [serachValue, setSearchValue] = useState("");
  const searchRef = useRef(null);
  const { clickedElementStoreId, setClickedElementStoreId } = useData();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    const redirectedStore = localStorage.getItem("redirectedStore");
    if (redirectedStore) {
      // setSearchVisible(true);
      console.log('axc',redirectedStore)
      setSearchValue(redirectedStore);
      setFiltereredTable(redirectedStore);
      // setOpenRows([]);
      localStorage.removeItem("redirectedStore");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  // logic for redirection to analysis page on clicking of bar of bar chart in dashboard page
  if (clickedElementStoreId !== "") {
    setFiltereredTable(clickedElementStoreId);
    setClickedElementStoreId("");
  }

  return (
    <>
      <div className="w-full h-16 md:fixed z-10 bg-white border-b-2 px-3">
        <div className="flex justify-between items-center">
          <div className="md:hidden block">
            <MobileNav />
          </div>
          <div className="md:py-3 md:pl-4 md:flex items-center justify-between md:text-2xl text-xl font-sans text-gray-600 font-bold tracking-wide w-[80%] h-[12]">
            Shelf-fullness analysis
            {searchVisible ? (
              <div
                ref={searchRef}
                className="self-center md:mr-1 "
                style={{ width: "36.7%" }}
              >
                <Fade right duration={1400}>
                  <TextField
                    fullWidth
                    label="Search Store"
                    variant="outlined"
                    color="success"
                    value={serachValue}
                    onChange={(e) => {
                      setFiltereredTable(e.target.value);
                      setSearchValue(e.target.value);
                      setOpenRows([]);
                    }}
                    size="small"
                  />
                </Fade>
              </div>
            ) : (
              <Fade left duration={1400}>
                <button
                  onClick={toggleSearch}
                  className="rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:outline-none ml-5 "
                >
                  <FiSearch className="text-gray-500" />
                </button>
              </Fade>
            )}
          </div>
          <div className="md:hidden block">
            <MobileUserDetailsPage />
          </div>
        </div>
      </div>
    </>
  );
}
