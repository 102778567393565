import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { FiDownload } from "react-icons/fi";
import { AiOutlineFileAdd } from "react-icons/ai";
import { AiFillFile } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import ExampleCSV from "./../User/User_Format.csv";
import ExampleImg from "../Authservice/images/BulkUserListEx.png";
import AddedFailedUserList from "./addedUserFailedList";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import { USERBULKUPLOAD } from "../Authservice/api";
import {
  // toast,
  Toaster,
} from "react-hot-toast";
import { saveAs } from "file-saver";

export default function BulkAddUserButton() {
  const [selectedFile, setSelectedFile] = useState(null);

  function handleFileSelect(event) {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.type !== "text/csv") {
      alert("Please choose a CSV file.");
      event.target.value = "";
      return;
    }

    setSelectedFile(file);
  }

  const [bulkUpload, setBulkUpload] = useState(false);

  async function handleSubmited() {
    const formData = new FormData();
    formData.append("csv", selectedFile);

    try {
      const data = await USERBULKUPLOAD(formData);
      if (data) {
        // window.location.reload(true);
        setBulkUpload(data.data);
        setFull(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // console.log("file", selectedFile);

  const handleDelete = () => {
    setSelectedFile(null);
  };

  // const refreshPage = () => {
  //   window.location.reload(true);
  // };

  //!BULKADD BUTTON POPUP REQUIREMENTS FOR MOBILE & TABLET STARTS
  const [full, setFull] = useState(false);
  const handleFull = () => {
    setFull(true);
  };
  const handleEmpty = () => {
    setFull(false);
  };
  //BULKADD BUTTON REQUIREMENTS FOR MOBILE & TABLET POPUP ENDS

  //?BULKADD BUTTON POPUP REQUIREMENTS FOR LAPTOP & DESKTOP STARTS
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //BULKADD BUTTON REQUIREMENTS FOR LAPTOP & DESKTOP POPUP ENDS

  // DONWLOAD THE CSV FILE
  function handleDownload() {
    fetch(ExampleCSV)
      .then((response) => response.blob())
      .then((blob) =>
        saveAs(blob, "user.csv", { type: "text/csv;charset=utf-8" })
      )
      .catch((error) => console.error(error));
  }

  return (
    <>
      {/*BULK ADD STORE BUTTON FOR MOBILE AND TAB VERSION*/}
      {/* <div className="xl:hidden"> */}
      <div className="hidden">
        <Button
          className="h-10"
          id="rubikFont"
          variant="outlined"
          sx={{
            color: "#10b981",
            borderColor: "#10b981",
          }}
          component="label"
          onClick={handleFull}
        >
          Add Multiple Users
        </Button>
      </div>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#d1d5db",
            boxShadow: "none",
          },
        }}
        fullScreen
        open={full}
        onClose={handleEmpty}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#10b981" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ADD MULTIPLE USERS
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClosed}>
                save
              </Button> */}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleEmpty}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className="overflow-y-auto scrollbar flex flex-col space-y-3">
          {/*HEADING DIV*/}
          {/* <div className="w-full text-gray-600  font-semibold text-xl px-3">
            <span id="rubikFont" classname="">
              ADD MULTIPLE USERS
            </span>
          </div> */}
          {/*CONTENT DIV*/}
          <div className="w-full flex flex-col space-y-5">
            {/*DOWNLOAD FORMAT DIV STEP-1*/}
            <div className="w-full px-3 py-3 bg-white border border-gray-400 rounded-md flex flex-col space-y-2">
              <div className="w-full flex flex-row space-x-1">
                <span
                  id="rubikFont"
                  className="text-base md:text-lg underline text-gray-600 font-semibold"
                >
                  Step-1:
                </span>
                <span
                  id="rubikFont"
                  className="text-base md:text-lg self-center underline text-gray-600 font-semibold"
                >
                  Download the format
                </span>
              </div>
              <p id="rubikFont" className="py-1">
                To add multiple users at once a .CSV file need to be uploaded
                with all the required information regarding the new users in a
                certain format.
              </p>
              <div className="w-full flex flex-col md:flex-row space-y-1 md:space-x-3">
                <p
                  id="rubikFont"
                  className="flex flex-row md:flex-col font-semibold text-gray-500 justify-center"
                >
                  Download the .CSV file format
                </p>
                <div className="w-full md:w-auto flex justify-center">
                  {/* <a
                    href={ExampleCSV}
                    download="Format for users"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <Button
                    onClick={handleSubmited}
                    id="rubikFont"
                    variant="contained"
                    sx={{
                      backgroundColor: "#10b981",
                    }}
                    startIcon={<FiDownload />}
                    // onClick={handleClickOpen}
                    //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
                  >
                    Download
                  </Button>
                  {/* </a> */}
                </div>
              </div>
              <p className="text-sm text-slate-400">
                *If you already have the .CSV file with the format, please
                upload it below
              </p>
            </div>
            {/*EXAMPLE FORMAT DIV STEP-2*/}
            <div className="w-full px-3 py-3 bg-white border border-gray-400 rounded-md flex flex-col space-y-2">
              <div className="w-full flex flex-row space-x-1">
                <span
                  id="rubikFont"
                  className="text-base md:text-lg underline text-gray-600 font-semibold"
                >
                  Step-2:
                </span>
                <span
                  id="rubikFont"
                  className="text-base md:text-lg self-center underline text-gray-600 font-semibold"
                >
                  Fill the format
                </span>
              </div>
              <p id="rubikFont" className="py-1">
                Fill the downloaded CSV format as per the example given below.
              </p>
              <div className="w-full flex justify-center">
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      className="aspect-video rounded-md"
                      src={ExampleImg}
                      alt="no img found"
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            {/* <div className="w-full border-2 rounded-md border-gray-500"></div> */}
            {/*UPLOAD FILE DIV STEP-3*/}
            <div className="w-full px-3 py-3 bg-white border border-gray-400 rounded-md flex flex-col space-y-2">
              <div className="w-full flex flex-row space-x-1">
                <span
                  id="rubikFont"
                  className="text-base md:text-lg underline text-gray-600 font-semibold"
                >
                  Step-3:
                </span>
                <span
                  id="rubikFont"
                  className="text-base md:text-lg self-center underline text-gray-600 font-semibold"
                >
                  Upload the file
                </span>
              </div>
              <p id="rubikFont" className="py-1">
                Please make sure that the .CSV file is in correct format with
                all the new users and their required information.
              </p>
              <div className="w-full flex flex-col space-y-1">
                <div className="w-full flex flex-col justify-center space-y-1 py-5">
                  <Button
                    className="w-fit self-center"
                    id="rubikFont"
                    variant="outlined"
                    component="label"
                    sx={{
                      color: "#10b981",
                      borderColor: "#10b981",
                    }}
                    startIcon={<AiOutlineFileAdd />}
                  >
                    Upload file
                    <input
                      hidden
                      accept=".csv"
                      multiple
                      type="file"
                      onChange={handleFileSelect}
                    />
                  </Button>
                  <p
                    id="rubikFont"
                    className="flex flex-row self-center justify-center text-slate-500 text-sm w-full"
                  >
                    Supports CSV file only
                  </p>
                  {selectedFile === undefined || selectedFile === null ? (
                    <></>
                  ) : (
                    <div className="flex w-full md:w-6/12 self-center flex-row px-3 justify-between bg-emerald-100 py-2 rounded-md">
                      <div className="flex flex-row space-x-2">
                        <AiFillFile className="self-center text-xl text-emerald-600" />
                        {selectedFile && (
                          <span id="rubikFont" className="self-center">
                            {selectedFile.name}
                          </span>
                        )}
                      </div>
                      <MdDelete
                        onClick={handleDelete}
                        className="self-center text-xl text-emerald-600 hover:text-rose-600 cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                {/* {selectedFile && <span className="flex-grow text-ellipsis">{selectedFile.name}</span>} */}
                {/* <span>{selectedFile}</span> */}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end">
            {selectedFile === undefined || selectedFile === null ? (
              <Button
                id="rubikFont"
                variant="contained"
                disabled
                sx={{
                  backgroundColor: "#10b981",
                }}
                // startIcon={<FiDownload />}
                // onClick={handleClickOpen}
                //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
              >
                SUBMIT
              </Button>
            ) : (
              <Button
                id="rubikFont"
                variant="contained"
                sx={{
                  backgroundColor: "#10b981",
                }}
                onClick={handleSubmited}
                // onClick={refreshPage}
                // startIcon={<FiDownload />}
                // onClick={handleClickOpen}
                //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
              >
                SUBMIT
              </Button>
            )}
          </div>
          <Toaster />
        </DialogContent>
      </Dialog>

      {/*BULK ADD STORE BUTTON FOR LAPTOP AND DESKTOP VERSION*/}
      {/* <div className="hidden xl:block"> */}
      <div className="">
        <Button
          className="h-10"
          id="rubikFont"
          variant="contained"
          sx={{
            backgroundColor: "#10b981",
            // paddingBottom: 1,
          }}
          component="label"
          onClick={handleOpen}
        >
          Add Multiple Users
        </Button>
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#d1d5db",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent className="overflow-y-auto scrollbar flex flex-col space-y-3">
          {/*HEADING DIV*/}
          <div className="w-full text-gray-600 flex justify-between font-semibold text-xl px-3">
            <span id="rubikFont" className="self-center">
              ADD MULTIPLE USERS
            </span>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {/*CONTENT DIV*/}
          <div className="w-full flex flex-col space-y-5">
            {/*DOWNLOAD FORMAT DIV STEP-1*/}
            <div className="w-full px-3 py-3 bg-white border border-gray-400 rounded-md flex flex-col space-y-2">
              <div className="w-full flex flex-row space-x-1">
                <span
                  id="rubikFont"
                  className="text-base md:text-lg underline text-gray-600 font-semibold"
                >
                  Step-1:
                </span>
                <span
                  id="rubikFont"
                  className="text-base md:text-lg self-center underline text-gray-600 font-semibold"
                >
                  Download the format
                </span>
              </div>
              <p id="rubikFont" className="py-1">
                To add multiple users at once a .CSV file need to be uploaded
                with all the required information regarding the new users in a
                certain format.
              </p>
              <div className="w-full flex flex-col md:flex-row space-y-1 md:space-x-3">
                <p
                  id="rubikFont"
                  className="flex flex-row md:flex-col font-semibold text-gray-500 justify-center"
                >
                  Download the .CSV file format
                </p>
                <div className="w-full md:w-auto flex justify-center">
                  <Button
                    id="rubikFont"
                    onClick={handleDownload}
                    variant="contained"
                    sx={{
                      backgroundColor: "#10b981",
                    }}
                    startIcon={<FiDownload />}
                  >
                    Download
                  </Button>
                </div>
              </div>
              <p className="text-sm text-slate-400">
                *If you already have the .CSV file with the format, please
                upload it below
              </p>
            </div>
            {/*EXAMPLE FORMAT DIV STEP-2*/}
            <div className="w-full px-3 py-3 bg-white border border-gray-400 rounded-md flex flex-col space-y-2">
              <div className="w-full flex flex-row space-x-1">
                <span
                  id="rubikFont"
                  className="text-base md:text-lg underline text-gray-600 font-semibold"
                >
                  Step-2:
                </span>
                <span
                  id="rubikFont"
                  className="text-base md:text-lg self-center underline text-gray-600 font-semibold"
                >
                  Fill the format
                </span>
              </div>
              <p id="rubikFont" className="py-1">
                Fill the downloaded CSV format as per the example given below.
              </p>
              <div className="w-full flex justify-center">
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      className="aspect-video rounded-md"
                      src={ExampleImg}
                      alt="no img found"
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            {/*UPLOAD FILE DIV STEP-3*/}
            <div className="w-full px-3 py-3 bg-white border border-gray-400 rounded-md flex flex-col space-y-2">
              <div className="w-full flex flex-row space-x-1">
                <span
                  id="rubikFont"
                  className="text-base md:text-lg underline text-gray-600 font-semibold"
                >
                  Step-3:
                </span>
                <span
                  id="rubikFont"
                  className="text-base md:text-lg self-center underline text-gray-600 font-semibold"
                >
                  Upload the file
                  {/* Instruction */}
                </span>
              </div>
              <p id="rubikFont" className="py-1">
                Please make sure that the .CSV file is in correct format with
                all the new users and their required information.
              </p>
              <div className="w-full flex flex-col space-y-1">
                <div className="w-full flex flex-col justify-center space-y-1 py-5">
                  <Button
                    className="w-fit self-center"
                    id="rubikFont"
                    variant="outlined"
                    component="label"
                    sx={{
                      color: "#10b981",
                      borderColor: "#10b981",
                    }}
                    startIcon={<AiOutlineFileAdd />}
                  >
                    Upload file
                    <input
                      hidden
                      accept=".csv"
                      multiple
                      type="file"
                      onChange={handleFileSelect}
                    />
                  </Button>
                  <p
                    id="rubikFont"
                    className="flex flex-row self-center justify-center text-slate-500 text-sm w-full"
                  >
                    Supports CSV file only
                  </p>
                  {selectedFile === undefined || selectedFile === null ? (
                    <></>
                  ) : (
                    <div className="flex w-full md:w-6/12 self-center flex-row px-3 justify-between bg-emerald-100 py-2 rounded-md">
                      <div className="flex flex-row space-x-2">
                        <AiFillFile className="self-center text-xl text-emerald-600" />
                        {selectedFile && (
                          <span id="rubikFont" className="self-center">
                            {selectedFile.name}
                          </span>
                        )}
                      </div>
                      <MdDelete
                        onClick={handleDelete}
                        className="self-center text-xl text-emerald-600 hover:text-rose-600 cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                {/* {selectedFile && <span className="flex-grow text-ellipsis">{selectedFile.name}</span>} */}
                {/* <span>{selectedFile}</span> */}
              </div>
              {bulkUpload && <AddedFailedUserList upload_data={bulkUpload} />}
            </div>
          </div>

          <div className="w-full flex justify-end">
            <div className="flex gap-2">
              <Button
                id="rubikFont"
                variant="contained"
                sx={{
                  backgroundColor: "#FB1751",
                }}
                onClick={handleClose}
                // startIcon={<FiDownload />}
                // onClick={handleClickOpen}
                //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
              >
                {/* {bulkStore === null ? (
                  <CircularProgress />
                ) : ( */}
                <span>CANCEL</span>
                {/* )} */}
              </Button>
              {selectedFile === undefined || selectedFile === null ? (
                <Button
                  id="rubikFont"
                  variant="contained"
                  disabled
                  sx={{
                    backgroundColor: "#10b981",
                  }}
                  // startIcon={<FiDownload />}
                  // onClick={handleClickOpen}
                  //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
                >
                  SUBMIT
                </Button>
              ) : (
                <Button
                  id="rubikFont"
                  variant="contained"
                  sx={{
                    backgroundColor: "#10b981",
                  }}
                  onClick={handleSubmited}
                  // startIcon={<FiDownload />}
                  // onClick={handleClickOpen}
                  //   className="font-bold px-3  text-white rounded-md shadow-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500"
                >
                  SUBMIT
                </Button>
              )}
            </div>

            <Toaster />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
