import React from "react";
// import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import { GetALLStoreAPI } from "../Authservice/api";
export default function AddedStores(props) {
  const stores = props.stores;
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [stores, setStores] = useState(false);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  // GET THE USERS DETAIL FROM LOCAL STORAGE
  const users = JSON.parse(localStorage.getItem("users"));
  // console.log("Useres id", users)
  // const user_store_data = users ;

  //  GET ALL STORE API INTEGRATION

  const users_store_details = [];

  if (stores && users) {
    const user_store_len = users.data.stores.length;
    for (let count = 0; count < user_store_len; count++) {
      const filterStore = stores.filter((store) => {
        return store._id === users.data.stores[count];
      });
      // console.log("filterStore ", filterStore);
      // checkedItems[count]["fullness"] = filterStore[0];
      users_store_details.push(filterStore[0]);
    }
  }
  
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: { lg: 560, xl: 580, xxl: 700 } }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontSize: "17px",
                    color: "white",
                    backgroundColor: "#10b981",
                    fontWeight: "bold",
                  },
                }}
              >
                {/* {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))} */}
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Shelf</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="whitespace-nowrap md:whitespace-normal">
              {users_store_details ? (
                <>
                  {users_store_details.map((val, id) => {
                    return (
                      <TableRow>
                        <TableCell>{val.number}</TableCell>
                        <TableCell>{val.name}</TableCell>
                        <TableCell align="center">
                          {val.brands.length}
                        </TableCell>
                        <TableCell align="center">
                          {val.shelves.length}
                        </TableCell>
                        <TableCell>{val.location}</TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <>No Data Found </>
              )}

              {/* {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })} */}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </>
  );
}
