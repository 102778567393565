import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";
import {
  //  ADDSTOREUSER,
  REMOVESTOREUSER,
} from "../Authservice/api";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function AddRemoveStores(props) {
  const [checkedItems, setCheckedItems] = useState([]);
  const storeID = [];
  const [open, setOpen] = useState(false);
  const [
    fullWidth,
    //  setFullWidth
  ] = useState(true);
  const [
    maxWidth,
    //  setMaxWidth
  ] = useState("md");
  const [
    // inputData,
    setInputData,
  ] = useState("");
  const [addstore, setAddstore] = useState(false);
  const [storesId, setStoresId] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setCheckedItems(props.stores);
  };

  // console.log("checkedItems", checkedItems);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "All Select") {
      let TempData = checkedItems.map((items) => {
        return { ...items, isChecked: checked };
      });
      // console.log(TempData);
      setCheckedItems(TempData);
    } else {
      let TempData = checkedItems.map((items) =>
        items.name === name ? { ...items, isChecked: checked } : items
      );
      // console.log(TempData);
      setCheckedItems(TempData);
    }
  };

  useEffect(() => {
    //  extract store ID from store
    if (checkedItems) {
      checkedItems.filter((items) =>
        items.isChecked === true ? storeID.push(items.id) : null
      );
    }
    setStoresId(storeID);
    // console.log("storeID", storeID);
    // eslint-disable-next-line
  }, [checkedItems]);

  // console.log("storeID", storesId);

  //  SUBMIT THE STORE ID AND API INTEGRATION
  const users = JSON.parse(localStorage.getItem("users"));
  // console.log("users", users);
  const handleSubmit = async () => {
    const id = users.data.id;

    try {
      const data = await REMOVESTOREUSER(id, storesId);
      // console.log(data.data);

      setAddstore(data.data);
      if (data) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (addstore) {
    localStorage.setItem("users", JSON.stringify(addstore));
  }
  // localStorage.setItem("users", JSON.stringify(addstore));
  // console.log("setAdd", addstore);

  return (
    <>
      <button
        type="button"
        onClick={handleClickOpen}
        className="w-26 h-10 px-2 bg-rose-500 hover:bg-rose-700 active:bg-rose-600 shadow-xl rounded-md text-white text-xs  lg:text-sm font-semibold"
      >
        Remove Store
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div className="w-full h-full flex flex-col space-y-3">
            <div className="w-full h-5/6 container overflow-y-auto">
              <div className="container overflow-y-auto">
                <Box sx={{ flexGrow: 1 }}>
                  <AppBar
                    position="static"
                    color="primary"
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "#10b981",
                    }}
                  >
                    <Toolbar>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        Remove Stores from monitoring list
                      </Typography>
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search store"
                          inputProps={{ "aria-label": "search" }}
                          onChange={(e) => setInputData(e.target.value)}
                        />
                      </Search>
                    </Toolbar>
                  </AppBar>
                  <Container>
                    <div className="w-full flex flex-col space-y-3">
                      <div className="container mt-5 rounded-xl overflow-y-auto scrollbar">
                        <table className="w-full bg-white whitespace-nowrap md:whitespace-normal">
                          <thead className="border border-grey-light">
                            <tr className="bg-gray-200 rounded-xl">
                              <th className=" flex px-5 items-center py-5">
                                <input
                                  type="checkbox"
                                  className=" rounded-lg  cursor-pointer  border-gray-300  shadow-sm "
                                  name="All Select"
                                  checked={
                                    checkedItems.filter(
                                      (items) => items?.isChecked !== true
                                    ).length < 1
                                  }
                                  onChange={handleChange}
                                />
                              </th>
                              <th className="p-3 text-left">
                                <div className="font-semibold">Store name</div>
                              </th>
                              <th className="p-3 text-left">
                                <div className="font-semibold">Location</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {checkedItems ? (
                              <>
                                {checkedItems.map((val, id) => {
                                  return (
                                    <tr key={id} className=" rounded-l-lg">
                                      <td className="px-5 py-4 border-b border-l border-grey-light">
                                        <input
                                          type="checkbox"
                                          className="form-check-input rounded-lg  cursor-pointer  border-gray-300  shadow-sm   "
                                          checked={val.isChecked || false}
                                          value={val.id}
                                          name={val.name}
                                          id="mastercheck"
                                          onChange={handleChange}
                                        />
                                      </td>
                                      <td className="border-grey-light border-b border-l text-sm 2xl:text-base  mb-2 hover:bg-gray-100 p-3">
                                        {val.name}
                                      </td>

                                      <td className="border-grey-light border text-sm 2xl:text-base mb-2  hover:bg-gray-100 p-3">
                                        {val.location}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <>Data not found </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="w-full h-10 flex justify-end">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="bg-emerald-500 hover:bg-emerald-700 active:bg-emerald-600 shadow-md rounded-md text-white font-semibold w-32 lg:w-36"
                        >
                          Save Changes
                        </button>
                      </div> */}
                    </div>
                  </Container>
                </Box>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: "#f43f5e",
            }}
            onClick={handleSubmit}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
