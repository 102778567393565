import { React, useState, useEffect } from "react";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { DialogContent, Dialog } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  // toast,
  Toaster,
} from "react-hot-toast";
// import AWS from 'aws-sdk';
// import { Socket } from "socket.io-client";
// const ENDPOINT = 'wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test';

export default function Report({ setAllData, setLoading }) {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const [readySocket, setReadySocket] = useState(null);
  // const socket = new WebSocket(
  //   "wss://3qfy4flbkk.execute-api.us-east-1.amazonaws.com/test"
  // );
  const userss = localStorage.getItem("users");
  const userDatas = JSON.parse(userss);
  // let storesData;
  let user_roles;

  if (userDatas) {
    // storesData = userDatas.data.stores;
    // eslint-disable-next-line
    user_roles = userDatas.data.user_role;
  }

  // console.log("messages", messagess)
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");

  //!FOR THE SELECT FIELDS FROM MUI WITH CHECKMARKS STARTS
  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };

  const recipients = [
    "Agent",
    "Partial Onboarding",
    "Rejected shelf",
    "Verified shelf",
  ];

  const [recipientsRole, setRecipientsRole] = useState([]);
  // eslint-disable-next-line
  const handleChangeRecipients = (event) => {
    const {
      target: { value },
    } = event;
    setRecipientsRole(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const users = localStorage.getItem("users");
  // eslint-disable-next-line
  const userData = JSON.parse(users);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [statesAndCitiesMap, setStatesAndCitiesMap] = useState({});

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(
          "https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev/activecom/get_unique_location"
        );
        const data = await response.json();

        if (data) {
          const states = data.states;

          states.unshift("All");

          setStates(states);

          const citiesWithAllOption = data.cities;
          citiesWithAllOption.unshift("All");

          setCities(citiesWithAllOption);
          const statesAndCitiesMap = data.statesAndCitiesMap;
          setStatesAndCitiesMap(statesAndCitiesMap);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);

  const [statesName, setStatesName] = useState([]);
  const [citiesName, setCitiesName] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const allData = {
      report: recipientsRole[0],
      state: statesName,
      city: citiesName,
    };

    setAllData(allData);
    console.log("all data", allData);
    setOpen(false);
    setLoading(true);

    // try {
    //   const response = await fetch('http://localhost:8081/data/summary/', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(allData),
    //   });

    //   if (response.ok) {
    //     const data = await response.json();
    //     toast.success("Report Generated");
    //     setTimeout(function () {
    //       setOpen(false);
    //     }, 1000);
    //     console.log("report",data);
    //   } else {
    //     console.error('Failed to create report:', response.status);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     handleSubmit();
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [readySocket]);

  const renderTags = (value) => {
    const maxTagsToShow = 1; // Maximum number of tags to show before truncating
    const additionalTagsCount = value.length - maxTagsToShow;

    const tagsToShow = value.slice(0, maxTagsToShow);
    const additionalTags = additionalTagsCount > 0 ? `...` : null;

    return (
      <p>
        {tagsToShow.join(", ")}
        {additionalTags && <>, {additionalTags}</>}
      </p>
    );
  };
  return (
    <>
      <button
        type="button"
        onClick={handleOpen}
        className="flex space-x-2 px-3 py-2.5 mt-1 xl:mt-0 bg-emerald-500 hover:bg-emerald-700 active:bg-emerald-600 shadow-2xl rounded-md text-white "
      >
        <span id="rubikFont" className="self-center drop-shadow-2xl">
          Report
        </span>
        <HiOutlineDocumentReport className="text-lg self-center drop-shadow-2xl" />
      </button>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
            // backgroundColor: "#d1d5db",
            // boxShadow: "none",
          },
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          sx={{ padding: 0 }}
          className="overflow-y-auto scrollbar"
        >
          <div className="w-full h-full flex flex-col space-y-1">
            <div className="w-full h-12 bg-emerald-500 text-white items-center px-3 md:px-8 flex justify-between ">
              <div className="flex flex-row space-x-2">
                <span id="rubikFont" className="text-lg">
                  Analysis Report
                </span>
              </div>
              <RxCross2
                onClick={handleClose}
                className="text-xl cursor-pointer"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col pb-3 space-y-3 px-3 md:px-8">
                <div className="flex flex-row py-2 border-b border-gray-400 hover:border-b-2 hover:border-black space-x-3 md:space-x-8">
                  {/* Dropdown for Recipients */}
                  <Autocomplete
                    style={{ marginRight: "8%" }}
                    fullWidth
                    multiple
                    id="recipients-autocomplete"
                    options={recipients}
                    value={recipientsRole}
                    onChange={(event, newValue) => {
                      if (newValue.includes("All")) {
                        setRecipientsRole(recipients);
                        return;
                      }
                      setRecipientsRole(newValue);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={recipientsRole.indexOf(option) > -1}
                          color="primary"
                        />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reports"
                        variant="outlined"
                      />
                    )}
                    renderTags={renderTags}
                  />
                  {/* Dropdown for States */}
                  <Autocomplete
                    fullWidth
                    multiple
                    id="states-autocomplete"
                    options={states}
                    value={statesName}
                    onChange={(event, newValue) => {
                      if (newValue.includes("All")) {
                        setStatesName(states);
                        setAvailableCities(cities);
                        setCitiesName([]); // Clear the selected cities when the state changes
                        return;
                      }
                      setStatesName(newValue);
                      // Filter cities based on the selected state
                      const selectedCities = newValue.reduce((acc, state) => {
                        if (statesAndCitiesMap[state]) {
                          acc.push(...statesAndCitiesMap[state]);
                        }
                        return acc;
                      }, []);
                      selectedCities.unshift("All");
                      setAvailableCities(selectedCities);
                      setCitiesName([]); // Clear the selected cities when the state changes
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={statesName.indexOf(option) > -1}
                          color="primary"
                        />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="States"
                        variant="outlined"
                      />
                    )}
                    renderTags={renderTags}
                  />

                  {/* Dropdown for Cities  */}
                  <Autocomplete
                    fullWidth
                    multiple
                    id="cities-autocomplete"
                    options={availableCities}
                    value={citiesName}
                    onChange={(event, newValue) => {
                      if (newValue.includes("All")) {
                        setCitiesName(availableCities);
                        return;
                      }
                      setCitiesName(newValue);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={citiesName.indexOf(option) > -1}
                          color="primary"
                        />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cities"
                        variant="outlined"
                      />
                    )}
                    renderTags={renderTags}
                  />
                </div>

                <div className="w-full flex justify-end">
                  <button onSubmit={handleSubmit} class="sendButton">
                    <span>Generate Report</span>
                  </button>
                  <Toaster />
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
