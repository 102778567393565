import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";

export default function AddedFailedUserList(props) {
  // PROPS DRILLING
  const all_data = props.upload_data;
  const [clickVal, setClickVal] = useState(false);

  // GET THE NO OF FAILD AND SUCCESS DATA
  const faild_data = [];
  const success_data = [];


  //   for (let count = 0; count < all_data.length; count++) {
  //     if (
  //       all_data[count].valid === false ||
  //       all_data[count].store_id === false ||
  //       all_data[count].mn_name === false ||
  //       all_data[count].mn_ID === false ||
  //       all_data[count].emty_id === false ||
  //       all_data[count].validUser === false ||
  //       all_data[count].mn_emty_mail === false ||
  //       all_data[count].Email === false ||
  //       all_data[count].us_emty_mail === false ||
  //       all_data[count].uEmail === false ||
  //       all_data[count].mn_number === false ||
  //       all_data[count].mNumber === false ||
  //       all_data[count].us_number === false ||
  //       all_data[count].uNumber === false
  //     ) {
  //       faild_data.push(all_data[count]);
  //     } else {
  //       success_data.push(all_data[count]);
  //     }
  //   }

  const all_manager_data = [];
  const all_user_data = [];
  all_data.forEach((obj) => {
    const {
      _1,
      _8,
      _9,
      _10,
      _11,
      _12,
      _13,
      _14,
      _15,
      Email,
      emty_id,
      mNumber,
      mn_ID,
      mn_emty_mail,
      us_emty_mail,
      mn_name,
      mn_number,
      store_id,
      uEmail,
      uNumber,
      us_number,
      us_name,
      // valid,
      validManager,
      validUser,
      checkmn_digit_number_mn,
      checkmn_digit_number_us,
      spc_mn_name,
      spc_us_name,
      valid_mn_Email,
      valid_us_Email,
    } = obj;
    const nameAgeObject = {
      role: "manager",
      storeID: _1,
      Name: _8,
      Number: _9,
      Email: _10,
      user_id: _11,
      valid_email: Email,
      valid_number: mNumber,
      empty_id: mn_ID,
      emptyMail: mn_emty_mail,
      emptyName: mn_name,
      empty_number: mn_number,
      emptyStoreID: store_id,
      validUserId: validManager,
      valid_digit_num: checkmn_digit_number_mn,
      check_sp_name: spc_mn_name,
      validate_email_id: valid_mn_Email,
    };
    const ageContactEmailObject = {
      role: "user",
      storeID: _1,
      Name: _12,
      Number: _13,
      Email: _14,
      user_id: _15,
      emptyStoreID: store_id,
      emptyName: us_name,
      valid_email: uEmail,
      valid_number: uNumber,
      empty_number: us_number,
      emptyMail: us_emty_mail,
      validUserId: validUser,
      empty_id: emty_id,
      valid_digit_num: checkmn_digit_number_us,
      check_sp_name: spc_us_name,
      validate_email_id: valid_us_Email,
    };
    all_manager_data.push(nameAgeObject);
    all_user_data.push(ageContactEmailObject);
  });

  const allUser_failed_data = all_manager_data.concat(all_user_data);

  for (let count = 0; count < allUser_failed_data.length; count++) {
    if (
      allUser_failed_data[count].emptyStoreID === false ||
      allUser_failed_data[count].emptyName === false ||
      allUser_failed_data[count].valid_email === false ||
      allUser_failed_data[count].valid_number === false ||
      allUser_failed_data[count].empty_number === false ||
      allUser_failed_data[count].emptyMail === false ||
      allUser_failed_data[count].validUserId === false ||
      allUser_failed_data[count].empty_id === false ||
      allUser_failed_data[count].valid_digit_num === false ||
      allUser_failed_data[count].check_sp_name === false ||
      allUser_failed_data[count].validate_email_id === false
    ) {
      faild_data.push(allUser_failed_data[count]);
    } else {
      success_data.push(allUser_failed_data[count]);
    }
  }


  const success_filter_data = [];
  if (success_data.length > 0) {
    for (let count = 0; count < success_data.length; count++) {
      if (Object.keys(success_data[count]).length < 17) {
      } else if(success_data[count].storeID === undefined){
        
        
      } else {
        success_filter_data.push(success_data[count]);
      }
    }
  }


  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [open, setOpen] = useState(false);
  const handleOpen = (event) => {
    setClickVal(event);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="w-full flex flex-col space-y-1.5 py-2">
        <div className="self-center flex flex-row space-x-4">
          <div
            value="success"
            onClick={(e) => handleOpen("success")}
            id="rubikFont"
            className="flex flex-row space-x-3 px-4 py-1.5 bg-emerald-400 hover:bg-emerald-500 hover:cursor-pointer drop-shadow-xl shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)] text-center lg:text-lg 2xl:text-xl rounded-md 2xl:rounded-md justify-center text-emerald-50"
          >
            <span>Added :</span>
            <span>{success_filter_data.length}</span>
          </div>
          <div
            onClick={(e) => handleOpen("failed")}
            id="rubikFont"
            className="flex flex-row space-x-3 px-4 py-1.5 bg-red-400 hover:bg-red-500 hover:cursor-pointer drop-shadow-xl shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)] text-center lg:text-lg 2xl:text-xl rounded-md 2xl:rounded-md justify-center text-red-50"
          >
            <span>Failed :</span>
            <span>{faild_data.length}</span>
          </div>
        </div>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          // PaperProps={{
          //   style: {
          //     // backgroundColor: "#d1d5db",
          //     boxShadow: "none",
          //   },
          // }}
        >
          <DialogContent>
            <div>
              <div
                id="rubikFont"
                className="text-lg text-gray-400 font-bold p-b2"
              >
                {clickVal === "failed" ? (
                  <span>FAILED TO UPLOAD</span>
                ) : (
                  <span>UPLOADED SUCCESSFULLY</span>
                )}
              </div>
              <table className="w-full  sm:bg-white rounded-lg md:overflow-hidden overflow-x-auto sm:shadow-lg">
                <thead className="  text-white whitespace-nowrap md:whitespace-normal">
                  <tr
                    //  className="bg-emerald-500 rounded-lg  mb-2"
                    className={`rounded-lg  mb-2 ${
                      clickVal === "failed" ? "bg-red-500" : "bg-emerald-500"
                    }`}
                  >
                    <th className="px-3 py-3 text-center border-b border-gray-300 tracking-wider">
                      <div className=" font-semibold text-base">Store Id</div>
                    </th>
                    <th className="px-3 py-3 text-center border-b border-gray-300 tracking-wider">
                      <div className=" font-semibold text-base">User Id</div>
                    </th>

                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Name
                      </div>
                    </th>
                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Email
                      </div>
                    </th>
                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Contact
                      </div>
                    </th>
                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center">
                        Role
                      </div>
                    </th>
                    <th className="text-left">
                      <div className="px-3 py-3 font-semibold text-base text-center"></div>
                    </th>
                    {/* <th className="px-8 py-4">
                    <div className="font-semibold text-right text-base">
                      Delete
                    </div>
                  </th> */}
                  </tr>
                </thead>
                {clickVal === "failed"
                  ? faild_data.length > 0 &&
                    faild_data.map((val, id) => {
                      return (
                        <tbody className="text-sm divide-y hover:bg-gray-200 divide-grey-light whitespace-nowrap	md:whitespace-normal">
                          <tr className="border-b border-gray-300">
                            <td className="px-3 py-3 text-center">
                              {val.emptyStoreID ? (
                                <>{val.storeID}</>
                              ) : (
                                <span className="text-red-600	font-bold">
                                  Missing Data
                                </span>
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.validUserId === false ? (
                                <span className="text-red-600	font-bold">
                                  Duplicate ID
                                </span>
                              ) : val.empty_id === false ? (
                                <span className="text-red-600	font-bold">
                                  Missing Data
                                </span>
                              ) : (
                                val.user_id
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.emptyName ? (
                                val.check_sp_name === false ? (
                                  <span className="text-red-600	font-bold">
                                    Not Valid Name
                                  </span>
                                ) : (
                                  <>{val.Name}</>
                                )
                              ) : (
                                <span className="text-red-600	font-bold">
                                  Missing Data
                                </span>
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.valid_email === false ? (
                                <span className="text-red-600	font-bold">
                                  Duplicate Email
                                </span>
                              ) : val.emptyMail === false ? (
                                <span className="text-red-600	font-bold">
                                  Missing Data
                                </span>
                              ) : val.validate_email_id === false ? (
                                <span className="text-red-600	font-bold">
                                  Not Valid Email
                                </span>
                              ) : (
                                val.Email
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.valid_number === false ? (
                                <span className="text-red-600	font-bold">
                                  Duplicate Number
                                </span>
                              ) : val.empty_number === false ? (
                                <span className="text-red-600	font-bold">
                                  Missing Data
                                </span>
                              ) : val.valid_digit_num === false ? (
                                <span className="text-red-600	font-bold">
                                  Not Valid Number
                                </span>
                              ) : (
                                val.Number
                              )}
                            </td>
                            <td className="px-3 py-3 text-center">
                              {val.role}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  : success_filter_data.length > 0 &&
                    success_filter_data.map((val, id) => {
                      return (
                        <tbody className="text-sm divide-y hover:bg-gray-200 divide-grey-light whitespace-nowrap	md:whitespace-normal">
                          <tr className="border-b border-gray-300">
                            <td className="px-3 py-3 text-center">{val.storeID}</td>
                            <td className="px-3 py-3 text-center">{val.user_id}</td>
                            <td className="px-3 py-3 text-center">{val.Name}</td>
                            <td className="px-3 py-3 text-center">{val.Email}</td>
                            <td className="px-3 py-3 text-center">{val.Number}</td>
                            <td className="px-3 py-3 text-center">{val.role}</td>
                          </tr>
                        </tbody>
                      );
                    })}
              </table>
            </div>
          </DialogContent>
        </Dialog>
        {/* <div className="self-center py-1.5">
                  <span
                    className="text-blue-700 text-lg font-medium underline hover:text-blue-500 hover:cursor-pointer"
                    id="rubikFont"
                  >
                    View Result in List
                  </span>
                </div> */}
      </div>
    </>
  );
}
