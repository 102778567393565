import React, { useEffect, useState } from "react";
// import Backdrop from "@mui/material/Backdrop";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { Formik, Form } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { number } from "yup/lib/locale";
import axios from "axios";
// import { async } from "@firebase/util";
// import Alert from '@mui/material/Alert';
import { GetALLStoreAPI } from "../Authservice/api";
import token from "../Authservice/authToken";
import Button from "@mui/material/Button";
import BulkAddUserButton from "./bulkAddUserButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Alert, Autocomplete, Snackbar } from "@mui/material";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: 2.5,
//   disply: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

export default function AddUserModal() {
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ isToast: false, message: "", type: "" });
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [store, setStore] = useState(false);
  const [userInput, setUserInput] = useState({
    user_name: "",
    user_id: "",
    user_role: "",
    number: "",
    store: "",
    stores:[],
    device_id: "",
    email: "",
    status:"Active"
   
  });
  // const initialValues = {
  //   user_name: "",
  //   user_id: "",
  //   user_role: "",
  //   email: "",
  //   number: "",
  //   location: "",
  //   store: "",
  // };

  // const url = "http://216.48.181.202:9889";
  // const url = "http://216.48.183.210:9889";

  const lambUrl = "https://gu44ge6xhk.execute-api.ap-south-1.amazonaws.com/dev";
  useEffect(() => {
    async function getUser() {
      try {
        const data = await GetALLStoreAPI();
        // console.log(data.data);
        setStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getUser();
  }, []);
  // console.log("AllStore", store);

  const onSubmit = (values) => {
    // console.log("userDetails");
    // console.log(
    //   "🚀 ~ file: addUserModal.js ~ line 48 ~ onSubmit ~ value̥s",
    //   values
    // );
    // const userDetails = {
    //   user_name: values.user_name,
    //   user_id: values.user_id,
    //   user_role: values.user_role,
    //   email: values.email,
    //   number: values.number,
    //   location: values.location,
    //   store: values.store,
    // };

    // try {
    //   const data = await axios.post(`${lambUrl}/user/create`, userDetails, {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: await token(),
    //     },
    //   });
    //   // console.log("User Data",data);
    //   if (data) {
    //     setOpen(false);
    //     window.location.reload();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };


  const validationSchema = Yup.object({
    [userInput.user_name]: Yup.string().required("User Name is Required"),
    [userInput.user_id]: Yup.string().required("User ID is Required"),
    [userInput.user_role]: Yup.string().required("Please Select User Role"),
    [userInput.email]: Yup.string()
      .email("Invalid email format")
      .required("Email is Required"),
    [userInput.number]: Yup.string()
      .max(10, "Number should be 10 digits")
      .required("Number is Required"),
    // location: Yup.string().required("Location is Required"),
    [userInput.store]: Yup.string().required("Store ID is Required"),
  });

  const formik = useFormik({
    initialValues: userInput,
    validationSchema,
    onSubmit,
  });
  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);
  const handleSubmit = async() => {
    try {
      const data = await axios.post(`${lambUrl}/user/create`, userInput, {
        headers: {
          Accept: "application/json",
          Authorization: await token(),
        },
      });
      // console.log("User Data",data);
      if (data) {
        setOpen(false);
        window.location.reload();
      }
    } catch (error) {
      setToast({isToast: true, message: error.response.data, type: "warning" });
      console.log(error.response.data);
    }
    // console.log(userInput);
  };
  const handleToastClose = () =>{
    setToast({isToast: false, message: "", type: ""})
  }
  return (
    <div className="bg-gray-100 flex space-x-3 mt-3">
      {/* {userData.data.user_role === "Cluster Manager" ? ( */}
      {userData.data.user_role === "Super Admin" ||
      userData.data.user_role === "NHQ" ? (
        <>
          <div className="flex justify-end items-center">
            <div className="flex space-x-3 justify-center items-center">
              {/* {userData.data.user_role === "Super Admin" ? ( */}
              <Button
                className="h-10"
                id="rubikFont"
                variant="contained"
                sx={{
                  backgroundColor: "#10b981",
                }}
                onClick={handleOpen}
                // className="font-bold pd-4 text-white w-24 rounded-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500  h-8 mt-2 "
              >
                Add an User
              </Button>
              {/* ) : (
                <></>
              )} */}

              {/* {userData.data.user_role === "Super Admin" ? ( */}
              <BulkAddUserButton />
              {/* ) : (
                <></>
              )} */}
            </div>
            {/* <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}> */}
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClose}
            >
              <DialogContent className="overflow-y-auto scrollbar">
                <div className="w-full font-[roboto]">
                  <div className="w-full flex justify-between text-xl mb-5 text-gray-600 font-bold">
                    <span className="self-center">Add New User</span>
                    <IconButton
                      edge="end"
                      onClick={handleClose}
                      aria-label="close"
                      className="self-center"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="w-full flex sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2 pb-4">
                  <div className="w-full">
                    <TextField
                      fullWidth
                      label="User ID"
                      required
                      name="user_id"
                      variant="outlined"
                      onChange={(e) => {
                        setUserInput({ ...userInput, user_id: e.target.value });
                      }}
                      onBlur={formik.handleBlur}
                      value={userInput.user_id}
                    />
                    {formik.touched.user_id && formik.errors.user_id ? (
                      <div className="ml-2 text-sm text-red-600">
                        {formik.errors.user_id}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <TextField
                      fullWidth
                      label="User Name"
                      required
                      name="user_name"
                      variant="outlined"
                      onChange={(e) => {
                        setUserInput({
                          ...userInput,
                          user_name: e.target.value,

                        });
                      }}
                      onBlur={formik.handleBlur}
                      value={userInput.user_name}
                    />
                    {formik.touched.user_name && formik.errors.user_name ? (
                      <div className=" ml-2 text-sm text-red-600">
                        {formik.errors.user_name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="w-full flex sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2 pb-4">
                  <div className="w-full flex flex-col">
                    <div>
                      <FormControl required fullWidth>
                        <InputLabel>User Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          name="user_role"
                          label="User Role"
                          onChange={(e) => {
                            setUserInput({
                              ...userInput,
                              user_role: e.target.value,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          value={userInput.user_role}
                        >
                          <MenuItem value={"user"}>Agent</MenuItem>
                          <MenuItem value={"manager"}>Manager</MenuItem>
                          <MenuItem value={"Cluster Manager"}>
                            Cluster Manager
                          </MenuItem>
                          <MenuItem value={"NHQ"}>Super User</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      {formik.touched.user_role && formik.errors.user_role ? (
                        <div className=" ml-2 text-sm text-red-600">
                          {formik.errors.user_role}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full">
                    {/* <TextField
                      fullWidth
                      type="text"
                      label="Store ID"
                      required
                      name="store"
                      variant="outlined"
                      onChange={(e) => {
                        setUserInput({
                          ...userInput,
                          store_id: e.target.value,
                        });
                      }}
                      onBlur={formik.handleBlur}
                      value={userInput.store}
                    /> */}
                    <Autocomplete
                      options={!store ? [{label:"Loading...", id:0}] : store}
                      getOptionLabel={store=>{
                        let number = store.store_id
                        return number}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Your Store"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, value) => {
                        console.log(value.id);
                        setUserInput({
                          ...userInput,
                          store: value.id,
                          stores:[value.id]
                        });
                      }}
                    />
                    {formik.touched.store && formik.errors.store ? (
                      <div className=" ml-2 text-sm text-red-600">
                        {formik.errors.store}
                      </div>
                    ) : null}
                  </div>
                  </div>
                
                <div className="w-full flex sm:space-x-2 flex-wrap sm:flex-nowrap gap-y-2 pb-4">
                {/* <div className="w-full flex flex-col pb-5"> */}
                <div className="w-full">
                  
                  <TextField
                    fullWidth
                    type="Number"
                    label="Contact Number"
                    required
                    name="number"
                    variant="outlined"
                    onChange={(e) => {
                      setUserInput({ ...userInput, number: e.target.value });
                    }}
                    onBlur={formik.handleBlur}
                    value={userInput.number}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <p className=" text-sm text-red-600">
                      {formik.errors.number}
                    </p>
                  ) : null}
                  </div>
                  
                  <div className="w-full">
                  {userInput.user_role === "manager" || userInput.user_role === "Cluster Manager" || userInput.user_role === "NHQ" ? (
                  <TextField
                    fullWidth
                    required
                    type="Email"
                    label="Email ID"
                    name="email"
                    variant="outlined"
                    onChange={(e) => {
                      setUserInput({ ...userInput, email: e.target.value });
                    }}
                    onBlur={formik.handleBlur}
                    value={userInput.email}
                  />
                  ) : (
                    <TextField
                    fullWidth
                    type="Email"
                    label="Email ID"
                    name="email"
                    variant="outlined"
                    onChange={(e) => {
                      setUserInput({ ...userInput, email: e.target.value });
                    }}
                    onBlur={formik.handleBlur}
                    value={userInput.email}
                  />
                  )}
                  {formik.touched.email && formik.errors.email ? (
                    <div className=" ml-2 text-sm text-red-600">
                      {formik.errors.email}
                    </div>
                  ) : null}
                  </div>
                </div>
                
                {/* <div className="w-full flex flex-col pb-5">
                  <TextField
                    fullWidth
                    type="text"
                    label="Device ID"
                    required
                    name="device id"
                    variant="outlined"
                    onChange={(e) => {
                      setUserInput({ ...userInput, device_id: e.target.value });
                    }}
                    onBlur={formik.handleBlur}
                    value={userInput.device_id}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <p className=" text-sm text-red-600">
                      {formik.errors.number}
                    </p>
                  ) : null}
                </div> */}
                <div className="w-full font-[roboto] flex justify-end gap-2">
                  <button
                    onClick={handleClose}
                    className=" rounded-md shadow-md text-sm font-bold bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-20 h-8"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className=" rounded-md shadow-md text-sm font-bold bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-20 h-8"
                  >
                    Save
                  </button>
                </div>
              
                {toast.isToast && (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleToastClose}>
              <Alert
                onClose={handleToastClose}
                severity={toast.type}
                sx={{ width: "100%" }}
              >
                {toast.message}
              </Alert>
            </Snackbar>
          )}
              </DialogContent>
            </Dialog>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}