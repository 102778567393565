import React, { useState, useRef, useEffect } from "react";
import { BsFillSuitDiamondFill } from "react-icons/bs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core";
// import { IoMdClose } from "react-icons/io";
import { UPDATE_SOP } from "../../../Authservice/api";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ImCross } from "react-icons/im";
// import { MuiThemeProvider } from "@material-ui/core";
import {
  SHELF_VERFICATION,
  UPDATE_SHELF_VERIFICATION_SOP,
} from "../../../Authservice/api";
import Tooltip from "@mui/material/Tooltip";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import WeightageTable from "./weightageTable";
import toast, { Toaster } from "react-hot-toast";
// import result_image from "../result_image.jpeg";
// import image from "../Landscape.jpg";
import NewLoader from "../../../others/loaderUi";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Alert, Snackbar } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GrUndo } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import "./complete.css";
// import { bg } from "date-fns/locale";

// const steps = ["4 points Shelf capture", "Model Learning Verification"];
const rejectReason = [
  "Image is blurry",
  "Bad flash exposure",
  "Shelf is not fully visible",
  "Shelf is not taken in parts",
  "All trays are not coming",
  "Image angle/view is not correct",
  "Camera is too close to the shelf",
  "Camera is too far from the shelf",
  "Not a valid shelf image",
  "Other",
];

const orientations = ["horizontal", "vertical"];

export default function WeightageAnnotation(props) {
  const [imgFull, setImgFull] = useState(false);
  const [open, setOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [sopCount, setSopCount] = useState(false);
  const [alignment, setAlignment] = useState("filled");
  const [imageUrl, setImageUrl] = useState(false);
  const [dataFromChild, setDataFromChild] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  // const [firstStepCompleted, setFirstStepCompleted] = useState(false);
  // const [secondStepCompleted, setSecondStepCompleted] = useState(false);
  const [reject_url, setRejected_Url] = useState(false);
  const [image_url, setImage_url] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonText, setReasonText] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [orientation, setOrientation] = useState("");
  const [oriPopup, setOriPopup] = useState(false);
  const [savingLoader, setSavingLoader] = useState(false);
  const [saved, setSaved] = useState(false);
  const [muiToast, setMuiToast] = useState({
    isToast: false,
    message: "",
    type: "",
    vertical: "",
    horizontal: "",
    duration: 8000,
  });
  const fullWidth = true;
  const maxWidth = "sm";

  const handleLoaderOpen = () => {
    setSavingLoader(true);
  };
  const handleLoaderClose = () => {
    setSavingLoader(false);
  };

  //! VERIFIED TRAY PLOTTING REQUIREMENTS
  //? START
  const [verifiedImgSize, setVerifiedImgSize] = useState({
    width: 0,
    height: 0,
  });
  const [verifiedBoxProps, setVerifiedBoxProps] = useState([]);
  const [plottedBoxProps, setPlottedBoxProps] = useState([]);
  const verifiedImageRef = useRef(null);

  // const [isLoadComplete, setIsLoadComplete] = useState(false);

  const handleVerifiedLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const verifiedImgDiv = verifiedImageRef.current;
    const { width, height } = verifiedImgDiv.getBoundingClientRect();
    setVerifiedImgSize({ ...verifiedImgSize, width: width, height: height });
    const scalingFactor_width = width / naturalWidth;
    const scalingFactor_height = height / naturalHeight;
    setPlottedBoxProps(
      verifiedBoxProps.map((verifiedBox) => {
        const { id, x1, y1, width, height } = verifiedBox;
        const x1_scaled = Math.floor(x1 * scalingFactor_width);
        // const x2_scaled = Math.floor(x2 * scalingFactor_width);
        const y1_scaled = Math.floor(y1 * scalingFactor_height);
        // const y2_scaled = Math.floor(y2 * scalingFactor_height);
        const width_scaled = Math.ceil(width * scalingFactor_width);
        const height_scaled = Math.ceil(height * scalingFactor_height);

        return {
          id: id,
          // count: threshold,
          x1: x1_scaled,
          // x2: x2_scaled,
          y1: y1_scaled,
          // y2: y2_scaled,
          width: width_scaled,
          height: height_scaled,
        };
      })
    );
    // setIsLoadComplete(true);
  };

  //? END
  //! VERIFIED TRAY PLOTTING REQUIREMENTS

  //! ANNOTATION REQUIRED CONSTS
  //? START
  const imageRef = useRef(null);
  const [realDimension, setRealDimension] = useState({
    width: 0,
    height: 0,
  });
  const [plottedDimensions, setPlottedDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [drawing, setDrawing] = useState(false);
  const [imgOffset, setImgOffset] = useState({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [endPos, setEndPos] = useState({ x: 0, y: 0 });
  const [id, setId] = useState(0);
  const [predBoxes, setPredBoxes] = useState([]);
  const [boxProps, setBoxProps] = useState([]);
  const [scaledBoxProps, setScaledBoxProps] = useState([]);
  const [hoveredBoxId, setHoveredBoxId] = useState(null);
  const [expanded, setExpanded] = useState("panel1");
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  // const [captureCompleted, setCaptureCompleted] = useState(false);
  //? END
  //! ANNOTATION REQUIRED CONSTS

  //! ANNOTATION REQUIRED FUNCTIONS
  //? START
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  //Find real, rendered dimensions & offset wrt VP of the image
  const findDimensions = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const imgDiv = imageRef.current;
    const { width, height, left, top } = imgDiv.getBoundingClientRect();
    // console.log("width", imgDiv.getBoundingClientRect().width);
    // console.log("height", imgDiv.getBoundingClientRect().height);
    setImgSize({ ...imgSize, width: width, height: height });
    const scalingFactor_width = width / naturalWidth;
    const scalingFactor_height = height / naturalHeight;
    setBoxProps(
      predBoxes.map((predBox) => {
        const { id, x1, x2, y1, y2, threshold } = predBox;
        const x1_scaled = Math.ceil(x1 * scalingFactor_width) + left;
        const x2_scaled = Math.floor(x2 * scalingFactor_width) + left;
        const y1_scaled = Math.ceil(y1 * scalingFactor_height) + top;
        const y2_scaled = Math.floor(y2 * scalingFactor_height) + top;
        return {
          id: id,
          count: threshold,
          x1: x1_scaled,
          x2: x2_scaled,
          y1: y1_scaled,
          y2: y2_scaled,
          width: x2_scaled - x1_scaled,
          height: y2_scaled - y1_scaled,
        };
      })
    );
    setRealDimension({ width: naturalWidth, height: naturalHeight });
    setPlottedDimensions({ width: width, height: height });
    setImgOffset({ x: left, y: top });
    setExpanded("panel2");
  };

  // console.log("BOX PROPS", boxProps);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setDrawing(true);
    const x = event.clientX;
    const y = event.clientY;
    setStartPos({ x: x, y: y });
    setEndPos({ x: x, y: y });
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (!drawing) {
      return;
    }

    let x = event.clientX;
    let y = event.clientY;

    if (drawing) {
      setEndPos({ x, y });
    }
  };

  const handleMouseUp = () => {
    if (drawing) {
      setDrawing(false);
      // const rect = {
      //   left: Math.min(startPos.x, endPos.x) - imgOffset.x,
      //   top: Math.min(startPos.y, endPos.y) - imgOffset.y,
      //   width: Math.abs(endPos.x - startPos.x),
      //   height: Math.abs(endPos.y - startPos.y),
      // };
      const box = {
        id: id + 1,
        count: 0,
        x1: Math.min(startPos.x, endPos.x),
        x2: Math.max(startPos.x, endPos.x),
        y1: Math.min(startPos.y, endPos.y),
        y2: Math.max(startPos.y, endPos.y),
        width: Math.abs(endPos.x - startPos.x),
        height: Math.abs(endPos.y - startPos.y),
      };
      // setRectangles((prevRectangles) => [...prevRectangles, rect]);
      setId(id + 1);
      setBoxProps((prevBoxProps) => [...prevBoxProps, box]);
    }
  };

  const handleUndoRect = () => {
    if (boxProps.length > 0) {
      // const newRectangles = [...rectangles];
      const newBoxProps = [...boxProps];
      const newScaledBoxProps = [...scaledBoxProps];
      // newRectangles.pop(); // Remove the last object
      newBoxProps.pop(); // Remove the last object
      newScaledBoxProps.pop(); // Remove the last object
      // setRectangles(newRectangles);
      setBoxProps(newBoxProps);
      setScaledBoxProps(newScaledBoxProps);
      setId(id - 1);
    }
  };

  // const handleInputChange = (index, field, value) => {
  //   const updatedBoxProps = [...boxProps];
  //   updatedBoxProps[index][field] = value;
  //   setBoxProps(updatedBoxProps);
  // };

  const handleInputChange = (index, field, value) => {
    if (field === "id") {
      const isDuplicate = boxProps.some(
        (box, i) => i !== index && box.id === value
      );

      if (isDuplicate) {
        // alert("Two tray numbers cannot be similar.");
        setMuiToast({
          isToast: true,
          message: "Two trays can't have same numbers",
          type: "error",
          vertical: "top",
          horizontal: "center",
          isLoading: false,
          duration: 8000,
        });
        return;
      }
    }

    handleToastClose();
    const updatedBoxProps = [...boxProps];
    updatedBoxProps[index][field] = value;
    setBoxProps(updatedBoxProps);
  };

  const handleDelete = (id) => {
    const updatedBoxProps = boxProps.filter((box) => box.id !== id);
    setId(id - 1);
    setBoxProps(updatedBoxProps);
  };

  useEffect(() => {
    const scale_factor_x = realDimension.width / plottedDimensions.width;
    const scale_factor_y = realDimension.height / plottedDimensions.height;

    setScaledBoxProps(
      boxProps.map((boxProp) => {
        const { id, x1, x2, y1, y2, count } = boxProp;
        const x1_scaled = Math.floor((x1 - imgOffset.x) * scale_factor_x);
        const x2_scaled = Math.ceil((x2 - imgOffset.x) * scale_factor_x);
        const y1_scaled = Math.floor((y1 - imgOffset.y) * scale_factor_y);
        const y2_scaled = Math.ceil((y2 - imgOffset.y) * scale_factor_y);
        return {
          id: id,
          count: count,
          x: x1_scaled,
          y: y1_scaled,
          w: x2_scaled - x1_scaled,
          h: y2_scaled - y1_scaled,
        };
      })
    );
    // eslint-disable-next-line
  }, [boxProps]);

  //   console.log("Drawed", rectangles);
  //   console.log("Box", boxProps);
  // console.log("Scaled", scaledBoxProps);
  //? END
  //! ANNOTATION REQUIRED FUNCTIONS

  //! EDIT TRAY REQUIRED CONSTS & FUNCTIONS
  //? START
  const [edit, setEdit] = useState(false);
  const editImgRef = useRef(null);
  const [editRealDimension, setEditRealDimension] = useState({
    width: 0,
    height: 0,
  });
  const [editPlottedDimensions, setEditPlottedDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [editImgSize, setEditImgSize] = useState({ width: 0, height: 0 });
  const [editDrawing, setEditDrawing] = useState(false);
  const [editImgOffset, setEditImgOffset] = useState({ x: 0, y: 0 });
  const [editStartPos, setEditStartPos] = useState({ x: 0, y: 0 });
  const [editEndPos, setEditEndPos] = useState({ x: 0, y: 0 });
  const [editId, setEditId] = useState(0);
  const [editPredBoxes, setEditPredBoxes] = useState([]);
  const [editBoxProps, setEditBoxProps] = useState([]);
  const [editScaledBoxProps, setEditScaledBoxProps] = useState([]);
  const [editHoveredBoxId, setEditHoveredBoxId] = useState(null);

  const handleEditOpen = () => {
    setEdit(true);
  };
  const handleEditClose = () => {
    setEdit(false);
    setAlignment("filled");
  };
  // console.log("Edit", edit);

  const findEditImgDimensions = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const editImgDiv = editImgRef.current;
    const { width, height, left, top } = editImgDiv.getBoundingClientRect();
    setEditImgSize({ ...editImgSize, width: width, height: height });
    const scalingFactor_width = width / naturalWidth;
    const scalingFactor_height = height / naturalHeight;

    setEditBoxProps(
      editPredBoxes.map((predBox) => {
        const { id, x1, x2, y1, y2, threshold } = predBox;
        const x1_scaled = Math.ceil(x1 * scalingFactor_width) + left;
        const x2_scaled = Math.floor(x2 * scalingFactor_width) + left;
        const y1_scaled = Math.ceil(y1 * scalingFactor_height) + top;
        const y2_scaled = Math.floor(y2 * scalingFactor_height) + top;
        return {
          id: id,
          count: threshold,
          x1: x1_scaled,
          x2: x2_scaled,
          y1: y1_scaled,
          y2: y2_scaled,
          width: x2_scaled - x1_scaled,
          height: y2_scaled - y1_scaled,
        };
      })
    );
    setEditRealDimension({ width: naturalWidth, height: naturalHeight });
    setEditPlottedDimensions({ width: width, height: height });
    setEditImgOffset({ x: left, y: top });
  };

  const handleEditMouseDown = (event) => {
    event.preventDefault();
    setEditDrawing(true);
    const x = event.clientX;
    const y = event.clientY;
    setEditStartPos({ x: x, y: y });
    setEditEndPos({ x: x, y: y });
  };

  const handleEditMouseMove = (event) => {
    event.preventDefault();
    if (!editDrawing) {
      return;
    }

    let x = event.clientX;
    let y = event.clientY;

    if (editDrawing) {
      setEditEndPos({ x, y });
    }
  };

  const handleEditMouseUp = () => {
    if (editDrawing) {
      setEditDrawing(false);
      const box = {
        id: editId + 1,
        count: 0,
        x1: Math.min(editStartPos.x, editEndPos.x),
        x2: Math.max(editStartPos.x, editEndPos.x),
        y1: Math.min(editStartPos.y, editEndPos.y),
        y2: Math.max(editStartPos.y, editEndPos.y),
        width: Math.abs(editEndPos.x - editStartPos.x),
        height: Math.abs(editEndPos.y - editStartPos.y),
      };
      // setRectangles((prevRectangles) => [...prevRectangles, rect]);
      setEditId(editId + 1);
      setEditBoxProps((prevEditBoxProps) => [...prevEditBoxProps, box]);
    }
  };

  const handleEditUndoRect = () => {
    if (editBoxProps.length > 0) {
      // const newRectangles = [...rectangles];
      const newEditBoxProps = [...editBoxProps];
      const newEditScaledBoxProps = [...editScaledBoxProps];
      // newRectangles.pop(); // Remove the last object
      newEditBoxProps.pop(); // Remove the last object
      newEditScaledBoxProps.pop(); // Remove the last object
      // setRectangles(newRectangles);
      setEditBoxProps(newEditBoxProps);
      setEditScaledBoxProps(newEditScaledBoxProps);
      setEditId(editId - 1);
    }
  };

  const handleEditInputChange = (index, field, value) => {
    if (field === "id") {
      const isDuplicate = editBoxProps.some(
        (box, i) => i !== index && box.id === value
      );

      if (isDuplicate) {
        alert("Two trays can't have same numbers");
        // setMuiToast({
        //   isToast: true,
        //   message: "Two trays can't have same numbers",
        //   type: "error",
        //   vertical: "top",
        //   horizontal: "center",
        //   isLoading: false,
        //   duration: 8000,
        // });
        return;
      }
    }
    // handleToastClose();
    const updatedEditBoxProps = [...editBoxProps];
    updatedEditBoxProps[index][field] = value;
    setEditBoxProps(updatedEditBoxProps);
  };

  const handleEditDelete = (id) => {
    const updatedEditBoxProps = editBoxProps.filter((box) => box.id !== id);
    setEditId(id - 1);
    setEditBoxProps(updatedEditBoxProps);
  };

  useEffect(() => {
    const scale_factor_x =
      editRealDimension.width / editPlottedDimensions.width;
    const scale_factor_y =
      editRealDimension.height / editPlottedDimensions.height;

    setEditScaledBoxProps(
      editBoxProps.map((boxProp) => {
        const { id, x1, x2, y1, y2, count } = boxProp;
        const x1_scaled = Math.floor((x1 - editImgOffset.x) * scale_factor_x);
        const x2_scaled = Math.ceil((x2 - editImgOffset.x) * scale_factor_x);
        const y1_scaled = Math.floor((y1 - editImgOffset.y) * scale_factor_y);
        const y2_scaled = Math.ceil((y2 - editImgOffset.y) * scale_factor_y);
        return {
          id: id,
          count: count,
          x: x1_scaled,
          y: y1_scaled,
          w: x2_scaled - x1_scaled,
          h: y2_scaled - y1_scaled,
        };
      })
    );
    // eslint-disable-next-line
  }, [editBoxProps]);

  // const handleSendEdit = () => {
  //   console.log("Edited Tray", editScaledBoxProps);
  // };

  const handleSendEdit = async () => {
    // setSaveClicked(true);
    const length_scaledBoxProps = editScaledBoxProps.length;
    const wholeNumberValue = Math.floor(100 / length_scaledBoxProps);
    const lastObjectValue =
      100 - wholeNumberValue * (length_scaledBoxProps - 1);

    for (let i = 0; i < editScaledBoxProps.length - 1; i++) {
      editScaledBoxProps[i].weight = wholeNumberValue;
    }

    editScaledBoxProps[editScaledBoxProps.length - 1].weight = lastObjectValue;

    const tray_group = [];
    const trays = [];
    for (let count = 0; count < editScaledBoxProps.length; count++) {
      tray_group.push({
        group: editScaledBoxProps[count].id,
        groupName: "",
        weightage: editScaledBoxProps[count].weight,
        threshold: editScaledBoxProps[count].count,
        trys: [
          {
            tray_number: editScaledBoxProps[count].id,
            tray_weightage: editScaledBoxProps[count].weight,
          },
        ],
      });

      trays.push({
        number: editScaledBoxProps[count].id,
        weighatage: editScaledBoxProps[count].weight,
        cordinate: [
          `${editScaledBoxProps[count].x}, ${editScaledBoxProps[count].y}, ${editScaledBoxProps[count].w}, ${editScaledBoxProps[count].h}`,
        ],
      });
    }

    const final_try_data = {
      tray_groups: tray_group,
      trays: trays,
    };

    handleLoaderOpen();
    setSaved(false);

    // setMuiToast({
    //   isToast: true,
    //   message: "Saving the changes",
    //   type: "",
    //   vertical: "top",
    //   horizontal: "center",
    //   isLoading: true,
    //   duration: null,
    // });

    const final_data = {
      id: imageUrl.val[0]._id,
      sop: {
        tray_group: final_try_data.tray_groups,
        trays: final_try_data.trays,
      },
    };
    try {
      const update = await UPDATE_SOP(final_data);
      if (update) {
        setSaved(true);
        // console.log("Update Successfully")
        // setMuiToast({
        //   isToast: true,
        //   message: "Shelf Accepted",
        //   type: "success",
        //   vertical: "top",
        //   horizontal: "center",
        //   isLoading: false,
        //   duration: 8000,
        // });
        timer.current = window.setTimeout(() => {
          handleClose();
          handleLoaderClose();
          window.location.reload(false);
        }, 1500);
      }
    } catch (error) {
      console.log("Error updating the edited trays", error);
    }
  };

  //? END
  //! EDIT TRAY REQUIRED CONSTS & FUNCTIONS

  const [selectedReason, setSelectedReason] = useState("");
  useEffect(() => {
    setReason(selectedReason);
    if (selectedReason === "Other") {
      setReasonText(true);
    } else {
      setReasonText(false);
    }
  }, [selectedReason]);
  // console.log("Texfield", reasonText);
  const sopData = props.sopVerify;
  // console.log("sopData", sopData);
  let mergedArray;
  if (sopData) {
    mergedArray = sopData.allSOP.flatMap((item) =>
      item.sop.map((val) => ({
        val,
        brand: item.brand,
      }))
    );
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCloseFull = () => {
    setImgFull(false);
    // setIsLoadComplete(false);
  };
  const timer = useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleToastClose = () => {
    setMuiToast({
      isToast: false,
      message: "",
      type: "",
      vertical: "",
      horizontal: "",
      isLoading: false,
      duration: 8000,
    });
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const handleOpen = (e, value) => {
    if (value) {
      const cordinateData = value.val[0].trays.map((tray) => {
        const [x1, y1, width, height] = tray.cordinate[0]
          .split(", ")
          .map(Number);
        return {
          id: tray.number,
          x1: x1,
          // x2: x1 + width,
          y1: y1,
          // y2: y1 + height,
          width: width,
          height: height,
        };
      });

      const editCordinateData = value.val[0].trays.map((tray) => {
        const [x1, y1, width, height] = tray.cordinate[0]
          .split(", ")
          .map(Number);
        return {
          id: tray.number,
          x1: x1,
          x2: x1 + width,
          y1: y1,
          y2: y1 + height,
          width: width,
          height: height,
        };
      });
      for (const doc of editCordinateData) {
        const filter = value.val[0].tray_group.filter((val) => {
          return val.group === doc.id;
        });
        if (filter.length === 0) {
          doc.threshold = 0;
        } else {
          doc.threshold = filter[0].threshold;
        }
      }
      setEditPredBoxes(editCordinateData);
      setVerifiedBoxProps(cordinateData);
    }
    setEditId(value.val[0].trays.length);
    setSopCount(e);
    setImageUrl(value);
    setOpen(true);
    // console.log("Value", value);
  };
  // console.log("Verified", verifiedBoxProps);

  const handleNewOpen = (e, value) => {
    setSopCount(e);
    setImageUrl(value);
    setNewOpen(true);
    setToastOpen(true);
    setMuiToast({
      isToast: true,
      message: "In below image Select 4 points in clockwise direction",
      type: "info",
      vertical: "top",
      horizontal: "center",
      isLoading: false,
      duration: 8000,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const handleOpenFull = (id, val) => {
    setImgFull(true);
    setRejected_Url(val);
  };

  const handleNewClose = () => {
    setConfirm(false);
    setNewOpen(false);
  };
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    // console.log("dataFromChild", data);
  };

  const [saveClicked, setSaveClicked] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);

  const handleUpdate = async () => {
    // setSaveClicked(true);
    const threshold = [];
    for (let count = 0; count < dataFromChild.tray_group.length; count++) {
      if (dataFromChild.tray_group[count].trys.length === 0) {
      } else {
        threshold.push(dataFromChild.tray_group[count].threshold);
      }
    }
    const hasElementLessThanOne = threshold.some((element) => element < 1);
    // console.log("hasElementLessThanOne", hasElementLessThanOne);
    const final_data = {
      id: dataFromChild._id,
      sop: {
        tray_group: dataFromChild.tray_group,
        trays: dataFromChild.trays,
      },
    };
    // console.log("final_data", final_data);
    // console.log("dataFromChild.tray_group", dataFromChild.tray_group);

    let totalWeight = 0.0;

    for (const obj of dataFromChild.tray_group) {
      totalWeight += parseFloat(obj.weightage);
    }

    const desiredTotal = 100.0; // Use floating-point value for comparison
    const epsilon = 0.0001;

    const isTotal100 = Math.abs(totalWeight - desiredTotal) < epsilon;

    try {
      if (hasElementLessThanOne === true) {
        toast.error("Please assign value to Fullness count");
      }
      if (isTotal100) {
        const update = await UPDATE_SOP(final_data);
        if (update) {
          // console.log("Update Successfully")
          toast.success("Update Successfully");
          setSaveClicked(true);
          setResetClicked(false);
        }
      } else {
        toast.error("The Weightage not 100. PLease check");
      }
    } catch (error) {}
  };

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  // --------------------------------------------
  // Functions for coordinate picking
  const imgDivRef = useRef(null);
  const [coordinates, setCoordinates] = useState([]);
  const [actualCoordinates, setActualCoordinates] = useState([]);
  const [actualImageDimensions, setActualImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [renderedDimensions, setRenderedDimensions] = useState({
    width: 0,
    height: 0,
  });

  // Function to pick up coordinates on image click
  const handleImageClick2 = (event) => {
    // console.log(event);
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    // console.log("X Coordinate:", x);
    // console.log("Y Coordinate:", y);

    const divElement = imgDivRef.current;
    const divRect = divElement.getBoundingClientRect();
    const x_div = divRect.left;
    const y_div = divRect.top;
    const x_actual = event.clientX - x_div + 8;
    const y_actual = event.clientY - y_div + 7;
    // console.log("X actual Coordinate:", x_actual);
    // console.log("Y actual Coordinate:", y_actual);
    if (coordinates.length < 4) {
      setCoordinates((prev) => [...prev, { x, y }]);
      setActualCoordinates((prev) => [...prev, { x_actual, y_actual }]);
    }
  };

  // Function to find dimensions of rendered image
  const updateRenderedDimensions = () => {
    const imgDiv = imgDivRef.current;
    if (imgDiv) {
      const { width, height } = imgDiv.getBoundingClientRect();
      setRenderedDimensions({ width, height });
    }
  };

  useEffect(() => {
    updateRenderedDimensions();
    window.addEventListener("resize", updateRenderedDimensions);
    return () => {
      window.removeEventListener("resize", updateRenderedDimensions);
    };
  }, []);

  //Function to find actual dimensions of the image
  const findActualDimensions = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    naturalWidth > naturalHeight
      ? setOrientation("horizontal")
      : setOrientation("vertical");
    setActualImageDimensions({ width: naturalWidth, height: naturalHeight });
    updateRenderedDimensions();
  };
  const [transformedCoordinates, setTransformedCoordinates] = useState(false);
  useEffect(() => {
    const scale_factor_x =
      actualImageDimensions.width / renderedDimensions.width;
    const scale_factor_y =
      actualImageDimensions.height / renderedDimensions.height;

    setTransformedCoordinates(
      coordinates.map((coordinate) => {
        const { x, y } = coordinate;
        const x_transformed = x * scale_factor_x;
        const y_transformed = y * scale_factor_y;
        return { x: x_transformed, y: y_transformed };
      })
    );
  }, [coordinates]);
  // console.log("Orientation", orientation);

  // console.log("Transformed", transformedCoordinates);

  const handleOpenOriPopup = () => {
    setOriPopup(true);
  };

  const handleCloseOriPopup = () => {
    setOriPopup(false);
  };

  // Function to comapre coordinates
  const handleCaptureClick = async () => {
    const finalData = {
      sop_id: imageUrl.val[0]._id,
      cordinate: transformedCoordinates,
      img_url:
        alignment === "empty"
          ? imageUrl.val[0].empty_image.image_url
          : imageUrl.val[0].filled_image.image_url,
    };
    setIsLoading(true);
    setOriPopup(false);
    // console.log("finalData", finalData);
    try {
      const data = await SHELF_VERFICATION(finalData);
      if (data) {
        // console.log("Response", data.data.trays);
        const cordinateData = data.data.trays.map((tray) => {
          const [x1, y1, width, height] = tray.cordinate[0]
            .split(", ")
            .map(Number);
          return {
            id: tray.number,
            x1: x1,
            x2: x1 + width,
            y1: y1,
            y2: y1 + height,
            width: width,
            height: height,
          };
        });
        for (const doc of cordinateData) {
          const filter = data.data.tray_groups.filter((val) => {
            return val.group === doc.id;
          });
          if (filter.length === 0) {
            doc.threshold = 0;
          } else {
            doc.threshold = filter[0].threshold;
          }
        }
        setId(data.data.trays.length);
        setPredBoxes(cordinateData);
        setIsLoading(false);
        // setDropdownOpen(true);
        // setCaptureCompleted(true);
        setImage_url(data.data);
        setIsLoading(false);
        // setFirstStepCompleted(true);
        // setActiveStep(1);
      }
      setShowResult(true);
    } catch (error) {
      console.log("shelf verification error", error);
    }
  };
  // console.log("predBox", predBoxes);
  // console.log("iageUr", image_url);

  // const toggleSecondDropdown = () => {
  //   if (captureCompleted) {
  //     // Only allow opening the second dropdown if capture is completed
  //     setSecondDropdownOpen(!secondDropdownOpen);
  //   }
  // };

  const handleUndo = () => {
    if (actualCoordinates.length > 0) {
      const newActualCoordinates = [...actualCoordinates];
      const newCoordinates = [...coordinates];
      const newtTransformedCoordinates = [...transformedCoordinates];
      newActualCoordinates.pop(); // Remove the last object
      newCoordinates.pop(); // Remove the last object
      newtTransformedCoordinates.pop(); // Remove the last object
      setActualCoordinates(newActualCoordinates);
      setCoordinates(newCoordinates);
      setTransformedCoordinates(newtTransformedCoordinates);
    }
  };

  // const imageRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (image_url) {
      const imageElement = imageRef.current;

      const handleImageLoad = () => {
        setDimensions({
          width: imageElement.naturalWidth,
          height: imageElement.naturalHeight,
        });
      };

      if (imageElement.complete) {
        handleImageLoad();
      } else {
        imageElement.addEventListener("load", handleImageLoad);
      }

      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [image_url]);
  // console.log("image_url", image_url);

  const handleAcceptClick = async () => {
    // FILTER THE IMAGE_URL

    const length_scaledBoxProps = scaledBoxProps.length;
    const wholeNumberValue = Math.floor(100 / length_scaledBoxProps);
    const lastObjectValue =
      100 - wholeNumberValue * (length_scaledBoxProps - 1);

    for (let i = 0; i < scaledBoxProps.length - 1; i++) {
      scaledBoxProps[i].weight = wholeNumberValue;
    }

    scaledBoxProps[scaledBoxProps.length - 1].weight = lastObjectValue;

    const tray_group = [];
    const trays = [];
    for (let count = 0; count < scaledBoxProps.length; count++) {
      tray_group.push({
        group: scaledBoxProps[count].id,
        groupName: "",
        weightage: scaledBoxProps[count].weight,
        threshold: scaledBoxProps[count].count,
        trys: [
          {
            tray_number: scaledBoxProps[count].id,
            tray_weightage: scaledBoxProps[count].weight,
          },
        ],
      });

      trays.push({
        number: scaledBoxProps[count].id,
        weighatage: scaledBoxProps[count].weight,
        cordinate: [
          `${scaledBoxProps[count].x}, ${scaledBoxProps[count].y}, ${scaledBoxProps[count].w}, ${scaledBoxProps[count].h}`,
        ],
      });
    }

    const final_try_data = {
      tray_groups: tray_group,
      trays: trays,
    };

    setMuiToast({
      isToast: true,
      message: "Accepting the shelf",
      type: "",
      vertical: "top",
      horizontal: "center",
      isLoading: true,
      duration: null,
    });
    const final_data = {
      id: imageUrl.val[0]._id,
      webApp: image_url.image_urls.homographic_corrected_image_url,
      mobile: image_url.image_urls.tray_plotted_image_withoutnumber_url,
      raw_image: image_url.image_urls.homographic_corrected_image_url,
      tray_groups: final_try_data.tray_groups,
      trays: final_try_data.trays,
      img_type: alignment,
      type: orientation,
      status: "verified",
      checked: isChecked,
    };
    try {
      const data = await UPDATE_SHELF_VERIFICATION_SOP(final_data);
      if (data) {
        // setSecondStepCompleted(true);
        setMuiToast({
          isToast: true,
          message: "Shelf Accepted",
          type: "success",
          vertical: "top",
          horizontal: "center",
          isLoading: false,
          duration: 8000,
        });
        timer.current = window.setTimeout(() => {
          handleNewClose();
          window.location.reload(false);
        }, 1500);
      }
    } catch (error) {
      console.log("update shelf verifiction sop error", error);
    }
  };

  // const handleAcceptClick = () => {
  //   setToast({
  //     isToast: true,
  //     message: "Accepting the shelf",
  //     type: "",
  //     vertical: "top",
  //     horizontal: "center",
  //     isLoading: true,
  //     duration: null,
  //   });
  // };

  const handleOpenConfirm = () => {
    setConfirm(true);
  };

  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const handleRejected = async () => {
    setMuiToast({
      isToast: true,
      message: "Rejecting",
      type: "",
      vertical: "top",
      horizontal: "center",
      isLoading: true,
      duration: null,
    });
    handleCloseConfirm();
    try {
      const final_data = {
        id: imageUrl.val[0]._id,
        raw_image:
          alignment === "empty"
            ? imageUrl.val[0].empty_image.image_url
            : imageUrl.val[0].filled_image.image_url,
        status: "Rejected",
        rejected_reason: reason,
        img_type: alignment,
        checked: isChecked,
      };
      const data = await UPDATE_SHELF_VERIFICATION_SOP(final_data);
      if (data) {
        setMuiToast({
          isToast: true,
          message: "Rejected",
          type: "error",
          vertical: "top",
          horizontal: "center",
          isLoading: false,
          duration: 8000,
        });
        // console.log("rejected");
        timer.current = window.setTimeout(() => {
          handleNewClose();
          window.location.reload(false);
        }, 1500);
      }
    } catch (error) {}
  };

  return (
    <>
      {mergedArray &&
        mergedArray.map((val, id) => {
          return val.val[0].filled_image.status_verify === "verified" ? (
            <Tooltip
              title={
                <div className="flex flex-col space-y-1">
                  <span>
                    {val.brand} /{val.val[0].name}
                  </span>
                  <img
                    className="w-fit h-fit max-h-[300px] z-10"
                    src={val.val[0].filled_image.image_url}
                    alt="Not found"
                  />
                </div>
              }
            >
              <span>
                <BsFillSuitDiamondFill
                  onClick={(e) => handleOpen(id, val)}
                  className="text-2xl m-1 text-green-500 Blink hover:cursor-pointer"
                />
              </span>
            </Tooltip>
          ) : val.val[0].filled_image.status_verify ===
            "pending_verification" ? (
            userData.data.user_role === "Super Admin" ? (
              <Tooltip title="Pending Verification ">
                <span>
                  <BsFillSuitDiamondFill
                    onClick={(e) => handleNewOpen(id, val)}
                    className="text-2xl m-1 text-amber-500 hover:cursor-pointer"
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Pending Verification ">
                <span>
                  <BsFillSuitDiamondFill className="text-2xl m-1 text-amber-500 hover:cursor-pointer" />
                </span>
              </Tooltip>
            )
          ) : // ) : (
          //   <Tooltip title="Pending Verification ">
          //     <span>
          //       <BsFillSuitDiamondFill className="text-2xl m-1 text-amber-500 hover:cursor-pointer" />
          //     </span>
          //   </Tooltip>
          // )
          val.val[0].filled_image.status_verify === "pending_upload" ? (
            <Tooltip title="No Data">
              <span>
                <BsFillSuitDiamondFill className="text-2xl m-1 text-red-500" />
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <div className="grid">
                  <span>Re-Capture</span>
                  <span>{val.val[0].rejected_reason}</span>
                </div>
              }
            >
              <span>
                <BsFillSuitDiamondFill
                  onClick={(e) => handleOpenFull(id, val)}
                  className="text-2xl m-1 text-blue-500"
                />
              </span>
            </Tooltip>
          );
        })}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          className="overflow-y-auto scrollbar"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div className="w-full h-full flex flex-col">
            <div className="w-full flex flex-row bg-stone-300 border-b border-gray-400 py-2.5 justify-between px-3">
              <div className="flex flex-col sm:flex-row space-y-1 space-x-0 sm:space-y-0 sm:space-x-4">
                {imageUrl && (
                  <span
                    id="rubikFont"
                    className="font-semibold text-xl self-start sm:self-center"
                  >
                    {imageUrl.brand} / Shelf {sopCount + 1}
                  </span>
                )}
                <div className="">
                  <div>
                    <ToggleButtonGroup
                      fullWidth
                      sx={{ minWidth: 200, backgroundColor: "white" }}
                      className="h-10"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        value="filled"
                        sx={{ borderColor: "#10b981", color: "#10b981" }}
                      >
                        Filled
                      </ToggleButton>
                      <ToggleButton
                        value="empty"
                        sx={{ borderColor: "#10b981", color: "#10b981" }}
                      >
                        Emptied
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse lg:flex-row gap-2">
                {userData.data.user_role === "Super Admin" &&
                edit &&
                alignment === "filled" ? (
                  <Tooltip
                    title={
                      edit && editBoxProps.length > 0
                        ? "Undo last tray"
                        : "No trays available"
                    }
                  >
                    <button
                      id="poppinsFont"
                      className={`w-24 py-2 flex justify-center space-x-2 rounded-md shadow-md ${
                        editBoxProps.length > 0
                          ? "bg-white border border-gray-600 text-gray-600"
                          : "text-gray-400 bg-slate-300"
                      }`}
                      //   onClick={handleUndo}
                      onClick={handleEditUndoRect}
                    >
                      <GrUndo
                        //  className="text-gray-600 self-center"
                        className={`self-center ${
                          actualCoordinates.length > 0
                            ? "text-gray-600"
                            : "text-gray-400"
                        }`}
                      />
                      <span className="">Undo</span>
                    </button>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {userData.data.user_role === "Super Admin" &&
                alignment === "filled" &&
                !edit ? (
                  <div className="hidden xl:block w-fit h-fit shadow-md rounded-md">
                    <Button
                      id="rubikFont"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      sx={{
                        color: "#10b981",
                        borderColor: "#10b981",
                        backgroundColor: "white",
                        borderRadius: "6px",
                        width: "185px",
                        height: "41px",
                      }}
                      component="label"
                      onClick={handleEditOpen}
                    >
                      Open Edit Tray
                    </Button>
                  </div>
                ) : userData.data.user_role === "Super Admin" && edit ? (
                  <div className="hidden xl:block w-fit h-fit shadow-md rounded-md">
                    <Button
                      id="rubikFont"
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      sx={{
                        color: "#10b981",
                        borderColor: "#10b981",
                        backgroundColor: "white",
                        borderRadius: "6px",
                        width: "185px",
                        height: "41px",
                      }}
                      component="label"
                      onClick={handleEditClose}
                    >
                      Close Edit Tray
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
                {userData.data.user_role === "Super Admin" ||
                userData.data.user_role === "NHQ" ? (
                  <Tooltip title={edit ? "Save edited trays" : "Save Changes"}>
                    <button
                      type="submit"
                      // onClick={(e) => handleUpdate()}
                      // onClick={edit ? handleSendEdit : (e) => handleUpdate()}
                      onClick={edit ? handleSendEdit : (e) => handleUpdate()}
                      className=" rounded-md shadow-md font-[roboto] bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-24 h-10"
                    >
                      Save
                    </button>
                  </Tooltip>
                ) : (
                  <></>
                )}

                <Toaster />
                <button
                  onClick={handleClose}
                  className=" rounded-md font-[roboto] shadow-md bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-24 h-10"
                >
                  Close
                </button>
                {/* <IoMdClose
                  onClick={handleClose}
                  className="text-2xl hover:cursor-pointer self-center"
                /> */}
              </div>
            </div>
            {!edit ? (
              <div className="flex-grow flex flex-col xl:flex-row">
                <div className="w-full xl:w-[70%] h-full flex flex-col justify-end">
                  <div className="w-[100%] h-full flex flex-col items-center justify-center">
                    {imageUrl && (
                      // <TransformWrapper>
                      //   <TransformComponent>
                      <div className="w-full h-full self-center flex flex-col justify-center">
                        <div
                          className={`relative w-[${verifiedImgSize.width}] h-[${verifiedImgSize.height}] self-center`}
                        >
                          <img
                            ref={verifiedImageRef}
                            onLoad={handleVerifiedLoad}
                            src={
                              alignment === "empty"
                                ? imageUrl.val[0].empty_image.image_url
                                : imageUrl.val[0].filled_image.image_url
                            }
                            alt="img not found"
                            className="max-h-[85vh]"
                          />
                          {imageUrl && (
                            <>
                              {!edit &&
                                alignment === "filled" &&
                                !imageUrl.val[0].filled_image.image_url.includes(
                                  "_tray_plotted"
                                ) &&
                                plottedBoxProps.map((box, index) => (
                                  <div
                                    key={index}
                                    // className="absolute border-2 border-white cursor-not-allowed"
                                    className={`absolute border-2 border-white`}
                                    style={{
                                      left: box.x1,
                                      top: box.y1,
                                      width: box.width,
                                      height: box.height,
                                    }}
                                  >
                                    <div className="relative w-full flex justify-between -top-6 px-1.5">
                                      <span className="text-rose-600 font-bold text-xl drop-shadow-2xl">
                                        {box.id}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      </div>
                      //   </TransformComponent>
                      // </TransformWrapper>
                    )}
                  </div>
                </div>
                <div className="flex-grow lg:h-[88vh] border-l overflow-y-auto scrollbar shadow-lg">
                  <WeightageTable
                    filled={alignment}
                    setDataFromChild={handleDataFromChild}
                    sopdata={imageUrl.val}
                    saveClicked={saveClicked}
                    setSaveClicked={setSaveClicked}
                    resetClicked={resetClicked}
                    setResetClicked={setResetClicked}
                  />
                </div>
              </div>
            ) : (
              <div className="flex-grow flex">
                <div className="w-3/4 h-full flex justify-center">
                  <div
                    onMouseDown={alignment === "filled" && handleEditMouseDown}
                    onMouseMove={alignment === "filled" && handleEditMouseMove}
                    onMouseUp={alignment === "filled" && handleEditMouseUp}
                    onMouseLeave={alignment === "filled" && handleEditMouseUp}
                    className={`self-center w-[${editImgSize.width}] h-[${editImgSize.height}]`}
                  >
                    <img
                      ref={editImgRef}
                      onLoad={findEditImgDimensions}
                      src={
                        alignment === "empty"
                          ? imageUrl.val[0].empty_image.image_url
                          : imageUrl.val[0].filled_image.image_url
                      }
                      alt="img not found"
                      // className="max-h-[85vh] cursor-crosshair"
                      className={`max-h-[85vh] ${
                        alignment === "filled" ? "cursor-crosshair" : ""
                      }`}
                    />
                    {edit &&
                      alignment === "filled" &&
                      editBoxProps.map((box, index) => (
                        <div
                          key={index}
                          // className="absolute border-2 border-white cursor-not-allowed"
                          className={`absolute border-2 border-white cursor-not-allowed ${
                            editHoveredBoxId === box.id
                              ? "border-lime-500 bg-lime-500 bg-opacity-[.3]"
                              : ""
                          }`}
                          style={{
                            left: box.x1,
                            top: box.y1,
                            width: box.width,
                            height: box.height,
                          }}
                        >
                          <div className="relative w-full flex justify-between -top-6 px-1.5">
                            <span className="text-rose-600 font-bold text-2xl drop-shadow-2xl">
                              {box.id}
                            </span>
                            <span className="text-green-500 font-bold text-xl drop-shadow-2xl">
                              Count: {box.count}
                            </span>
                          </div>
                          {/* <span className="relative text-green-600 font-bold text-base -top-6 right-1">
                                {box.count}
                              </span> */}
                        </div>
                      ))}
                    {editDrawing && (
                      <div
                        className="absolute border-2 border-green-500"
                        style={{
                          left: Math.min(editStartPos.x, editEndPos.x),
                          top: Math.min(editStartPos.y, editEndPos.y),
                          width: Math.abs(editEndPos.x - editStartPos.x),
                          height: Math.abs(editEndPos.y - editStartPos.y),
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="relative w-1/4 h-full bg-[#F1F1F1] ">
                  <div className="xl:max-h-[88vh] 2xl:max-h-[89vh] overflow-y-auto scrollbar  space-y-1">
                    <div className="w-full flex flex-col space-y-1">
                      {editBoxProps.map((box, index) => (
                        <div
                          onMouseEnter={
                            alignment === "filled"
                              ? () => setEditHoveredBoxId(box.id)
                              : null
                          }
                          onMouseLeave={() => setEditHoveredBoxId(null)}
                          key={index}
                          // className="w-full h-20 flex "
                          className={`w-full h-20 flex ${
                            editHoveredBoxId === box.id
                              ? "border-t border-b border-r border-black"
                              : ""
                          }`}
                        >
                          <div
                            // className="w-2 h-full border border-black bg-lime-400"
                            className={`w-2 h-full border border-black ${
                              alignment === "filled"
                                ? "bg-lime-400"
                                : "bg-lime-300"
                            }`}
                          ></div>
                          <div
                            // className="px-2 flex-grow flex justify-between bg-lime-300"
                            className={`px-2 flex-grow flex justify-between bg-lime-300 ${
                              alignment === "filled"
                                ? "bg-lime-300"
                                : "bg-lime-200"
                            }`}
                          >
                            <div className="flex space-x-5">
                              <div className="flex flex-col justify-center space-y-1">
                                <span className="font-[roboto] font-semibold">
                                  Tray No`
                                </span>
                                <input
                                  className="border border-black shadow-2xl px-2 self-center w-20 h-7"
                                  type="number"
                                  disabled={
                                    alignment === "empty" ? true : false
                                  }
                                  value={box.id}
                                  onChange={(e) =>
                                    handleEditInputChange(
                                      index,
                                      "id",
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                              </div>
                              <div className="flex flex-col justify-center space-y-1">
                                <span className="font-[roboto] font-semibold">
                                  Count
                                </span>
                                <input
                                  className="border border-black shadow-2xl px-2 self-center w-20 h-7"
                                  type="number"
                                  disabled={
                                    alignment === "empty" ? true : false
                                  }
                                  value={box.count}
                                  onChange={(e) =>
                                    handleEditInputChange(
                                      index,
                                      "count",
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <button
                              disabled={alignment === "empty" ? true : false}
                              onClick={() => handleEditDelete(box.id)}
                            >
                              <MdDelete
                                className={`self-center text-2xl ${
                                  alignment === "filled"
                                    ? "text-rose-600"
                                    : " text-slate-400 opacity-[0.5]"
                                }`}
                                // className="self-center text-rose-600 text-2xl"
                              />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={newOpen}
        onClose={handleNewClose}
        PaperProps={{
          sx: {
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          className="overflow-y-auto scrollbar"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div className="w-full h-full flex flex-col">
            <div className="w-full flex flex-row bg-[#f1f1f1] border-b border-gray-400 py-2.5 justify-between px-3">
              <div className="flex flex-col sm:flex-row space-y-1 space-x-0 sm:space-y-0 sm:space-x-4">
                {imageUrl && (
                  <span
                    id="rubikFont"
                    className="font-semibold text-xl self-start sm:self-center"
                  >
                    {imageUrl.brand}/ {imageUrl.val[0].name}
                  </span>
                )}

                <div className="">
                  <div>
                    <ToggleButtonGroup
                      fullWidth
                      sx={{ minWidth: 200, backgroundColor: "white" }}
                      className="h-10"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        value="filled"
                        sx={{ borderColor: "#10b981", color: "#10b981" }}
                      >
                        Filled
                      </ToggleButton>
                      <ToggleButton
                        value="empty"
                        sx={{ borderColor: "#10b981", color: "#10b981" }}
                      >
                        Emptied
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse sm:flex-row gap-2">
                {!isLoading && (
                  <Tooltip
                    title={
                      !showResult && actualCoordinates.length > 0
                        ? "Undo selected points"
                        : "Undo last drawn tray"
                    }
                  >
                    <button
                      id="poppinsFont"
                      // className="w-24 py-2 flex justify-center space-x-2 rounded-md shadow-md bg-white border border-gray-600 text-gray-600"
                      className={`w-24 py-2 flex justify-center space-x-2 rounded-md shadow-md ${
                        !showResult && actualCoordinates.length > 0
                          ? "bg-white border border-gray-600 text-gray-600"
                          : showResult && boxProps.length > 0
                          ? "bg-white border border-gray-600 text-gray-600"
                          : "text-gray-400 bg-slate-300"
                      }`}
                      //   onClick={handleUndo}
                      onClick={!showResult ? handleUndo : handleUndoRect}
                    >
                      <GrUndo
                        //  className="text-gray-600 self-center"
                        className={`self-center ${
                          actualCoordinates.length > 0
                            ? "text-gray-600"
                            : "text-gray-400"
                        }`}
                      />
                      <span className="">Undo</span>
                    </button>
                  </Tooltip>
                )}
                <button
                  onClick={handleNewClose}
                  className=" rounded-md font-[roboto] shadow-md bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-24 h-10"
                >
                  Close
                </button>
              </div>
            </div>

            <div className="flex h-full">
              {/* Left container for the image */}
              <div className="flex-grow flex flex-col xl:flex-row">
                {/* <div className="w-full xl:w-[100%] h-full flex flex-col justify-center items-center"> */}
                <div className="w-full xl:w-[100%] h-full flex flex-col justify-center items-center">
                  {imageUrl && (
                    <div>
                      {isLoading ? (
                        <div
                        // style={{
                        // //   display: "flex",
                        // //   alignItems: "center",
                        // //   justifyContent: "center",
                        // //   height: "100vh", // Set the loader container to the full viewport height
                        // }}
                        >
                          <NewLoader />
                          {/* Display the loader while capturing */}
                        </div>
                      ) : !isLoading && !showResult ? (
                        <div className="w-full h-full self-center flex flex-col justify-center items-center relative">
                          <div
                            className="max-w-full max-h-full m-3 flex justify-center"
                            ref={imgDivRef}
                          >
                            <img
                              onClick={handleImageClick2}
                              onLoad={findActualDimensions}
                              src={
                                alignment === "empty"
                                  ? imageUrl.val[0].empty_image.image_url
                                  : imageUrl.val[0].filled_image.image_url
                              }
                              alt="img not found"
                              className="max-h-[85vh]"
                              style={{
                                cursor: "crosshair",
                              }}
                            />
                          </div>

                          {actualCoordinates.map((point, index) => (
                            <div
                              key={index}
                              style={{
                                position: "absolute",
                                left: point.x_actual + "px",
                                top: point.y_actual + "px",
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#10B981",
                                borderRadius: "45%",
                              }}
                            ></div>
                          ))}
                        </div>
                      ) : (
                        <div
                          // ref={imageRef}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp}
                          className={`flex justify-center w-[${imgSize.width}] h-[${imgSize.height}]`}
                        >
                          {image_url && (
                            <img
                              ref={imageRef}
                              onLoad={findDimensions}
                              // onMouseDown={handleMouseDown}
                              // onMouseMove={handleMouseMove}
                              // onMouseUp={handleMouseUp}
                              // onMouseLeave={handleMouseUp}
                              // src={
                              //   image_url.image_urls
                              //     .tray_plotted_image_withnumber_url
                              // }
                              src={
                                image_url.image_urls
                                  .homographic_corrected_image_url
                              }
                              alt="img not found"
                              className="max-h-[85vh]"
                              style={{
                                // marginLeft: "30px",
                                cursor: "crosshair",
                              }}
                            />
                          )}
                          {boxProps.map((box, index) => (
                            <div
                              key={index}
                              // className="absolute border-2 border-white cursor-not-allowed"
                              className={`absolute border-2 border-white cursor-not-allowed ${
                                hoveredBoxId === box.id
                                  ? "border-lime-500 bg-lime-500 bg-opacity-[.3]"
                                  : ""
                              }`}
                              style={{
                                left: box.x1,
                                top: box.y1,
                                width: box.width,
                                height: box.height,
                              }}
                            >
                              <div className="relative w-full flex justify-between -top-6 px-1.5">
                                <span className="text-rose-600 font-bold text-2xl drop-shadow-2xl">
                                  {box.id}
                                </span>
                                <span className="text-green-500 font-bold text-xl drop-shadow-2xl">
                                  Count: {box.count}
                                </span>
                              </div>
                              {/* <span className="relative text-green-600 font-bold text-base -top-6 right-1">
                                {box.count}
                              </span> */}
                            </div>
                          ))}
                          {drawing && (
                            <div
                              className="absolute border-2 border-green-500"
                              style={{
                                left: Math.min(startPos.x, endPos.x),
                                top: Math.min(startPos.y, endPos.y),
                                width: Math.abs(endPos.x - startPos.x),
                                height: Math.abs(endPos.y - startPos.y),
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {/* </div> */}
              </div>

              {/* Right container for the stepper */}

              <div className="flex flex-col bg-stone-200 w-1/4 max-h-screen">
                <Accordion
                  disabled={!isLoading && !showResult ? false : true}
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                  sx={{
                    backgroundColor: "rgb(241, 241, 241)",
                    marginBottom: "0",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      backgroundColor: "rgb(216, 216, 216)",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      4 points shelf capture
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <div className="flex flex-row py-3 justify-center">
                      {userData.data.user_role === "Super Admin" &&
                      !isLoading &&
                      !showResult &&
                      transformedCoordinates.length < 4 ? (
                        <Tooltip title="Select 4 points over the image to proceed">
                          <button
                            type="submit"
                            className="rounded-md shadow-md font-[roboto] text-gray-400 bg-slate-300 w-24 h-10"
                          >
                            Capture
                          </button>
                        </Tooltip>
                      ) : userData.data.user_role === "Super Admin" &&
                        !isLoading &&
                        !showResult ? (
                        <button
                          type="submit"
                          onClick={(e) => handleOpenOriPopup()}
                          className="rounded-md shadow-md font-[roboto] bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-24 h-10"
                        >
                          Capture
                        </button>
                      ) : null}

                      {userData.data.user_role === "Super Admin" &&
                        !isLoading &&
                        !showResult && (
                          <div className="flex flex-col items-center">
                            <button
                              onClick={handleOpenConfirm}
                              className="rounded-md font-[roboto] shadow-md bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-24 h-10"
                            >
                              Reject
                            </button>
                            <div className="mt-2 flex items-center">
                              <Checkbox
                                defaultChecked={isChecked}
                                onChange={handleCheckboxChange}
                                color="primary"
                              />
                              <span>Save for Retraining</span>
                            </div>
                          </div>
                        )}

                      <Toaster />
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  disabled={!showResult ? true : false}
                  expanded={expanded === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                  sx={{
                    backgroundColor: "rgb(241, 241, 241)",
                    marginBottom: "0",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      backgroundColor: "rgb(216, 216, 216)",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Model learning verification
                    </Typography>
                  </AccordionSummary>
                  {/* {captureCompleted && ( */}
                  <AccordionDetails
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 2,
                      paddingBottom: 0,
                    }}
                    className="xl:max-h-[67vh] 2xl:max-h-[73vh] overflow-y-auto scrollbar"
                  >
                    <div className="w-full flex flex-col space-y-1">
                      {boxProps.map((box, index) => (
                        <div
                          onMouseEnter={() => setHoveredBoxId(box.id)}
                          onMouseLeave={() => setHoveredBoxId(null)}
                          key={index}
                          // className="w-full h-20 flex "
                          className={`w-full h-20 flex ${
                            hoveredBoxId === box.id
                              ? "border-t border-b border-r border-black"
                              : ""
                          }`}
                        >
                          <div className="w-2 h-full border border-black bg-lime-400"></div>
                          <div className="px-2 flex-grow flex justify-between bg-lime-300">
                            <div className="flex space-x-5">
                              <div className="flex flex-col justify-center space-y-1">
                                <span className="font-[roboto] font-semibold">
                                  Tray No`
                                </span>
                                <input
                                  className="border border-black shadow-2xl px-2 self-center w-20 h-7"
                                  type="number"
                                  value={box.id}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "id",
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                              </div>
                              <div className="flex flex-col justify-center space-y-1">
                                <span className="font-[roboto] font-semibold">
                                  Count
                                </span>
                                <input
                                  className="border border-black shadow-2xl px-2 self-center w-20 h-7"
                                  type="number"
                                  value={box.count}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "count",
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <button onClick={() => handleDelete(box.id)}>
                              <MdDelete className="self-center text-rose-600 text-2xl" />
                            </button>
                          </div>
                        </div>
                      ))}

                      <div className="w-full flex flex-row justify-center py-3">
                        {userData.data.user_role === "Super Admin" &&
                        !isLoading &&
                        showResult ? (
                          <button
                            type="submit"
                            // onClick={(e) => handleAcceptClick()}
                            onClick={(e) => handleAcceptClick()}
                            className="rounded-md shadow-md font-[roboto] bg-emerald-500 active:bg-emerald-400 hover:bg-emerald-700 text-white w-24 h-10"
                          >
                            Accept
                          </button>
                        ) : (
                          <></>
                        )}
                        <Toaster />

                        {/* Container for buttons */}

                        <div className="flex flex-col items-center">
                          <button
                            onClick={handleOpenConfirm}
                            className="rounded-md font-[roboto] shadow-md bg-red-500 active:bg-red-400 hover:bg-red-700 text-white w-24 h-10"
                          >
                            Reject
                          </button>
                          <div className="mt-2 flex items-center">
                            <Checkbox
                              defaultChecked={isChecked}
                              onChange={handleCheckboxChange}
                              color="primary"
                            />
                            <span>Save for Retraining</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                  {/* )} */}
                </Accordion>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth="xs"
        open={oriPopup}
        // onClose={handleClose}
      >
        <DialogContent className="">
          <div className="w-full h-full flex flex-col space-y-4 m-auto">
            <div className="w-full flex space-x-3">
              <span className="font-[roboto] font-semibold self-center">
                Image Orientation
              </span>
              <div className="flex-grow">
                <FormControl fullWidth>
                  <InputLabel>Select Orientaion</InputLabel>
                  <Select
                    label="Select Orientaion"
                    name="orientation"
                    value={orientation}
                    onChange={(e) => {
                      setOrientation(e.target.value);
                    }}
                  >
                    {orientations.map((orientation, index) => {
                      return (
                        <MenuItem value={orientation} key={index}>
                          {orientation}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Box sx={{ "& button": { m: 1 } }}>
              <div className="w-full flex justify-center">
                <button
                  id="poppinsFont"
                  className="w-24 py-1 rounded-md shadow-md bg-emerald-500 text-white"
                  onClick={(e) => handleCaptureClick()}
                >
                  Proceed
                </button>
                <button
                  id="poppinsFont"
                  className="w-24 py-2 rounded-md shadow-md border border-gray-600 text-gray-600"
                  onClick={handleCloseOriPopup}
                >
                  Cancel
                </button>
              </div>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={confirm}
        // onClose={handleClose}
      >
        <DialogContent className="">
          <div className="w-full h-full flex flex-col space-y-4 m-auto">
            {/* <div className="m-auto text-5xl border border-black">
              <AiOutlineExclamationCircle className="text-[#fb1751]" />
            </div>
            <div id="poppinsFont" className="m-auto flex flex-col space-y-3">
              <span className="text-xl text-wrap text-center self-center">
                Are you sure you want to <br /> tare the Weight ?
              </span>
              <span className=" text-sm text-gray-600 self-center">
                (This process may take a minute)
              </span>
            </div> */}
            <div className="w-full flex space-x-3">
              <span className="font-[roboto] font-semibold self-center">
                Reason for rejection
              </span>
              <div className="flex-grow">
                <FormControl fullWidth>
                  <InputLabel>Select a reason</InputLabel>
                  <Select
                    label="Select a reason"
                    name="reason"
                    value={selectedReason}
                    onChange={(e) => {
                      setSelectedReason(e.target.value);
                    }}
                  >
                    {rejectReason.map((reason, index) => {
                      return (
                        <MenuItem value={reason} key={index}>
                          {reason}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            {reasonText && (
              <div className="w-full">
                <TextField
                  // sx={{ width: 500 }}
                  fullWidth
                  id="outlined-multiline-static"
                  // label="Reason for rejection"
                  placeholder="Please provide a reason for rejecting the image"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  // defaultValue="Default Value"
                />
              </div>
            )}

            <Box sx={{ "& button": { m: 1 } }}>
              <div className="w-full flex justify-center">
                {reason === "" || reason === "Other" ? (
                  <button
                    id="poppinsFont"
                    className="w-24 py-1 rounded-md shadow-md text-gray-400 bg-slate-300"
                  >
                    Reject
                  </button>
                ) : (
                  <button
                    id="poppinsFont"
                    className="w-24 py-1 rounded-md shadow-md bg-red-500 text-white"
                    onClick={(e) => handleRejected()}
                  >
                    Reject
                  </button>
                )}
                <button
                  id="poppinsFont"
                  className="w-24 py-2 rounded-md shadow-md border border-gray-600 text-gray-600"
                  onClick={handleCloseConfirm}
                >
                  Cancel
                </button>
              </div>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={imgFull}
        onClose={handleCloseFull}
        PaperProps={{
          sx: {
            width: "100%",
            maxHeight: "1300px",
            background: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
          {reject_url && (
            <div className="self-center">
              <ImCross
                onClick={handleCloseFull}
                className="z-20 text-xl cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
                style={{
                  right: "4%",
                  top: "2%",
                }}
              />

              <div
                className="fixed top-4 px-4 left-0 w-full h-16 z-10 flex flex-col space-y-1"
                style={{ background: "rgba(0, 0, 0, 0.3)" }}
              >
                <span className="text-white opacity-100 font-semibold text-lg">
                  {reject_url.brand}
                </span>
                <span className="text-white opacity-100 font-semibold text-lg">
                  {reject_url.val[0].name}
                </span>
              </div>

              <TransformWrapper>
                <TransformComponent>
                  <img
                    className="self-center"
                    style={{ maxHeight: "95svh" }}
                    src={reject_url.val[0].filled_image.image_url}
                    alt="img not found"
                  />
                </TransformComponent>
              </TransformWrapper>
              <div className="absolute bottom-6 right-6 font-semibold text-white text-lg">
                <span>User ID: {reject_url.val[0].user_id}</span>
                <br />
                <span>User Name: {reject_url.val[0].user_name}</span>
                <br />
                <span>
                  {`Date: ${reject_url.val[0].updatedAt.split("T")[0]}`}
                </span>
                <br />
                <span>
                  {`Time: ${new Date(
                    reject_url.val[0].updatedAt
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}`}
                </span>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={savingLoader}>
        <DialogContent>
          <div className="flex space-x-2">
            <div className="font-[rubik] tracking-wider font-medium text-lg">
              Saving Changes
            </div>
            {!saved ? (
              <CircularProgress size={25} className="self-center h-full" />
            ) : (
              <svg
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  class="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  class="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {muiToast.isToast && (
        <Snackbar
          open={toastOpen}
          autoHideDuration={muiToast.duration}
          onClose={handleToastClose}
          anchorOrigin={{
            vertical: muiToast.vertical,
            horizontal: muiToast.horizontal,
          }}
        >
          <Alert
            onClose={handleToastClose}
            className="flex"
            // variant="filled"
            color="info"
            elevation={6}
            severity={muiToast.type}
            sx={{
              width: "100%",
              // backgroundColor: "#22c55e",
              fontSize: "16px",
            }}
          >
            <div className="h-full self-center flex space-x-2">
              {muiToast.isLoading && (
                <CircularProgress size={15} className="self-center" />
              )}
              <span className="h-full self-center">{muiToast.message}</span>
            </div>
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
