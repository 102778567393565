import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
// import FingerprintIcon from "@mui/icons-material/Fingerprint";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Button, Typography } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink } from "react-router-dom";

const MobileUserDetailsPage = () => {
  const [open, setOpen] = useState(false);

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);
  // console.log("users Detauls", userData);
  let name_user = "";
  if (userData) {
    const name = userData.data.user_name;
    const upper_case_text = name.slice(0, 1).toUpperCase();
    name_user = upper_case_text;
  }
  // console.log(name_user)
  const auth = getAuth();
  const navigateTo = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // console.log("Sign-out successful");
        navigateTo("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div>
      <Box>
        <IconButton
          // onClick={handleClick}
          size="small"
          // sx={{ ml: 2 }}
          onClick={() => setOpen((prevState) => !prevState)}
          aria-haspopup="true"
        >
          <Avatar sx={{ width: 32, height: 32 }}></Avatar>
        </IconButton>
      </Box>
      <Drawer
        open={open}
        onClose={() => setOpen((prevState) => !prevState)}
        anchor="right"
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            maxWidth: 360,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {userData ? (
            <>
              <div className="flex flex-col justify-center items-center w-full h-32 bg-gray-100 ">
                <Avatar
                // src={Image.card}
                >
                  {name_user}
                </Avatar>
                <Typography variant="p" className="font-medium" component="h2">
                  {userData.data.user_role}
                </Typography>
              </div>

              <MenuItem id="settingsHeading">
                <ListItemIcon>
                  <PersonIcon fontSize="medium" />
                </ListItemIcon>
                {userData.data.user_name}
              </MenuItem>
              <MenuItem id="settingsHeading">
                <ListItemIcon>
                  <EmailIcon fontSize="medium" />
                </ListItemIcon>
                {userData.data.email}
              </MenuItem>
              <MenuItem id="settingsHeading">
                <ListItemIcon>
                  <DriveFileRenameOutlineIcon fontSize="medium" />
                </ListItemIcon>
                {userData.data.user_id}
              </MenuItem>
              <MenuItem id="settingsHeading">
                <ListItemIcon>
                  <LocalPhoneIcon fontSize="medium" />
                </ListItemIcon>
                {userData.data.number}
              </MenuItem>
              {/* <MenuItem id="settingsHeading">
                <ListItemIcon>
                  <LocationOnIcon fontSize="medium" />
                </ListItemIcon>
                {userData.data.store_location}
              </MenuItem> */}
              {userData.data.user_role === "Super Admin" ? (
                <NavLink to={`/settings`}>
                  <MenuItem id="settingsHeading">
                    <ListItemIcon>
                      <SettingsIcon
                        fontSize="medium"
                        sx={{
                          color: "#10b981",
                        }}
                      />
                    </ListItemIcon>
                    My Settings
                  </MenuItem>
                </NavLink>
              ) : (
                <></>
              )}

              <MenuItem>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#10b981", color: "#FFFFFF" }}
                  fullWidth
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </MenuItem>
            </>
          ) : (
            <>No Code Founs</>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default MobileUserDetailsPage;
