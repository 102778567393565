import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
// import FingerprintIcon from "@mui/icons-material/Fingerprint";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  //  Button,
  Typography,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
export default function UserMenu() {
  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);
  // console.log("users Detauls", userData);
  let name_user = "";
  if (userData) {
    const name = userData.data.user_name;
    const upper_case_text = name.slice(0, 1).toUpperCase();
    name_user = upper_case_text;
  }
  // console.log(name_user)
  const auth = getAuth();
  const navigateTo = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // console.log("Sign-out successful");
        navigateTo("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <>
      <Box>
        <IconButton
          onClick={handleClick}
          size="small"
          // sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Tooltip title="User Profile">
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </Tooltip>
        </IconButton>
      </Box>
      {userData ? (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          // onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 60,
                height: 60,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="flex flex-col justify-center items-center w-full h-32 bg-gray-100 ">
            <Typography variant="p" component="h2">
              {userData.data.user_role === "NHQ" ? <>Super User</> : <>{userData.data.user_role}</>}
            </Typography>
            <Avatar
            // src={Image.card}
            >
              {name_user}
            </Avatar>
          </div>

          <MenuItem id="settingsHeading">
            <ListItemIcon>
              <PersonIcon fontSize="medium" />
            </ListItemIcon>
            {userData.data.user_name}
          </MenuItem>
          <MenuItem id="settingsHeading">
            <ListItemIcon>
              <EmailIcon fontSize="medium" />
            </ListItemIcon>
            {userData.data.email}
          </MenuItem>
          <MenuItem id="settingsHeading">
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="medium" />
            </ListItemIcon>
            {userData.data.user_id}
          </MenuItem>
          <MenuItem id="settingsHeading">
            <ListItemIcon>
              <LocalPhoneIcon fontSize="medium" />
            </ListItemIcon>
            {userData.data.number}
          </MenuItem>
          {/* <MenuItem id="settingsHeading">
            <ListItemIcon>
              <LocationOnIcon fontSize="medium" />
            </ListItemIcon>
            {userData.data.store_location}
          </MenuItem> */}
          {userData.data.user_role === "Super Admin" ? 
          <NavLink to={`/settings`}>
            <MenuItem id="settingsHeading">
              <ListItemIcon>
                <SettingsIcon
                  fontSize="medium"
                  sx={{
                    color: "#10b981",
                  }}
                />
              </ListItemIcon>
              My settings
            </MenuItem>
          </NavLink> : <></>}
          <MenuItem>
            <button
              className="w-full h-10 rounded-md text-white bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400"
              // variant="contained"
              // sx={{
              //   backgroundColor: "#10b981",
              // }}
              // fullWidth
              onClick={handleLogout}
            >
              Logout
            </button>
          </MenuItem>
        </Menu>
      ) : (
        <>No User found </>
      )}
    </>
  );
}