import React, { useState, useEffect } from "react";
// import TextField from "@mui/material/TextField";
// import Input from "@mui/material/Input";
import { BiSearch } from "react-icons/bi";

export default function SearchBar({ setInputValue }) {
  const [inputData, setInputData] = useState("");
  useEffect(() => {
    setInputValue(inputData);
    // apiRes()
    // eslint-disable-next-line
  }, [inputData]);
  return (
    <>
      <div className="pl-2">
        <BiSearch className="h-full text-2xl text-gray-400" />
      </div>
      <input
        // placeholder="Search store"
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => setInputData(e.target.value)}
        id="searchBar"
        className="outline-none rounded-md md:rounded-none pl-2"
        placeholder="Search by User or Store Name"
      />

      {/* <TextField
        // fullWidth
        style={{ width: "70%" }}
        InputLabelProps={{
          style: {
            // color: "black",
            fontSize: "17px",
            fontWeight: "bold",
            // paddingBottom: "10px",
            // margin: "dense"
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              height: 53,
              borderRadius: "0px",
              //   width: 50,
              border: "none",
              borderRight: "2px solid gray",
              //   fontSize: "17px",
              //   fontWeight: "bold",
              //   color: 'white'
            },
          },
        }}
        label="Search by User or Store Name"
      /> */}
    </>
  );
}
