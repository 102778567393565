import React, { useState, useEffect } from "react";
// import { VscGraphLine } from "react-icons/vsc";
import { MdOutlineSpaceDashboard } from "react-icons/md";
// import { FaRegChartBar } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { BiStore } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { MdOutlineContactPage } from "react-icons/md";
import { BsChatRightText } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import NeoLogo from "../Authservice/images/neo_disha_final_logo.gif";
// import Logo from "../Authservice/images/Asset.png";
// import Logo from "../Authservice/images/NeoPhyte_logo.gif";
import Logo from "../Authservice/images/reliance-trends-logo-png.png";
import { useLocation } from "react-router-dom";
import { useData } from "../../context/chartContext";

export default function SideNav() {
  //Navbar onclick action
  const [activeNav, setActiveNav] = useState(0);
  const {setCappedDate} = useData();
  let location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setCappedDate(null)
        setActiveNav(1);
        break;
      case "/analysis":
        setActiveNav(2);
        break;
      case "/stores":
        setCappedDate(null)
        setActiveNav(3);
        break;
      case "/user":
        setCappedDate(null)
        setActiveNav(4);
        break;
      // case "/chatPage":
      //   setActiveNav(5);
      //   break;
      // case "/summary":
      //   setCappedDate(null)
      //   setActiveNav(5);
      //   break;
      case "/comingSoon":
        setCappedDate(null)
        setActiveNav(6);
        break;
      case "/documentation":
        setCappedDate(null)
        setActiveNav(7);
        break;
      case "/contactUs":
        setCappedDate(null)
        setActiveNav(8);
        break;
      default:
        setActiveNav(0);
    }
    // eslint-disable-next-line
  }, [activeNav]);

  // const users = localStorage.getItem("users");
  // const userData = JSON.parse(users);

  return (
    <>
      {/* <div className="w-1/6 shadow-md bg-white  ">
        <div className="h-16 shadow-sm">
          <div className="flex justify-center pt-2">
            <NavLink to={"/dashboard"}>
              <img src={Logo} className="w-5/6" alt="Logo" />
            </NavLink>
          </div>
        </div> */}

      <div className="w-2/12 fixed left-0 shadow-md bg-white h-screen  ">
        <div className="flex h-24 justify-center">
          <div className="w-4/6 self-center">
            {/* <NavLink to={`/dashboard`}> */}
            <button
              type="button"
              onClick={() => window.open("/dashboard", "_self")}
            >
              <img className="scale-[0.8] 2xl:scale-[0.6]" src={Logo} alt="Logo" />   
              {/* <img className="scale-[1.2] 2xl:scale-[0.6]" src={Logo} alt="Logo" /> */}
            </button>
            {/* </NavLink> */}
          </div>
        </div>
        {/* <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between ">
          <div className="flex flex-col mt-3 py-4 space-y-2  border-b-2 border-white-200"> */}
        <div className="w-full h-4/6 overflow-y-auto overflow-x-hidden flex flex-col">
          <div className="w-11/12 self-end flex flex-col space-y-2">
            <div>
              <NavLink to={`/dashboard`}>
                <p
                  className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                    activeNav === 1
                      ? "text-white bg-gray-200 rounded-l-lg "
                      : ""
                  }`}
                >
                  <span className="inline-flex justify-center items-center ml-2 ">
                    <MdOutlineSpaceDashboard
                      className={`text-gray-600 font-medium text-lg ${
                        activeNav === 1 ? "text-emerald-500" : ""
                      }`}
                    />
                  </span>
                  <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                    Dashboard
                  </span>
                </p>
              </NavLink>
            </div>

            <div>
              <NavLink to={`/analysis`}>
                <p
                  className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                    activeNav === 2
                      ? "text-white bg-gray-200 rounded-l-lg "
                      : ""
                  }`}
                >
                  <span className="inline-flex justify-center items-center ml-2">
                    <VscGraph
                      className={`text-gray-600 font-medium text-lg ${
                        activeNav === 2 ? "text-emerald-500" : ""
                      }`}
                    />
                  </span>
                  <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                    Analysis
                  </span>
                </p>
              </NavLink>
            </div>

            <>
              <div>
                <NavLink to={`/stores`}>
                  <p
                    className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                      activeNav === 3
                        ? "text-white bg-gray-200 rounded-l-lg "
                        : ""
                    }`}
                  >
                    <span className="inline-flex justify-center items-center ml-2">
                      <BiStore
                        className={`text-gray-600 font-medium text-lg ${
                          activeNav === 3 ? "text-emerald-500" : ""
                        }`}
                      />
                    </span>
                    <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                      Store
                    </span>
                  </p>
                </NavLink>
              </div>
            </>

            <>
              <div>
                <NavLink to={`/user`}>
                  <p
                    className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                      activeNav === 4
                        ? "text-white bg-gray-200 rounded-l-lg "
                        : ""
                    }`}
                  >
                    <span className="inline-flex justify-center items-center ml-2">
                      <BiUser
                        className={`text-gray-600 font-medium text-lg ${
                          activeNav === 4 ? "text-emerald-500" : ""
                        }`}
                      />
                    </span>
                    <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                      User
                    </span>
                  </p>
                </NavLink>
              </div>

              {/* <div>
                <NavLink to={`/summary`}>
                  <p
                    className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                      activeNav === 5
                        ? "text-white bg-gray-200 rounded-l-lg "
                        : ""
                    }`}
                  >
                    <span className="inline-flex justify-center items-center ml-2">
                      <TbReportAnalytics
                        className={`text-gray-600 font-medium text-lg ${
                          activeNav === 5 ? "text-emerald-500" : ""
                        }`}
                      />
                    </span>
                    <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                      Summary
                    </span>
                  </p>
                </NavLink>
              </div> */}
              <div>
                {/* <NavLink to={`/chatPage`}> */}
                {/* <NavLink to={`/comingSoon`}> */}
                  {/* <p
                    className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                      activeNav === 6
                        ? "text-white bg-gray-200 rounded-l-lg "
                        : ""
                    }`}
                  >
                    <span className="inline-flex justify-center items-center ml-2">
                      <BsChatRightText
                        className={`text-gray-600 font-medium text-lg ${
                          activeNav === 6 ? "text-emerald-500" : ""
                        }`}
                      />
                    </span>
                    <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                      Active Comm.
                    </span>
                  </p> */}
                {/* </NavLink> */}
              </div>
              <div>
                <NavLink to={`/documentation`}>
                  <p
                    className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                      activeNav === 7
                        ? "text-white bg-gray-200 rounded-l-lg "
                        : ""
                    }`}
                  >
                    <span className="inline-flex justify-center items-center ml-2">
                      <CgFileDocument
                        className={`text-gray-600 font-medium text-xl ${
                          activeNav === 7 ? "text-emerald-500" : ""
                        }`}
                        // className="text-gray-600 font-medium text-lg"
                      />
                    </span>
                    <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                      Documentation
                    </span>
                  </p>
                </NavLink>
              </div>
              <div>
                <NavLink to={`/contactUs`}>
                  <p
                    className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                      activeNav === 8
                        ? "text-white bg-gray-200 rounded-l-lg "
                        : ""
                    }`}
                  >
                    <span className="inline-flex justify-center items-center ml-2">
                      <MdOutlineContactPage
                        className={`text-gray-600 font-medium text-xl ${
                          activeNav === 8 ? "text-emerald-500" : ""
                        }`}
                        // className="text-gray-600 font-medium text-lg"
                      />
                    </span>
                    <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                      Contact Us
                    </span>
                  </p>
                </NavLink>
              </div>
            </>
          </div>
          {/* <div className="mb-5 flex flex-col">
            <div className="w-11/12 self-end flex flex-col py-4 space-y-2"> */}
          {/* <a
                // href="https://docs.google.com/document/d/1lyMFIWKa44RXQwZ8DOKyGSdqaP_C8uHfKKKc3d_ya3c/edit?usp=sharing"
                href="https://drive.google.com/file/d/1p9nL1VNTdVVCzWRpoE23DAe5MF_REh9x/view"
                target="_blank"
                rel="noreferrer"
              > */}
          {/* <NavLink to={`/documentation`}>
                <p
                  // className={`relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6 ${
                  //   activeNav === 5
                  //     ? "text-white bg-gray-200 rounded-l-lg "
                  //     : ""
                  // }`}
                  className="relative flex flex-row items-center h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent dark:hover:border-gray-800 focus:bg-blue-500 hover:rounded-l-lg focus:text-white pr-6"
                >
                  <span className="inline-flex justify-center items-center ml-2">
                    <CgFileDocument
                      // className={`text-gray-600 font-medium text-lg ${
                      //   activeNav === 5 ? "text-emerald-500" : ""
                      // }`}
                      className="text-gray-600 font-medium text-lg"
                    />
                  </span>
                  <span className="ml-3 text-gray-600 font-sans text-sm	font-medium	 tracking-wide truncate">
                    Documentation
                  </span>
                </p>
              </NavLink> */}
          {/* </a> */}
          {/* </div>
            <div className="w-11/12 self-end flex flex-col space-y-2"> */}
          {/* <a
                href="https://neophyte.live/contact"
                target="_blank"
                rel="noreferrer"
              > */}
          {/* <NavLink to={`/contactUs`}>
                <p className="relative flex flex-row items-center  h-9 px-6 focus:outline-none  dark:hover:bg-gray-300 hover:bg-purple-200  text-white hover:text-white  border-transparent  dark:hover:border-gray-800 hover:rounded-l-lg focus:bg-blue-500 focus:text-white pr-6">
                  <span className="inline-flex justify-center items-center ml-2">
                    <MdOutlineContactPage className="text-gray-600 font-medium text-lg" />
                  </span>
                  <span className="ml-3 text-sm text-md font-sans	font-medium	 text-gray-600 tracking-wide truncate">
                    Contact Us
                  </span>
                </p>
              </NavLink> */}
          {/* </a> */}
          {/* </div>
          </div>
          <hr /> */}

          <div className=" absolute bottom-0 w-full flex flex-col">
            {/* <span className="text-sm self-center ont-sans text-gray-700	font-medium	 tracking-wide truncate">
              Version:1.0.1
            </span> */}
            <div className="w-full">
              <img className="scale-[0.7]" src={NeoLogo} alt="NeoLogo" />
            </div>

            <div className="w-full text-xs pb-2 flex justify-center text-gray-700">
              <p>copyright © 2023 NeoPhyte.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}