import React from "react";
import { useState, useEffect } from "react";
import SideNav from "../../others/sidenav";
import ChatHeader from "./chatHeader";
import StatusDropdown from "./statusDropdown";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CityAvatarGroup from "./cityAvatar";

import {
  // GETALLCHAT,
  GETALLCHATIDS,
  GETONECHAT,
  // CREATECOMMENTCHAT,
  GetALLUserAPI,
  GetALLStoreAPI,
  getChatData,
  getBroadcastData,
  BROADCASTCREATEDBYDATA,
} from "../../Authservice/api";
import { DialogContent, Dialog } from "@mui/material";
// import TextField from "@mui/material/TextField";
import ViewChat from "./viewChat";
import SearchBar from "./searchBar";
// import DateRangePicker from "./dateRangePicker";
// import MobileDateRangePicker from "./mobileDateRangePicker";
import MonthWiseSelector from "./monthWiseSelector";
import MobileMonthWiseSelector from "./mobileMonthWiseSelector";
import SendBroadcast from "./broadcast/sendBroadcast";
// import { AiOutlineUser } from "react-icons/ai";
// import { GrUserManager } from "react-icons/gr";
import { FaUserFriends } from "react-icons/fa";
import { FaUserTag } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
// import { MdOutlineAdminPanelSettings } from "react-icons/md";
// import SelectDateFrom from "./selectDateFrom"; GrUserManager MdOutlineAdminPanelSettings

import {
  Avatar,
  // ListItem, ListItemAvatar,
  Tooltip,
} from "@mui/material";

export default function ChatPage() {
  const [selected, setSelected] = useState("");
  const [monthSelected, setMonthSelected] = useState(new Date().getMonth() + 1);
  const [chats, setChats] = useState([]);
  const [chatId, setChatId] = useState(false);
  const [opened, setOpened] = useState(false);
  const [filled, setFilled] = useState(false);
  // const [comment, setComment] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [user, setUser] = useState(false);
  const [store, setStore] = useState(false);
  const [broadcast, setBroadcast] = useState([]);
  // const [parentdata, setParentdata] = useState("");
  const [inputValue, setInputValue] = useState("");
  // console.log("inputValue", inputValue);
  // console.log("setselected", selected);
  // console.log("selected")
  // API INTEGRATION FOR GET ALL CHAT
  // const users = localStorage.getItem("users");
  // const userData = JSON.parse(users);
  // console.log("users Detauls", userData);
  // let userStoreID = "";
  // if (userData) {
  //   userStoreID = userData.data.store;
  // }
  // useEffect(() => {
  //   async function getChats() {
  //     try {
  //       const data = await GETALLCHAT();
  //       setChats(data.data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getChats();
  // }, []);

  // GET ALL THE REPORTS BASED ON THE FILTER
  //  console.log("monthSelected", monthSelected)
  const userss = localStorage.getItem("users");
  const userDatas = JSON.parse(userss);

  let stores;
  let user_roles;
  let userID;
  if (userDatas) {
    stores = userDatas.data.stores;
    user_roles = userDatas.data.user_role;
    userID = userDatas.data._id;
  }

  // GET ALL THE BROADCAST MESSAGE CREATED BY USER  BROADCASTCREATEDBYDATA
  useEffect(() => {
    async function broaddcastMessage() {
      try {
        const data = await BROADCASTCREATEDBYDATA(userID);
        setBroadcast(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    broaddcastMessage();
    // eslint-disable-next-line
  }, []);

  console.log(userID);
  console.log(user_roles);

  // useEffect(() => {
  //   async function getBroadcast() {
  //     try {
  //       const data = await getBroadcastData(userID);
  //       setBroadcast(data.data);
  //     } catch (error) {
  //       console.error("Error fetching broadcast data:", error);
  //     }
  //   }
  //   getBroadcast();
  // }, []);

  console.log("broadcast", broadcast);

  const cityShortForms = {
    Bhubaneshwar: "BHU",
    Mumbai: "MUM",
    Agra: "AG",
    Allahabad: "ALL",
    Ahmedabad: "AMD",
    Amritsar: "AMR",
    Balurghat: "BAL",
    Bangalore: "BLR",
    Bareilly: "BAR",
    Calicut: "CAL",
    Chandigarh: "CHD",
    Chas: "CHA",
    Chennai: "CHE",
    Coimbatore: "COI",
    Dehradun: "DEH",
    Delhi: "DEL",
    Dimapur: "DIM",
    Faizabad: "FAI",
    Gangtok: "GAN",
    Ghaziabad: "GHA",
    Gorakhpur: "GOR",
    Gurgaon: "GUR",
    Guwahati: "GUW",
    Gwalior: "GWA",
    Hyderabad: "HYD",
    Jaipur: "JAI",
    Jalandhar: "JAL",
    Jammu: "JK",
    Jamshedpur: "JAM",
    Jorhat: "JOR",
    Kochi: "KOC",
    Kolhapur: "KOL",
    Kolkata: "KOL",
    Lucknow: "LUC",
    Madurai: "MAD",
    Nagpur: "NAG",
    Nashik: "NAS",
    "Navi Mumbai": "NAVM",
    Pathankot: "PATH",
    Patna: "PAT",
    Pratapgarh: "PRA",
    Pune: "PUN",
    Raipur: "RAI",
    Ranchi: "RAN",
    Raurkela: "RAU",
    Rishikesh: "RIS",
    Sangli: "SAN",
    Siliguri: "SIL",
    Tamluk: "TAM",
    Tiruchirappalli: "TIR",
    Trichy: "TRI",
    Udaipur: "UDA",
    Udhampur: "UDH",
    Vadodara: "VAD",
    Varanasi: "VAR",
    Vijayawada: "VIJ",
    Visakhapatnam: "VIS",
    Vizag: "VIZ",
  };

  useEffect(() => {
    async function getReports() {
      const Data = {
        status: selected,
        date: monthSelected,
        Store_IDs: stores,
        user_role: user_roles,
      };
      try {
        const data = await GETALLCHATIDS(Data);
        setChats(data.data);
        
      } catch (error) {
        console.log(error);
      }
    }
    getReports();
    // eslint-disable-next-line
  }, [selected, monthSelected]);

  console.log("chats",chats);


  // GETALL USER API INTEGRATION
  useEffect(() => {
    async function getUser() {
      try {
        const data = await GetALLUserAPI();
        setUser(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getUser();
  }, []);

  console.log("user", user);

  useEffect(() => {
    async function getStore() {
      try {
        const data = await GetALLStoreAPI();
        setStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStore();
  }, []);
  console.log("store", store);

  // FILETR THE USER NAME
  if (user && chats) {
    for (let count = 0; count < chats.length; count++) {
      const filterStore = user.filter((user) => {
        return user._id === chats[count].userID;
      });

      // console.log("filterStore", filterStore)

      chats[count]["user_name"] = filterStore[0].user_name;
      // checkedItems[count]["fullness"] = filterStore[0];
    }
  }

  // FILTER THE STORE NAME
  if (store && chats) {
    for (let count = 0; count < chats.length; count++) {
      const filterStore = store.filter((store) => {
        return store._id === chats[count].storeID;
      });

      // console.log("filterStore", filterStore)
      chats[count]["store_name"] = filterStore[0].name;
      // checkedItems[count]["fullness"] = filterStore[0];
    }
  }

  // POP UP OPEN WHEN CLICK ON VIEW BUTTON
  // const openCardView = async (e) => {
  //   // console.log("e", e);
  //   // messageID = e
  //   // setCardViewIsActive(!cardViewIsActive);
  //   // setChatId(e);

  //   try {
  //     const data = await GETONECHAT(e);
  //     // console.log(data.data);
  //     if (data) {
  //       setOpened(true);
  //     }
  //     setChatId(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const openCardViewMobile = async (e) => {
    // console.log("e", e);
    // messageID = e
    // setCardViewIsActive(!cardViewIsActive);
    // setChatId(e);

    try {
      const data = await GETONECHAT(e);
      // console.log(data.data);
      if (data) {
        setFilled(true);
      }
      setChatId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // THIS FUNCTION IS USE FOR SEARCH THE USER NAME AND STORE NAME
  // const searchTable = (data) => {
  //   // console.log("data", data);
  //   let filterValue = "";
  //   if (data) {
  //     // console.log("data is here", data)/
  //     filterValue = data.filter(
  //       (item) =>
  //         item.store_name?.toLowerCase().includes(inputValue.toLowerCase()) ||
  //         item.user_name?.toLowerCase().includes(inputValue.toLowerCase())
  //     );
  //   }

  //   return filterValue;
  //   // return data.filter((item) =>
  //   //   item.store_name.toLowerCase().includes(inputValue.toLowerCase())
  //   // );
  // };
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Nov",
    "Dec",
  ];

  // CLOSE THE POP UP
  const handleClosed = () => setOpened(false);
  const handleEmptied = () => setFilled(false);

  console.log(chats);

  return (
    <>
      <div className="w-full h-screen flex bg-gray-100 overflow-y-auto md:scrollbar">
        <div className="md:contents hidden">
          <SideNav />
        </div>
        <div className="w-2/12 h-screen hidden md:block"></div>

        <div className="w-full lg:w-5/6 h-full">
          <div className="w-full bg-white">
            <ChatHeader />
          </div>
          <div className="w-full h-16 hidden md:block"></div>
          <div
            // id="settingsMainDiv"
            className="w-full px-2 py-2 xl:px-7 xl:py-3 2xl:p-7 bg-gray-100 flex flex-col xl:space-y-0 2xl:space-y-4"
          >
            {user_roles === "NHQ" || user_roles === "Super Admin" ? (
              <></>
            ) : (
              <div className="w-full h-14 md:h-14 flex flex-col">
                {/* <div className="w-full h-8 md:h-1/2 p-1 flex flex-col justify-end">
                  <span
                    id="rubikFont"
                    className="font-semibold text-xl lg:text-2xl 2xl:text-3xl text-gray-600"
                  >
                    Your Support Requests
                  </span>
                </div> */}

                <div className="w-full p-1 h-full md:h-full">
                  <div className="w-full h-full  flex bg-white rounded-md shadow-md">
                    <StatusDropdown
                      className="self-start"
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <SearchBar setInputValue={setInputValue} />
                    {/* <DateDropdown selected={selected} setSelected={setSelected} /> */}
                    {/* <SelectDateFrom /> */}
                    {/* <DateRangePicker setParentdata={setParentdata} /> */}
                    <MonthWiseSelector
                      // className="self-start"
                      selected={monthSelected}
                      setSelected={setMonthSelected}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-row space-x-2">
              <MobileMonthWiseSelector
                // className="self-start"
                selected={monthSelected}
                setSelected={setMonthSelected}
              />
              <div className="md:hidden self-center">
                <SendBroadcast />
              </div>
            </div>
            {/* <MobileDateRangePicker /> */}

            <div className="container overflow-auto m-auto">
              <table className="w-full  sm:bg-white rounded-lg md:overflow-hidden overflow-x-auto sm:shadow-lg my-5">
                <thead>
                  {user_roles === "Super Admin" || user_roles === "NHQ" ? (
                    <tr className="bg-emerald-500 text-white rounded-lg mb-2">
                      {/* <th className="px-3 py-3 text-center tracking-wider">
                          <div className=" font-semibold text-base 2xl:text-lg">
                            Sender
                          </div>
                        </th> */}
                      <th className="text-left">
                        <div className="px-5 py-3 font-semibold text-base 2xl:text-lg">
                          Subject
                        </div>
                      </th>
                      <th className="text-left">
                        <div className="px-5 py-3 font-semibold text-base 2xl:text-lg">
                          City
                        </div>
                      </th>
                      <th className="text-left">
                        <div className="px-3 py-3 text-left font-semibold text-base 2xl:text-lg">
                          {/* Store Name */}
                          Sent To
                        </div>
                      </th>

                      <th className="px-3 py-4">
                        <div className="font-semibold w-36 md:w-auto text-center text-base 2xl:text-lg">
                          Broadcasted On
                        </div>
                      </th>
                      <th className="px-3 py-4">
                        <div className="font-semibold text-center text-base 2xl:text-lg">
                          Action
                        </div>
                      </th>
                    </tr>
                  ) : (
                    <tr className="bg-emerald-500 text-white rounded-lg mb-2">
                      <th className="px-3 py-3 text-center tracking-wider">
                        <div className=" font-semibold text-base 2xl:text-lg">
                          {/* Name */}
                          Sender
                        </div>
                      </th>
                      <th className="text-left">
                        <div className="px-3 py-3 font-semibold text-base 2xl:text-lg">
                          Subject
                        </div>
                      </th>
                      <th className="text-left">
                        <div className="px-3 py-3 font-semibold text-base 2xl:text-lg">
                          {/* Store Name */}
                          From
                        </div>
                      </th>

                      <th className="text-center">
                        <div className="px-3 py-3 w-32 md:w-auto font-semibold text-base 2xl:text-lg">
                          Status
                        </div>
                      </th>
                      <th className="px-3 py-4">
                        <div className="font-semibold w-36 md:w-auto text-center text-base 2xl:text-lg">
                          Last Updated On
                        </div>
                      </th>
                      <th className="px-3 py-4">
                        <div className="font-semibold text-center text-base 2xl:text-lg">
                          Action
                        </div>
                      </th>
                    </tr>
                  )}
                </thead>

                <tbody className="text-sm whitespace-nowrap ">
                  {user_roles === "Super Admin" || user_roles === "NHQ" ? (
                    <>
                      {broadcast &&
                        broadcast.map((val, id) => {
                          return (
                            <tr
                              id="rubikFont"
                              // className="hover:bg-gray-200"
                              className={`hover:bg-gray-200 ${val.user.map(
                                (val, id) =>
                                  val === "user"
                                    ? "grad text-white border-b border-blue-light"
                                    : val === "manager"
                                    ? "grad text-white border-b border-grey-light"
                                    : val === "CLuster Manager"
                                    ? "grad text-white border-b border-red-light"
                                    : "grad text-white border-b"
                              )}`}
                              keys={id}
                            >
                              {/* <td
                               id="rubikFont"
                               className="px-3 py-3 text-center"
                             >
                               ADMIN
                             </td> */}
                              <td className="px-5 py-5 text-left">
                                {val.title}
                              </td>
                              <td className="px-5 py-5 text-left">
                                <CityAvatarGroup
                                  cities={val.city.map((cityName) => ({
                                    name: cityName,
                                    shortForm:
                                      cityShortForms[cityName] || cityName,
                                  }))}
                                />
                              </td>

                              <td className="px-5 py-3 flex flex-row justify-start space-x-2">
                                {val.user.map((val, id) => {
                                  return (
                                    // <ListItem alignItems="center">
                                    <>
                                      {val === "user" ? (
                                        <>
                                          <Tooltip title="User">
                                            <Avatar
                                              sx={{
                                                color: "#d97706",
                                                backgroundColor: "#fde68a",
                                                border: "none",
                                                borderColor: "#d97706",
                                              }}
                                              className="self-center"
                                            >
                                              <FaUserFriends
                                                stroke={1.5}
                                                size="1.3rem"
                                              />
                                            </Avatar>
                                          </Tooltip>
                                        </>
                                      ) : val === "manager" ? (
                                        <>
                                          <Tooltip title="Manager">
                                            <Avatar
                                              sx={{
                                                color: "#0284c7",
                                                backgroundColor: "#bae6fd",
                                                border: "none",
                                                borderColor: "#0284c7",
                                              }}
                                              className="self-center"
                                            >
                                              <FaUserTag
                                                stroke={1.5}
                                                size="1.3rem"
                                              />
                                            </Avatar>
                                          </Tooltip>
                                        </>
                                      ) : val === "Cluster Manager" ? (
                                        <>
                                          <Tooltip title="Cluster Manager">
                                            <Avatar
                                              sx={{
                                                color: "#059669",
                                                backgroundColor: "#a7f3d0",
                                                border: "none",
                                                borderColor: "#059669",
                                              }}
                                              className="self-center"
                                            >
                                              <FaUserShield
                                                stroke={1.5}
                                                size="1.3rem"
                                              />
                                            </Avatar>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </td>

                              <td className="text-center px-3 py-3">
                                <div className="flex space-x-1 justify-center">
                                  <span>
                                    {new Date(val.updatedAt).getDate()}
                                  </span>
                                  <span>
                                    {month[new Date(val.updatedAt).getMonth()]}
                                  </span>
                                  <span>
                                    {new Date(val.updatedAt).getFullYear()}
                                  </span>
                                  <span>at</span>
                                  <div>
                                    <span>
                                      {new Date(val.updatedAt).getHours() <
                                      10 ? (
                                        <>
                                          0{new Date(val.updatedAt).getHours()}
                                        </>
                                      ) : (
                                        <>
                                          {new Date(val.updatedAt).getHours()}
                                        </>
                                      )}{" "}
                                    </span>
                                    :
                                    <span>
                                      {new Date(val.updatedAt).getMinutes() <
                                      10 ? (
                                        <>
                                          0
                                          {new Date(val.updatedAt).getMinutes()}
                                        </>
                                      ) : (
                                        <>
                                          {new Date(val.updatedAt).getMinutes()}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center px-3 py-3 flex justify-center">
                                {/* <button
                             onClick={(e) => openCardView(val._id)}
                             type="button"
                             className="w-20 h-10 xl:hidden rounded-md shadow-md bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400 text-white"
                           >
                             View
                           </button> */}
                                <button
                                  onClick={(e) => openCardViewMobile(val._id)}
                                  type="button"
                                  className="w-20 h-10 rounded-md shadow-md bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400 text-white"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {chats ? (
                        chats.map((val, id) => {
                          return (
                            <tr
                              id="rubikFont"
                              // className="hover:bg-gray-200"
                              className={`hover:bg-gray-200 ${
                                val.type === "NHQ"
                                  ? "grad text-white border-b border-grey-light"
                                  : ""
                              }`}
                              keys={id}
                            >
                              <td
                                id="rubikFont"
                                className="px-3 py-3 text-center"
                              >
                                {/* {val.user_name} */}
                                {val.type === "NHQ" ? (
                                  <span className="drop-shadow-2xl">Admin</span>
                                ) : (
                                  <span className="drop-shadow-2xl">
                                    Agents
                                  </span>
                                )}
                              </td>
                              <td className="px-3 py-5 text-left">
                                {val.title}
                              </td>
                              <td className="px-3 py-3 text-left">
                                {/* {val.store_name} */}
                                {val.type === "NHQ" ? (
                                  <span className="drop-shadow-2xl">
                                    National Headquarters
                                  </span>
                                ) : (
                                  <span className="drop-shadow-2xl">
                                    {val.store_name}
                                  </span>
                                )}
                              </td>
                              <td className="px-3 py-3 text-center">
                                {val.status === "completed" ? (
                                  <div
                                    id="rubikFont"
                                    className="flex drop-shadow-2xl justify-center rounded-xl border-2 border-emerald-400"
                                  >
                                    <span className="text-emerald-400 drop-shadow-2xl">
                                      Completed
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    id="rubikFont"
                                    className="flex drop-shadow-2xl justify-center rounded-xl border-2 border-yellow-500"
                                  >
                                    <span className="text-yellow-500 drop-shadow-2xl">
                                      In progress
                                    </span>
                                  </div>
                                )}

                                {/* <div
                                id="rubikFont"
                                className="flex justify-center rounded-xl border-2 border-yellow-500"
                              >
                                <span className="text-yellow-500">
                                  In progress
                                </span>
                              </div> */}
                              </td>
                              <td className="text-center px-3 py-3">
                                <div className="flex space-x-1 justify-center">
                                  <span>
                                    {new Date(val.updatedAt).getDate()}
                                  </span>
                                  <span>
                                    {month[new Date(val.updatedAt).getMonth()]}
                                  </span>
                                  <span>
                                    {new Date(val.updatedAt).getFullYear()}
                                  </span>
                                  <span>at</span>
                                  <div>
                                    <span>
                                      {new Date(val.updatedAt).getHours() <
                                      10 ? (
                                        <>
                                          0{new Date(val.updatedAt).getHours()}
                                        </>
                                      ) : (
                                        <>
                                          {new Date(val.updatedAt).getHours()}
                                        </>
                                      )}{" "}
                                    </span>
                                    :
                                    <span>
                                      {new Date(val.updatedAt).getMinutes() <
                                      10 ? (
                                        <>
                                          0
                                          {new Date(val.updatedAt).getMinutes()}
                                        </>
                                      ) : (
                                        <>
                                          {new Date(val.updatedAt).getMinutes()}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center px-3 py-3 flex justify-center">
                                <button
                                  onClick={(e) => openCardViewMobile(val._id)}
                                  type="button"
                                  className="w-20 h-10 self-center rounded-md shadow-md bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-400 text-white"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>No data found</>
                      )}{" "}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "#d1d5db",
              boxShadow: "none",
            },
          }}
          // fullWidth={fullWidth}
          // maxWidth={maxWidth}
          fullScreen
          open={opened}
          onClose={handleClosed}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#10b981" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Communication
              </Typography>
              {/* <Button autoFocus color="inherit" onClick={handleClosed}>
                  save
                </Button> */}
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClosed}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent className="overflow-y-auto scrollbar">
            <div className="w-full h-full">
              <ViewChat userIDs={user} chatData={chatId} />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "#d1d5db",
              boxShadow: "none",
            },
          }}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={filled}
          onClose={handleEmptied}
        >
          <DialogContent className="overflow-y-auto scrollbar">
            <div className="w-full h-full">
              <ViewChat userIDs={user} chatData={chatId} />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
