import React, {
  useState,
  useEffect,
  // useRef
} from "react";
// import useLeafletMap from "./gmap";
import axios from "axios";
import { GetALLStoreAPI, SOPVERIFICATION } from "../Authservice/api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { BiStore } from "react-icons/bi";
import TextField from "@mui/material/TextField";
import { MdDelete } from "react-icons/md";
import SopVerificationBrand from "./SopVerification/sopVerification_brand";
import SopVerificationShelf from "./SopVerification/sopVerification_shelf";
// import MySVGComponent from "../others/loaderUi";
// import Loaders from "../others/loader";

import NewLoader from "./../others/loaderUi";
// import noDataFoundImage from "E:/disha_web_app_frontend/src/components/Authservice/images/3009287.png"
import noDataFoundImage from "../Authservice/images/3009287.png";

// import Skeleton from "@mui/material/Skeleton";
import {
  // DeleteStore,
  GetallStoreTableApi,
  GETONESTORES,
  GetFullnessCaptures,
  BRANDCOORDINATE,
} from "../Authservice/api";
import Pagination from "@mui/material/Pagination";
import { useFormik } from "formik";
import token from "../Authservice/authToken";
// import Tooltip from "@mui/material/Tooltip";
import EditStore from "./editStore";
// import EditStoreMap from "./editStoreMap";
// import LapDeskEditStoreNew from "./lapDeskEditStoreNew";
// import LapDeskEditStore from "./lapDeskEditStore";
// eslint-disable-next-line
// import MobTabEditStore from "./mobTabEditStore";
// import MobTabEditStoreNew from "./mobTabEditStoreNew";

// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import markerIcon from "../Authservice/images/storeIcon4.png";

// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import SopVerification from "./SopVerification/sopVerificaton";
// import Weightage from "./SopVerification/Weightage/newWeightage";
// import Weightage from "./SopVerification/Weightage/weightage";
import WeightageAnnotation from "./SopVerification/Weightage/weightageAnnotation";
import { IoChevronUp } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import { AiFillPlusSquare } from "react-icons/ai";
import { AiFillMinusSquare } from "react-icons/ai";
import { useData } from "../../context/chartContext";
// import StoreItem from "./storeItem";
import "./diamondAlignment.css"
import { Tooltip } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@mui/material/styles';


// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const StoreItem = ({ val, id, allBrandData }) => {
  const [brandCord, setBrandCord] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  console.log(val);
  const handleMenuOpen = (id) => {
    setIsTrue(!isTrue);
  };
  return (
    <>
      <tr onClick={(e) => setBrandCord(id)} className="w-full">
        <td colSpan="1" className="px-3 py-3  text-center w-[5%] md:w-[5%] xl:w-[4%] 2xl:w-[6%] ">
          {val.number}
        </td>

        <td colSpan="1" className="w-[4%]  p-3 ">
          <div
            onClick={(e) => handleMenuOpen(id)}
            className="h-full flex flex-col justify-center "
          >
            {isTrue !== true ? (
              <AiFillPlusSquare className="text-lg cursor-pointer text-blue-500 mt-1 self-center" />
            ) : (
              <AiFillMinusSquare className="text-lg cursor-pointer text-rose-500 mt-1 self-center" />
            )}
          </div>
        </td>

        <td
          colSpan={2}
          id="rubikFont"
          className="px-10 md:px-8 lg:px-8 xl:px-8 2xl:px-12 2xl:w-[550px] xl:w-[225px] lg:w-[490px] sm:w-[500px] text-base 2xl:text-lg text-left  custom-intermediate"
          // className="px-10 md:px-8 lg:px-10 2xl:w-[450px] lg:w-[200px] text-base 2xl:text-lg text-left bg-blue-300"
        >
          {val.brand}
        </td>
        <td className="h-full flex flex-grow p-3 xl:flex-wrap text-sm 2xl:text-base  text-center ">
          <SopVerificationBrand verifyData={val} brandCord={brandCord} />
        </td>
        <td className="w-[10%] p-3"></td>
      </tr>

      {isTrue &&
        allBrandData[brandCord].sop.map((val, id) => {
          return (
            <tr key={id} className="border-t bg-slate-100 border-gray-200 w-full">
              <td colSpan="5" className="">
                <div className="w-full h-full">
                  <table className="w-full">
                    <thead
                      id="rubikFont"
                      className=" text-gray-500 font-semibold"
                    >
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <React.Fragment>
                        <tr className="w-full">
                          <td colSpan="1" className="px-5 py-3 text-center w-[5%] md:w-[5%] xl:w-[4%] 2xl:w-[6%] ">
                            <div className="h-full invisible flex flex-col justify-center">
                              <IoChevronUp className="text-lg mt-1" />
                            </div>
                          </td>
                          <td colSpan="1" className="2xl:w-[5%] xl:w-[1%] lg:w-[1%] md:w-[1%] p-3 invisible ">
                            1
                          </td>
                          <td
                            id="rubikFont"
                            className="px-10 md:px-8 lg:px-8 xl:px-8 2xl:px-12 2xl:w-[575px] xl:w-[400px] lg:w-[410px] md:w-[420px] sm:w-[500px] custom-intermediate-2"
                          >
                            Shelf {id + 1}
                          </td>
                          <td className="h-full flex flex-grow p-3 xl:flex-wrap text-sm 2xl:text-base  text-center">
                            <SopVerificationShelf sopData={val} />
                          </td>
                          <td className="w-[10%] p-3"></td>
                        </tr>
                      </React.Fragment>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default function StoreListNew(props) {
  // API State
  const searchInput = props.searchInput;
  let searchReasults = props.searchResult;
  let openRows = props.openRows;
  let setOpenRows = props.setOpenRows;
  // console.log("searchResult", searchReasults)

  // console.log("Props", props)
  // const navValue = props.navValue;
  // console.log("Toggle button value",navValue);
  const [storesValue, setStoresValue] = useState(false);
  const [fullness, setFullness] = useState(false);
  const [allStore, setAllStore] = useState(false);

  const [allBrandData, setAllBrandData] = useState(false);
  const [
    // storeDelete,
    setStoreDelete,
  ] = useState(false);
  const [
    usersID,
    //  setUsersID
  ] = useState(false);
  // console.log("store id ", usersID);
  const [
    // detailStore,
    setDetailsStore,
  ] = useState(false);

  // const url = "http://216.48.181.202:9889";
  // const url = "http://localhost:8080";

  const usersIDs = localStorage.getItem("users");
  const userDataID = JSON.parse(usersIDs);

  let stores;
  if (userDataID) {
    stores = userDataID.data.stores;
  }

  const url = "http://216.48.183.210:9889";
  //Formik for edit store
  const initialValues = {
    name: "",
    id: "",
    location: "",
    number: "",
    // brands: "",
    // shelves: "",
  };

  const onSubmit = async (values) => {
    // console.log(values);
    const storeDetails = {
      name: values.name,
      id: values.id,
      number: values.number,
      location: values.location,
      // brands: values.brands,
      // shelves: values.shelves,
    };

    try {
      const data = await axios.post(
        `${url}/data/store/update/details/${usersID}`,
        storeDetails,
        {
          headers: {
            Accept: "application/json",
            Authorization: await token(),
          },
        }
      );
      // console.log(data);
      if (data) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });


  const size = 7;
  const {page, setPage} = useData();


  useEffect(() => {
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage) {
      setPage(parseInt(savedPage, 10));
    }
  }, []);

  const pageChangeHandle = (event, pageNumber) => {
    if (props.onClickFunction) {
      props.onClickFunction(pageNumber);
    }
    setPage(pageNumber);
  };

  useEffect(() => {
    async function getStores() {
      const response = {
        pages: page,
        sizes: size,
        store_id: stores,
      };

      try {
        const data = await GetallStoreTableApi(response);
        setStoresValue(data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
    // eslint-disable-next-line
  }, [page]);
  let store;
  if (searchReasults) {
    store = searchReasults;
  } else {
    store = storesValue;
  }
  let storeLength = "";
  if (store) {
    storeLength = Math.ceil(store.data.storeLength / size);
  }

  //  popup  Delete Store button

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //  Popup Edit Store button
  const [edit, setEdit] = useState(false);

  // const handleUpdateOpen = () => {
  //   setEdit(true);
  // };

  const handleUpdateClose = () => {
    setEdit(false);
  };

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");

  // Api integration for delete Store
  const deleteStore = async () => {
    try {
      // const data = await DeleteStore(storeDelete);
      // console.log("delete", data);
      // console.log("store deleted successfully ");

      handleClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
      // console.log("store not deleted ");
    }
  };

  // get one store details
  useEffect(() => {
    async function getStoreDetails() {
      try {
        const data = await GETONESTORES(usersID);
        if (data) {
          setDetailsStore(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getStoreDetails();
    // eslint-disable-next-line
  }, [usersID]);
  //
  useEffect(() => {
    async function getStores() {
      try {
        const data = await GetALLStoreAPI();

        setAllStore(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
  }, []);

  const [brandData, setBrandData] = useState(false);
  useEffect(() => {
    async function getStores() {
      try {
        const data = await BRANDCOORDINATE();
        // console.log(data.data);
        setBrandData(data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getStores();
  }, []);

  useEffect(() => {
    async function getFullness() {
      try {
        const data = await GetFullnessCaptures();
        // console.log(data.data);
        // setStore(data.data.data);
        if (data) {
          setFullness(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getFullness();
  }, []);

  if (allStore && fullness) {
    for (let count = 0; count < allStore.length; count++) {
      const filterStore = fullness.filter((store) => {
        return store._id === allStore[count]._id;
      });
      if (filterStore[0] === undefined) {
        allStore[count]["fullness"] = 0;
      } else {
        allStore[count]["fullness"] = filterStore[0].avg_shelffullness;
      }
    }
  }

  if (allStore) {
    for (let count = 0; count < allStore.length; count++) {
      try {
        const ipChange = allStore[count].location_ip;
        const split = ipChange.split(",");
        const getlat_split = split[0];
        const getlog_split = split[1];

        allStore[count]["location_ip"] = [getlat_split, getlog_split];
      } catch (error) {}
    }
  }

  //?SELECT CITY CONSTANTS
  const [city, setCity] = useState(false);

  const handleChange = (event) => {
    setCity(event.target.value);
  };
  // console.log("City", city);

  // console.log("all the store", allStore);
  // const [mapStoreID, setMapStoreID] = useState(false);
  // function useLeafletMap() {
  //   const [map, setMap] = useState(null);

  //   useEffect(() => {
  //     let map;
  //     if (!city) {
  //       map = L.map("map", {
  //         center: [19.7515, 75.7139],
  //         zoom: 6.5,
  //         zoomControl: false,
  //         attributionControl: false,
  //       });
  //     } else {
  //       try {
  //         map = L.map("map", {
  //           center: city,
  //           zoom: 11.5,
  //           zoomControl: false,
  //           attributionControl: false,
  //         });
  //       } catch (error) {}
  //     }

  //     L.tileLayer(
  //       "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
  //       {
  //         maxZoom: 18,
  //         minZoom: 5,
  //       }
  //     ).addTo(map);

  //     setMap(map);

  //     return () => {
  //       map.remove();
  //     };
  //     // eslint-disable-next-line
  //   }, [city]);

  //   return map;
  // }
  // const map = useLeafletMap();

  const [
    mapStoreID,
    // setMapStoreID
  ] = useState(null);
  // const [popup, setPopup] = useState(false);

  // const handleUpdateClosePopup = () => {
  //   setPopup(false);
  // };
  // const markerIcon = "https://p.kindpng.com/picc/s/148-1489367_map-marker-push-pin-left-yellow-icon-png.png";
  // useEffect(() => {
  //   if (!map) return;
  //   if (allStore) {
  //     try {
  //       allStore.forEach((city) => {
  //         if (city.location_ip === undefined) {
  //         } else {
  //           // eslint-disable-next-line
  //           const marker = L.marker(city.location_ip, {
  //             icon: L.icon({
  //               iconUrl: markerIcon,
  //               iconSize: [40, 41],
  //               iconAnchor: [12, 41],
  //               popupAnchor: [1, -34],
  //               tooltipAnchor: [16, -28],
  //             }),
  //           })
  //             .addTo(map)
  //             // .on("click", function (e) {
  //             //   // execute a function when the marker is clicked
  //             //   // console.log(`Marker clicked: ${city.name}`);
  //             //   setMapStoreID(city._id);
  //             //   if (city) {
  //             //     setPopup(true);
  //             //   }
  //             // })
  //             .on("mouseover", function (e) {
  //               this.bindTooltip(
  //                 `<b>${city.name}</b><br>Fullness: ${city.fullness.toFixed(
  //                   1
  //                 )} `
  //               ).openTooltip();
  //             })
  //             .on("mouseout", function (e) {
  //               this.unbindTooltip();
  //             });

  //           // .on('click', () => )
  //         }
  //       });
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   }
  // }, [map, allStore, navValue]);

  // AIzaSyBya328-9Vff7TkfgNC9pYBKHeiS4LJbQg

  // console.log("MapStoreID", mapStoreID);
  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);


  const toggleRow = (index) => {
    if (openRows.includes(index)) {
      setOpenRows(openRows.filter((i) => i !== index));
    } else {
      setOpenRows([index]);
    }

    if (store) {
      setAllBrandData(store.data.stores[index].SOP.allSOP);
    }
  };

  // const handleSearch = () => {
  //   // console.log(searchInput);
  //   return store.data.stores
  // };
  console.log("Store data", store)

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#a3a4a0',
          },
        },
      },
    },
  });

  return (
    <>
      <div className="flex flex-col pb-5 xl:pb-0 xl:flex-row xl:space-x-3 w-full">
        {/* {navValue === "map" ? (
          <div className="w-full h-[96svh] xl:h-[80svh] mt-4 xl:mt-6 flex flex-col space-y-2">
            <div className="flex flex-col-reverse xl:flex-col justify-between">
              <div className="w-full flex justify-end">
                <div className="w-full md:w-48">
                  <FormControl
                    fullWidth
                    // sx={{ mt: 0, maxWidth: 209 }}
                    size="small"
                    color="success"
                  >
                    <InputLabel id="demo-select-small">Select City</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      // value={city}
                      value=""
                      label="Select City"
                      onChange={handleChange}
                    >
                      {brandData === {} || !brandData ? (
                        <>
                          <MenuItem value={[19.076, 72.8777]}>Mumbai</MenuItem>
                          <MenuItem value={[19.033, 73.0297]}>
                            Navi Mumbai
                          </MenuItem>
                          <MenuItem value={[18.5204, 73.8567]}>Pune</MenuItem>
                          <MenuItem className="">
                            <Skeleton
                              className="w-full h-24"
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </MenuItem>
                          <MenuItem className="">
                            <Skeleton
                              className="w-full h-24"
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </MenuItem>
                          <MenuItem className="">
                            <Skeleton
                              className="w-full h-24"
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </MenuItem>
                        </>
                      ) : (
                        brandData.city.map((val, id) => (
                          <MenuItem value={val.coord}>{val.city}</MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div
              key={navValue}
              id="map"
              className="flex-grow rounded-md shadow-lg bg-white"
            ></div>
          </div>
        ) : ( */}
        <div className="container mt-5 space-y-4 overflow-x-auto m-auto overflow-y-hidden xl:overflow-hidden">
          <table className="w-[1100px] xl:w-full sm:bg-white rounded-lg md:overflow-hidden overflow-x-auto sm:shadow-lg">
            {store && store.data.stores.length === 0 && searchInput.length > 0 ?(<div> </div>):
              <thead className="  text-white whitespace-nowrap md:whitespace-normal">
                <tr className="bg-emerald-500 rounded-lg  mb-2">
                  <th className="text-left w-[4%] p-3"></th>
                  <th className="px-3 py-3 text-center w-[5%] border-gray-300 tracking-wider">
                    <div className=" font-semibold text-base">ID</div>
                  </th>
                  <th className="text-left w-[35.7%] ">
                    <div className="px-3 py-3 font-semibold text-base">Name</div>
                  </th>
                  <th className="text-left">
                    <div className="px-3 py-3 font-semibold text-base">
                      <span>Shelf Verification</span>
                      <ThemeProvider theme={theme}>
                        <Tooltip
                          title={
                            <div style={{ display: "flex",flexDirection:'column' }}>
                              <div style={{ display: "flex", alignItems:'center', justifyContent:'flex-start',marginBottom:'2px' }}>
                                <div
                                  style={{
                                    backgroundColor: "red",
                                    height: "10px",
                                    width: "10px",
                                    marginRight: "5px",
                                  }}
                                ></div>
                                <div>No Data</div>
                              </div>
                              <div style={{ display: "flex", alignItems:'center', justifyContent:'flex-start',marginBottom:'2px' }}>
                                <div
                                  style={{
                                    backgroundColor: "#F59E0B",
                                    height: "10px",
                                    width: "10px",
                                    marginRight: "5px",
                                  }}
                                ></div>
                                <div>Pending Shelves</div>
                              </div>
                              <div style={{ display: "flex", alignItems:'center', justifyContent:'flex-start',marginBottom:'2px' }}>
                                <div
                                  style={{
                                    backgroundColor: "#22C55E",
                                    height: "10px",
                                    width: "10px",
                                    marginRight: "5px",
                                  }}
                                ></div>
                                <div>Verified Shelves</div>
                              </div>
                              <div style={{ display: "flex", alignItems:'center', justifyContent:'flex-start',marginBottom:'2px' }}>
                                <div
                                  style={{
                                    backgroundColor: "#3b82f6",
                                    height: "10px",
                                    width: "10px",
                                    marginRight: "5px",
                                  }}
                                ></div>
                                <div>Re-Capture</div>
                              </div>
                            </div>
                          }
                          arrow
                        >
                          <button
                            style={{
                              textAlign: "center",
                              lineHeight: "12px",
                              backgroundColor: "white",
                            }}
                            className="w-[15px] h-[15px] ml-[8px] rounded-full border-none cursor-pointer text-green-500 text-[15px] mt-[4px]"
                          >
                            i
                          </button>
                        </Tooltip>
                      </ThemeProvider>
                    </div>
                  </th>

                  <th className="text-left">
                    <div className="px-3 py-3 font-semibold text-base text-center">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
            }
            {store ? (
              store.data.stores.map((val, id) => {
                return (
                  <tbody
                    key={id}
                    className="text-md 2xl:text-lg font-medium divide-y hover:bg-gray-200 divide-grey-light whitespace-nowrap	md:whitespace-normal"
                  >
                    <React.Fragment key={id}>
                      <tr className="border-b border-gray-300">
                        <td className="p-3 2xl:p-5 w-[4%] text-center">
                          <button
                            className="focus:outline-none"
                            onClick={() => toggleRow(id)}
                          >
                            {openRows.includes(id) ? (
                              <div className="h-full flex flex-col justify-center">
                                <IoChevronUp className="text-lg mt-1" />
                              </div>
                            ) : (
                              <div className="h-full flex flex-col justify-center">
                                <IoChevronDown className="text-lg mt-1" />
                              </div>
                            )}
                          </button>
                        </td>
                        <td className="p-3 text-center">{val.store_id}</td>
                        <td className="p-3 text-left">{val.name}</td>

                        <td className="h-full flex p-3 xl:flex-wrap text-sm 2xl:text-base  text-center">
                          {/* <SopVerification sopVerify={val.SOP} /> */}
                          {/* <Weightage sopVerify={val.SOP} page={page} setPage={setPage} pageChangeHandle={pageChangeHandle} /> */}
                          <WeightageAnnotation
                            sopVerify={val.SOP}
                            page={page}
                            setPage={setPage}
                            pageChangeHandle={pageChangeHandle}
                          />
                        </td>

                        <td className="px-3 py-3 w-[10%] text-2xl text-center">
                          <div className="flex justify-center space-x-3 xl:space-x-8 2xl:space-x-12">
                            <div className="">
                              {/*EDIT VERSION FOR MOBILE VERSION*/}
                              {/* <MobTabEditStoreNew
                                  mapStoreID={mapStoreID}
                                  setuserID={val.id}
                                /> */}
                              {/* EDIT VERSION FOR DESKTOP VERSION */}
                              {/* <LapDeskEditStoreNew
                                  mapStoreID={mapStoreID}
                                  setuserID={val._id}
                                /> */}
                              <EditStore
                                mapStoreID={mapStoreID}
                                setuserID={val._id}
                              />
                            </div>

                            {userData.data.user_role === "Super Admin" ? (
                              <>
                                <MdDelete
                                  onClick={() => {
                                    handleClickOpen();
                                    setStoreDelete(val.id);
                                  }}
                                  className="text-rose-600 hover:cursor-pointer"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </tr>
                      {openRows.includes(id) && (
                        <tr className="border-t border-b bg-slate-100 border-gray-200">
                          <td colSpan="5" className="">
                            <div className="w-full h-full py-2">
                              <table className="xl:w-full">
                                <thead
                                  id="rubikFont"
                                  className=" text-gray-500 font-semibold whitespace-nowrap md:whitespace-normal"
                                >
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>

                                  <React.Fragment>
                                    <div>
                                      {allBrandData.map((val, id) => {
                                        return (
                                          <StoreItem key={id} val={val} id={id} allBrandData={allBrandData} />
                                        );
                                      })}

                                    </div>
                                  </React.Fragment>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  </tbody>
                );
              })
            ) : searchInput.length===0 ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="w-full">
                    <div className="w-full flex justify-center">
                      <NewLoader />
                    </div>
                  </td>
                </tr>
              </tbody>
            ):<div></div>}
          </table>

          {store && store.data.stores.length === 0 && searchInput.length > 0 && (
              <div className="flex items-center justify-center mt-10">
                <img
                  src={noDataFoundImage}
                  alt="No Data Found"
                  style={{ width: "35%", height: "35%" }}
                />
              </div>
            )}
          {store && store.data.stores.length === 0 && searchInput.length > 0 ?(<div> </div>):

            <div className="w-full flex justify-center">
              <Pagination
                className="min-w-max m-auto text-center"
                onChange={(event, pageNumber) =>
                  pageChangeHandle(event, pageNumber)
                }
                count={storeLength}
                color="success"
                page={page}
              />
            </div>}
        </div>
        {/* )} */}
      </div>

      {/* <Dialog
        open={popup}
        onClose={handleUpdateClosePopup}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        // PaperProps={{
        //   sx: {
        //     width: "85%",
        //     maxWidth: "1300px",
        //     height: "100%",
        //     maxHeight: "800px",
        //     background: "transparent",
        //     boxShadow: "none",
        //   },
        // }}
      >
        <DialogContent className="overflow-y-auto scrollbar">
          <EditStoreMap
            popup={popup}
            handleUpdateClosePopup={handleUpdateClosePopup}
            setuserID={mapStoreID}
          />
        </DialogContent>
      </Dialog> */}

      <Dialog open={open} onClose={handleClose}>
        <DialogContent className="flex flex-col space-y-4 m-auto">
          <div className="m-auto text-7xl">
            <AiOutlineExclamationCircle />
          </div>
          <div className="text-xl m-auto font-semibold">Are you sure?</div>
          <Box sx={{ "& button": { m: 1 } }}>
            <div>
              <Button
                onClick={deleteStore}
                variant="contained"
                color="error"
                size="medium"
              >
                Yes, delete it!
              </Button>
              <Button
                onClick={handleClose}
                color="success"
                variant="contained"
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={edit}
        onClose={handleUpdateClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent className="flex flex-col space-y-4">
          <div className="w-full text-xl text-gray-600 font-bold">
            Edit Store
          </div>
          <div className="text-4xl m-auto">
            <BiStore />
          </div>
          <form onSubmit={formik.handleSubmit} action="">
            <div className="flex flex-col space-y-9">
              <TextField
                fullWidth
                label="Store Name"
                name="name"
                // defaultValue={detailStore.data.name}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                fullWidth
                label="Location"
                name="location"
                // defaultValue={detailStore.data.location}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                fullWidth
                label="Store Number"
                name="number"
                // defaultValue={detailStore.data.number}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                fullWidth
                label="Brand"
                name="brands"
                // defaultValue={detailStore.data.brands}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                fullWidth
                label="Shelf"
                name="shelves"
                // defaultValue={detailStore.data.shelves}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className=" flex justify-end">
                <div className="flex flex-row space-x-2">
                  <button
                    onClick={handleUpdateClose}
                    className="bg-rose-600 rounded-md shadow-md text-sm font-bold active:bg-rose-500 hover:bg-rose-800 text-white w-20 h-8"
                  >
                    Cancel
                  </button>
                  <div>
                    <button
                      type="submit"
                      className="bg-emerald-600 rounded-md shadow-md text-sm font-bold active:bg-emerald-500 hover:bg-emerald-800 text-white w-20 h-8"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}