import React, { useState, useEffect } from "react";
import AddStore from "./newStore";
// import Filter from './filter';
// import StoreList from "./storelist";
import StoreListNew from "./storelistNew";
import SideNav from "../others/sidenav";
// POPUP Add Store Button
import StoreHeader from "./storeHeader";
import BulkAddStoreButton from "./bulkAddStoreButton";
import {
  GetallStoreTableApi,
  GetAllStoreVerifiedReport,
  GetAllStorePendingReport,
  GetAllStoreRejectedReport,
  GetAllStoreReport,
} from "../Authservice/api";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import Button from "@mui/material/Button";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { FiSearch } from "react-icons/fi";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { useData } from "../../context/chartContext";
import { FiDownload } from "react-icons/fi";

export default function Store() {
  const [soon] = useState(true);
  const [searchStoreInput, setSearchStoreInput] = useState("");
  const [searchValue, setSearchValue] = useState(false);
  const [openRows, setOpenRows] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("All");
  //  popup  Add store button

  const users = localStorage.getItem("users");
  const userData = JSON.parse(users);

  //?FOR TOOGLE BUTTON START

  // const [alignment, setAlignment] = useState("map");

  // const handleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };
  // const handleclickMap = () => {
  //   window.location.reload(true);
  // };
  const size = 7;
  const { page, setPage } = useData();
  console.log("pagecontext", page);

  const usersIDs = localStorage.getItem("users");
  const userDataID = JSON.parse(usersIDs);
  let stores;
  if (userDataID) {
    stores = userDataID.data.stores;
  }

  useEffect(() => {
    const storedSearchResult = sessionStorage.getItem("searchResult");

    if (storedSearchResult) {
      setSearchStoreInput(storedSearchResult);
      setPage(1);
    }
  }, []);

  const handleSearch = async (pageNumber) => {
    let no_page;
    // console.log("datsjsjjsjsja", pageNumber)
    if (pageNumber) {
      no_page = pageNumber;
    } else {
      no_page = page;
    }

    // console.log("no_page", no_page);
    const response = {
      pages: no_page,
      sizes: size,
      search: searchStoreInput,
      store_idss: stores,
      status: selectedFilter,
    };

    try {
      const data = await GetallStoreTableApi(response);

      setSearchValue(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchStoreInput, selectedFilter, page]);

  useEffect(() => {
    if (searchStoreInput) {
      setPage(1);
      sessionStorage.removeItem("currentPage");
      sessionStorage.setItem("searchResult", searchStoreInput);
    } else {
      sessionStorage.removeItem("searchResult");
    }
  }, [searchStoreInput]);

  //!FOR TOOGLE BUTTON END

  //FOR verification filter

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  };

  const downloadCSV = async () => {
    try {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear().toString().substring(2);
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      const timestamp = `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
      if (selectedFilter === "verify") {
        const response = await GetAllStoreVerifiedReport();

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }
        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_store_${selectedFilter}_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else if (selectedFilter === "pending") {
        const response = await GetAllStorePendingReport();

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }
        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_store_${selectedFilter}_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else if (selectedFilter === "rejected") {
        const response = await GetAllStoreRejectedReport();

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }

        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_store_${selectedFilter}_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const response = await GetAllStoreReport();

        if (response && response.data.length === 0) {
          alert("No data to download.");
          return;
        }

        const csvData = convertToCSV(response.data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `disha_store_All_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while downloading store data.");
    }
  };
  return (
    <>
      {soon === false ? (
        <div className="w-full h-screen flex  bg-gray-100 ">
          <div className="md:contents hidden">
            <SideNav />
          </div>
          <div className="w-2/12 h-screen hidden md:block"></div>
          <div div className="w-5/6 h-full grid ">
            <div className="h-full"></div>
            <div className="w-full text-center font-extrabold text-2xl">
              Coming Soon
            </div>

            <div className="h-full"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex bg-gray-100 overflow-y-auto md:scrollbar">
          <div className="md:contents hidden">
            <SideNav />
          </div>
          <div className="w-2/12 h-screen hidden md:block"></div>

          <div className="w-full lg:w-5/6 h-full">
            <div className="w-full bg-white">
              <StoreHeader />
            </div>
            <div className="w-full h-16 hidden md:block"></div>
            <div className="w-full md:px-10 px-4 py-1 bg-gray-100">
              <div className="relative flex flex-col space-y-3 xl:space-y-6 xl:flex-row xl:justify-between w-full">
                {/* <div className="h-full xl:mt-6">
                  <ToggleButtonGroup
                    fullWidth
                    sx={{ minWidth: 300 }}
                    className="h-10"
                    // color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="map"
                      onClick={handleclickMap}
                      sx={{ borderColor: "#10b981", color: "#10b981" }}
                    >
                      Map View
                    </ToggleButton>
                    <ToggleButton
                      value="table"
                      sx={{ borderColor: "#10b981", color: "#10b981" }}
                    >
                      Tabular View
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div> */}
                {/* {alignment !== "map" && ( */}
                <div className="flex-grow xl:self-end xl:pr-3 flex flex-row relative">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Search Store By ID "
                    size="small"
                    color="success"
                    value={searchStoreInput}
                    className="flex-grow"
                    onChange={(e) => {
                      setSearchStoreInput(e.target.value);
                      setOpenRows([]);
                    }}
                  ></TextField>
                  <div
                    onClick={(e) => handleSearch()}
                    className="w-10 h-full flex justify-center hover:cursor-pointer absolute right-2 sm:right-4"
                  >
                    <FiSearch className="self-center text-3xl text-emerald-500 hover:text-emerald-400 active:text-emerald-700" />
                  </div>
                </div>
                {/* )} */}

                <div>
                  <FormControl variant="outlined" size="small" color="success">
                    <InputLabel
                      id="demo-select-small-label"
                      color="success"
                      sx={{ marginLeft: "8px" }}
                    >
                      Verification Filter
                    </InputLabel>
                    <Select
                      labelId="new-select-label"
                      id="new-select"
                      label="Verification Filter"
                      color="success"
                      value={selectedFilter}
                      onChange={handleFilterChange}
                      className=" ml-2 mr-5 "
                      style={{ width: "200px" }}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"verify"}>Verified</MenuItem>
                      <MenuItem value={"pending"}>
                        Pending Verification
                      </MenuItem>
                      <MenuItem value={"rejected"}>Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="flex space-x-3 xl:mt-6">
                  <AddStore />
                  {userData.data.user_role === "Super Admin" ||
                  userData.data.user_role === "NHQ" ? (
                    <BulkAddStoreButton />
                  ) : (
                    <></>
                  )}

                  <Button
                    className="h-10"
                    id="rubikFont"
                    variant="contained"
                    sx={{
                      backgroundColor: "#10b981",
                    }}
                    startIcon={<FiDownload />}
                    onClick={downloadCSV}
                    // className="font-bold pd-4 text-white w-24 rounded-md bg-green-600 hover:bg-emerald-800 active:bg-emerald-500  h-8 mt-2 "
                  >
                    Report
                  </Button>
                </div>
              </div>

              <StoreListNew
                // navValue={alignment}
                searchInput={searchStoreInput}
                searchResult={searchValue}
                onClickFunction={handleSearch}
                openRows={openRows}
                setOpenRows={setOpenRows}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
