import React from "react";
import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

//AiFillCaretDown

export default function StatusDropdown({ selected, setSelected }) {
  const [isActive, setIsActive] = useState(false);
  // const options = ["progress", "completed", "all"];
  const optionss = [
    { name: "In Progress", value: "progress" },
    { name: "Completed", value: "completed" },
    { name: "All", value: "all" },
  ];
  return (
    <>
      <div id="status_dropdown" className="border-r-2 border-gray-300">
        <div
          id="status_dropdown-btn"
          className="flex flex-row justify-between"
          onClick={(e) => setIsActive(!isActive)}
        >
          Status
          <AiFillCaretDown className="self-center" />
        </div>
        {isActive && (
          <div id="status_dropdown-content">
            {optionss.map((option) => (
              <div
                onClick={(e) => {
                  setSelected(option.value);
                  setIsActive(false);
                }}
                id="status_dropdown-item"
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
